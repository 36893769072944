import { useState } from "react";
import { postConnection } from "../../../../helpers/connections";

export const AddConnection = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [userName, setUserName] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setResponse("");
    setLoading(true);

    try {
      const response = await postConnection(userName, "");
      setResponse(JSON.stringify(response));
    } catch (e) {
      setError(JSON.stringify(e));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  return (
    <div className="form addition-form m-2">
      <h1>Add new connection</h1>
      <form className="p-2" onSubmit={handleSubmit}>
        <div className="form-group row column my-1">
          <label htmlFor="userName">User Name</label>
          <input
            type="text"
            name="userName"
            id="userName"
            required
            value={userName}
            onChange={handleChange}
          />
        </div>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <button type="submit" className="btn-xsmall light">
            Add Connection
          </button>
        )}
        {error && <h3>Error: {error}</h3>}
      </form>
      {response && <h3>Response: {response}</h3>}
    </div>
  );
};
