import moment from "moment";
import React, { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { RiskValue } from "../../../../interfaces/fallRisk/fallRisk.interface";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { env } from "../../../../env";
import { AutoFakeAI } from "../AutoFakeAI/AutoFakeAI";
import { SelectPatient } from "../../atoms/SelectPatient/SelectPatient";

let socket: Socket;
const initiateAISocket = async (cb: (obj: object) => void) => {
  const url = env.REACT_APP_AI_URL;

  if (!socket && url) {
    socket =
      process.env.NODE_ENV === "production"
        ? io(url, {
            path: "/ai-socket/",
            rememberUpgrade: true,
          })
        : io(url, { path: "/ai-socket/" });
    console.log(`Connecting socket...`);
    socket.on("connect", function () {
      console.log(`Connected to AI socket: ${url} - ${socket.id}`);
    });
    socket.on("fallRisk", (obj) => {
      console.log(`New Fall Risk ${obj}`);
      cb(obj);
    });
    return socket;
  }
};

export const sendFakeAI = ({
  id,
  fallRisk,
  time,
  fallRiskDebug,
}: {
  id: string;
  fallRisk: RiskValue;
  time: string;
  fallRiskDebug: string;
}) => {
  if (!socket) return false;

  socket.emit("fakeAI", { id, fallRisk, time, fallRiskDebug });
  console.log(`Fake AI sent:${id} ${fallRisk}`);
};

interface Props {
  patients: Patient[];
  patientsLoading: boolean;
}

export const FakeAI: React.FC<Props> = ({ patients, patientsLoading }) => {
  const [id, setId] = useState("");
  const [riskValue, setRiskValue] = useState(0);
  const [lastFallRiskEvent, setLastFallRiskEvent] = useState<string | null>(
    null
  );

  const riskList = [0, 1, 2];

  useEffect(() => {
    initiateAISocket((obj: any) => {
      try {
        setLastFallRiskEvent(JSON.stringify(obj));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);

  const handleSubmit = (e?: any) => {
    e?.preventDefault();
    if (id && !isNaN(riskValue)) {
      sendFakeAI({
        id,
        fallRisk: riskValue as RiskValue,
        time: moment().utc().format(),
        fallRiskDebug: "Sending fake AI from commands",
      });
    }
  };

  const handleChange = (e: { target: { value: string } }) => {
    setId(e.target.value);
  };

  const handleRiskChange = (e: { target: { value: string } }) => {
    setRiskValue(Number(e.target.value));
  };

  return (
    <div className="m-2">
      <h2>Send fake AI Data</h2>
      <form onSubmit={handleSubmit} aria-label="fake-ai-form" className="p-2">
        <div className="form-group row column my-1">
          <label htmlFor="id-patient">Choose patient</label>
          {!patientsLoading && (
            <SelectPatient patients={patients} handleChange={handleChange} />
          )}
          {patientsLoading && (
            <div className="loaderDiv">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="risk">Choose risk</label>
          <select
            value={riskValue}
            onChange={handleRiskChange}
            aria-label="fake-ai-value"
          >
            {riskList.map((currRisk) => (
              <option value={currRisk} key={currRisk}>
                {currRisk}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          aria-label="submit-btn"
          className="btn-xsmall light"
        >
          Send fake AI
        </button>
      </form>
      <div>
        {lastFallRiskEvent ? (
          <p>{JSON.stringify(lastFallRiskEvent)}</p>
        ) : (
          <p>No hay eventos recientes</p>
        )}
      </div>
      <hr />
      <AutoFakeAI
        id={id}
        loading={patientsLoading}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleRiskChange={handleRiskChange}
        riskList={riskList}
        riskValue={riskValue}
        patients={patients}
      />
    </div>
  );
};
