import { Contacts } from "../interfaces/contacts/contacts.interface";

const sortedList = ["PCT", "Nurse", "ChargeNurse", "UnitDirector"];

export const sortContacts = (contacts: Contacts[]) => {
  const list = contacts.sort(
    (conA, conB) =>
      sortedList.indexOf(conA.type) - sortedList.indexOf(conB.type)
  );

  return list
};
