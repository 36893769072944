import React, { ReactNode, useMemo } from "react";
import { ConnectionInfo } from "../../../../interfaces/connections/connections.interface";
import { ConnectionActivityEnum } from "../../../../enums/connectionsEnum";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import { getMinutesFromBrowserStatus } from "../../../../utils/getMinutesFromBrowserStatus";

interface Props {
  connection: ConnectionInfo;
  calculateTimeFrom:
    | ConnectionActivityEnum.BrowserActive
    | ConnectionActivityEnum.BrowserInactive;
  Icon?: ReactNode;
  label: string;
}

export const BrowserStatusCard: React.FC<Props> = ({
  connection,
  calculateTimeFrom,
  Icon = <InfoIcon />,
  label,
}) => {
  const mins = useMemo(
    () => getMinutesFromBrowserStatus(connection.activities, calculateTimeFrom),
    [connection.activities]
  );
  return (
    <div
      className="card-black"
      style={{
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        justifyContent: "center",
      }}
    >
      {Icon} <p className="highlight">{mins}</p> <p>{label}</p>
    </div>
  );
};
