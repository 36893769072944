import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  AssignForm,
  ContactExpiration,
  ContactsPill,
  ContactType,
} from "../../interfaces/contacts/contacts.interface";

export interface AssignedContactState {
  contactsByPatient: {
    [room: string]: ContactsPill;
  };
}
const initialAssignForm: AssignForm = {
  status: false,
  patientId: "",
  formMode: "assign",
};

const initialState: AssignedContactState = {
  contactsByPatient: {},
};

export const assignedContactSlice = createSlice({
  name: "assignedContact",
  initialState,
  reducers: {
    setContacts: (
      state: AssignedContactState,
      action: PayloadAction<ContactsPill>
    ) => {
      let contactsToExpire =
        state.contactsByPatient[action.payload.room]?.contactsToExpire || [];

      state.contactsByPatient[action.payload.room] = {
        ...action.payload,
        contactsToExpire,
      };
    },
    removeContact: (
      state: AssignedContactState,
      action: PayloadAction<{ room: string }>
    ) => {
      state.contactsByPatient[action.payload.room] = {
        ...state.contactsByPatient[action.payload.room],
        assigned: false,
      };
    },
    setContactExpiration: (
      state: AssignedContactState,
      action: PayloadAction<ContactExpiration>
    ) => {
      const { room, contactId, endDateTime, type } = action.payload;
      const filteredContacts =
        state.contactsByPatient[room]?.contactsToExpire?.filter(
          (contact) => contact.id !== contactId
        ) || [];

      if (state.contactsByPatient[room]?.contactsToExpire) {
        state.contactsByPatient[room]["contactsToExpire"] = [
          ...(filteredContacts as {
            id: string;
            endDateTime: string;
            type: ContactType;
          }[]),
          {
            id: contactId,
            endDateTime,
            type,
          },
        ];
      } else {
        state.contactsByPatient[room] = {
          ...state.contactsByPatient[room],
          contactsToExpire: [
            {
              id: contactId,
              endDateTime,
              type,
            },
          ],
        };
      }
    },
    removeExpiredContact: (
      state: AssignedContactState,
      action: PayloadAction<{ room: string; contactId: string }>
    ) => {
      const { room, contactId } = action.payload;
      if (
        !state.contactsByPatient[room]?.contactsToExpire?.some(
          (contact) => contact.id === contactId
        )
      ) {
        return;
      }

      const filteredContacts = state.contactsByPatient[
        room
      ]?.contactsToExpire?.filter((contact) => contact.id !== contactId);

      if (state.contactsByPatient[room]?.contactsToExpire) {
        state.contactsByPatient[room].contactsToExpire = filteredContacts;
      }
    },
    removeAssignedContact: (
      state: AssignedContactState,
      action: PayloadAction<{ room: string }>
    ) => {
      const { room } = action.payload;

      delete state.contactsByPatient[room];
    },
  },
});

export default assignedContactSlice.reducer;

export const {
  setContacts,
  removeContact,
  setContactExpiration,
  removeExpiredContact,
  removeAssignedContact,
} = assignedContactSlice.actions;
