import { useState } from "react";
import { ConnectionInfo } from "../../../../interfaces/connections/connections.interface";
import { deleteConnection } from "../../../../helpers/connections";

interface Props {
  connectionsList: ConnectionInfo[];
}

export const RemoveConnections: React.FC<Props> = ({ connectionsList }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [connectionId, setConnectionId] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (connectionId) {
      setLoading(true);
      try {
        const response = await deleteConnection(connectionId);
        setResponse(JSON.stringify(response));
      } catch (e) {
        setError(JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setError("");
    setResponse("");
    setConnectionId(e.target.value);
  };

  return (
    <div className="form addition-form m-2">
      <h2>Remove connection (waits 30seconds)</h2>
      <form action="" className="p-2" onSubmit={handleSubmit}>
        <div className="form-group row column my-1">
          <label htmlFor="connection">Select Connection</label>
          <select
            name="connection"
            id="connection"
            value={connectionId}
            onChange={handleChange}
            required
          >
            <option value="">--</option>
            {connectionsList.map((connection) => (
              <option
                value={connection.id}
                key={`option-remove-${connection.id}`}
              >
                {connection.userName}
              </option>
            ))}
          </select>
        </div>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <button type="submit" className="btn-xsmall light">
            Remove Connection
          </button>
        )}
        {error && <h3>Error: {error}</h3>}
      </form>
      {response && <h3>Response: {response}</h3>}
    </div>
  );
};
