import React, { ReactNode } from "react";

interface Props {
  patientsLength: number;
  children: ReactNode;
}

export const SidebarWrapper: React.FC<Props> = ({
  children,
  patientsLength,
}) => {
  return (
    <div className={`sidebar row-100vh ${patientsLength === 0 ? "void" : ""}`}>
      {children}
    </div>
  );
};
