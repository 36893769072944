import moment from "moment";
import { calculateDateTime } from "./calcDateTime";

export const nextEndShift = (
  utcDateTime: string = moment().utc().format(),
  virtualUTCDateTime: string = ""
) => {
  let currDateTZ = moment
    .utc(virtualUTCDateTime || utcDateTime)
    .local()
    .format();

  let next7PM = calculateDateTime("07:00", "PM", virtualUTCDateTime);

  let next7AM = calculateDateTime("07:00", "AM", virtualUTCDateTime);

  let nightDiff = moment(next7PM).diff(currDateTZ, "minutes");
  let morningDiff = moment(next7AM).diff(currDateTZ, "minutes");

  if (nightDiff > morningDiff) {
    return moment(next7AM).utc().format();
  }

  return moment(next7PM).utc().format();
};
