import { Patient } from "../../../../interfaces/patients/patients.interface";
import { truncateLongStr } from "../../../../utils/hyphenLongStrings";

interface Props {
  patients: Patient[];
  handleChange: (e: any) => void;
}

export const SelectPatient: React.FC<Props> = ({ patients, handleChange }) => {
  if (!patients.length) {
    return (
      <select>
        <option>No patients added</option>
      </select>
    );
  }

  return (
    <select
      onChange={handleChange}
      onInput={handleChange}
      name="patientId"
      required
    >
      <option value="">--</option>
      {patients.map((currPatient, index) => {
        return (
          <option value={currPatient.id} key={currPatient.id}>
            {truncateLongStr(
              `${currPatient.firstName} ${currPatient.lastName}`
            )}{" "}
            ROOM {currPatient.roomNumber}
          </option>
        );
      })}
    </select>
  );
};
