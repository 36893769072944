export enum ESitterEventsEnum {
  ContactCreatedEvent,
  ContactUpdatedEvent,
  PatientAddedEvent,
  NoteAddedEvent,
  PatientUpdatedEvent,
  ActivityAddedEvent,
  PatientRemovedEvent,
  FallRiskDeclinedEvent,
  AdmissionAddedEvent,
  EscalationPathStartedEvent,
  EscalationPathCompletedEvent,
  AdmissionUpdatedEvent,
  ContactExpiredEvent,
  ContactRemovedEvent,
  ContactAboutToExpireEvent,
  AnalysisStartedEvent,
  AnalysisStoppedEvent,
  FallRiskUpdatedEvent,
  FallRiskConfirmedEvent,
  FallRiskReceivedEvent,
  fallRisk,
  LoadBalanceCompletedEvent,
  BreakStartedEvent,
  BreakEndedEvent,
  ESitterConnected,
  ESitterDisconnected,
  ESitterSocketError,
  ConnectionActivityAddedEvent,
}
