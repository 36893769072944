import React from "react";
import { Activity } from "../../../../interfaces/activity/activity.interface";
import { parseDate } from "../../../../utils/parseDate";
import { parseJSON } from "../../../../utils/parseJSON";
import {
  getTimeBetweenTwoDates,
} from "../../../../utils/timeOperations";

interface Props {
  call: Activity;
  closeDateTime?: string;
}
export const CallInfo: React.FC<Props> = ({ call, closeDateTime }) => {
  const { day, hour } = parseDate(call.dateTime);
  const passedTime =
    closeDateTime && getTimeBetweenTwoDates(call.dateTime, closeDateTime);

  const activityParsed = call.activity && parseJSON(call.activity);
  return (
    <div className="call-info" aria-label="call-info">
      <div className="title">
        {day} <span className="hour">{hour}</span>
        {activityParsed.reason?.length > 0 &&
          activityParsed.reason.map((currReason: string, index: number) => {
            return (
              <p className="reason" key={index} aria-label="reason">
                I: {currReason}
              </p>
            );
          })}
        {activityParsed.outcome?.length > 0 &&
          activityParsed.outcome.map((currOutcome: string, index: number) => {
            return (
              <p className="reason" key={index} aria-label="outcome">
                O: {currOutcome}
              </p>
            );
          })}
        {passedTime && (
          <p className="reason" aria-label="passedTime">
            {`Staff arrived in ${
              passedTime.minutes ? `${passedTime.minutes} minutes ` : ""
            } ${passedTime.seconds} seconds`}
          </p>
        )}
      </div>
    </div>
  );
};
