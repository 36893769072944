import React, { useEffect, useState } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { SiderbarTitle } from "../../atoms/SidebarTitle/SidebarTitle";
import { PatientDetailed } from "../../molecules/PatientDetailed/PatientDetailed";
import { PatientMenuContainer } from "../../molecules/PatientMenuContainer/PatientMenuContainer";
import { RemovedPatients } from "../../molecules/RemovedPatients/RemovedPatients";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { SidebarWrapper } from "../../atoms/SidebarWrapper/SidebarWrapper";
import { getDeletedPatients } from "../../../../helpers/patients";
import logger from "../../../../logger/logger";

interface Props {
  patients: Patient[];
  selectedPatientId?: string;
}

export const Sidebar: React.FC<Props> = ({ patients, selectedPatientId }) => {
  const [modal, setModal] = useState(false);
  const [chosenPatient, setChosenPatient] = useState<Patient | undefined>(
    undefined
  );
  const [removedPatients, setRemovedPatients] = useState<Patient[]>([]);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);

  const fetchRemovedPatients = async () => {
    try {
      setloading(true);
      const response = await getDeletedPatients();
      setRemovedPatients(response);
    } catch (e) {
      logger.error("Error fetching removed patients", e);
      setError(true);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchRemovedPatients();
  }, [patients]);

  useEffect(() => {
    setChosenPatient(patients.find((pat) => pat.id === selectedPatientId));
  }, [selectedPatientId, patients]);

  useEffect(() => {
    if (chosenPatient) {
      setModal(false);
    }
  }, [chosenPatient]);

  useEffect(() => {
    if (modal) {
      fetchRemovedPatients();
    }
  }, [modal]);

  if (chosenPatient) {
    return (
      <SidebarWrapper patientsLength={patients.length}>
        <PatientDetailed patient={chosenPatient} />
      </SidebarWrapper>
    );
  }

  return (
    <SidebarWrapper patientsLength={patients.length}>
      <SiderbarTitle />
      <PatientMenuContainer
        patients={patients}
        overlay={modal}
        onClick={() => setModal(false)}
      />
      <RemovedPatients
        onClick={() => setModal(!modal)}
        isModalOpen={modal}
        removedPatients={removedPatients}
        removedPatientsLoading={loading}
        error={error}
      />
    </SidebarWrapper>
  );
};
