import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import logger from "../../logger/logger";
import {
  ConnectionActivity,
  ConnectionInfo,
  WorkloadInfo,
} from "../../interfaces/connections/connections.interface";
import { LoadingEnum } from "../../enums/global";
import {
  getConnections,
  getWorkloadByConnection,
} from "../../helpers/connections";
import {
  getActivePeople,
  getContactsByLocation,
  getDeletedPatients,
  getLocations,
} from "../../helpers/patients";
import {
  ActivePeopleByHospital,
  ContactsByLocation,
  LocationInfo,
  Patient,
} from "../../interfaces/patients/patients.interface";
import { Issue } from "../../interfaces/issues/issues.interface";
import { IssueEnum } from "../../enums/issues";

export const getConnectionsList = createAsyncThunk(
  "connections/get",
  async (includesInactive: boolean = false) => {
    const connectionsList: ConnectionInfo[] = await getConnections(
      includesInactive
    );
    logger.log(`Connection List length ${connectionsList.length}`);
    return connectionsList;
  }
);

export const getLocationList = createAsyncThunk("locations/get", async () => {
  const locationList: LocationInfo[] = await getLocations();
  logger.log(`Location list length ${locationList.length}`);
  return locationList;
});

export const getActivePeopleList = createAsyncThunk(
  "activePeople/get",
  async () => {
    const activePeopleList: ActivePeopleByHospital[] = await getActivePeople();
    logger.log(`Active people list length ${activePeopleList.length}`);
    return activePeopleList;
  }
);

export const getContactsByLocationList = createAsyncThunk(
  "contactsByLocationHash/get",
  async (locationId: string) => {
    const contactsByLocation: ContactsByLocation[] =
      await getContactsByLocation(locationId);

    logger.log(
      `Contacts By Location ${locationId} length: ${contactsByLocation.length}`
    );

    return { [locationId]: contactsByLocation };
  }
);

export const getRemovedPatientList = createAsyncThunk(
  "removedPatients/get",
  async () => {
    const deletedPatients = await getDeletedPatients();
    logger.log(`Removed patients length ${deletedPatients.length}`);
    return deletedPatients;
  }
);

export const getWorkloadInfo = createAsyncThunk(
  "workload/get",
  async (connectionId: string) => {
    const workloadInfo: WorkloadInfo = await getWorkloadByConnection(
      connectionId
    );

    return { workloadInfo, connectionId };
  }
);

export interface SupervisorState {
  list: ConnectionInfo[];
  locations: LocationInfo[];
  deletedPatients: Patient[];
  activePeople: ActivePeopleByHospital[];
  issuesList: Issue[];
  contactsByLocation: { [location: string]: ContactsByLocation[] };
  loading: LoadingEnum;
}

const initialState: SupervisorState = {
  list: [],
  locations: [],
  activePeople: [],
  deletedPatients: [],
  contactsByLocation: {},
  issuesList: [],
  loading: LoadingEnum.idle,
};

const supervisorSlice = createSlice({
  name: "supervisors",
  initialState,
  reducers: {
    resetIssues: (state:SupervisorState)=>{
      state.issuesList = []
    },
    addIssue: (state: SupervisorState, action: PayloadAction<Issue>) => {
      const { issueType, patientId } = action.payload;

      const index = state.issuesList.findIndex(
        (i) => i.issueType === issueType && i.patientId === patientId
      );

      if (index !== -1) {
        return;
      }

      state.issuesList.push(action.payload);
    },
    removeIssue: (
      state: SupervisorState,
      action: PayloadAction<{ issueType: IssueEnum; patientId: string }>
    ) => {
      const { issueType, patientId } = action.payload;

      const index = state.issuesList.findIndex(
        (i) => i.issueType === issueType && i.patientId === patientId
      );

      if (index === -1) {
        return;
      }

      state.issuesList.splice(index, 1);
    },
    updateESitterActivities: (
      state: SupervisorState,
      action: PayloadAction<{
        connectionId: string;
        activities: ConnectionActivity[];
      }>
    ) => {
      const { connectionId, activities } = action.payload;
      const index = state.list.findIndex((con) => con.id === connectionId);

      if (index === -1) {
        return;
      }

      state.list[index].activities = activities;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getConnectionsList.fulfilled,
      (state: SupervisorState, action: { payload: ConnectionInfo[] }) => {
        state.list = action.payload;
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getConnectionsList.pending, (state: SupervisorState) => {
      state.loading = LoadingEnum.pending;
    });
    builder.addCase(getConnectionsList.rejected, (state: SupervisorState) => {
      state.loading = LoadingEnum.failed;
      state.list = [];
    });
    builder.addCase(
      getLocationList.fulfilled,
      (state: SupervisorState, action: { payload: LocationInfo[] }) => {
        state.locations = action.payload;
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getLocationList.pending, (state: SupervisorState) => {
      state.loading = LoadingEnum.pending;
    });
    builder.addCase(getLocationList.rejected, (state: SupervisorState) => {
      state.loading = LoadingEnum.failed;
      state.locations = [];
    });
    builder.addCase(
      getActivePeopleList.fulfilled,
      (
        state: SupervisorState,
        action: { payload: ActivePeopleByHospital[] }
      ) => {
        state.activePeople = action.payload;
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getActivePeopleList.pending, (state: SupervisorState) => {
      state.loading = LoadingEnum.pending;
    });
    builder.addCase(getActivePeopleList.rejected, (state: SupervisorState) => {
      state.loading = LoadingEnum.failed;
      state.activePeople = [];
    });
    builder.addCase(
      getContactsByLocationList.fulfilled,
      (
        state: SupervisorState,
        action: { payload: { [location: string]: ContactsByLocation[] } }
      ) => {
        state.contactsByLocation = {
          ...state.contactsByLocation,
          ...action.payload,
        };
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(
      getContactsByLocationList.pending,
      (state: SupervisorState) => {
        state.loading = LoadingEnum.pending;
      }
    );
    builder.addCase(
      getContactsByLocationList.rejected,
      (state: SupervisorState) => {
        state.loading = LoadingEnum.failed;
        state.contactsByLocation = {};
      }
    );
    builder.addCase(
      getRemovedPatientList.fulfilled,
      (state: SupervisorState, action: PayloadAction<Patient[]>) => {
        state.deletedPatients = action.payload;
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getRemovedPatientList.pending, (state: SupervisorState) => {
      state.loading = LoadingEnum.pending;
    });
    builder.addCase(
      getRemovedPatientList.rejected,
      (state: SupervisorState) => {
        state.loading = LoadingEnum.failed;
        state.deletedPatients = [];
      }
    );
    builder.addCase(
      getWorkloadInfo.fulfilled,
      (
        state: SupervisorState,
        action: PayloadAction<{
          workloadInfo: WorkloadInfo;
          connectionId: string;
        }>
      ) => {
        const { workloadInfo, connectionId } = action.payload;

        const index = state.list.findIndex(
          (eSitter) => eSitter.id === connectionId
        );

        if (index === -1) {
          return;
        }

        state.list[index].workload = workloadInfo;
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getWorkloadInfo.pending, (state: SupervisorState) => {
    });
    builder.addCase(getWorkloadInfo.rejected, (state: SupervisorState) => {
    });
  },
});

export default supervisorSlice.reducer;

export const { updateESitterActivities, addIssue, removeIssue, resetIssues } =
  supervisorSlice.actions;
