import React from "react";
import { LanguageLogo } from "../../../../imgs/LanguageLogo";
import { PipeLogo } from "../../../../imgs/PipeLogo";
import { RoomLogo } from "../../../../imgs/RoomLogo";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { cutString } from "../../../../utils/cutString";
import { ContactMenu } from "../ContactMenu/ContactMenu";
import { IIcon } from "../../../../imgs/IIcon";

interface Props {
  patient: Patient;
  expanded?: boolean;
  assignClick?: () => any | void;
}

export const PatientInfoBody: React.FC<Props> = ({
  patient,
  assignClick,
  expanded = false,
}) => {
  return (
    <div className="patient-info" aria-label="patient-info">
      <div className="row align-center py-1">
        <RoomLogo />
        <p>Room {patient.roomNumber}</p>
        <PipeLogo />
        <LanguageLogo />
        <p className="language">{cutString(patient.language)}</p>
      </div>

      <div className="row align-center" style={{ paddingBottom: "8px" }}>
        <IIcon />
        <div className="language" style={{ marginRight: "8px" }}>
          Unit {patient.nurseUnitName || patient.unitId}
        </div>
        {patient.cartId && (
          <>
            <PipeLogo />

            <p className="language">
              Cart{" "}
              <span style={{ textTransform: "capitalize" }}>
                {patient.cartId?.split("-")[2] || patient.cartId}
              </span>
            </p>
          </>
        )}
      </div>

      <ContactMenu
        room={patient.roomNumber}
        patient={patient}
        expanded={expanded}
      />
    </div>
  );
};
