import {
  Patient,
  PatientStatus,
} from "../../../../interfaces/patients/patients.interface";
import { PatientName } from "../PatientName/PatientName";

interface Props {
  isVideoLoading?: boolean;
  mutedStatus: boolean;
  patient: Patient;
  toogleMuted?: (arg: boolean) => void;
  defaultStatus?: PatientStatus;
  shouldCloseMenu?: boolean;
  statusLabel: string;
}

export const UpperMenu: React.FC<Props> = ({
  isVideoLoading,
  mutedStatus,
  patient,
  toogleMuted,
  defaultStatus,
  shouldCloseMenu,
  statusLabel,
}) => {
  return (
    <div className="upper-menu" aria-label="upper-menu">
      <div className="room-number">
        <h3>
          <span>ROOM</span> {patient.roomNumber}
        </h3>
      </div>
      {patient.patientStatus !== defaultStatus?.id && statusLabel && (
        <div className="pill-alert info">
          <span
            className="line-clamp one"
            style={{ fontFamily: "Inter", fontSize: "0.75rem" }}
          >
            {statusLabel}
          </span>
        </div>
      )}
      <PatientName
        patient={patient}
        isVideoLoading={isVideoLoading}
        mutedStatus={mutedStatus}
        toogleMuted={toogleMuted}
        defaultStatus={defaultStatus}
        shouldCloseMenu={shouldCloseMenu}
      />
    </div>
  );
};
