interface Props {
  isError?: boolean;
  onClick?: () => void;
}

export const InfoIcon: React.FC<Props> = ({ isError = false, onClick }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="info-icon"
      className={`info-icon ${isError && "error-icon"}`}
      onClick={onClick}
    >
      <path
        d="M14 0.666687C6.63996 0.666687 0.666626 6.64002 0.666626 14C0.666626 21.36 6.63996 27.3334 14 27.3334C21.36 27.3334 27.3333 21.36 27.3333 14C27.3333 6.64002 21.36 0.666687 14 0.666687ZM15.3333 20.6667H12.6666V18H15.3333V20.6667ZM15.3333 15.3334H12.6666V7.33335H15.3333V15.3334Z"
        fill="black"
      />
    </svg>
  );
};
