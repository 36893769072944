const MAX_LENGTH_FOR_WORD = 16;

export const addHyphen = (str: string): string => {
  return `${str.trimStart()}-`;
};

export const splitWord = (
  word: string,
  maxLength: number = MAX_LENGTH_FOR_WORD
): string => {
  if (word.length <= maxLength) {
    return word;
  }

  const strArray = [addHyphen(word.slice(0, maxLength))];

  let secondPart = word.slice(maxLength);

  let i = 1;
  while (secondPart.length > maxLength) {
    strArray[i] = addHyphen(secondPart.slice(0, maxLength));
    secondPart = secondPart.slice(maxLength);
    i++;
  }
  strArray[i] = secondPart.trim();
  return strArray.join("");
};

export const truncateLongStr = (
  longString: string,
  maxLength: number = MAX_LENGTH_FOR_WORD
): string[] => {
  if (!longString) {
    return [""];
  }

  const strArray = longString.split(" ");

  for (let i = 0; i < strArray.length; i++) {
    const word = strArray[i];
    strArray[i] = splitWord(word, maxLength);
  }

  return strArray.join(" ").split("\n");
};
