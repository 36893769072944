import React, { useEffect, useState } from "react";
import { updatePatientsActivity } from "../../../../features/patients/patientsSlice";
import { getPatientById } from "../../../../helpers/patients";
import { addActivity } from "../../../../helpers/commandsPatient";
import { Alert } from "../../atoms/Alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import { Plus } from "../../../../imgs/Plus";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { RootState } from "../../../../stores";
import {
  ActivityTypeEnum,
  AddActivity,
} from "../../../../interfaces/activity/activity.interface";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import logger from "../../../../logger/logger";
import moment from "moment";

type Questions = "reason" | "outcome" | "circumstances" | "location";

const options: { [key in Questions]: string[] } = {
  reason: [
    "Patient wasn’t wearing a gown",
    "Patient required assistance",
    "Patient out of bed",
    "Other",
    "Patient fell",
  ],
  outcome: [
    "Reached PCT",
    "Reached Nurse",
    "Reached Charge Nurse",
    "Reached Unit Director",
    "Did not reach",
  ],
  circumstances: [
    "Patient alone",
    "Patient with family",
    "Patient with care team",
  ],
  location: ["Out of bed", "Bathroom", "Outside of camera view"],
};

const sequences: { [key in Questions]: Questions[] } = {
  reason: ["outcome"],
  circumstances: ["location"],
  location: ["outcome"],
  outcome: [],
};

const questions: { [key in Questions]: string } = {
  reason: "What was the incident?",
  outcome: "What was the outcome?",
  circumstances: "What were the circumstances of the fall?",
  location: "Where did the patient fall?",
};

interface Props {
  id: string;
  roomNumber: string;
  helperToFetchPatient?: (id: string) => Promise<Patient | any>;
}

export const DocumentIncident: React.FC<Props> = ({
  id,
  helperToFetchPatient = getPatientById,
}) => {
  const { newCallInput, otherOptionInput } = useSelector(
    (state: RootState) => state.openForms
  );
  const [activityAdded, setActivityAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [formQuestion, setformQuestion] = useState<
    keyof typeof options | undefined
  >("reason");
  const [data, setData] = useState<{ [key in string | Questions]: string[] }>();
  const [disable, setDisable] = useState(true);
  const { token,
          session: {
              sub: [username, userid],
        }, 
  } = useSelector((state: RootState) => state.sessionInfo);
  const [currentAnswer, setCurrentAnswer] = useState<string>("");

  const resetNewCall = () => {
    setformQuestion("reason");
    dispatch(setForm({ typeInput: "otherOptionInput", status: false }));
  };

  const toogleNewCall = () => {
    if (newCallInput.status) {
      resetNewCall();
    }
    dispatch(
      setForm({
        typeInput: "newCallInput",
        status: !newCallInput.status,
      })
    );
    setActivityAdded(false);
    setData(undefined);
  };

  useEffect(() => {
    if (activityAdded) {
      setTimeout(() => {
        setActivityAdded(false);
      }, 2000);
    }
  }, [activityAdded]);

  const handleOtherOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(setForm({ typeInput: "otherOptionInput", status: true }));
    }
  };

  const handleOtherInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisable(!e.target.value);
    setCurrentAnswer(e.target.value.trimStart());
    dispatch(
      setForm({
        typeInput: "otherOptionInput",
        status: true,
        text: e.target.value.trimStart(),
      })
    );
    if (!formQuestion) {
      return;
    }
    setData({ ...data, [formQuestion]: [e.target.value.trimStart()] });
  };

  const handleForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formQuestion) {
      return;
    }

    if (currentAnswer === "Patient fell") {
      setformQuestion("circumstances");
      return;
    }

    let nextQuestion: Questions | undefined = sequences[formQuestion][0];

    setformQuestion(nextQuestion);
    if (nextQuestion) {
      dispatch(
        setForm({
          typeInput: "otherOptionInput",
          status: false,
        })
      );
      setDisable(true);
      return;
    }

    handleSubmit();
  };

  const handleSubmit = () => {
    if (!data) {
      return;
    }
    sendData(
      JSON.stringify({
        ...data,
        isPillOpen: Boolean(data.outcome?.[0] !== options.outcome[4]),
      }),
      data.reason?.includes(options.reason[4])
        ? ActivityTypeEnum.PatientFell
        : ActivityTypeEnum.NurseCallToNurse
    );
    setData(undefined);
  };

  const handleCancel = () => {
    if (formQuestion === "reason") {
      toogleNewCall();
      return;
    }

    if (formQuestion === "location") {
      setformQuestion("circumstances");
      return;
    }

    if (formQuestion === "outcome" && data?.location) {
      setformQuestion("location");
      return;
    }

    setformQuestion("reason");
    setData(undefined);
  };

  const sendData = async (data: string, activityType: ActivityTypeEnum) => {
    const obj: AddActivity = {
      id: id,
      user: userid,
      activity: data,
      activityType,
      clientDateTime: moment().utc().format(),
    };
    setLoading(true);
    try {
      await addActivity(obj, token);
      setActivityAdded(true);
      dispatch(setForm({ typeInput: "newCallInput", status: false }));
      resetNewCall();
      updatePatient();
    } catch (e) {
      logger.error("Adding new call", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const updatePatient = async () => {
    try {
      const updatedPatient = await helperToFetchPatient(id);
      dispatch(updatePatientsActivity(updatedPatient));
    } catch (e) {
      logger.error("Updating patient", e);
    }
  };

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      return;
    }
    setDisable(false);
    setCurrentAnswer(e.target.value);
    dispatch(setForm({ typeInput: "otherOptionInput", status: false }));

    if (!formQuestion) {
      return;
    }
    setData({ ...data, [formQuestion]: [e.target.value] });
  };

  const mapOptions = (
    option:
      | HTMLInputElement
      | React.Key
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | undefined,
    i: any
  ) => {
    if (option !== "Other") {
      return (
        <div
          className="row form-control radio-option"
          key={option as React.Key}
        >
          <input
            type="radio"
            name={formQuestion}
            value={option as string}
            onChange={handleChangeRadio}
            aria-label={`${formQuestion}-${i}`}
            id={`${formQuestion}-${i}`}
          />
          <label htmlFor={`${formQuestion}-${i}`}>{option as string}</label>
        </div>
      );
    }

    return (
      <div className="row form-control radio-option" key={option as React.Key}>
        <input
          type="radio"
          checked={otherOptionInput.status}
          name={formQuestion}
          value={option as string}
          onChange={handleOtherOption}
          aria-label={`other-radio-${formQuestion}`}
          id={`other-radio-${formQuestion}`}
        />

        <div className="form-group row align-center" style={{ gap: "8px" }}>
          <label htmlFor={`other-radio-${formQuestion}`}>{option}</label>

          {otherOptionInput.status && (
            <input
              type="text"
              aria-label={`other-input-${formQuestion}`}
              required
              name={formQuestion}
              value={otherOptionInput.text}
              onChange={handleOtherInput}
              onKeyUp={(e) => e.key === "Escape" && toogleNewCall()}
              className="blue-input transparent thin"
              title="This field is required."
              maxLength={400}
              minLength={5}
              pattern="([a-zA-ZÀ-ÿ0-9-,a-z. '])+"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`nurse-call`}>
      {/*button to register new call*/}
      <div className="row">
        <button
          aria-label="new-call-btn"
          className={`icon btn-xsmall opaque-input ${
            (newCallInput.status || activityAdded) && "disabled"
          } semibold`}
          disabled={newCallInput.status || activityAdded}
          onClick={toogleNewCall}
        >
          <Plus /> <p>ADD DOCUMENTATION</p>
        </button>
      </div>

      {/* textarea to record call purpose */}
      {newCallInput.status && !loading && formQuestion && (
        <div className="form-box filled" style={{ position: "relative" }}>
          <div className="row">
            <h3>{questions[formQuestion]}</h3>
          </div>

          <div style={{ position: "absolute", top: "0.8rem", right: "0.4rem" }}>
            <CloseIcon action={toogleNewCall} />
          </div>

          <form
            className="form-100"
            onSubmit={handleForm as unknown as React.FormEventHandler}
            aria-label="options-form"
          >
            <div className="form-options">
              {options[formQuestion].map((option: any, i: any) =>
                mapOptions(option, i)
              )}
            </div>

            <div className="row btn-group left reverse-order">
              <button
                className="btn-small light"
                aria-label="submit-btn"
                disabled={disable}
                type="submit"
              >
                {formQuestion !== "outcome" ? "CONTINUE" : "SUBMIT"}
              </button>
              <button
                className="btn-small transparent outlined"
                onClick={handleCancel}
                type="button"
              >
                {formQuestion === "reason" ? "CANCEL" : "BACK"}
              </button>
            </div>
          </form>
        </div>
      )}
      {activityAdded && (
        <Alert
          text={"Call registered successfully"}
          type={"success"}
          closeBtn={true}
        />
      )}
      {loading && (
        <Alert text={"Submitting..."} type={"info"} closeBtn={false} />
      )}
      {error && (
        <ErrorMsg
          errorMsg={ErrorTypeEnum.Retry}
          filled={false}
          closeBtn={false}
          padding={"none"}
          position={"inline"}
        />
      )}
    </div>
  );
};
