import React from "react";

interface Props {
  action?: () => any;
  orientation?: "left" | "up" | "right" | "down";
  tooltipText?: string;
  invisible?: boolean;
  disabled?:boolean;
}

export const ArrowDownLogo: React.FC<Props> = ({
  action,
  orientation,
  tooltipText,
  invisible,
  disabled
}) => {
  return (
    <div className="tooltip">
      <svg
        className={`expansion-arrow arrow ${orientation} ${
          invisible && "invisible"
        } ${disabled && "disabled"}`}
        onClick={action}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="expansion-arrow"
      >
        <path
          d="M9.88 11.4533L16 17.56L22.12 11.4533L24 13.3333L16 21.3333L8 13.3333L9.88 11.4533Z"
          fill="black"
        />
      </svg>
      {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
    </div>
  );
};
