import { useCallback, useEffect, useState } from "react";
import {
  disconnectVideoSocket,
  initiateVideoSocket,
} from "../helpers/socketVideo";
import { disconnectAISocket, initiateAISocket } from "../helpers/socketAI";
import {
  ZeroStatus,
  removeBlockedRisk,
  removeRiskById,
  setCurrentRisk,
  setPreviousRisk,
  setRiskById,
  setZeroAvailable,
} from "../features/fallRisk/fallRisk";
import { RiskValue } from "../interfaces/fallRisk/fallRisk.interface";
import {
  disconnectESitterSocket,
  initiateESitterSocket,
} from "../helpers/socketESitter";
import { getDeviceInfoByLocation, getPatientById } from "../helpers/patients";
import {
  MAX_PATIENTS,
  deleteContact,
  deletePatient,
  removeAddedPatient,
  setAddedPatient,
  setNewPatient,
  updatePatientsActivity,
} from "../features/patients/patientsSlice";
import { Patient } from "../interfaces/patients/patients.interface";
import {
  setContactExpiration,
  removeExpiredContact,
  removeAssignedContact,
} from "../features/assignedContact/assignedContactSlice";
import { lessThan10Min } from "../utils/timeOperations";
import { setSelectedPatient } from "../features/selectedPatient/selectedPatient";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { PillAlert } from "../interfaces/pillAlert/pillAlerts.interface";
import moment from "moment";
import { removePillAlert } from "../features/pillAlerts/pillAlertsSlice";
import {
  addGlobalError,
  setErrorById,
} from "../features/globalErrors/globalErrorsSlice";
import { addActivity } from "../helpers/commandsPatient";
import {
  ActivityPillEnum,
  ActivityTypeEnum,
  AddActivity,
} from "../interfaces/activity/activity.interface";
import { ErrorTypeEnum } from "../enums/errorType";
import { ESitterEventsEnum } from "../enums/eSitterEvents";
import logger from "../logger/logger";
import { removeAlarm } from "../features/sosAlarm/sosAlarmSlice";
import { env } from "../env";
import { deleteExpirationTime } from "../features/timeToExpire/timeToExpireSlice";
import { setTakeABreakStatus } from "../features/session/sessionSlice";
import { usePatients } from "./usePatients";
import { EnvVarsConfig } from "../enums/global";
import { updateListOfPatients } from "../utils/updateListOfPatients";
import { MonitoringOperationsEnum } from "../enums/apisOperations";

const DELAY_TO_WAIT_BACKEND = 3000;

export const useSocket = (
  connectToSocket: boolean,
  dispatch: (arg: any) => any,
  helperToFetchPatient = getPatientById
) => {
  const {
    checkIfUserIsOnBreak,
    getFilteredPatients,
    addDevicesInfoToPatients,
    setPatientsMonitoring,
    updateAllPatientInfo,
  } = usePatients();
  const [socketActive, setSocketActive] = useState(false);
  const [errorSocket, setErrorSocket] = useState<
    { status: boolean; error: ErrorTypeEnum }[]
  >([]);
  const [incomingMsg, setIncomingMsg] = useState<{
    eventType: ESitterEventsEnum | null;
    message: any;
  }>({
    eventType: null,
    message: "",
  });
  const [incomingData, setIncomingData] = useState<{
    id: string;
    fallRisk: RiskValue;
  } | null>(null);
  const { list } = useSelector((state: RootState) => state.patients);
  const { patientId } = useSelector(
    (state: RootState) => state.selectedPatient
  );
  const { currentRisk, blockedRisk, riskById, zeroStatusById } = useSelector(
    (state: RootState) => state.fallRisk
  );
  const { pillsByPatient } = useSelector(
    (state: RootState) => state.pillAlerts
  );
  const {
    connectionId,
    break: { active: isInBreak },
    token,
    session:{
        sub: [username, userid],
    }, 
  } = useSelector((state: RootState) => state.sessionInfo);
  const [idToUnlockAI, setIdToUnlockAI] = useState<string>("");
  const [eSitterSocketReady, setESitterSocketReady] = useState(false);

  useEffect(() => {
    const start = () => {
      if (!socketActive) {
        initiateAISocket(
          setError,
          () => unsetError(ErrorTypeEnum.AISocket),
          fallRiskSubscription
        );
        initiateVideoSocket(setError, () =>
          unsetError(ErrorTypeEnum.VideoSocket)
        );
        initiateESitterSocket(
          setError,
          () => {
            setESitterSocketReady(true);
            unsetError(ErrorTypeEnum.ESitterSocket);
          },
          (eventType: ESitterEventsEnum, message: any) =>
            setIncomingMsg({ eventType, message })
        );
        setSocketActive(true);
      }
    };
    connectToSocket && start();
  }, [connectToSocket]);

  const fallRiskSubscription = (data: { Id: string; FallRisk: RiskValue }) => {
    const { Id: id, FallRisk: fallRisk } = data;

    setIncomingData({ id, fallRisk });
  };

  useEffect(() => {
    if (!incomingData?.id || isNaN(incomingData?.fallRisk)) {
      return;
    }

    const { id, fallRisk } = incomingData;

    const isZeroNowAvailable = zeroStatusById[id] === ZeroStatus.Available;

    if (
      id !== currentRisk.id ||
      fallRisk !== currentRisk.fallRisk ||
      isZeroNowAvailable
    ) {
      dispatch(setPreviousRisk(currentRisk));
      dispatch(setCurrentRisk(incomingData));
      setIncomingData({ id: "", fallRisk: 0 });
      if (
        (isNaN(blockedRisk[currentRisk.id]) ||
          blockedRisk[currentRisk.id] !== fallRisk) &&
        riskById[id] !== fallRisk &&
        !isNaN(riskById[id])
      ) {
        dispatch(setRiskById(incomingData));
        dispatch(removeBlockedRisk({ id }));
        setIdToUnlockAI(id);
      }
    }
  }, [incomingData]);

  useEffect(() => {
    if (idToUnlockAI && zeroStatusById[idToUnlockAI] === ZeroStatus.Blocked) {
      unblockZeroForId(idToUnlockAI);
      setIdToUnlockAI("");
    }
  }, [idToUnlockAI, zeroStatusById]);

  const unblockZeroForId = (id: string) => {
    setTimeout(() => {
      dispatch(setZeroAvailable({ id }));
    }, (env.REACT_APP_SECS_TO_FREEZE_AI || 5) * 1000);
  };

  const setError = (error: ErrorTypeEnum) => {
    setErrorSocket([
      ...errorSocket.filter((err) => err.error !== error),
      { status: true, error },
    ]);
  };

  const unsetError = (error: ErrorTypeEnum) => {
    setErrorSocket([
      ...errorSocket.filter((err) => err.error !== error),
      { status: false, error },
    ]);
  };

  const fetchPatientAndUpdate = useCallback(
    async (id: string, updateActivities: boolean = false) => {
      logger.log(`Fetch patient by id ${id}`);
      const patient = await helperToFetchPatient(id);
      if (updateActivities) {
        patient && dispatch(updatePatientsActivity(patient));
        return;
      }
      updateAllPatientInfo(id);
      return;
    },
    [helperToFetchPatient, getDeviceInfoByLocation, dispatch]
  );

  const updateContactExpiration = useCallback(
    (patient: Patient, contactId: string, endDateTime: string) => {
      let currContact = patient?.contacts?.find(
        (contact) => contact.id === contactId
      );
      if (currContact) {
        const parsedDate = endDateTime.endsWith("Z")
          ? endDateTime
          : `${endDateTime}Z`;

        if (lessThan10Min(parsedDate, 11)) {
          dispatch(
            setContactExpiration({
              room: patient.roomNumber,
              contactId: contactId,
              type: currContact.type,
              endDateTime:
                (endDateTime as string) || (currContact?.endDateTime as string),
            })
          );
        } else {
          dispatch(
            removeExpiredContact({ room: patient.roomNumber, contactId })
          );
          dispatch(deleteExpirationTime({ contactId }));
        }
        if (`${currContact?.endDateTime}Z` !== endDateTime) {
          logger.log(
            `Times aren't equal: current ${currContact?.endDateTime}Z - incomming ${endDateTime}`
          );
          fetchPatientAndUpdate(patient.id);
        }
      }
    },
    [lessThan10Min, dispatch]
  );

  const closePill = async (
    patientId: string,
    room: string,
    type: ActivityPillEnum
  ) => {
    const data: AddActivity = {
      id: patientId,
      user: userid,
      activity: '{"isPillOpen": false}',
      activityType: type as unknown as ActivityTypeEnum,
      clientDateTime: moment().utc().format(),
    };

    dispatch(removePillAlert({ room }));
    try {
      logger.log(`End escalation path`, data);
      await addActivity(data, token);
    } catch (e) {
      dispatch(
        setErrorById({
          id: patientId,
          errorMsg: ErrorTypeEnum.NetworkError,
        })
      );
    }
  };

  useEffect(() => {
    if (!incomingMsg.eventType || !incomingMsg.message) {
      return;
    }
    const { eventType, message } = incomingMsg;
    if (eventType) {
      listenESitterEvents(eventType, message, list, patientId, pillsByPatient);
    }
  }, [incomingMsg, list]);

  const listenESitterEvents = useCallback(
    async (
      eventType: ESitterEventsEnum,
      message: any,
      patients: Patient[],
      selectedPatient: string,
      pillsByPatient: {
        [room: string]: PillAlert;
      }
    ) => {
      logger.log(`[${ESitterEventsEnum[eventType]}]`, message);
      let response: any | Patient = null;

      switch (eventType) {
        case ESitterEventsEnum.AdmissionAddedEvent:
          dispatch(setAddedPatient(message.Id));
          if (EnvVarsConfig[env.REACT_APP_TAKE_A_BREAK] === EnvVarsConfig.on) {
            return;
          }
          response = await helperToFetchPatient(message.Id);
          if (response?.active) {
            const patients = await addDevicesInfoToPatients([response]);
            dispatch(setNewPatient(patients[0]));
            setPatientsMonitoring(
              [response],
              MonitoringOperationsEnum.beginMonitoring
            );
            setTimeout(() => {
              dispatch(removeAddedPatient(message.Id));
            }, 5000);
          }
          break;
        case ESitterEventsEnum.PatientUpdatedEvent:
          if (!message.Id) {
            return;
          }
          setTimeout(() => {
            updateAllPatientInfo(message.Id);
          }, DELAY_TO_WAIT_BACKEND);
          break;
        case ESitterEventsEnum.ActivityAddedEvent:
          if (message.ActivityType && ActivityPillEnum[message.ActivityType]) {
            logger.log(
              `Activity for pill ${ActivityPillEnum[message.ActivityType]}`
            );
            fetchPatientAndUpdate(message.Id, true);
          }

          break;
        case ESitterEventsEnum.PatientRemovedEvent:
          response = patients.find((patient) => patient.id === message.Id);
          if (!response) {
            return;
          }
          dispatch(deletePatient(message.Id));
          dispatch(removeAlarm({ room: response.roomNumber }));
          dispatch(removeAssignedContact({ room: response.roomNumber }));
          dispatch(removeRiskById({ id: message.Id }));
          setPatientsMonitoring(
            [response],
            MonitoringOperationsEnum.endMonitoring
          );
          if (selectedPatient === message.Id) {
            dispatch(setSelectedPatient(""));
          }
          break;
        case ESitterEventsEnum.ContactAboutToExpireEvent:
          if (message.PatientId) {
            response = patients.find(
              (patient) => patient.id === message.PatientId
            );
            response &&
              updateContactExpiration(
                response,
                message.Id,
                message.EndDateTime as string
              );
            return;
          }
          if (message.UnitId as string) {
            patients.forEach((patient: Patient) => {
              if (patient.unitId === message.UnitId) {
                updateContactExpiration(
                  patient,
                  message.Id,
                  message.EndDateTime as string
                );
              }
            });
          }
          break;
        case ESitterEventsEnum.ContactRemovedEvent:
          if (message.Id) {
            dispatch(deleteExpirationTime({ contactId: message.Id }));
          }
          if (message.PatientId) {
            response = patients.find(
              (patient) => patient.id === message.PatientId
            );
            response.roomNumber &&
              dispatch(
                removeExpiredContact({
                  room: response.roomNumber,
                  contactId: message.Id,
                })
              );
            dispatch(deleteContact({ patientId: response.id, id: message.Id }));
            return;
          }
          if ((message.UnitId as string) && message.Id) {
            patients.forEach((patient: Patient) => {
              if (patient.unitId === message.UnitId) {
                dispatch(
                  removeExpiredContact({
                    room: patient.roomNumber,
                    contactId: message.Id,
                  })
                );
                dispatch(
                  deleteContact({ patientId: patient.id, id: message.Id })
                );
              }
            });
          }
          break;
        case ESitterEventsEnum.EscalationPathCompletedEvent:
          if (message.Id) {
            response = patients.find((patient) => patient.id === message.Id);
            if (
              response.roomNumber &&
              pillsByPatient[response.roomNumber] &&
              pillsByPatient[response.roomNumber].type !==
                ActivityPillEnum.dispatch
            ) {
              const type = pillsByPatient[response.roomNumber]?.type;
              type && closePill(message.Id, response.roomNumber, type);
            }
          }
          break;
        case ESitterEventsEnum.LoadBalanceCompletedEvent:
          if (EnvVarsConfig[env.REACT_APP_TAKE_A_BREAK] === EnvVarsConfig.off) {
            return;
          }
          try {
            const connectionInfo = await checkIfUserIsOnBreak(connectionId);
            if (!connectionInfo.patientsAssigned) {
              return;
            }
            logger.log(
              `Patients assigned by load balancer: ${connectionInfo.patientsAssigned.length}`
            );
            const newPatients = await getFilteredPatients(
              connectionInfo.patientsAssigned.slice(0, MAX_PATIENTS)
            );
            if (!newPatients) {
              return;
            }
            const patientsWithDevice = await addDevicesInfoToPatients(
              newPatients
            );
            if (!patientsWithDevice) {
              return;
            }
            updateListOfPatients(
              patientsWithDevice,
              patients,
              (pat: Patient) => {
                logger.log(`Patient already saved ${pat.id}`);
              },
              (pat: Patient) => {
                logger.log(`Patient to add ${pat.id}`);
                dispatch(setNewPatient(pat));

                setPatientsMonitoring(
                  [pat],
                  MonitoringOperationsEnum.beginMonitoring
                );
              },
              (pat: Patient) => {
                logger.log(`Patient to delete ${pat.id}`);
                dispatch(deletePatient(pat.id));
              }
            );
          } catch (e) {
            dispatch(addGlobalError(ErrorTypeEnum.FetchFailed));
          }
          break;
        case ESitterEventsEnum.BreakStartedEvent:
          if (
            !isInBreak &&
            message.StartedDateTime &&
            message.Id === connectionId
          ) {
            dispatch(
              setTakeABreakStatus({
                active: true,
                startDateTime: message.StartedDateTime,
              })
            );
          }
          break;
        case ESitterEventsEnum.BreakEndedEvent:
          if (isInBreak && message.Id === connectionId) {
            dispatch(setTakeABreakStatus({ active: false }));
          }
          break;
        case ESitterEventsEnum.ESitterSocketError:
          logger.error(
            `Error from ESitter socket - ${JSON.stringify(message)}`
          );
          break;
        default:
          break;
      }

      setIncomingMsg({ eventType: null, message: "" });
    },
    [
      addDevicesInfoToPatients,
      checkIfUserIsOnBreak,
      connectionId,
      isInBreak,
      closePill,
      updateContactExpiration,
      fetchPatientAndUpdate,
      getFilteredPatients,
      setPatientsMonitoring,
      helperToFetchPatient,
    ]
  );

  const closeSocket = () => {
    if (socketActive) {
      disconnectVideoSocket();
      disconnectESitterSocket();
      disconnectAISocket();
      setSocketActive(false);
    }
  };

  return { socketActive, closeSocket, errorSocket, eSitterSocketReady };
};
