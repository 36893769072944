import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  SessionExpired,
  SessionInfo,
} from "../../interfaces/shared/shared.interface";
import { LoadingEnum, UserTypes } from "../../enums/global";
import axiosHttpClient from "../../utils/axiosHttpClient";
import { env } from "../../env";
import { parseJSON } from "../../utils/parseJSON";
import moment from "moment";
import { alreadyExpired } from "../../utils/timeOperations";

export interface SessionState {
  session: SessionInfo;
  loading: LoadingEnum;
  error: SessionExpired;
  token: string;
  connectionId: string;
  break: {
    active: boolean;
    startDateTime?: string;
  };
  notifications: {
    areActive: boolean;
    areSuspended: boolean;
  };
}

export const isSessionActive = createAsyncThunk(
  "session/decodingToken",
  async ({ token }: { token: string }) => {
    const endpoint = `${env.REACT_APP_LOGIN_SERVICE}/login/${token}`;
    const decodeResult = await axiosHttpClient.getData(endpoint);
    if (decodeResult?.data?.iss) {
      return { session: decodeResult.data, token };
    }
    throw decodeResult.data;
  }
);

const initialState: SessionState = {
  session: {
    iss: "",
    sub: ["", "", undefined],
    iat: 0,
    exp: 0,
  },
  token: "",
  loading: LoadingEnum.idle,
  error: {
    name: "",
    message: "",
    expiredAt: "",
  },
  connectionId: "",
  break: {
    active: false,
  },
  notifications: {
    areActive: true,
    areSuspended: false,
  },
};

export const sessionInfo = createSlice({
  name: "session",
  initialState,
  reducers: {
    getSavedSession: (
      state: SessionState,
      action: PayloadAction<UserTypes>
    ) => {
      const session = sessionStorage.getItem("eSitterSession");
      const token = sessionStorage.getItem("eSitterToken");
      const parsedSession =
        session && (parseJSON(session) as unknown as SessionInfo);
      const expirationDate =
        parsedSession &&
        parsedSession.exp &&
        moment(parsedSession.exp, "X").utc().format();

      const userType = action.payload;

      if (
        parsedSession &&
        parsedSession?.sub?.length === 3 &&
        token &&
        expirationDate &&
        parsedSession.sub?.[2] === userType &&
        !alreadyExpired(expirationDate)
      ) {
        state.session = parsedSession;
        state.token = token;
        state.loading = LoadingEnum.succeeded;
        return;
      }

      state.loading = LoadingEnum.failed;
    },
    removeSession: (
      state: SessionState,
      action: PayloadAction<{ avoidRedirection: boolean } | undefined>
    ) => {
      sessionStorage.removeItem("eSitterSession");
      sessionStorage.removeItem("eSitterToken");
      sessionStorage.removeItem("connectionId");

      state.session = initialState.session;
      state.token = "";
      state.error = initialState.error;
      state.connectionId = "";
      state.break = initialState.break;

      if (action.payload?.avoidRedirection) {
        return;
      }
      window.location.replace(env.REACT_APP_LOGIN_PAGE);
    },
    setConnectionId: (state: SessionState, action: PayloadAction<string>) => {
      state.connectionId = action.payload;
    },
    setTakeABreakStatus: (
      state: SessionState,
      action: PayloadAction<{ active: boolean; startDateTime?: string }>
    ) => {
      const { active } = action.payload;

      if (active && action.payload.startDateTime) {
        state.break = action.payload;
        return;
      }

      state.break = {
        active,
        startDateTime: "",
      };
    },
    resetLoading: (state: SessionState) => {
      state.loading = LoadingEnum.idle;
    },
    setNotificationsStatus: (
      state: SessionState,
      action: PayloadAction<boolean>
    ) => {
      state.notifications.areActive = action.payload;
    },
    setNotificationsSuspension: (
      state: SessionState,
      action: PayloadAction<boolean>
    ) => {
      state.notifications.areSuspended = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      isSessionActive.fulfilled,
      (state: SessionState, action) => {
        const { session, token } = action.payload;
        sessionStorage.setItem("eSitterSession", JSON.stringify(session));
        sessionStorage.setItem("eSitterToken", token);

        state.session = session as unknown as SessionInfo;
        state.token = token;
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(isSessionActive.pending, (state: SessionState) => {
      state.loading = LoadingEnum.pending;
    });
    builder.addCase(isSessionActive.rejected, (state: SessionState, action) => {
      state.error = action.error as unknown as SessionExpired;
      state.loading = LoadingEnum.failed;
    });
  },
});

export default sessionInfo.reducer;

export const {
  getSavedSession,
  removeSession,
  setConnectionId,
  setTakeABreakStatus,
  resetLoading,
  setNotificationsStatus,
  setNotificationsSuspension,
} = sessionInfo.actions;
