import JsSIP from "jssip";
import { useEffect, useState } from "react";
import stayInBedAudio from "../audios/StayInBed.ogg";
import alarmAudio from "../audios/alarm_max.ogg";
import ring from "../audios/ringing.ogg";
import { EndEvent, IncomingAckEvent, RTCSession } from "jssip/lib/RTCSession";
import { IncomingResponse } from "jssip/lib/SIPMessage";
import { IncomingRTCSessionEvent, UAConfiguration } from "jssip/lib/UA";
import {
  DisconnectEvent,
  WebSocketInterface,
} from "jssip/lib/WebSocketInterface";
import { env } from "../env";
import { ErrorTypeEnum } from "../enums/errorType";
import { CallError } from "../interfaces/shared/shared.interface";
import { CallLabelEnum } from "../enums/callLabel";
import logger from "../logger/logger";
import { getAudioMedia } from "../utils/getUserMedia";

let globalSession: RTCSession;
let audioToPlay: "stayInBed" | "alarm" | "none" | "" = "";

const incomingAudio = new Audio(ring);

const socket: WebSocketInterface = new JsSIP.WebSocketInterface(
  `${env.REACT_APP_CALL_HOST}:8089/ws`
);

const configuration: UAConfiguration = {
  sockets: [socket],
  uri: `sip:webrtc_client@${env.REACT_APP_CALL_HOST?.slice(6)}`,
  password: env.REACT_APP_SIP_PASSWORD,
};

const ua = new JsSIP.UA(configuration);

export const initiateCallEvents = (
  onConnected: () => any,
  onDisconnected: () => any,
  onUnregistration: () => any,
  onNewRTCSession: () => any
) => {
  ua.start();
  //JsSIP.debug.enable("JsSIP:*");
  JsSIP.debug.disable();

  ua.on("connected", () => {
    logger.log(`Call socket connected`);
    onConnected();
  });

  ua.on("disconnected", (e: DisconnectEvent) => {
    logger.error(`Call socket disconnected`);
    onDisconnected();
  });

  ua.on("unregistered", () => {
    logger.warn(`Call unregistered`);
    onUnregistration();
  });

  ua.on("newRTCSession", (e: IncomingRTCSessionEvent) => {
    logger.log(`New RTC Session ${audioToPlay} ${e.session}`);
    onNewRTCSession();
    globalSession = e.session;
    if (globalSession.direction === "incoming") {
      incomingAudio.play();
      return;
    }
    hangOn();

    if (audioToPlay === "alarm" || audioToPlay === "stayInBed") {
      const audio = audioToPlay === "alarm" ? alarmAudio : stayInBedAudio;
      sendAudioMessage(audio, globalSession);
      return;
    }
    listenAudio(globalSession);
  });
};

const sendAudioMessage = async (url: string, session: RTCSession) => {
  const audioMessage = createAudioSrc(url);
  try {
    await audioMessage.audio.play();
  } catch (e) {
    logger.error(`Error on audio Message`, e);
  }
  session.connection.addTrack(audioMessage.rTrack);

  audioMessage.audio.addEventListener("ended", () => {
    logger.log(`Play ended`);
    hangOn();
  });
};

const createAudioSrc = (url: string) => {
  const audio = new Audio(url);
  audio.preload = "auto";
  audio.volume = 1;
  const audioContext = new AudioContext();
  const rSource = audioContext.createMediaElementSource(audio);
  const rDest = audioContext.createMediaStreamDestination();
  rSource.connect(rDest);

  const rTrack = rDest.stream.getAudioTracks()[0];

  return { audio, rDest, rSource, rTrack };
};

const listenAudio = (session: RTCSession) => {
  if (session.connection) {
    logger.log(`Listen to audio`);
    session.connection.addEventListener("track", function (e: RTCTrackEvent) {
      logger.log("Got new stream");
      // Start remote audio stream
      const remoteAudio = document.createElement("audio");
      remoteAudio.srcObject = e.streams[0];
      remoteAudio.play();
    });
  }
};

const hangOn = () => {
  const STATUS_CONFIRMED = 9;
  if (globalSession?.status === STATUS_CONFIRMED) {
    globalSession?.terminate();
  }
};

export const useCall = (onEndedCall?: () => void) => {
  const [callLabel, setCallLabel] = useState<CallLabelEnum>(CallLabelEnum.Call);
  const [callError, setCallError] = useState<CallError>(null);
  const [callEnded, setCallEnded] = useState(false);

  useEffect(() => {
    const DELAY_TO_REMOVE_MESSAGE = 5000;

    const timer = setTimeout(() => {
      callEnded && setCallEnded(false);
    }, DELAY_TO_REMOVE_MESSAGE);

    if (callLabel === CallLabelEnum.HangOn) {
      clearTimeout(timer);
      callEnded && setCallEnded(false);
    }

    return () => clearTimeout(timer);
  }, [callEnded, callLabel]);

  // Register callbacks to desired call events
  const eventHandlers = {
    progress: (e: {
      originator: "remote" | "local";
      response: IncomingResponse;
    }) => {
      setCallLabel(CallLabelEnum.Call);
      logger.log("CALL is in progress");
    },
    accepted: (e: {
      originator: "remote" | "local";
      response: IncomingResponse;
    }) => {
      logger.log(`CALL accepted`);
    },
    confirmed: (e: IncomingAckEvent) => {
      logger.log("CALL CONFIRMED");
      setCallLabel(CallLabelEnum.HangOn);
      setCallError(null);
    },
    failed: (e: EndEvent) => {
      logger.error("CALL FAILED with cause", e);
      switch (e.cause) {
        case "Busy":
          setCallError(ErrorTypeEnum.BusyCall);
          break;
        case "SIP Failure Code":
          setCallError(ErrorTypeEnum.CodeFailure);
          break;
        case "Canceled":
          setCallError(ErrorTypeEnum.Canceled);
          break;
        default:
          setCallError(ErrorTypeEnum.Default);
          break;
      }
      setTimeout(() => {
        setCallError(null);
      }, 5000);
    },
    ended: function (e: any) {
      logger.log("CALL ENDED with cause: ", e);
      onEndedCall?.();
      setCallLabel(CallLabelEnum.Call);
      setCallEnded(true);
    },
    connection: (e: any) => {
      logger.log(`CALL Connected`, e);
    },
    registered: (e: any) => {
      logger.log(`CALL Registered`);
    },
  };

  const options = {
    eventHandlers: eventHandlers,
    mediaConstraints: { audio: true, video: false },
  };

  const startCall = async (phone: string = "1001") => {
    if (callLabel === CallLabelEnum.HangOn) {
      hangOn();
    }

    if (callLabel === CallLabelEnum.Call) {
      logger.log(`Calling to extension ${phone}`);
      try {
        await getAudioMedia(
          () => setCallError(null),
          () => setCallError(ErrorTypeEnum.MicUnavailable)
        );
        ua.call(phone, options);
      } catch (e) {
        setCallError(ErrorTypeEnum.Default);
      }
      return;
    }
  };

  const handleStayInBed = (phone: string) => {
    audioToPlay = "stayInBed";
    startCall(phone);
  };

  const handleAlarm = (phone: string) => {
    audioToPlay = "alarm";
    startCall(phone);
  };

  const handleCall = (phone: string) => {
    audioToPlay = "none";
    startCall(phone);
  };

  return {
    handleCall,
    handleAlarm,
    handleStayInBed,
    hangOn,
    callLabel,
    callError,
    callEnded,
  };
};
