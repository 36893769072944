import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Arrow } from "../../imgs/Arrow";
import routes from "../../routes";
import store, { RootState } from "../../stores";
import { Patient } from "../../interfaces/patients/patients.interface";
import { TableMetada } from "../../interfaces/shared/shared.interface";
import { getPassedTime, getPassedTimeText } from "../../utils/timeOperations";
import { RemovalConfirmation } from "../UI/atoms/RemovalConfirmation/RemovalConfirmation";
import { getRemovedPatientList } from "../../features/supervisors/supervisorsSlice";

export const RemovedPatients = () => {
  const navigate = useNavigate();
  const { deletedPatients } = useSelector(
    (state: RootState) => state.supervisors
  );
  const [undoRemovalPatient, setUndoRemovalPatient] = useState<Patient>();

  const metadata: TableMetada = useMemo(
    () => ({
      columns: [
        {
          title: "Patient Name",
          compute: (deletedPatient: Patient) =>
            `${deletedPatient.lastName}, ${deletedPatient.firstName}`,
        },
        {
          title: "Room",
          compute: (deletedPatient: Patient) => deletedPatient.roomNumber,
        },
        {
          title: "Hospital Name",
          compute: (deletedPatient: Patient) =>
            deletedPatient.hospitalName || "Hospital Not Assigned",
        },
        {
          title: "Unit",
          compute: (deletedPatient: Patient) =>
            deletedPatient.nurseUnitName || "Unit Not Assigned",
        },
        {
          title: "Time Removed",
          compute: (deletedPatient: Patient) => {
            if (!deletedPatient.deletedDate) {
              return "No removed date";
            }
            const time = getPassedTime(deletedPatient.deletedDate, true, true);

            return getPassedTimeText(
              time.seconds,
              time.minutes,
              time.hours,
              time.day
            );
          },
        },
        {
          title: "",
          compute: (deletedPatient: Patient) => {
            return (
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#4BC17E",
                }}
                onClick={() => setUndoRemovalPatient(deletedPatient)}
              >
                UNDO
              </p>
            );
          },
        },
      ],
    }),
    [getPassedTime, getPassedTimeText]
  );

  return (
    <>
      <div className="row align-center" style={{ marginBottom: "40px" }}>
        <Arrow onClick={() => navigate(routes.supervisor.main)} />
        <h2 style={{ marginLeft: "20px" }}>Removed Patients</h2>
      </div>
      {!deletedPatients || deletedPatients.length === 0 ? (
        <div className="info">
          <h3>No patients removed yet.</h3>
        </div>
      ) : (
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {metadata.columns.map((column) => (
                  <th
                    key={`th-label-${column.title}`}
                    className={column.className}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="interactive">
              {deletedPatients.map((activePerson) => (
                <tr key={`tr-${activePerson.id}`}>
                  {metadata.columns.map((column) => (
                    <td
                      key={`td-${activePerson.id}-${column.title}`}
                      className={`${column.className} ${
                        column.title === "Status" &&
                        `pill ${column
                          .compute(activePerson)
                          ?.toString()
                          .toLocaleLowerCase()}`
                      }`}
                    >
                      <p>{column.compute(activePerson)}</p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {undoRemovalPatient && (
        <RemovalConfirmation
          patient={undoRemovalPatient}
          onCancel={() => setUndoRemovalPatient(undefined)}
          onSubmit={() => store.dispatch(getRemovedPatientList())}
        />
      )}
    </>
  );
};
