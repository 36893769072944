export interface Activity {
  activityTypeName?: ActivityTypeName | string;
  activity?: string;
  activityType?: ActivityTypeEnum;
  dateTime: string;
  createdDateTime: string;
}

export enum ActivityTypeEnum {
  OtherActivity,
  NurseCallToNurse,
  FallRiskChanged,
  AudioReproduced,
  NurseDispatched,
  AudioIntervention,
  EscalationPathStarted,
  EscalationPathCompleted,
  FallRiskDeclined,
  FallRiskConfirmed,
  AlarmReproduced,
  PatientRemoved,
  CameraStart,
  CameraOff,
  EnableMicrophone,
  DisableMicrophone,
  AlarmOn,
  AlarmOff,
  SpeakerOn,
  SpeakerOff,
  NurseCallEnded,
  ContactAssigned,
  ContactNotAssigned,
  StaffArrived,
  PatientFell
}

export enum ActivityPillEnum {
  dispatch = 1,
  audioIntervention = 5,
  audioMessage = 3,
  sosAlert = 10,
  assignNurse = 4,
  contactExpiration = 0,
  callEnded = 20,
}

export type ActivityTypeName =
  | "OtherActivity"
  | "NurseCallToNurse"
  | "FallRiskChanged"
  | "AudioReproduced"
  | "NurseDispatched"
  | "AudioIntervention"
  | "EscalationPathStarted"
  | "EscalationPathCompleted"
  | "FallRiskDeclined"
  | "FallRiskConfirmed"
  | "AlarmReproduced"
  | "PatientRemoved"
  | "CameraStart"
  | "CameraOff"
  | "EnableMicrophone"
  | "DisableMicrophone"
  | "AlarmOn"
  | "AlarmOff"
  | "SpeakerOn"
  | "SpeakerOff";

export interface AddActivity {
  id: string;
  user: string;
  activity: string;
  clientDateTime: string;
  activityType: ActivityTypeEnum;
}
