import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  PillAlert,
  TimerStatus,
} from "../../interfaces/pillAlert/pillAlerts.interface";

export interface SosAlarmState {
  alarms: PillAlert[];
}

const initialState: SosAlarmState = {
  alarms: [],
};

export const sosAlarm = createSlice({
  name: "sosAlarm",
  initialState,
  reducers: {
    setNewAlarm: (state: SosAlarmState, action: PayloadAction<PillAlert>) => {
      const { room, timerStatus } = action.payload;

      const indexOfRoom = state.alarms.findIndex(
        (alarm) => alarm.room === room
      );

      if (indexOfRoom !== -1) {
        state.alarms[indexOfRoom] = {
          ...state.alarms[indexOfRoom],
          ...action.payload,
        };
        return;
      }

      state.alarms = [...state.alarms, { ...action.payload }];
    },
    removeAlarm: (
      state: SosAlarmState,
      action: PayloadAction<{ room: string }>
    ) => {
      const { room } = action.payload;

      state.alarms = state.alarms.filter((alarm) => alarm.room !== room);
    },
    setTimer: (
      state: SosAlarmState,
      action: PayloadAction<{ room: string; time: string }>
    ) => {
      const { room, time } = action.payload;

      const indexOfRoom = state.alarms.findIndex(
        (alarm) => alarm.room === room
      );

      state.alarms[indexOfRoom]["time"] = time;
    },
  },
});

export default sosAlarm.reducer;

export const { setNewAlarm, removeAlarm, setTimer } = sosAlarm.actions;
