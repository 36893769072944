import { useState } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import logger from "../../../../logger/logger";
import { undoDeletePatient } from "../../../../helpers/commandsPatient";
import { RemovedPatientsList } from "../RemovedPatientsList/RemovedPatientsList";

interface Props {
  onClick: () => void;
  isModalOpen: boolean;
  removedPatients: Patient[];
  removedPatientsLoading: boolean;
  error: boolean;
}

export const RemovedPatients: React.FC<Props> = ({
  onClick,
  isModalOpen,
  removedPatients,
  removedPatientsLoading,
  error,
}) => {
  const [loading, setloading] = useState(false);
  const [errorsById, setErrorsById] = useState<{ [key: string]: boolean }>({});

  const handleUndoRemoval = async (patientId: string) => {
    setErrorsById({ ...errorsById, [patientId]: false });
    try {
      setloading(true);
      await undoDeletePatient(patientId);
      onClick();
    } catch (e) {
      logger.error("Error undo removal", e);
      setErrorsById({ ...errorsById, [patientId]: true });
    } finally {
      setloading(false);
    }
  };

  if (removedPatients.length > 0 && !loading) {
    return (
      <div className="footer">
        <p className={`alert-text btn`} onClick={onClick}>
          {isModalOpen
            ? "Close removed patients"
            : "Removed patients (last 24 hrs)"}
        </p>
        {isModalOpen && (
          <RemovedPatientsList
            loading={loading || removedPatientsLoading}
            error={error}
            removedPatients={removedPatients}
            errorsById={errorsById}
            handleUndoRemoval={handleUndoRemoval}
          />
        )}
      </div>
    );
  }

  return <></>;
};
