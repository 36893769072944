import { PlayAudio } from "../../../../imgs/PlayAudio";
interface IconProps {
  loading: boolean;
  disabled: boolean;
  sendAudioMessage: () => any;
  playAudioMsg: boolean;
}

export const PlayButton: React.FC<IconProps> = ({
  disabled,
  loading,
  sendAudioMessage,
  playAudioMsg,
}) => {
  if (disabled || loading) {
    return <PlayAudio action={sendAudioMessage} disabled={true} />;
  }

  return (
    <PlayAudio
      action={sendAudioMessage}
      tooltipText={
        "Play message"
      }
      active={playAudioMsg}
      disabled={loading}
    />
  );
};
