import { useEffect } from "react";
import { AlarmIcon } from "../../../../imgs/AlarmIcon";
import { Check } from "../../../../imgs/Check";
import { ClockIcon } from "../../../../imgs/ClockIcon";
import { Nurse } from "../../../../imgs/Nurse";
import { QuestionIcon } from "../../../../imgs/Question";
import { ActivityPillEnum } from "../../../../interfaces/activity/activity.interface";
import { TimerStatus } from "../../../../interfaces/pillAlert/pillAlerts.interface";
import { TooltipText } from "../TooltipText/TooltipText";
import { tooltipTextPosition } from "../../../../utils/calculateTooltipPosition";
import React from "react";
import { useTimer } from "../../../../hooks/useTimer";
import { LabelOnPill } from "../LabelOnPill/LabelOnPill";

interface Props {
  type?: ActivityPillEnum;
  nurseArrived: boolean;
  tooltipText?: string;
  timerStatus?: TimerStatus;
  defaultStartSec?: number;
  defaultStartMin?: number;
  endDateTime?: string;
  onExpiration?: () => any | void;
  setTotalSeconds?: (arg: number) => void;
  saveTime?: (time: string) => any | void;
  saveExpirationTime?: (time: string) => any | void;
  finishTimer: boolean;
}

export const PillContent: React.FC<Props> = ({
  type,
  nurseArrived,
  tooltipText,
  timerStatus,
  defaultStartMin,
  defaultStartSec,
  endDateTime,
  onExpiration,
  saveTime,
  setTotalSeconds,
  saveExpirationTime,
  finishTimer,
}) => {
  const { timer, isTimerEnded, stopTimer, resumeTimer, Timer, totalSeconds } =
    useTimer(
      timerStatus === "play",
      1000,
      defaultStartMin,
      defaultStartSec,
      0,
      endDateTime
    );

  useEffect(() => {
    if (timerStatus) {
      resumeTimer();
      return;
    }

    stopTimer();
  }, [timerStatus]);

  useEffect(() => {
    if (!setTotalSeconds) {
      return;
    }
    setTotalSeconds(totalSeconds);
  }, [totalSeconds]);

  useEffect(() => {
    if (saveTime && !isTimerEnded) {
      saveTime(timer);
    }

    if (saveExpirationTime && !isTimerEnded) {
      saveExpirationTime(timer);
    }

    if (isTimerEnded) {
      onExpiration?.();
    }
  }, [timer, isTimerEnded]);

  const showIcons = () => {
    switch (type) {
      case ActivityPillEnum.dispatch:
        return (
          <>
            <Nurse />
            <LabelOnPill
              type={ActivityPillEnum.dispatch}
              isChecked={nurseArrived}
              Timer={Timer}
              tooltipText={tooltipText}
            />
          </>
        );
      case ActivityPillEnum.assignNurse:
        return (
          <>
            <Nurse /> <QuestionIcon />
            <TooltipText
              text={tooltipText}
              position={tooltipTextPosition(type)}
            />
          </>
        );
      case ActivityPillEnum.contactExpiration:
        return (
          <>
            <Nurse /> <ClockIcon />
            <TooltipText
              text={`${tooltipText} ${timer}`}
              position={tooltipTextPosition(type)}
            />
          </>
        );
      case ActivityPillEnum.sosAlert:
        return (
          <>
            <AlarmIcon noHover={true} />
            <LabelOnPill
              type={ActivityPillEnum.sosAlert}
              isChecked={finishTimer}
              Timer={Timer}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{showIcons()}</>;
};
