import { useEffect, useState } from "react";
import { CallLabelEnum } from "../../../../enums/callLabel";
import { useTimer } from "../../../../hooks/useTimer";
import { AudioAlert } from "../../atoms/AudioAlert/AudioAlert";
import { AiFeedback } from "../../../../interfaces/shared/shared.interface";

interface Props {
  microMuted: boolean;
  callLabel: CallLabelEnum;
  callLoading: boolean;
  aiFeedback: AiFeedback;
  playAudioMsg: boolean;
  callEnded: boolean;
  undoFeedbackAI: () => any;
}
export const CurrentAlert: React.FC<Props> = ({
  microMuted,
  callLabel,
  callLoading,
  aiFeedback,
  playAudioMsg,
  callEnded,
  undoFeedbackAI,
}) => {
  const { timer, resumeTimer, stopTimer, resetTimer } = useTimer(false);
  const [renderLoading, setRenderLoading] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (!microMuted && callLabel === CallLabelEnum.HangOn) {
      setIsSpeaking(true);
      resumeTimer();
      return;
    }
    stopTimer();
    resetTimer();
    setIsSpeaking(false);

    const audioInterventionLoading =
      !microMuted && callLabel === CallLabelEnum.Call;
    const audioMessageOrAlarmLoading = callLoading;
    const DELAY_TO_SHOW_lOADING = 2000;

    if (!audioInterventionLoading && !audioMessageOrAlarmLoading) {
      setRenderLoading(false);
      return;
    }
    const interval = setTimeout(() => {
      setRenderLoading(audioInterventionLoading || audioMessageOrAlarmLoading);
    }, DELAY_TO_SHOW_lOADING);

    return () => clearTimeout(interval);
  }, [microMuted, callLabel, callLoading]);

  if (callEnded) {
    return <AudioAlert text={"Audio intervention ended"} />;
  }

  if (isSpeaking) {
    return <AudioAlert text={`Speaking ${timer}`} />;
  }

  if (playAudioMsg) {
    return <AudioAlert text={"Playing audio..."} />;
  }

  if (aiFeedback.confirmation) {
    return (
      <AudioAlert
        text={"Fall risk alert agreed successfully."}
        undoAction={undoFeedbackAI}
        flatBorders={true}
      />
    );
  }

  if (aiFeedback.declination) {
    return (
      <AudioAlert
        text={"Fall risk alert disagreed successfully."}
        undoAction={undoFeedbackAI}
        flatBorders={true}
      />
    );
  }

  if (renderLoading) {
    return <AudioAlert text={"Loading..."} />;
  }

  return <></>;
};
