import React from "react";
import { Activity } from "../../../../interfaces/activity/activity.interface";
import { parseDate } from "../../../../utils/parseDate";

interface Props {
  activity: Activity;
}
export const AudioInfo: React.FC<Props> = ({ activity }) => {
  const { day, hour } = parseDate(activity.dateTime);

  return (
    <div className="call-info" aria-label="call-info">
      <div className="title">
        {day} <span className="hour">{hour}</span>
      </div>
    </div>
  );
};
