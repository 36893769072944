import moment from "moment";
import { sendVideoFlag } from "../helpers/socketVideo";
import { URLify } from "../utils/URLify";

export const useVideoRecording = () => {
  const startVideoRecord = ({
    admissionId,
    time,
    room,
    location,
    cartId,
    issue,
  }: {
    admissionId: string;
    room: string;
    time: number;
    location: string;
    cartId?: string;
    issue: string;
  }) => {
    if (time > 0) {
      const now = moment.now();
      const filename = `${admissionId}-${issue}-${now}`;
      const parsedFilename = URLify(filename, filename.length);
      sendVideoFlag("start-video-recording", {
        filename: parsedFilename,
        room,
        location,
        cartId,
        time,
      });
    }
  };

  return {
    startVideoRecord,
  };
};
