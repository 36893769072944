import { useState } from "react";
import { createContact } from "../../../../helpers/commandsContact";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { SelectPatient } from "../../atoms/SelectPatient/SelectPatient";

interface Props {
  patients: Patient[];
  patientsLoading: boolean;
}

export const AssignNurse: React.FC<Props> = ({ patients, patientsLoading }) => {
  const [nurse, setNurse] = useState({
    patientId: "",
    Name: "",
    phoneNumber: "",
    endDateTime: "",
    contactType: "",
    isPrimary: false,
  });
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSent(false);
    setNurse({
      ...nurse,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nurse) {
      try {
        setLoading(true);
        await createContact(nurse);
        setSent(true);
      } catch (e) {
        setError(JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="m-2">
      <h1>Nurse assignment</h1>
      <form className="form p-2" onSubmit={handleSubmit}>
        <div className="form-group row column my-1">
          <label htmlFor="patientId">Choose patient</label>
          {!loading && (
            <SelectPatient patients={patients} handleChange={handleChange} />
          )}
          {(loading || patientsLoading) && (
            <div className="loaderDiv">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="Name">Name</label>
          <input
            type="text"
            name="Name"
            onChange={handleChange}
            value={nurse.Name}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            type="text"
            name="phoneNumber"
            onChange={handleChange}
            value={nurse.phoneNumber}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="endDateTime">End shift time</label>
          <input
            type="datetime-local"
            name="endDateTime"
            onChange={handleChange}
            value={nurse.endDateTime}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="contactType">Contact Type</label>
          <select
            name="contactType"
            value={nurse.contactType}
            onChange={handleChange}
          >
            <option value="" disabled>--</option>
            <option value="PCT">PCT</option>
            <option value="Nurse">Nurse</option>
            <option value="ChargeNurse">ChargeNurse</option>
            <option value="UnitDirector">UnitDirector</option>
          </select>
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="contactType">¿Is it primary?</label>
          <select
            name="contactType"
            value={nurse.contactType}
            onChange={handleChange}
          >
            <option value={"false"}>No</option>
            <option value={"true"}>Yes</option>
          </select>
        </div>
        <button type="submit" className="btn-xsmall light">
          Submit
        </button>
        {error && <h4>{error}</h4>}
      </form>
      {sent && <p>Nurse assigned successfully</p>}
    </div>
  );
};
