import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectedPatientState {
  patientId: string;
}

const initialState: SelectedPatientState = {
  patientId: "",
};

export const selectedPatientSlice = createSlice({
  name: "selectedPatient",
  initialState,
  reducers: {
    setSelectedPatient: (
      state: SelectedPatientState,
      action: PayloadAction<string>
    ) => {
      state.patientId = action.payload;
    },
    removeSelectedPatients: (state: SelectedPatientState) => {
      state.patientId = "";
    },
  },
});

export default selectedPatientSlice.reducer;

export const { setSelectedPatient, removeSelectedPatients } =
  selectedPatientSlice.actions;
