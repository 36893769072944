import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import logger from "../logger/logger";

class axiosHttpClient {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create();
  }

  async getData(url: string, config?: AxiosRequestConfig<{}> | undefined) {
    try {
      if (!url || url === "") throw new Error("URL should not be empty");

      const response = await this.instance.get(url, config);
      return new HttpResponse(response.status, response.data);
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async postData(
    url: string,
    data?: {},
    config?: AxiosRequestConfig<{}> | undefined
  ) {
    try {
      if (!url || url === "") throw new Error("URL should not be empty");

      const response = await this.instance.post(url, data, config);
      return new HttpResponse(response.status, response.data);
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async putData(
    url: string,
    data?: {},
    config?: AxiosRequestConfig<{}> | undefined
  ) {
    try {
      if (!url || url === "") throw new Error("URL should not be empty");

      const response = await this.instance.put(url, data, config);
      return new HttpResponse(response.status, response.data);
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async patchData(
    url: string,
    data?: {},
    config?: AxiosRequestConfig<{}> | undefined
  ) {
    try {
      if (!url || url === "") throw new Error("URL should not be empty");

      const response = await this.instance.patch(url, data, config);
      return new HttpResponse(response.status, response.data);
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async deleteData(url: string, data?: {}) {
    try {
      if (!url || url === "") throw new Error("URL should not be empty");

      const response = await this.instance.delete(url, { data });
      return new HttpResponse(response.status, response.data);
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  private async handleError(error: any) {
    logger.error("Axios instance error", error);
    let status: number = 418;
    let data: any | undefined;

    if (error && error.response?.status) status = error.response.status;
    if (error && error.response?.data) data = error.response.data;
    return new HttpResponse(status, data);
  }
}
export default new axiosHttpClient();

class HttpResponse {
  constructor(status: number, data: any) {
    this.status = status;
    this.data = data;
    this.isSuccessStatusCode = this.status >= 200 && this.status < 300;
  }
  readonly status: number;
  readonly data: any;
  readonly isSuccessStatusCode: boolean;
}
