import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { Contacts } from "../../../../interfaces/contacts/contacts.interface";
import { DetailedContact } from "../../atoms/DetailedContact/DetailedContact";
import { ContactForm } from "../../atoms/ContactForm/ContactForm";
import { EditIcon } from "../../../../imgs/EditIcon";
import {
  resetAllForms,
  setForm,
} from "../../../../features/openForms/openFormSlice";
import { Inputs } from "../../../../interfaces/shared/shared.interface";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { getPatientById } from "../../../../helpers/patients";
import { updatePatientByProp } from "../../../../features/patients/patientsSlice";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import logger from "../../../../logger/logger";
import { cutString } from "../../../../utils/cutString";
import { OpenFormsState } from "../../../../features/openForms/openFormSlice";

const metadataContacts: {
  title: string;
  formName: "pctInput" | "nurseInput" | "chargeNurseInput" | "unitDirInput";
  info: Contacts;
}[] = [
  {
    title: "PCT",
    formName: "pctInput",
    info: {
      id: "",
      Name: "",
      phone: "",
      endDateTime: "",
      type: "PCT",
    },
  },
  {
    title: "Nurse",
    formName: "nurseInput",
    info: {
      id: "",
      Name: "",
      phone: "",
      endDateTime: "",
      type: "Nurse",
    },
  },
  {
    title: "Charge Nurse",
    formName: "chargeNurseInput",
    info: {
      id: "",
      Name: "",
      phone: "",
      endDateTime: "",
      type: "ChargeNurse",
    },
  },
  {
    title: "Unit Director",
    formName: "unitDirInput",
    info: {
      id: "",
      Name: "",
      phone: "",
      endDateTime: "",
      type: "UnitDirector",
    },
  },
];

interface Props {
  patient: Patient;
}
export const ContactsList: React.FC<Props> = ({ patient }) => {
  const dispatch = useDispatch();
  const { pctInput, nurseInput, unitDirInput, chargeNurseInput } = useSelector(
    (state: RootState) => state.openForms
  );
  const { list: patients } = useSelector((state: RootState) => state.patients);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const { contactsByPatient } = useSelector(
    (state: RootState) => state.assignedContact
  );
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [forms, setForms] = useState<Pick<
    OpenFormsState,
    "pctInput" | "nurseInput" | "unitDirInput" | "chargeNurseInput"
  > | null>();

  useEffect(() => {
    setForms({
      pctInput,
      nurseInput,
      unitDirInput,
      chargeNurseInput,
    });
  }, [pctInput, nurseInput, unitDirInput, chargeNurseInput]);

  const handleOpenForm = (formName: Inputs) => {
    dispatch(resetAllForms());
    dispatch(
      setForm({
        typeInput: formName,
        status: true,
      })
    );
  };

  const handleCloseForm = (formName: Inputs) => {
    setIsEditClicked(false);
    dispatch(
      setForm({
        typeInput: formName,
        status: false,
      })
    );
  };

  useEffect(() => {
    if (newContact) {
      setTimeout(() => {
        patient.unitId && updatePatientsByUnitId(patients, patient.unitId);
        setNewContact(false);
      }, 500);
    }
  }, [newContact]);

  const updatePatientsByUnitId = async (
    patients: Patient[],
    unitId: string
  ) => {
    const filteredPatients = patients.filter((pat) => pat.unitId === unitId);

    logger.log(
      `Shared contact patients ${filteredPatients.length} with location ${unitId}`
    );

    filteredPatients.forEach(async (pat, i) => {
      try {
        setLoading(true);
        await updatePatientFromBack(pat.id);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    });
  };

  const updatePatientFromBack = async (id: string) => {
    logger.log(`Fetch filtered patient by id ${id}`);
    const patient = await getPatientById(id);
    patient &&
      dispatch(updatePatientByProp({ data: patient, prop: "contacts" }));
  };

  const AddContactLabel: React.FC<{ formName: Inputs }> = ({ formName }) => {
    const isContactAssigned =
      contactsByPatient[patient.roomNumber]?.assigned && patient.contacts?.[0];
    if (isContactAssigned) {
      return (
        <div
          className="p-alert low-priority"
          onClick={() => !newContact && !loading && handleOpenForm(formName)}
          aria-label="no-contact-alert"
        >
          <p>Add Contact</p>
        </div>
      );
    }

    return (
      <div
        className="p-alert"
        onClick={() => !newContact && !loading && handleOpenForm(formName)}
        aria-label="no-contact-alert"
      >
        <p>Contact not assigned</p>
      </div>
    );
  };

  const HeaderWrapper: React.FC<{
    rowAligned?: boolean;
    title: string;
    children: any;
    contactName?: string;
  }> = ({ rowAligned = true, children, title, contactName }) => {
    return (
      <div
        className={`contact-header ${rowAligned && `row wrap align-center`}`}
      >
        <h2 className="contact-type">{title}</h2>
        {contactName &&
          contactName
            .split(" ")
            .map((word) => (
              <h2
                className="contact-name"
                key={`word-${contactName}-${word}`}
              >{`${word} `}</h2>
            ))}
        {children}
      </div>
    );
  };

  const ContactHeader: React.FC<{
    title: string;
    contactName?: string;
    loading: boolean;
    formName: Inputs;
  }> = ({ title, contactName, loading, formName }) => {
    if (loading) {
      return (
        <HeaderWrapper title={title}>
          <div className="loaderDiv">
            <div className="loader small"></div>
          </div>
        </HeaderWrapper>
      );
    }

    if (!contactName) {
      return (
        <HeaderWrapper title={title} rowAligned={false}>
          <AddContactLabel formName={formName} />
        </HeaderWrapper>
      );
    }

    return (
      <HeaderWrapper title={title} contactName={contactName}>
        <EditIcon
          invisible={false}
          action={() => {
            setIsEditClicked(true);
            handleOpenForm(formName);
          }}
        />
      </HeaderWrapper>
    );
  };

  return (
    <div className="assign-contact-form" aria-label="contact-list">
      {metadataContacts.map((metadata) => {
        const currContact = patient?.contacts?.find(
          (con) => con.type === metadata.info.type
        );

        if (forms?.[metadata.formName].status) {
          const isAboutToExpire =
            currContact?.isAboutToExpire ||
            contactsByPatient[patient.roomNumber]?.contactsToExpire?.some(
              (con) => con.id === currContact?.id
            );
          return (
            <div className="detailed-contact-bx" key={metadata.title}>
              <ContactHeader
                loading={loading}
                contactName={currContact?.Name}
                title={metadata.title}
                formName={metadata.formName}
              />
              {currContact && !loading && (
                <DetailedContact contact={currContact} />
              )}
              <ContactForm
                savedName={forms[metadata.formName].name}
                savedPhone={forms[metadata.formName].phone}
                contact={currContact}
                emptyInputs={isAboutToExpire && !isEditClicked}
                formName={metadata.formName}
                newContact={newContact}
                setNewContact={setNewContact}
                patientId={patient?.id || ""}
                location={patient?.location || ""}
                contactType={metadata.info.type}
                onCancel={() => handleCloseForm(metadata.formName)}
              />
            </div>
          );
        }

        if (currContact) {
          return (
            <div className="detailed-contact-bx" key={metadata.title}>
              <ContactHeader
                loading={loading}
                contactName={currContact?.Name}
                title={metadata.title}
                formName={metadata.formName}
              />
              {currContact && !loading && (
                <DetailedContact contact={currContact} />
              )}
              {error && (
                <ErrorMsg
                  errorMsg={ErrorTypeEnum.Retry}
                  filled={false}
                  closeBtn={false}
                  padding={"none"}
                  position={"inline"}
                />
              )}
            </div>
          );
        }

        return (
          <div className="detailed-contact-bx" key={metadata.title}>
            <ContactHeader
              loading={loading}
              title={metadata.title}
              formName={metadata.formName}
            />
            {error && (
              <ErrorMsg
                errorMsg={ErrorTypeEnum.Retry}
                filled={false}
                closeBtn={false}
                padding={"none"}
                position={"inline"}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
