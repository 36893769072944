import { AudioOperationsEnum } from "../enums/apisOperations";
import axiosHttpClient from "../utils/axiosHttpClient";
import EndpointGenerator from "../utils/EndpointGenerator";

export const playAudioMessage = async (
  location: string,
  room: string,
  lang: string,
  audioType: AudioOperationsEnum,
  cartId?: string
) => {
  if (!location || !room) {
    throw "Info is missing to play an audio";
  }

  const endpoint = EndpointGenerator.generateAudioQueryEndpoint(
    audioType,
    cartId || `${location}-${room}`,
    lang
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (
    response.isSuccessStatusCode &&
    response.data?.Status?.id &&
    !response.data?.Status?.error
  ) {
    return response.data;
  }

  if (response.data?.Status?.error) {
    throw response.data?.Status?.error;
  }

  throw response.status;
};

export const stopAudioMessage = async (
  location: string,
  room: string,
  cartId?: string
) => {
  const endpoint = EndpointGenerator.generateAudioQueryEndpoint(
    AudioOperationsEnum.stopRoom,
    cartId || `${location}-${room}`
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode && !response.data?.Status?.error) {
    return response.data;
  }

  if (response.data?.Status?.error) {
    throw response.data?.Status?.error;
  }

  throw response.status;
};
