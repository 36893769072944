export enum LoggerLevelEnum {
  info = 0,
  warn = 1,
  error = 2,
}

export enum LoggerLevelColorEnum {
  blue = 0,
  yellow = 1,
  red = 2,
}
