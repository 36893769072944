import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Inputs } from "../../interfaces/shared/shared.interface";

export interface OpenFormsState {
  noteInput: { status: boolean; text: string };
  newCallInput: { status: boolean; text: string };
  otherOptionInput: { status: boolean; text: string };
  pctInput: { status: boolean; name: string; phone: string };
  nurseInput: { status: boolean; name: string; phone: string };
  unitDirInput: { status: boolean; name: string; phone: string };
  chargeNurseInput: { status: boolean; name: string; phone: string };
  removeInput: { status: boolean; text: string };
  userInput: { status: boolean; user: string; pwd: string };
}

const initialState: OpenFormsState = {
  noteInput: { status: false, text: "" },
  newCallInput: { status: false, text: "" },
  otherOptionInput: { status: false, text: "" },
  pctInput: { status: false, name: "", phone: "" },
  nurseInput: { status: false, name: "", phone: "" },
  unitDirInput: { status: false, name: "", phone: "" },
  chargeNurseInput: { status: false, name: "", phone: "" },
  removeInput: { status: false, text: "" },
  userInput: { status: false, user: "", pwd: "" },
};

export const openFormsSlice = createSlice({
  name: "openForms",
  initialState,
  reducers: {
    setForm: (
      state: OpenFormsState,
      action: PayloadAction<{
        typeInput: Inputs;
        status: boolean;
        text?: string;
        name?: string;
        phone?: string;
        user?: string;
        pwd?: string;
      }>
    ) => {
      const { typeInput, status, text, name, phone, user, pwd } =
        action.payload;

      if (
        typeInput === "pctInput" ||
        typeInput === "nurseInput" ||
        typeInput === "unitDirInput" ||
        typeInput === "chargeNurseInput"
      ) {
        state[typeInput] = { status, name: name || "", phone: phone || "" };
        return;
      }

      if (typeInput === "userInput") {
        state.userInput = { status, user: user || "", pwd: pwd || "" };
        return;
      }

      state[typeInput] = { status, text: text || "" };
    },
    resetAllForms: (state: OpenFormsState) => {
      state.noteInput = { status: false, text: "" };
      state.newCallInput = { status: false, text: "" };
      state.otherOptionInput = { status: false, text: "" };
      state.removeInput = { status: false, text: "" };
      state.pctInput = { status: false, name: "", phone: "" };
      state.nurseInput = { status: false, name: "", phone: "" };
      state.unitDirInput = { status: false, name: "", phone: "" };
      state.chargeNurseInput = { status: false, name: "", phone: "" };
      state.userInput = { status: false, user: "", pwd: "" };
    },
  },
});

export default openFormsSlice.reducer;

export const { setForm, resetAllForms } = openFormsSlice.actions;
