import { useNavigate } from "react-router-dom";
import { ConnectionInfo } from "../../../../interfaces/connections/connections.interface";
import { TableMetada } from "../../../../interfaces/shared/shared.interface";
import {
  getPassedTime,
  getPassedTimeText,
} from "../../../../utils/timeOperations";
import routes from "../../../../routes";
import { Nurse } from "../../../../imgs/Nurse";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { ESitterIcons } from "../../atoms/ESitterIcons/ESitterIcons";
import { MAX_PATIENTS } from "../../../../features/patients/patientsSlice";

const metadata: TableMetada = {
  columns: [
    {
      title: "Name",
      compute: (connection: ConnectionInfo) => (
        <ESitterIcons eSitter={connection} />
      ),
      className: "main-td",
    },
    {
      title: "Status",
      compute: (connection: ConnectionInfo) =>
        connection.isOnBreak ? "On break" : "Active",
    },
    {
      title: "Patients",
      className: "text-center",
      compute: (connection: ConnectionInfo) => {
        if (connection.isOnBreak) {
          return "-";
        }

        if (connection.totalPatientsAssigned > MAX_PATIENTS) {
          return (
            <p>
              {MAX_PATIENTS}{" "}
              <span style={{ fontWeight: 500, color: "#cf6679" }}>{`(${
                connection.totalPatientsAssigned - MAX_PATIENTS
              } unmonitored)`}</span>
            </p>
          );
        }

        return connection.totalPatientsAssigned;
      },
    },
    {
      title: "Time online",
      compute: (connection: ConnectionInfo) => {
        const time = getPassedTime(connection.createdDate, true, true);

        return getPassedTimeText(
          time.seconds,
          time.minutes,
          time.hours,
          time.day
        );
      },
    },
    {
      title: "Workload",
      compute: (connection: ConnectionInfo) => {
        let color: string = "green";
        let widthPercentage: number = connection.workload?.total || 0;
        if (widthPercentage > 33) {
          color = "yellow";
        }
        if (widthPercentage > 66) {
          color = "red";
        }
        if (widthPercentage > 100) {
          widthPercentage = 100;
        }

        return (
          <div className="progress_container">
            <div
              className={`progress ${color}`}
              style={{
                width: `${widthPercentage}%`,
              }}
            ></div>
            <span>{connection.workload?.total || 0}%</span>
          </div>
        );
      },
    },
  ],
};

export const ESitterList: React.FC = () => {
  const navigate = useNavigate();
  const { list: eSitters } = useSelector(
    (state: RootState) => state.supervisors
  );

  return (
    <>
      <h2 style={{ marginBottom: "40px" }}>E-sitters</h2>
      <button
        className="btn btn-small transparent outlined icon"
        type="button"
        style={{ position: "fixed", top: "4rem", right: "2rem" }}
        onClick={() =>
          navigate(
            `/${routes.supervisor.parent}/${routes.supervisor.nested[0].main}/${routes.supervisor.nested[0].nested[0].main}`
          )
        }
      >
        <Nurse />
        Manage Contacts
      </button>
      <button
        className="btn alert-text fixed-button"
        onClick={() =>
          navigate(
            `/${routes.supervisor.parent}/${routes.supervisor.nested[0].main}/${routes.supervisor.nested[0].nested[1].main}`
          )
        }
      >
        Removed Patients (last 24hrs)
      </button>
      {!eSitters.length ? (
        <div className="info">
          <h3>No E-sitters assigned yet.</h3>
          <p>When E-sitters are added, you will see their information here.</p>
        </div>
      ) : (
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {metadata.columns.map((column) => (
                  <th
                    key={`th-label-${column.title}`}
                    className={column.className}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="interactive">
              {eSitters.map((esitter) => (
                <tr
                  key={`tr-${esitter.id}`}
                  onClick={() =>
                    navigate(`${routes.supervisor.main}/${esitter.id}`)
                  }
                >
                  {metadata.columns.map((column) => (
                    <td
                      key={`td-${esitter.id}-${column.title}`}
                      className={`${column.className} ${
                        column.title === "Status" &&
                        `pill ${column
                          .compute(esitter)
                          ?.toString()
                          .toLocaleLowerCase()}`
                      }`}
                    >
                      {typeof column.compute(esitter) === "string" ? (
                        <p>{column.compute(esitter)}</p>
                      ) : (
                        column.compute(esitter)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
