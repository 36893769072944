import { useNavigate, useParams } from "react-router-dom";
import store, { RootState } from "../../../../stores";
import { useSelector } from "react-redux";
import { Arrow } from "../../../../imgs/Arrow";
import routes from "../../../../routes";
import { ContactTypeEnum } from "../../../../enums/contactsEnum";
import {
  AddContact,
  ContactType,
  NurseUnit,
} from "../../../../interfaces/contacts/contacts.interface";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import logger from "../../../../logger/logger";
import { getNurseUnitsByLocation } from "../../../../helpers/patients";
import moment from "moment";
import { nextEndShift } from "../../../../utils/nextEndShift";
import { createContact } from "../../../../helpers/commandsContact";
import { Alert } from "../../atoms/Alert/Alert";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import { addZeroToHour } from "../../../../utils/addZeroToHour";
import TimePicker from "react-time-picker";
import { getContactsByLocationList } from "../../../../features/supervisors/supervisorsSlice";

const roles: { value: ContactType; label: ContactTypeEnum }[] = [
  {
    value: "ChargeNurse",
    label: ContactTypeEnum.ChargeNurse,
  },
  {
    value: "UnitDirector",
    label: ContactTypeEnum.UnitDirector,
  },
];

const LABEL_TIMER = 2000;
const initialState = {
  Name: "",
  phoneNumber: "",
  unitId: "",
  contactType: "",
  endDateTime: moment.utc(nextEndShift()).local().format(),
};

export const SuperAddContact = () => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { locations } = useSelector((state: RootState) => state.supervisors);
  const [values, setValues] = useState<AddContact>(initialState);
  const [hour, setHour] = useState(
    addZeroToHour("", true) + moment(nextEndShift()).format("LT").split(" ")[0]
  );
  const [ltTime, setLtTime] = useState(
    moment.utc(nextEndShift()).local().format("A")
  );
  const [nurseUnits, setNurseUnits] = useState<NurseUnit[] | null>(null);
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorTypeEnum | undefined>();
  const [showLabel, setShowLabel] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const controller = useMemo(() => new AbortController(), []);
  const previousRoute = useMemo(
    () =>
      `${routes.supervisor.main}/${routes.supervisor.nested[0].nested[0].main}/${locationId}`,
    [locationId]
  );

  useEffect(() => {
    const fetchUnits = async (locationId: string) => {
      try {
        setUnitsLoading(true);
        const units: NurseUnit[] = await getNurseUnitsByLocation(locationId);
        setNurseUnits(units);
      } catch (e) {
        logger.error(`Error fetching units ${e}`);
      } finally {
        setUnitsLoading(false);
      }
    };

    if (locationId) {
      fetchUnits(locationId);
    }

    return () => {
      if (loading) {
        controller.abort();
      }
    };
  }, []);

  const handleChangeHour = (value: any) => {
    setHour(value);
  };

  const handleChangeLT = (e: ChangeEvent<HTMLInputElement>) => {
    setLtTime(e.target.value);
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (newContact) {
      setTimeout(() => {
        locationId && store.dispatch(getContactsByLocationList(locationId));
        setNewContact(false);
        navigate(previousRoute);
      }, LABEL_TIMER);
    }
  }, [newContact]);

  const handleValidation = () => {
    if (
      !values.Name ||
      !values.unitId ||
      !values.phoneNumber ||
      !values.contactType
    ) {
      setShowLabel(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setShowLabel(false);
      setLoading(true);
      const response = await createContact(values, {
        signal: controller.signal,
      });
      logger.log(`New contact ${response}`);
      setNewContact(true);
      setValues(initialState);
    } catch (e) {
      logger.error(`Error adding new contact ${JSON.stringify(e)}`);
      switch (e) {
        case 404:
          setError(ErrorTypeEnum.NotFound);
          break;
        case 400:
          setError(ErrorTypeEnum.Retry);
          break;
        case 418:
          setError(ErrorTypeEnum.NetworkError);
          break;
        case 500:
          setError(ErrorTypeEnum.CommandContactsAPI);
          break;
        default:
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row align-center" style={{ marginBottom: "40px" }}>
        <Arrow onClick={() => navigate(previousRoute)} />
        <h2 style={{ marginLeft: "20px", marginRight: "20px" }}>
          Add Care Team Member
        </h2>
      </div>

      <div className="card">
        <form action="post" onSubmit={handleSubmit}>
          <div className="section m-1 form-control">
            <label>Hospital</label>
            <p className="bold mt-1">
              {locations.find((loc) => loc.id === locationId)?.name}
            </p>
          </div>
          <div className="section m-1 form-control">
            <label htmlFor="unitId">*Unit</label>
            <select
              required
              id="unitId"
              name="unitId"
              onChange={handleChange}
              value={values.unitId}
              className="select"
            >
              <option value="" disabled>
                {unitsLoading ? "Loading..." : "Select"}
              </option>
              {!unitsLoading &&
                nurseUnits &&
                nurseUnits.map((unit) => (
                  <option value={unit.id} key={unit.id}>
                    {unit.name}
                  </option>
                ))}
            </select>
            {showLabel && !values.unitId && (
              <label htmlFor={`Name`} className="error-label">
                <InfoIcon /> Unit is required
              </label>
            )}
          </div>
          <div className="section m-1 form-control">
            <label htmlFor="contactType">*Role</label>
            <select
              required
              id="contactType"
              name="contactType"
              onChange={handleChange}
              value={values.contactType}
            >
              <option value="" disabled>
                Select
              </option>
              {roles.map((role) => (
                <option value={role.value} key={`option-${role.value}`}>
                  {role.label}
                </option>
              ))}
            </select>
            {showLabel && !values.contactType && (
              <label htmlFor={`contactType`} className="error-label">
                <InfoIcon /> Role is required
              </label>
            )}
          </div>
          {values.unitId && values.contactType && (
            <>
              <div className="section m-1 form-control">
                <label htmlFor="Name">*Name</label>
                <input
                  required
                  type="text"
                  id="Name"
                  name="Name"
                  maxLength={30}
                  onChange={handleChange}
                  value={values.Name}
                />
                {showLabel && !values.Name && (
                  <label htmlFor={`Name`} className="error-label">
                    <InfoIcon /> Name is required
                  </label>
                )}
              </div>
              <div className="section m-1 form-control">
                <label htmlFor="phoneNumber">*Phone Number</label>
                <input
                  required
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  maxLength={30}
                  onChange={handleChange}
                  value={values.phoneNumber}
                />
                <p className="helper-text">Phone number and extension</p>
                {showLabel && !values.phoneNumber && (
                  <label htmlFor={`phoneNumber`} className="error-label">
                    <InfoIcon /> Phone number is required
                  </label>
                )}
              </div>
              {values.contactType !== "UnitDirector" && (
                <div className="form-control small">
                  <label htmlFor="endDateTime">*Shift end time</label>
                  <div className="row">
                    <TimePicker
                      className="time-picker"
                      disableClock={true}
                      required
                      format={"HH:mm"}
                      minTime="01:00"
                      maxTime="12:59"
                      name={`hour-input`}
                      hourAriaLabel={`hour-input`}
                      clearIcon={null}
                      value={hour}
                      onChange={handleChangeHour}
                    />
                    <div className="form-control halfWidth m-0 radio-option">
                      <input
                        type="radio"
                        name="ltTime"
                        id="ltTime"
                        value={"AM"}
                        aria-label={`am-radio`}
                        required
                        onChange={handleChangeLT}
                        checked={ltTime === "AM"}
                      />
                      <label htmlFor="ltTime">am</label>
                    </div>
                    <div className="form-control halfWidth m-0 radio-option">
                      <input
                        type="radio"
                        name="ltTime"
                        id="ltTime"
                        value={"PM"}
                        aria-label={`pm-radio`}
                        required
                        onChange={handleChangeLT}
                        checked={ltTime === "PM"}
                      />
                      <label htmlFor="ltTime">pm</label>
                    </div>
                  </div>
                </div>
              )}
              <div className="row btn-group left mt-3">
                <button
                  className={`btn btn-small transparent outlined ${
                    loading ? "disabled" : ""
                  }`}
                  type="reset"
                  disabled={loading}
                  onClick={() => navigate(previousRoute)}
                >
                  CANCEL
                </button>
                <button
                  className="btn btn-small light"
                  aria-label="submit-btn"
                  onClick={handleValidation}
                >
                  SAVE
                </button>
              </div>
            </>
          )}
          {newContact && (
            <Alert
              text={`Contact saved successfully`}
              type={"success"}
              closeBtn={false}
            />
          )}
          {loading && !newContact && (
            <Alert text={"Submitting..."} type={"info"} closeBtn={false} />
          )}
          {error && (
            <div className="mt-1">
              <ErrorMsg
                errorMsg={error}
                filled={false}
                closeBtn={false}
                padding={"none"}
                position={"inline"}
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};
