import { RiskLevel } from "../interfaces/fallRisk/fallRisk.interface";

export const OutOfBedIcon = ({ color = "normal" }: { color?: RiskLevel }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20336 9.43035C2.31352 9.33439 2.48104 9.2279 2.57551 9.19377C2.80258 9.1119 3.16385 9.21106 3.37707 9.41397C3.52169 9.5515 3.55787 10.2521 3.60608 13.8389L3.66334 16.9708L11.994 16.9901L11.9487 20.6845L12.1403 23.4615L3.66334 23.4822L3.63024 24.6209C3.61215 25.2471 3.54139 25.8269 3.47314 25.9091C3.40489 25.9913 3.12767 26.0586 2.85709 26.0586C2.77862 26.0586 2.70731 26.0644 2.6425 26.0696C2.52172 26.0794 2.42352 26.0873 2.34371 26.0527C1.99665 25.9025 1.99747 24.9487 2.00184 19.8477C2.00238 19.2179 2.00297 18.5248 2.00297 17.7623C2.00297 10.1363 2.01603 9.59364 2.20336 9.43035ZM12.9709 20.6871L13.4376 16.9934L15.3021 16.9977L14.7027 21.0936L14.7902 23.4551L13.162 23.4591L12.9709 20.6871ZM16.316 23.4515L17.6714 23.4483L17.8618 20.6872L17.6816 17.0031L16.2783 17L15.6115 16.9985L16.0281 17.1175L16.316 23.4515ZM18.6935 23.446L28.2814 23.4238V24.5612C28.2814 25.4427 28.3219 25.7393 28.4613 25.8787C28.6773 26.0946 29.6585 26.1243 29.8616 25.9212C29.9625 25.8203 29.999 24.8508 29.999 22.2724C29.999 18.8955 29.99 17.6199 29.7621 17.3304L29.5253 17.0292L18.7023 17.0053L18.884 20.6846L18.6935 23.446Z"
        fill="#FF0000"
      />
      <path
        d="M15.4357 17.7989C15.3028 18.8526 15.0466 19.8838 15.0114 20.9584C14.9666 22.3168 14.9714 23.6768 14.9282 25.0353C14.9026 25.8516 14.4127 26.2333 13.6746 26.0392C13.2936 25.9398 13.0854 25.6928 13.0678 25.2967C13.0198 24.259 12.9718 23.2214 12.9301 22.1821C12.9045 21.5566 12.9189 20.9311 12.8629 20.3056C12.778 19.3802 13.0358 18.4805 13.1191 17.5663C13.2119 16.5592 13.3784 15.5584 13.4649 14.5512C13.5385 13.706 13.2535 12.8913 13.1367 12.0621C13.0054 11.1399 12.8229 10.2241 12.6756 9.30355C12.6275 8.99882 12.5075 8.75665 12.2625 8.55938C10.5206 7.16407 9.00931 5.53621 7.59083 3.81693C7.15856 3.29249 7.36028 2.63172 8.01669 2.43927C8.32568 2.34945 8.55943 2.48417 8.75635 2.7087C9.43357 3.47853 10.1236 4.23713 10.7848 5.02299C11.5149 5.89065 12.4674 6.49047 13.3208 7.20898C13.4409 7.31002 13.5881 7.31643 13.737 7.31643C14.8417 7.31643 15.9464 7.30841 17.0511 7.32124C17.3185 7.32445 17.4962 7.18973 17.6931 7.0518C19.2332 5.98688 20.4388 4.58355 21.6379 3.1722C21.7676 3.01984 21.8973 2.86748 22.035 2.72314C22.4048 2.33662 22.741 2.31417 23.114 2.63974C23.5399 3.01182 23.5879 3.37428 23.2373 3.78325C21.9037 5.34055 20.6005 6.92992 18.9259 8.14881C18.5384 8.43108 18.3319 8.7791 18.2598 9.26826C18.0389 10.7566 17.7571 12.2353 17.5266 13.7236C17.3985 14.5528 17.6114 15.3691 17.7059 16.1871C17.8356 17.3113 18.0085 18.434 18.1045 19.5583C18.1894 20.5462 18.0741 21.5534 18.0357 22.5526C18.0021 23.4443 17.954 24.336 17.9108 25.2277C17.8852 25.7554 17.613 26.0376 17.0959 26.0745C16.5244 26.1146 16.1625 25.8644 16.0889 25.3448C16.0457 25.0433 16.0441 24.7337 16.0361 24.4274C16.004 23.0818 16.0168 21.733 15.9304 20.3922C15.8744 19.523 15.6758 18.6617 15.5414 17.7973C15.5045 17.7989 15.4677 17.8005 15.4293 17.8021L15.4357 17.7989Z"
        fill="#FF0000"
      />
      <path
        d="M17.1936 5.14909C17.1872 6.09694 16.4043 6.87158 15.4549 6.86677C14.5295 6.86356 13.7322 6.05524 13.729 5.11862C13.7258 4.17558 14.5119 3.39614 15.4645 3.39453C16.4459 3.39453 17.2 4.15955 17.1936 5.14909Z"
        fill="#FF0000"
      />
    </svg>
  );
};
