import moment from "moment";
import { nextEndShift } from "./nextEndShift";

export const calculateDateTime = (
  hour: string = "",
  period: string = "",
  virtualTime: string = ""
) => {
  if (!hour) {
    hour = moment().format("hh");
  }
  if (!period) {
    period = moment().format("A");
  }

  let hour24 = moment(`${hour} ${period}`, "LT").format("HH:mm:ss");
  let currHour = moment().format("HH:mm:ss");

  if (virtualTime) {
    currHour = moment(virtualTime).format("HH::mm:ss");
  }

  let timeZome = moment().format("Z");

  let dateTime = `${moment().format("YYYY-MM-DD")}T${hour24}${timeZome}`;

  if (currHour > hour24) {
    return moment(dateTime).add(1, "days").format();
  }

  return dateTime;
};

export const newEndDateTimeWithTZ = (
  inputHour: string = "",
  inputLT: string = "",
  currentUTCEndDateTime: string = "",
  virtualTime: string = ""
) => {
  if (!inputLT) {
    inputLT = moment
      .utc(currentUTCEndDateTime || nextEndShift())
      .local()
      .format("A");
  }

  if (inputHour && inputLT && virtualTime) {
    return calculateDateTime(inputHour, inputLT, virtualTime);
  }

  if (inputHour && inputLT) {
    return calculateDateTime(inputHour, inputLT);
  }

  if (inputLT && currentUTCEndDateTime) {
    let hour24Format = moment
      .utc(`${currentUTCEndDateTime}`)
      .local()
      .format("hh:mm");

    return calculateDateTime(hour24Format, inputLT);
  }

  return calculateDateTime("07:00", inputLT);
};
