const routes = {
  testing: {
    main: "/testing",
  },
  commands: {
    main: "/commands",
  },
  login: {
    main: "/login",
  },
  eSitter: {
    main: "/",
  },
  supervisor: {
    main: "/supervisor/e-sitters",
    parent: "supervisor",
    nested: [
      {
        main: "e-sitters",
        nested: [
          {
            main: "manage_contacts",
            nested: [
              {
                main: "add",
              },
            ],
          },
          {
            main: "removed_patients",
            nested: []
          }
        ],
      },
    ],
  },
};

export default routes;
