export enum ConnectionsEnum {
  TakeABreak,
  AddActivity,
  ReturnFromBreak,
  SwapUser,
  Analytics,
  workload,
  Logout,
  AddPatient
}

export enum ConnectionActivityEnum {
  BrowserActive,
  BrowserInactive,
  NetworkIssues,
}
