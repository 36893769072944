import { Patient } from "../interfaces/patients/patients.interface";

export const filterPatientsById = (
  patients: Patient[],
  ids: string[]
): Patient[] => {

  const patientsArray: Patient[] = []

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    for (let j = 0; j < patients.length; j++) {
      const patient = patients[j];

      if (patient.id === id) {
        patientsArray.push(patient)
        break;
      }
    }
  }

  return patientsArray
};
