import { useDispatch } from "react-redux";
import { ConnectionInfo } from "../interfaces/connections/connections.interface";
import { getPatientsByConnection } from "../helpers/connections";
import {
  removeSession,
  resetLoading,
  setTakeABreakStatus,
} from "../features/session/sessionSlice";
import {
  getAllPatients,
  getDeviceInfoByLocation,
  getPatientById,
  setMonitoring,
} from "../helpers/patients";
import { DeviceInfo, Patient } from "../interfaces/patients/patients.interface";
import logger from "../logger/logger";
import { env } from "../env";
import { MonitoringOperationsEnum } from "../enums/apisOperations";
import {
  MAX_PATIENTS,
  setAllPatients,
  updatePatient,
  updatePatientByProp,
  updatePatientsActivity,
} from "../features/patients/patientsSlice";
import { useCallback, useState } from "react";
import { ErrorTypeEnum } from "../enums/errorType";
import { EnvVarsConfig } from "../enums/global";
import { addGlobalError } from "../features/globalErrors/globalErrorsSlice";
import { updateESitterActivities } from "../features/supervisors/supervisorsSlice";
import { ActivityTypeEnum } from "../interfaces/activity/activity.interface";

export const usePatients = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchPatients = useCallback(
    async (connectId: string) => {
      setIsLoading(true);
      try {
        let newPatients: Patient[] = [];

        if (EnvVarsConfig[env.REACT_APP_TAKE_A_BREAK] === EnvVarsConfig.on) {
          const connectionInfo = await checkIfUserIsOnBreak(connectId);
          newPatients = await getFilteredPatients(
            connectionInfo.patientsAssigned.slice(0, MAX_PATIENTS)
          );
        } else {
          newPatients = (await getFilteredPatients()).slice(0, MAX_PATIENTS);
        }
        logger.log(`Patients assigned: ${newPatients.length}`);

        const patientsWithDevice = await addDevicesInfoToPatients(newPatients);
        dispatch(setAllPatients(patientsWithDevice));
        setPatientsMonitoring(
          patientsWithDevice,
          MonitoringOperationsEnum.beginMonitoring
        );
      } catch (e: any) {
        logger.error("Error on first fetch", e);
        dispatch(addGlobalError(ErrorTypeEnum.FetchFailed));
      } finally {
        setIsLoading(false);
        dispatch(resetLoading());
      }
    },
    [EnvVarsConfig]
  );

  const checkIfUserIsOnBreak = useCallback(async (connectionId: string) => {
    console.time("Connection info fetch");
    try {
      const connectionInfo: ConnectionInfo = await getPatientsByConnection(
        connectionId
      );
      connectionInfo.isOnBreak &&
        dispatch(
          setTakeABreakStatus({
            active: true,
            startDateTime: connectionInfo.breakeStartDateTime,
          })
        );

      console.timeEnd("Connection info fetch");

      return connectionInfo;
    } catch (e) {
      /* dispatch(removeSession()); */
      logger.error(
        `Error getting patients by connection Id ${JSON.stringify(e)}`
      );
      throw e;
    }
  }, []);

  const getFilteredPatients = useCallback(async (idsToFilter?: string[]) => {
    let patients: Patient[] = [];

    if (idsToFilter) {
      logger.log(`Searching patients: ${JSON.stringify(idsToFilter)}`);
      console.time("First patients fetch");
      const filteredPatients = idsToFilter.map((id) => getPatientById(id));

      patients = await Promise.all(filteredPatients);
      console.timeEnd("First patients fetch");
      return patients;
    }

    console.time("First patients fetch");
    patients = await getAllPatients();
    console.timeEnd("First patients fetch");
    return patients;
  }, []);

  const addDevicesInfoToPatients = useCallback(async (patients: Patient[]) => {
    console.time("Devices info fetch");
    const deviceInfoPromises = patients.map(async (patient: Patient) => {
      logger.log(
        `Getting device info for ${patient.location}-${patient.roomNumber}`
      );
      if (patient.location && patient.roomNumber) {
        const deviceInfo: DeviceInfo = await getDeviceInfoByLocation(
          patient.location,
          patient.roomNumber,
          patient.cartId
        );

        return {
          ...patient,
          deviceInfo,
        };
      }
      return patient;
    });
    try {
      const patientsWithDevice = await Promise.all(deviceInfoPromises);
      console.timeEnd("Devices info fetch");

      return patientsWithDevice;
    } catch (e) {
      dispatch(addGlobalError(ErrorTypeEnum.RoomDevicesAPI));
      return patients;
    }
  }, []);

  const setPatientsMonitoring = useCallback(
    (patients: Patient[], monitorOp: MonitoringOperationsEnum) => {
      patients.forEach(async (patient: Patient) => {
        if (!patient.location || !patient.roomNumber) {
          return;
        }

        try {
          const monitor = await setMonitoring(
            patient.location,
            patient.roomNumber,
            monitorOp,
            patient.cartId
          );
          logger.log(`Monitoring for ${patient.id} ${JSON.stringify(monitor)}`);
        } catch (e: any) {
          logger.error(`Failed to start monitoring for ${patient.id}`);
        }
      });
    },
    []
  );

  const getDeviceInfoAndUpdatePatient = useCallback(
    async (patient: Patient) => {
      try {
        const deviceInfo: DeviceInfo = await getDeviceInfoByLocation(
          patient.location,
          patient.roomNumber,
          patient.cartId
        );
        dispatch(
          updatePatientByProp({
            data: { id: patient.id, deviceInfo },
            prop: "deviceInfo",
          })
        );
      } catch (e: any) {
        logger.error(
          `Failed to update device info ${e} for patient ${patient.id}`
        );
      }
    },
    []
  );

  const getActivitiesByPatient = useCallback(async (patient: Patient) => {
    try {
      const pat: Patient = await getPatientById(patient.id);
      dispatch(updatePatientsActivity(pat));
    } catch (e: any) {
      logger.error(
        `Failed to update patient's activity ${e} for patient ${patient.id}`
      );
    }
  }, []);

  const updatePatientAsNewActivity = useCallback(
    (patientId: string, activity: ActivityTypeEnum, patients: Patient[]) => {
      const patient = patients.find((pat) => pat.id === patientId);
      if (!patient) {
        return;
      }

      if (
        activity === ActivityTypeEnum.SpeakerOn ||
        activity === ActivityTypeEnum.SpeakerOff
      ) {
        getDeviceInfoAndUpdatePatient(patient);
        return;
      }

      getActivitiesByPatient(patient);
    },
    []
  );

  const updateConnectionActivities = useCallback(
    async (connectionId: string) => {
      try {
        const con: ConnectionInfo = await getPatientsByConnection(connectionId);
        dispatch(
          updateESitterActivities({ connectionId, activities: con.activities })
        );
      } catch (e: any) {
        logger.error(`Error updating activites for connection ${e}`);
      }
    },
    []
  );

  const updatePatientContactsFromBack = useCallback(async (id: string) => {
    if (!id) {
      return;
    }
    logger.log(`Fetch patient by id ${id}`);
    const patient = await getPatientById(id);
    if (!patient) {
      return;
    }
    dispatch(updatePatientByProp({ data: patient, prop: "contacts" }));
  }, []);

  const updateAllPatientInfo = useCallback(async (patientId: string) => {
    const patient = await getPatientById(patientId);
    if (!patient) {
      return;
    }
    dispatch(updatePatient(patient));
    await getDeviceInfoAndUpdatePatient(patient);
  }, []);

  return {
    fetchPatients,
    isLoading,
    checkIfUserIsOnBreak,
    getFilteredPatients,
    addDevicesInfoToPatients,
    setPatientsMonitoring,
    getDeviceInfoAndUpdatePatient,
    getActivitiesByPatient,
    updatePatientAsNewActivity,
    updateConnectionActivities,
    updatePatientContactsFromBack,
    updateAllPatientInfo,
  };
};
