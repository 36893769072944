import React, { useCallback, useState } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { undoDeletePatient } from "../../../../helpers/commandsPatient";
import logger from "../../../../logger/logger";

interface Props {
  patient: Patient;
  onSubmit: () => void;
  onCancel: () => void;
}
export const RemovalConfirmation: React.FC<Props> = ({
  patient,
  onCancel,
  onSubmit,
}) => {
  const [error, setError] = useState<ErrorTypeEnum>();
  const [loading, setLoading] = useState(false);

  const handleUndoRemoval = useCallback(async (patientId: string) => {
    try {
      setLoading(true);
      await undoDeletePatient(patientId);
      onSubmit();
    } catch (e) {
      logger.error("Error undo removal", e);
      setError(ErrorTypeEnum.Default);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className="overlay dark-transparent">
      <div className="dialog" style={{ maxWidth: 420, padding: 32 }}>
        <div className="row align-center start">
          <p className="title-2">Undo Removal</p>
        </div>
        <div className="close">
          <CloseIcon action={onCancel} />
        </div>
        <div style={{ margin: "30px 0px 40px" }}>
          <p
            style={{
              fontSize: "1rem",
            }}
          >
            Put back video from patient{" "}
            <span style={{ fontWeight: 600, textTransform: "capitalize" }}>
              {patient.firstName} {patient.lastName}
            </span>?
          </p>
          {error && (
            <ErrorMsg
              filled={false}
              errorMsg={error}
              position="inline"
              padding="none"
              closeBtn={false}
            />
          )}
        </div>
        <div className="row btn-group right reverse-order">
          <button
            className={`btn-small light`}
            aria-label="submit-btn"
            type="submit"
            onClick={() => !loading && handleUndoRemoval(patient.id)}
            style={{ backgroundColor: "#66c295", border: "none", fontWeight: 600 }}
          >
            CONFIRM
          </button>
          <button
            className="btn-small transparent outlined"
            type="button"
            onClick={onCancel}
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};
