declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_API_PATIENTS: string;
  REACT_APP_API_COMMAND_PATIENTS: string;
  REACT_APP_API_COMMAND_NURSES: string;
  REACT_APP_AI_URL: string;
  REACT_APP_CALL_HOST: string;
  REACT_APP_VIDEO_RECORD: string;
  REACT_APP_ESITTER_SOCKET: string;
  REACT_APP_DEVICES_API: string;
  REACT_APP_SETTINGS_API: string;
  REACT_APP_RTSP_SERVER: string;
  REACT_APP_WHEP_DEV_VIDEO: string;
  REACT_APP_DEVICES_API_KEY: string;
  REACT_APP_LOGGER: "all" | "info" | "warn" | "error";
  REACT_APP_LOGIN_SERVICE: string;
  REACT_APP_LOGIN_PAGE: string;
  REACT_APP_SIP_PASSWORD: string;
  REACT_APP_AI_RECORDING_SECS: number;
  REACT_APP_DECLINE_RECORDING_SECS: number;
  REACT_APP_SECS_TO_FREEZE_AI: number;
  REACT_APP_ALARM_RECORDING_SECS: number;
  REACT_APP_API_CONNECTIONS: string;
  REACT_APP_TAKE_A_BREAK: "on" | "off";
  REACT_APP_SWITCH_USER_URL: string;
  REACT_APP_NOTIFICATIONS_WAIT: number;
  REACT_APP_MAX_PATIENTS: number;
};

export const env: EnvType = { ...process.env, ...window["env"] };
