import { RiskLevel } from "../interfaces/fallRisk/fallRisk.interface";

export const LyingIcon = ({ color = "normal" }: { color?: RiskLevel }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.18307 6.03416C3.07075 6.07473 2.87158 6.20134 2.74062 6.31543C2.51789 6.50956 2.50237 7.15473 2.50237 16.2213C2.50237 27.0653 2.40136 26.0849 3.51783 26.0849C3.83953 26.0849 4.16912 26.0048 4.25026 25.9071C4.3314 25.8093 4.41553 25.12 4.43704 24.3755L4.47639 23.0217L16.9672 22.987L29.4579 22.9523V24.3046C29.4579 25.3526 29.5061 25.7052 29.6718 25.871C29.9285 26.1276 31.0951 26.163 31.3366 25.9215C31.4566 25.8016 31.5 24.6489 31.5 21.5834C31.5 17.5685 31.4892 17.395 31.2183 17.0507L30.9368 16.6926L17.7065 16.6579L4.47639 16.6232L4.40832 11.5568C4.351 7.29236 4.30798 6.45946 4.13604 6.29596C3.88255 6.05472 3.45303 5.93682 3.18307 6.03416ZM7.59738 10.976C5.86446 11.8337 5.73186 14.1841 7.34675 15.4158C7.79069 15.7545 7.97666 15.8057 8.76477 15.806C9.53396 15.8064 9.7545 15.7492 10.2153 15.4301C10.5143 15.2231 10.8922 14.8166 11.0552 14.5267C11.591 13.5733 11.3641 12.0638 10.5781 11.353C9.84109 10.6866 8.51972 10.5194 7.59738 10.976ZM12.6225 10.9733C12.3401 11.0872 12.326 11.185 12.2778 13.3812C12.2502 14.6401 12.2637 15.7166 12.3079 15.7734C12.352 15.8301 16.2083 15.8607 20.8773 15.8415L28.2093 15.8112C28.8509 15.8085 29.3712 15.2907 29.377 14.6492V14.6492C29.3856 13.7174 29.3336 13.3996 29.1099 13.0176C28.472 11.9271 26.9573 11.1881 24.9201 10.9736C23.3743 10.8106 13.0265 10.8105 12.6225 10.9733Z"
        fill="#4BC17E"
      />
    </svg>
  );
};
