import { ErrorTypeEnum } from "../../../../enums/errorType";
import { OrderEnum } from "../../../../enums/global";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { cutString } from "../../../../utils/cutString";
import { sortPatientsByProp } from "../../../../utils/sortPatients";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";

interface Props {
  loading: boolean;
  error: boolean;
  removedPatients: Patient[];
  errorsById: { [key: string]: boolean };
  handleUndoRemoval: (id: string) => void;
}

export const RemovedPatientsList: React.FC<Props> = ({
  loading,
  error,
  removedPatients,
  errorsById,
  handleUndoRemoval,
}) => {
  if (loading) {
    return (
      <div className="modals-stack">
        <div className="modal">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modals-stack">
        <div className="modal">
          <ErrorMsg
            errorMsg={ErrorTypeEnum.Retry}
            filled={false}
            Icon={InfoIcon}
            closeBtn={false}
            padding={"none"}
            position={"inline"}
          />
        </div>
      </div>
    );
  }

  if (removedPatients?.length === 0) {
    return (
      <div className="modals-stack">
        <div className="modal">
          <p>No patients removed.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="modals-stack">
      {sortPatientsByProp(removedPatients, "deletedDate", OrderEnum.asc).map(
        (pat, i) => (
          <div
            className="modal"
            key={`modal-deleted-patient-${pat.id}`}
            aria-label="modal"
          >
            <p
              aria-label={`modal-deleted-patient-${pat.id}-${i}`}
            >{`${cutString(pat.lastName)}, ${cutString(pat.firstName)}`}</p>
            <p>Room {pat.roomNumber}</p>
            <p
              className="undo-btn btn"
              onClick={() => !error && handleUndoRemoval(pat.id)}
              aria-label={`undo-deleted-patient-${pat.id}-${i}`}
            >
              Undo
            </p>
            {errorsById[pat.id] && (
              <ErrorMsg
                errorMsg={ErrorTypeEnum.Retry}
                filled={false}
                Icon={InfoIcon}
                closeBtn={false}
                padding={"none"}
                position={"inline"}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};
