import React from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { Nurse } from "../../../../imgs/Nurse";
import { MuteIcon } from "../../../../imgs/MuteIcon";
import { CamOffIcon } from "../../../../imgs/CamOffIcon";
import { RootState } from "../../../../stores";
import { useSelector } from "react-redux";
import { IssueEnum } from "../../../../enums/issues";
import { AlarmIcon } from "../../../../imgs/AlarmIcon";

interface Props {
  pat: Patient;
}
export const PatientIcons: React.FC<Props> = ({ pat }) => {
  const { issuesList } = useSelector((state: RootState) => state.supervisors);
  return (
    <div className="row">
      {`${pat.lastName}, ${pat.firstName}`}
      <div className="icons-row" style={{ marginLeft: 8 }}>
        {issuesList
          .filter((is) => is.patientId === pat.id)
          .map((is) => {
            if (is.issueType === IssueEnum.ContactNotAssigned) {
              return (
                <div
                  className="icon-yellow"
                  key={`patient-issue-${pat.id}-${is.issueType}`}
                >
                  <Nurse
                    tooltipText="Care team member not assigned"
                    tooltipPosition="top"
                  />
                </div>
              );
            }

            if (is.issueType === IssueEnum.NetworkIssues) {
              return (
                <div
                  className="icon-yellow"
                  key={`patient-issue-${pat.id}-${is.issueType}`}
                >
                  <AlarmIcon
                    tooltipText="Network Issues"
                    tooltipPosition="top"
                  />
                </div>
              );
            }

            if (is.issueType === IssueEnum.SpeakerOff) {
              <div
                className="icon-red"
                key={`patient-issue-${pat.id}-${is.issueType}`}
              >
                <MuteIcon tooltipText="Speaker Offline" tooltipPosition="top" />
              </div>;
            }

            if (is.issueType === IssueEnum.CameraOff) {
              <div
                className="icon-red"
                key={`patient-issue-${pat.id}-${is.issueType}`}
              >
                <CamOffIcon
                  tooltipText="Camera Offline"
                  tooltipPosition="top"
                />
              </div>;
            }

            return;
          })}
      </div>
    </div>
  );
};
