import { Micro } from "../../../../imgs/Micro";
import { MicroMuted } from "../../../../imgs/MicroMuted";

export const MicroButton: React.FC<{
  microMuted: boolean;
  loading: boolean;
  disabled: boolean;
  changeMicro: () => any;
}> = ({ microMuted, loading, disabled, changeMicro }) => {
  if (disabled || loading) {
    return <MicroMuted action={changeMicro} disabled={true} />;
  }
  
  return (
    <div className="microBx">
      {microMuted ? (
        <MicroMuted tooltipText={"Click to speak"} action={changeMicro} />
      ) : (
        <Micro
          action={changeMicro}
          active={true}
          tooltipText={"Click to close mic"}
        />
      )}
    </div>
  );
};
