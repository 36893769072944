import { PositionEnum } from "../../../../enums/pillStyles";

interface Props {
  text?: string;
  position: PositionEnum;
}

export const TooltipText: React.FC<Props> = ({ text, position }) => {
  if (text) {
    return (
      <span
        className={`tooltiptext ${PositionEnum[position]}`}
        onClick={(e) => e.stopPropagation()}
      >
        {text}
      </span>
    );
  }

  return <></>;
};
