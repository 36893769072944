export enum ContactTypeEnum {
  PCT = "PCT",
  Nurse = "Nurse",
  UnitDirector = "Unit Director",
  ChargeNurse = "Charge Nurse",
}

export enum ContactTypeInputEnum {
  Nurse = "nurseInput",
  PCT = "pctInput",
  ChargeNurse = "chargeNurseInput",
  UnitDirector = "unitDirInput",
}

export enum MainContactsEnum {
  Nurse = 1,
  PCT = 2,
}
