import React, { useState, useMemo } from "react";
import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";
import { CallLogo } from "../../../../imgs/CallLogo";
import { Micro } from "../../../../imgs/Micro";
import { PlayAudio } from "../../../../imgs/PlayAudio";
import {
  Activity,
  ActivityTypeEnum,
} from "../../../../interfaces/activity/activity.interface";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { parseJSON } from "../../../../utils/parseJSON";
import { AudioInfo } from "../../atoms/AudioInfo/AudioInfo";
import { CallInfo } from "../../atoms/CallInfo/CallInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";

interface Props {
  patient: Patient;
  expansible?: boolean;
}
export const PatientHistory: React.FC<Props> = ({
  patient,
  expansible = false,
}) => {
  const { newCallInput } = useSelector((state: RootState) => state.openForms);

  const [callExpanded, setCallExpanded] = useState(false);
  const [audioMsgExpanded, setAudioMsgExpanded] = useState(false);
  const [audioIntExpanded, setAudioIntExpanded] = useState(false);
  const detailedInfo = useMemo(() => {
    const callsToNurse: Activity[] = [];
    const audioReproduced: Activity[] = [];
    const audioInterventions: Activity[] = [];

    if (expansible && patient.activity) {
      patient.activity.forEach((currActivity) => {
        switch (currActivity.activityType) {
          case ActivityTypeEnum.NurseCallToNurse: //calls to nurses
            callsToNurse.push({
              dateTime: currActivity.dateTime,
              activity: currActivity.activity,
              createdDateTime: currActivity.createdDateTime,
            });
            break;
          case ActivityTypeEnum.PatientFell: //calls to nurses
            callsToNurse.push({
              dateTime: currActivity.dateTime,
              activity: currActivity.activity,
              createdDateTime: currActivity.createdDateTime,
            });
            break;
          case ActivityTypeEnum.AudioReproduced: //audio messages
            audioReproduced.push({
              dateTime: currActivity.dateTime,
              activity: currActivity.activity,
              createdDateTime: currActivity.createdDateTime,
            });
            break;
          case ActivityTypeEnum.AudioIntervention: //audio interventions
            audioInterventions.push({
              dateTime: currActivity.dateTime,
              activity: currActivity.activity,
              createdDateTime: currActivity.createdDateTime,
            });
            break;
          case ActivityTypeEnum.NurseCallEnded: //calls to nurses
            callsToNurse.push({
              dateTime: currActivity.dateTime,
              activity: currActivity.activity,
              createdDateTime: currActivity.createdDateTime,
            });
            break;
          default:
            break;
        }
      });

      return {
        audioReproduced: audioReproduced.sort((a, b) =>
          a.dateTime > b.dateTime ? -1 : 1
        ),
        audioInterventions: audioInterventions.sort((a, b) =>
          a.dateTime > b.dateTime ? -1 : 1
        ),
        callsToNurse: callsToNurse.sort((a, b) =>
          a.dateTime > b.dateTime ? -1 : 1
        ),
        startedCalls: callsToNurse.filter(
          (call) => parseJSON(call.activity || "")?.reason
        ).length,
      };
    }
  }, [patient.activity, expansible]);

  const toogleCallExpansion = () => {
    setCallExpanded(!callExpanded);
  };

  const toogleAudioMsgExpansion = () => {
    setAudioMsgExpanded(!audioMsgExpanded);
  };

  const toogleAudioIntExpansion = () => {
    setAudioIntExpanded(!audioIntExpanded);
  };

  const reproducedAudiosExpanded = () => {
    return (
      <div
        className={`${audioMsgExpanded ? "expanded" : "collapsed"} my-1`}
        aria-label="reproduced-audios-bx"
      >
        <div className="row">
          <PlayAudio />
          <p>{detailedInfo?.audioReproduced.length || 0} audio messages</p>
          {(detailedInfo?.audioReproduced.length &&
            detailedInfo?.audioReproduced.length > 0 && (
              <ArrowDownLogo
                action={toogleAudioMsgExpansion}
                invisible={newCallInput.status}
              />
            )) || <></>}
        </div>
        <div className="row">
          <div className="expansible-menu" aria-label="reproduced-audios-menu">
            {detailedInfo?.audioReproduced?.map((currActivity) => {
              return (
                <AudioInfo
                  activity={currActivity}
                  key={currActivity.dateTime}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const audioInterventionsExpanded = () => {
    return (
      <div
        className={`${audioIntExpanded ? "expanded" : "collapsed"} my-1`}
        aria-label="audio-interventions-bx"
      >
        <div className="row">
          <Micro />
          <p>
            {detailedInfo?.audioInterventions.length || 0} audio interventions
          </p>
          {(detailedInfo?.audioInterventions.length &&
            detailedInfo?.audioInterventions.length > 0 && (
              <ArrowDownLogo
                action={toogleAudioIntExpansion}
                invisible={newCallInput.status}
              />
            )) || <></>}
        </div>
        <div className="row">
          <div
            className="expansible-menu"
            aria-label="audios-interventions-menu"
          >
            {detailedInfo?.audioInterventions?.map((currActivity) => {
              return (
                <AudioInfo
                  activity={currActivity}
                  key={currActivity.dateTime}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const callsToNurseExpanded = () => {
    return (
      <div
        className={`${callExpanded ? "expanded" : "collapsed"}`}
        aria-label="calls-bx"
      >
        <div className="row">
          <CallLogo />
          <p>{detailedInfo?.startedCalls || 0} calls</p>
          {(detailedInfo?.startedCalls && detailedInfo?.startedCalls > 0 && (
            <ArrowDownLogo
              action={toogleCallExpansion}
              invisible={newCallInput.status}
            />
          )) || <></>}
        </div>
        <div className="row">
          <div className={`expansible-menu`} aria-label="calls-menu">
            {detailedInfo?.callsToNurse?.map((currCall, i) => {
              if (currCall.activity && parseJSON(currCall.activity)?.reason) {
                const prevActivity = detailedInfo?.callsToNurse[i - 1];

                return (
                  <CallInfo
                    call={currCall}
                    closeDateTime={
                      parseJSON(prevActivity?.activity || "")?.isPillOpen ===
                      false
                        ? prevActivity.dateTime
                        : undefined
                    }
                    key={currCall.dateTime}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`patient-history`} aria-label="patient-history">
      <h3>
        History <span>(last 24 hours)</span>
      </h3>

      {!expansible ? (
        <>
          <div className="row my-1">
            <PlayAudio />
            <p>{detailedInfo?.audioReproduced.length || 0} audio messages</p>
          </div>

          <div className="row my-1">
            <Micro />
            <p>
              {detailedInfo?.audioInterventions.length || 0} audio interventions
            </p>
          </div>

          <div className="row">
            <CallLogo />
            <p>{detailedInfo?.startedCalls || 0} calls to nurse</p>
          </div>
        </>
      ) : (
        <>
          {reproducedAudiosExpanded()}
          {audioInterventionsExpanded()}
          {callsToNurseExpanded()}
        </>
      )}
    </div>
  );
};
