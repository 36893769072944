import { memo, useEffect, useRef } from "react";
export const VideoMemo = memo(
  ({ stream, muted }: { stream: MediaStream | undefined; muted: boolean }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
      if (videoRef?.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);

    return (
      <video
        ref={videoRef}
        className="player-wrapper"
        muted={muted}
        autoPlay
        playsInline
        controls={false}
      ></video>
    );
  }
);
