import { useEffect, useState } from "react";
import { analyzePathname } from "../../../../utils/analyzePathname";
import { Link } from "react-router-dom";
import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";

interface Props {
  pathname: string;
  mainRoute: string;
  idSearch?: {
    list: any[];
    idProp: string;
    labelProp: string;
  };
}

export const Breadcrumb: React.FC<Props> = ({
  pathname,
  mainRoute,
  idSearch,
}) => {
  const [routes, setRoutes] = useState<{ route: string; location: string }[]>(
    []
  );

  useEffect(() => {
    if (idSearch) {
      const INDEX_TO_REMOVE = 0;
      setRoutes(analyzePathname(pathname, idSearch, INDEX_TO_REMOVE));
      return;
    }
    setRoutes(analyzePathname(pathname));
  }, [pathname, idSearch]);

  if (mainRoute === pathname) {
    return <></>;
  }

  return (
    <div className="breadcrumb row">
      {routes.map((route, index) => (
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
          }}
          key={`link-${route.route}`}
        >
          <Link to={route.location}>
            <p
              key={route.route}
              className={`breadcrumb-title ${
                pathname === route.location ? "active" : "inactive"
              }`}
            >
              {route.route}
            </p>
          </Link>
          {index !== routes.length - 1 && <ArrowDownLogo orientation="right" />}
        </div>
      ))}
    </div>
  );
};
