import { useEffect, useState } from "react";
import { patientOperationAdd } from "../../../../helpers/commandsPatient";

const initiaState = {
  id: "",
  admissionId: "",
  admissionDateTime: "",
  firstName: "",
  lastName: "",
  language: "",
  roomNumber: "",
  location: "",
  cartId: "",
};
export const AddPatient = () => {
  const [patient, setPatient] = useState(initiaState);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatient({ ...patient, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setResponse(null);
    setloading(true);
    let res;
    try {
      res = await patientOperationAdd(patient);
      setResponse(res);
      setPatient(initiaState);
    } catch (e) {
      setError(JSON.stringify(e));
    } finally {
      setloading(false);
    }
  };

  const handleAutoId = () => {
    let date = new Date().toLocaleDateString();
    date = date.split("/").join("");

    let time = new Date().toLocaleTimeString();
    time = time.slice(0, -3).split(":").join("");

    const id = `${date}${time}`;

    setPatient({ ...patient, id, admissionId: id });
  };

  return (
    <div className="form addition-form m-2">
      <h1>Add new patient</h1>
      <datalist id="list-rooms">
        <option value="388"></option>
        <option value="152"></option>
        <option value="357"></option>
        <option value="206"></option>
        <option value="411"></option>
        <option value="439"></option>
        <option value="369"></option>
        <option value="339"></option>
        <option value="169"></option>
      </datalist>
      <div className="btn-group row right">
        <button
          type="submit"
          className="btn-xsmall light"
          onClick={handleAutoId}
        >
          Auto Id (datetime)
        </button>
      </div>
      <form onSubmit={handleSubmit} className="p-2">
        <div className="form-group row column my-1">
          <label htmlFor="id">Id</label>
          <input
            type="text"
            name="id"
            onInput={handleChange}
            value={patient.id}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="admissionId">Admission id</label>
          <input
            type="text"
            name="admissionId"
            onInput={handleChange}
            value={patient.admissionId}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="admissionDateTime">Admission datetime</label>
          <input
            type="datetime-local"
            name="admissionDateTime"
            onInput={handleChange}
            value={patient.admissionDateTime}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="firstName">Name</label>
          <input
            type="text"
            name="firstName"
            onInput={handleChange}
            value={patient.firstName}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="lastName">Last name</label>
          <input
            type="text"
            name="lastName"
            onInput={handleChange}
            value={patient.lastName}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="language">Language</label>
          <input
            type="text"
            name="language"
            onInput={handleChange}
            value={patient.language}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="language">Room</label>
          <input
            type="text"
            name="roomNumber"
            onInput={handleChange}
            value={patient.roomNumber}
            list={"list-rooms"}
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="location">Location</label>
          <input
            type="text"
            name="location"
            onInput={handleChange}
            value={patient.location}
            placeholder="location-UnitId"
            required
          />
        </div>
        <div className="form-group row column my-1">
          <label htmlFor="location">Cart Id</label>
          <input
            type="text"
            name="cartId"
            onInput={handleChange}
            value={patient.cartId}
            placeholder="100"
          />
        </div>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <button type="submit" className="btn-xsmall light">
            Save patient
          </button>
        )}
        {error && <h3>Error: {error}</h3>}
      </form>
      {response && <h3>Response: {response}</h3>}
    </div>
  );
};
