import { useCallback, useEffect, useState } from "react";
import {
  disconnectSupervisorSocket,
  initiateSupervisorSocket,
} from "../helpers/socketSupervisor";
import { ErrorTypeEnum } from "../enums/errorType";
import { ESitterEventsEnum } from "../enums/eSitterEvents";
import logger from "../logger/logger";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../stores";
import {
  getActivePeopleList,
  getContactsByLocationList,
  getRemovedPatientList,
  getWorkloadInfo,
} from "../features/supervisors/supervisorsSlice";
import { usePatients } from "./usePatients";

const DELAY_TO_WAIT_BACKEND = 1000;

export const useSupervisorSocket = (
  connectToSocket: boolean,
  onLoadBalanceCompleted: () => void
) => {
  const dispatch = useDispatch();
  const [socketActive, setSocketActive] = useState(false);
  const [errorSocket, setErrorSocket] = useState<
    { status: boolean; error: ErrorTypeEnum }[]
  >([]);
  const [incomingMsg, setIncomingMsg] = useState<{
    eventType: ESitterEventsEnum | null;
    message: any;
  }>({
    eventType: null,
    message: "",
  });
  const { locations, list: eSittersList } = useSelector(
    (state: RootState) => state.supervisors
  );
  const { list: patients } = useSelector((state: RootState) => state.patients);
  const {
    updatePatientAsNewActivity,
    updateConnectionActivities,
    updatePatientContactsFromBack,
    updateAllPatientInfo,
  } = usePatients();

  useEffect(() => {
    const start = () => {
      if (!socketActive) {
        initiateSupervisorSocket(
          setError,
          () => unsetError(ErrorTypeEnum.ESitterSocket),
          (eventType: ESitterEventsEnum, message: any) =>
            setIncomingMsg({ eventType, message })
        );
        setSocketActive(true);
      }
    };
    connectToSocket && start();
  }, [connectToSocket]);

  useEffect(() => {
    const { eventType, message } = incomingMsg;
    if (eventType) {
      listenESitterEvents(eventType, message);
    }
  }, [incomingMsg]);

  const setError = (error: ErrorTypeEnum) => {
    setErrorSocket([
      ...errorSocket.filter((err) => err.error !== error),
      { status: true, error },
    ]);
  };

  const unsetError = (error: ErrorTypeEnum) => {
    setErrorSocket([
      ...errorSocket.filter((err) => err.error !== error),
      { status: false, error },
    ]);
  };

  const updateContactsByLocation = () => {
    const LABEL_DELAY = 2000;
    setTimeout(() => {
      store.dispatch(getActivePeopleList());
      locations.forEach(({ id }) =>
        store.dispatch(getContactsByLocationList(id))
      );
    }, LABEL_DELAY);
  };

  const updateESittersWorkload = useCallback(() => {
    eSittersList.forEach((eS) => {
      store.dispatch(getWorkloadInfo(eS.id));
    });
  }, [eSittersList]);

  const listenESitterEvents = useCallback(
    async (eventType: ESitterEventsEnum, message: any) => {
      logger.log(`[${ESitterEventsEnum[eventType]}]`, message);

      switch (eventType) {
        case ESitterEventsEnum.LoadBalanceCompletedEvent:
          onLoadBalanceCompleted();
          break;
        case ESitterEventsEnum.ContactCreatedEvent:
          updateContactsByLocation();
          updatePatientContactsFromBack(message.PatientId);
          break;
        case ESitterEventsEnum.ContactUpdatedEvent:
          updateContactsByLocation();
          updatePatientContactsFromBack(message.PatientId);
          break;
        case ESitterEventsEnum.ContactRemovedEvent:
          updateContactsByLocation();
          updatePatientContactsFromBack(message.PatientId);
          break;
        case ESitterEventsEnum.ContactExpiredEvent:
          updateContactsByLocation();
          updatePatientContactsFromBack(message.PatientId);
          break;
        case ESitterEventsEnum.PatientRemovedEvent:
          store.dispatch(getRemovedPatientList());
          break;
        case ESitterEventsEnum.PatientUpdatedEvent:
          if (!message.Id) {
            return;
          }
          setTimeout(() => {
            updateAllPatientInfo(message.Id);
          }, DELAY_TO_WAIT_BACKEND);
          break;
        case ESitterEventsEnum.ActivityAddedEvent:
          updateESittersWorkload();
          if (!message.Id || !message.ActivityType) {
            return;
          }
          updatePatientAsNewActivity(
            message.Id,
            message.ActivityType,
            patients
          );
          break;
        case ESitterEventsEnum.ConnectionActivityAddedEvent:
          updateConnectionActivities(message.Id);
          break;
        default:
          break;
      }

      setIncomingMsg({
        eventType: null,
        message: "",
      });
    },
    [patients]
  );

  const closeSocket = () => {
    if (socketActive) {
      disconnectSupervisorSocket();
      setSocketActive(false);
    }
  };

  return { socketActive, closeSocket, errorSocket };
};
