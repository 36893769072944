import { useEffect, useState, useCallback } from "react";
import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";
import { UserIcon } from "../../../../imgs/UserIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  removeSession,
  setNotificationsStatus,
  setTakeABreakStatus,
} from "../../../../features/session/sessionSlice";
import { MenuIcon } from "../../../../imgs/MenuIcon";
import { RootState } from "../../../../stores";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";
import {
  addGlobalError,
  removeNotifications,
  setStatusGlobalError,
} from "../../../../features/globalErrors/globalErrorsSlice";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import {
  logoutConnection,
  setConnectionAction,
} from "../../../../helpers/connections";
import { ConnectionsEnum } from "../../../../enums/connectionsEnum";
import logger from "../../../../logger/logger";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { useTimer } from "../../../../hooks/useTimer";
import moment from "moment";
import { getPassedTime } from "../../../../utils/timeOperations";
import { env } from "../../../../env";
import { EnvVarsConfig, UserTypes } from "../../../../enums/global";
import { SwitchUser } from "../../atoms/SwitchUser/SwitchUser";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { NotificationOn } from "../../../../imgs/NotificationOn";
import { NotificationOff } from "../../../../imgs/NotificationOff";
import { NotificationsEnum } from "../../../../enums/notifications";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { MAX_PATIENTS } from "../../../../features/patients/patientsSlice";

const WAIT_TO_CLOSE_NOTIFICATION = 5000;
interface Props {
  patientsTotal: number;
  username: string;
  userType: UserTypes;
}

const initialDefaultTimer = {
  defaultMinutes: 0,
  defaultSeconds: 0,
  defaultHours: 0,
};

export const StatusBar: React.FC<Props> = ({
  patientsTotal,
  username,
  userType,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dispatch = useDispatch();
  const {
    generalErrors: errors,
    status,
    generalNotifications,
  } = useSelector((state: RootState) => state.globalErrors);
  const {
    userInput: { status: isSwitchUserOpen },
  } = useSelector((state: RootState) => state.openForms);
  const {
    connectionId,
    break: { active: isInBreak, startDateTime },
    notifications: { areActive },
  } = useSelector((state: RootState) => state.sessionInfo);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [defaultTimer, setDefaultTimer] = useState(initialDefaultTimer);
  const { timer, stopTimer, resumeTimer, resetTimer } = useTimer(
    isInBreak,
    1000,
    defaultTimer.defaultMinutes,
    defaultTimer.defaultSeconds,
    defaultTimer.defaultHours
  );
  const [error, setError] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);

  useEffect(() => {
    if (startDateTime) {
      const parsedDate = startDateTime?.endsWith("Z")
        ? startDateTime
        : `${startDateTime}Z`;

      const time = getPassedTime(parsedDate);

      setDefaultTimer({
        defaultHours: time.hours || 0,
        defaultMinutes: time.minutes,
        defaultSeconds: time.seconds,
      });
      return;
    }
  }, [startDateTime]);

  useEffect(() => {
    if (isInBreak) {
      resumeTimer();
      return;
    }
    stopTimer();
    resetTimer();
    setDefaultTimer({
      defaultMinutes: 0,
      defaultSeconds: 0,
      defaultHours: 0,
    });
  }, [isInBreak]);

  useEffect(() => {
    if (!errors.length) {
      closeGlobalErrors();
      return;
    }
    if (errors.includes(ErrorTypeEnum.StartBreak)) {
      setBtnDisabled(true);
    }
  }, [errors]);

  useEffect(() => {
    generalNotifications.forEach((not) => {
      setTimeout(() => {
        dispatch(removeNotifications(not));
      }, WAIT_TO_CLOSE_NOTIFICATION);
    });
  }, [generalNotifications]);

  const logout = useCallback(() => {
    setSessionLoading(true);
    try {
      connectionId && logoutConnection(connectionId);
      dispatch(removeSession());
    } catch (e) {
      logger.error("Error on logout", e);
    } finally {
      setSessionLoading(false);
    }
  }, [connectionId]);

  // RSD: Logout on window close...
  useEffect(() => {
    const handleWindowClose = () => {
      logout();
    };

    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
        window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [logout]);

  const toogleGlobalErros = useCallback(() => {
    dispatch(setStatusGlobalError(!status));
  }, [status]);

  const closeGlobalErrors = useCallback(() => {
    dispatch(setStatusGlobalError(false));
  }, []);

  const handleTakeABreak = useCallback(async () => {
    setLoading(true);
    try {
      const startDateTime = moment().utc().format();
      await setConnectionAction(
        ConnectionsEnum.TakeABreak,
        connectionId,
        startDateTime
      );
      dispatch(setTakeABreakStatus({ active: true, startDateTime }));
    } catch (e) {
      logger.error("Error starting break", e);
      dispatch(addGlobalError(ErrorTypeEnum.StartBreak));
    } finally {
      setLoading(false);
    }
  }, [connectionId]);

  const handleStopBreak = useCallback(async () => {
    setLoading(true);
    try {
      await setConnectionAction(ConnectionsEnum.ReturnFromBreak, connectionId);
      dispatch(setTakeABreakStatus({ active: false }));
    } catch (e) {
      logger.error("Error ending break", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [connectionId]);

  const handleOpenSwithUser = useCallback(() => {
    dispatch(setForm({ typeInput: "userInput", status: true }));
  }, []);

  const DropDown = () => {
    return (
      <div
        className={`user-info ${isCollapsed ? "collapsed" : "expanded"}`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <UserIcon />
        <p>{username}</p>
        <ArrowDownLogo />
        <div
          className="dropdown-overlay"
          onClick={() => setIsCollapsed(false)}
        ></div>
        <div
          className="dropdown-menu expansible-menu"
          onClick={() => setIsCollapsed(true)}
        >
          {sessionLoading ? (
            <div className="loader xsmall"></div>
          ) : (
            <>
              {userType === UserTypes.Esitter && (
                <p onClick={handleOpenSwithUser}>Switch user</p>
              )}
              <p onClick={logout}>Sign out</p>
            </>
          )}
        </div>
      </div>
    );
  };

  const BreakBtn = () => {
    return (
      <div
        className={`btn btn-huge green ${loading && "loading"}`}
        onClick={() => !loading && handleStopBreak()}
      >
        END BREAK
      </div>
    );
  };

  const BreakOverlay = () => {
    if (loading) {
      return (
        <div className="overlay">
          <div className="title-bx">
            <h3 className="title">Loading...</h3>
          </div>
          <BreakBtn />
        </div>
      );
    }

    return (
      <div className="overlay">
        <div className="title-bx">
          <h3 className="title">Break in progress</h3>
          <h3 className="title">{timer}</h3>
        </div>
        <BreakBtn />
        {error && (
          <div className="title">
            <ErrorMsg
              errorMsg={ErrorTypeEnum.StopBreak}
              filled={false}
              Icon={InfoIcon}
              closeBtn={false}
              padding={"none"}
              position={"inline"}
            />
          </div>
        )}
      </div>
    );
  };

  if (userType === UserTypes.Supervisor) {
    return (
      <div
        className={`statusBar full-width ${isSidebarOpen && "active-sidebar"}`}
      >
        <div
          className="menuIconBx"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <MenuIcon />
        </div>
        <div className="title">
          <h3>Supervisor view</h3>
        </div>
        <div className="content">
          <div className="global-error-bx">
            {generalNotifications.length !== 0 &&
              generalNotifications.map((not) => (
                <div className="notification" key={not}>
                  <p>{not}.</p>
                  <CloseIcon
                    action={() =>
                      dispatch(
                        removeNotifications(NotificationsEnum.ReassignSuccess)
                      )
                    }
                  />
                </div>
              ))}
            {errors.length > 0 && (
              <InfoIcon isError={true} onClick={toogleGlobalErros} />
            )}
            {status && errors.length && (
              <ErrorMsg
                position="top"
                width="auto"
                filled={true}
                Icon={false}
                closeBtn={true}
                globalErrors={errors}
                onClose={closeGlobalErrors}
              />
            )}
          </div>
        </div>
        <DropDown />
      </div>
    );
  }

  return (
    <>
      <div className={`statusBar ${isSidebarOpen && "active-sidebar"}`}>
        <div
          className="menuIconBx"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <MenuIcon />
        </div>
        <div
          className="patients-bx"
          style={{ alignItems: "center", gap: "1rem" }}
        >
          {patientsTotal > MAX_PATIENTS ? (
            <p>
              {`${MAX_PATIENTS} active ${
                patientsTotal === 1 ? "patient" : "patients"
              }`}{" "}
              <span style={{ fontWeight: 500, color: "#cf6679" }}>{`(${
                patientsTotal - MAX_PATIENTS
              } unmonitored)`}</span>
            </p>
          ) : (
            <p>{`${patientsTotal} active ${
              patientsTotal === 1 ? "patient" : "patients"
            }`}</p>
          )}
          <div onClick={() => dispatch(setNotificationsStatus(!areActive))}>
            {areActive ? (
              <NotificationOn tooltipText="Notifications on" />
            ) : (
              <NotificationOff tooltipText="Notifications off" />
            )}
          </div>
        </div>
        <div className="content">
          <div className="global-error-bx">
            {errors.length > 0 && (
              <InfoIcon isError={true} onClick={toogleGlobalErros} />
            )}
            {status && errors.length && (
              <ErrorMsg
                position="top"
                width="auto"
                filled={true}
                Icon={false}
                closeBtn={true}
                globalErrors={errors}
                onClose={closeGlobalErrors}
              />
            )}
          </div>
          {EnvVarsConfig[env.REACT_APP_TAKE_A_BREAK] === EnvVarsConfig.on && (
            <div className="take-a-break-bx">
              {loading ? (
                <div className="p-alert">
                  <p>Setting up your break...</p>
                </div>
              ) : (
                <button
                  className={`btn-xsmall opaque-input semibold ${
                    btnDisabled && "disabled"
                  }`}
                  onClick={handleTakeABreak}
                  disabled={loading || btnDisabled}
                >
                  TAKE A BREAK
                </button>
              )}
            </div>
          )}
        </div>
        <DropDown />

        {isSwitchUserOpen && <SwitchUser />}
      </div>
      {isInBreak && <BreakOverlay />}
    </>
  );
};
