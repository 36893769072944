import { useEffect, useState } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { CamsCard } from "../CamsCard/CamsCard";
import { getMaximumFrames } from "../../../../utils/maxBlackFrames";

interface Props {
  patients: Patient[];
  selectedPatient?: string;
}

export const MainPatients: React.FC<Props> = ({
  patients,
  selectedPatient,
}) => {
  const [voidCams, setVoidCams] = useState<{ id: number }[]>([]);

  useEffect(() => {
    const delta = getMaximumFrames(patients.length) - patients.length;
    let array = [];
    for (let i = 0; i < delta; i++) {
      array.push({ id: i });
    }
    setVoidCams(array);
  }, [patients]);

  return (
    <div className={`main-grid grid-${getMaximumFrames(patients.length)}`}>
      {patients.map((patient, index) => (
        <CamsCard
          patient={patient}
          key={patient.id}
          isPatientSelected={selectedPatient === patient.id}
          index={index}
        />
      ))}
      {voidCams.length > 0 &&
        voidCams.map((box) => {
          return <div className={"void-cam"} key={box.id}></div>;
        })}
    </div>
  );
};
