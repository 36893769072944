import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  FallRisk,
  RiskValue,
} from "../../interfaces/fallRisk/fallRisk.interface";
import logger from "../../logger/logger";

export enum ZeroStatus {
  Idle = 1,
  Blocked = 2,
  Available = 3,
}

export interface FallRiskState {
  riskById: {
    [id: string]: number;
  };
  currentRisk: FallRisk;
  previousRisk: FallRisk;
  blockedRisk: {
    [id: string]: number;
  };
  zeroStatusById: {
    [id: string]: ZeroStatus;
  };
  orderedIdsByRisk: string[];
  positionByFrame: {
    [index: number]: DOMRect;
  };
}

const initialState: FallRiskState = {
  riskById: {},
  currentRisk: {
    id: "",
    fallRisk: 0,
  },
  previousRisk: {
    id: "",
    fallRisk: 0,
  },
  blockedRisk: {},
  zeroStatusById: {},
  orderedIdsByRisk: [],
  positionByFrame: {},
};

export const fallRiskSlice = createSlice({
  name: "fallRisk",
  initialState,
  reducers: {
    setRiskById: (state: FallRiskState, action: PayloadAction<FallRisk>) => {
      const { id, fallRisk } = action.payload;

      if (
        state.riskById[id] &&
        fallRisk === 0 &&
        (!state.zeroStatusById[id] ||
          state.zeroStatusById[id] === ZeroStatus.Idle)
      ) {
        state.zeroStatusById[id] = ZeroStatus.Blocked;
        logger.log(`Zero is being blocked for ${id}`);
        return;
      }

      if (fallRisk === 0 && state.zeroStatusById[id] === ZeroStatus.Blocked) {
        logger.log(`Zero is blocked for ${id}`);
        return;
      }

      state.riskById = {
        ...state.riskById,
        [id]: Number(fallRisk) as RiskValue,
      };
      state.zeroStatusById[id] = ZeroStatus.Idle;
      state.orderedIdsByRisk = Object.entries(state.riskById)
        .sort(([, riskA], [, riskB]) => riskB - riskA)
        .map((riskById) => riskById[0]);
    },
    removeRiskById: (
      state: FallRiskState,
      action: PayloadAction<{ id: string }>
    ) => {
      const { id } = action.payload;

      delete state.riskById[id];
      state.orderedIdsByRisk = Object.entries(state.riskById)
        .sort(([, riskA], [, riskB]) => riskB - riskA)
        .map((riskById) => riskById[0]);
    },
    setCurrentRisk: (state: FallRiskState, action: PayloadAction<FallRisk>) => {
      state.currentRisk = {
        id: action.payload.id,
        fallRisk: Number(action.payload.fallRisk) as RiskValue,
      };
    },
    setPreviousRisk: (
      state: FallRiskState,
      action: PayloadAction<FallRisk>
    ) => {
      state.previousRisk = {
        id: action.payload.id,
        fallRisk: Number(action.payload.fallRisk) as RiskValue,
      };
    },
    resetRiskToZero: (state: FallRiskState, action: PayloadAction<string>) => {
      state.riskById = {
        ...state.riskById,
        [action.payload]: 0,
      };
    },
    setBlockedRisk: (state: FallRiskState, action: PayloadAction<FallRisk>) => {
      state.blockedRisk = {
        ...state.blockedRisk,
        [action.payload.id]: Number(action.payload.fallRisk) as RiskValue,
      };
    },
    removeBlockedRisk: (
      state: FallRiskState,
      action: PayloadAction<{ id: string }>
    ) => {
      delete state.blockedRisk[action.payload.id];
    },
    setZeroAvailable: (
      state: FallRiskState,
      action: PayloadAction<{ id: string }>
    ) => {
      const { id } = action.payload;
      logger.log(`Zero is no more blocked for ${id}`);
      state.zeroStatusById[id] = ZeroStatus.Available;
    },
    setInitialPositionByFrame: (
      state: FallRiskState,
      action: PayloadAction<{ index: number; info: DOMRect }>
    ) => {
      const { index, info } = action.payload;
      state.positionByFrame[index] = info;
    },
    resetPositions: (state: FallRiskState) => {
      state.positionByFrame = {};
      state.riskById = {}
    },
  },
});

export default fallRiskSlice.reducer;

export const {
  setRiskById,
  setCurrentRisk,
  setPreviousRisk,
  resetRiskToZero,
  setBlockedRisk,
  removeBlockedRisk,
  setZeroAvailable,
  removeRiskById,
  setInitialPositionByFrame,
  resetPositions,
} = fallRiskSlice.actions;
