import moment from "moment";
import { useEffect, useState } from "react";
import { env } from "../../../../env";
import io, { Socket } from "socket.io-client";

let socket: Socket;

const initiateESitterSocket = async (
  cb: ({ event, msg }: { event: string; msg: object }) => void
) => {
  const url = env.REACT_APP_ESITTER_SOCKET;

  if (!socket && url) {
    const path = "/esitter-socket/";
    socket =
      process.env.NODE_ENV === "production"
        ? io(url, {
            path,
            rememberUpgrade: true,
          })
        : io(url, { path });
    console.log(`Connecting socket...`);
    socket.on("connect", function () {
      console.log(`Connected to esitter socket: ${url} - ${socket.id}`);
    });
    socket.onAny((event, msg) => {
      console.log(`New Event ${event}: ${JSON.stringify(msg)}`);
      cb({ event, msg });
    });
    return socket;
  }
};

export const Listener = () => {
  const [eventReceived, setEventReceived] = useState<
    | {
        event: string;
        msg: string[];
        index: number;
      }
    | undefined
  >();
  const [eventsList, setEventsList] = useState<
    { event: string; msg: string[]; index: number }[]
  >([]);

  useEffect(() => {
    let i = 0;
    initiateESitterSocket(({ event, msg }) => {
      const array: string[] = [];
      Object.entries(msg).forEach((item) => {
        array.push(`${item[0]}: ${item[1]}`);
      });
      const now = moment().format();
      array.push(`Received at: ${now}`);
      setEventReceived({ event: event, msg: array, index: i++ });
    });
  }, []);

  useEffect(() => {
    if (
      eventReceived &&
      !eventsList.find((ev) => ev.index === eventReceived.index)
    ) {
      setEventsList([eventReceived, ...eventsList]);
      setEventReceived(undefined);
    }
  }, [eventReceived]);

  return (
    <div className="m-2">
      <h2>Listener of any from esitter-socket</h2>
      <button
        className="btn-xsmall transparent outlined"
        onClick={() => setEventsList([])}
      >
        Clear events list
      </button>
      <div
        className="my-2"
        style={{ hyphens: "auto", maxHeight: "400px", overflowY: "auto" }}
      >
        {eventsList.length > 0 ? (
          eventsList.map((e) => (
            <div
              key={`div-${e.event}-${e.index}`}
              style={{ borderTop: "solid 1px #bdbdbd" }}
              className="my-2"
            >
              <h3>Event: {e.event}</h3>
              <h4>
                Msg:
                {e.msg.map((prop) => (
                  <div key={`div-${e.event}-${prop}`}>
                    <span>{prop}</span>
                    <br />
                  </div>
                ))}
              </h4>
            </div>
          ))
        ) : (
          <h3>No events received</h3>
        )}
      </div>
    </div>
  );
};
