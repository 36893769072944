import { configureStore } from "@reduxjs/toolkit";
import assignedContact from "../features/assignedContact/assignedContactSlice";
import fallRisk from "../features/fallRisk/fallRisk";
import patients from "../features/patients/patientsSlice";
import pillAlerts from "../features/pillAlerts/pillAlertsSlice";
import selectedPatient from "../features/selectedPatient/selectedPatient";
import sosAlarm from "../features/sosAlarm/sosAlarmSlice";
import globalErrors from "../features/globalErrors/globalErrorsSlice";
import timeToExpire from "../features/timeToExpire/timeToExpireSlice";
import openForms from "../features/openForms/openFormSlice";
import sessionInfo from '../features/session/sessionSlice'
import supervisors from "../features/supervisors/supervisorsSlice";

const store = configureStore({
  reducer: {
    patients,
    selectedPatient,
    fallRisk,
    pillAlerts,
    assignedContact,
    sosAlarm,
    globalErrors,
    timeToExpire,
    openForms,
    sessionInfo,
    supervisors
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
