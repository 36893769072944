import React from "react";

interface Props {
  action?: () => any | void;
  tooltipText?: string;
  disabled?: boolean;
}

export const NotificationOff: React.FC<Props> = ({
  action,
  tooltipText,
  disabled = false,
}) => {
  return (
    <div className={`${tooltipText && "tooltip"}`}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`btn ${disabled && "disabled"} error-icon`}
        onClick={() => !disabled && action?.()}
      >
        <g clipPath="url(#clip0_2679_12711)">
          <path
            d="M15 14.0175L5.88 4.605L3.9525 2.6175L3 3.57L5.1 5.67V5.6775C4.71 6.42 4.5 7.2975 4.5 8.2425V11.9925L3 13.4925V14.2425H13.2975L14.7975 15.7425L15.75 14.79L15 14.0175ZM9 16.5C9.8325 16.5 10.5 15.8325 10.5 15H7.5C7.5 15.8325 8.1675 16.5 9 16.5ZM13.5 11.01V8.25C13.5 5.94 12.27 4.02 10.125 3.51V3C10.125 2.3775 9.6225 1.875 9 1.875C8.3775 1.875 7.875 2.3775 7.875 3V3.51C7.7625 3.5325 7.6575 3.57 7.56 3.6C7.485 3.6225 7.41 3.6525 7.335 3.6825H7.3275C7.32 3.6825 7.32 3.6825 7.3125 3.69C7.14 3.7575 6.9675 3.84 6.8025 3.9225C6.8025 3.9225 6.795 3.9225 6.795 3.93L13.5 11.01Z"
            fill="#CF6679"
          />
        </g>
        <defs>
          <clipPath id="clip0_2679_12711">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {tooltipText && <span className="tooltiptext down">{tooltipText}</span>}
    </div>
  );
};
