import React from "react";

interface Props {
  action?: () => any | void;
  tooltipText?: string;
  muted?: boolean;
  disabled?: boolean;
  tooltipPosition?: "right" | "down" | "left" | "top";
}
export const MuteIcon: React.FC<Props> = ({
  action,
  tooltipText,
  muted = false,
  disabled = false,
  tooltipPosition = "right",
}) => {
  return (
    <div className="tooltip">
      <svg
        onClick={(e) => {
          e.stopPropagation();
          action?.();
        }}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`audio-muted-icon ${muted && "selected"} ${
          disabled && "disabled"
        }`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.42257 11.5V20.5H11.4226L18.9226 28V23.0353L7.26903 11.5H5.42257ZM12.046 10.8766L5.47785 4.37504L4 5.86804L26.1416 27.7851L27.6195 26.2921L18.9226 17.6834V4L12.046 10.8766Z"
          fill="black"
        />
      </svg>
      {tooltipText && (
        <span className={`tooltiptext ${tooltipPosition}`}>{tooltipText}</span>
      )}
    </div>
  );
};
