import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";

interface Props {
  maxPage: number;
  currentPage: number;
  onNext: () => void;
  onPrevious: () => void;
}
export const Pagination: React.FC<Props> = ({
  maxPage,
  currentPage,
  onNext,
  onPrevious,
}) => {
  return (
    <div className="pagination">
      <ArrowDownLogo
        orientation="left"
        action={() => currentPage > 1 && onPrevious()}
        disabled={currentPage === 1}
      />
      {currentPage}/{maxPage}
      <ArrowDownLogo
        orientation="right"
        action={() => currentPage < maxPage && onNext()}
        disabled={currentPage === maxPage}
      />
    </div>
  );
};
