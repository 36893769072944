import {
  ContactType,
  Contacts,
} from "../interfaces/contacts/contacts.interface";
import { lessThan10Min } from "./timeOperations";

export const lookForContactExpiration = (
  id: string,
  roomNumber: string,
  actionForContactsType: (room: string, assigned: boolean) => any,
  actionOnExpiredContact: (
    room: string,
    contactId: string,
    endDateTime: string,
    contactType: ContactType,
    patientId: string
  ) => any,
  actionOnNoExpiration: (room: string, contactId: string) => any,
  contacts?: Contacts[]
) => {
  let hasPrimaryContacts = false;

  if (contacts?.length) {
    for (let j = 0; j < contacts.length; j++) {
      const contact = contacts[j];

      if (contact.type === "Nurse" || contact.type === "PCT") {
        hasPrimaryContacts = true;
      }

      const parsedDate = contact.endDateTime.endsWith("Z")
        ? contact.endDateTime
        : `${contact.endDateTime}Z`;

      if (contact.isAboutToExpire || lessThan10Min(parsedDate)) {
        actionOnExpiredContact(
          roomNumber,
          contact.id,
          contact.endDateTime,
          contact.type,
          id
        );
      } else {
        actionOnNoExpiration(roomNumber, contact.id);
      }
    }
  }

  actionForContactsType(roomNumber, hasPrimaryContacts);
};
