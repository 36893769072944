export const CogIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7602 8.62676C12.7869 8.42676 12.8002 8.2201 12.8002 8.0001C12.8002 7.78676 12.7869 7.57343 12.7536 7.37343L14.1069 6.3201C14.2269 6.22676 14.2602 6.04676 14.1869 5.91343L12.9069 3.7001C12.8269 3.55343 12.6602 3.50676 12.5136 3.55343L10.9202 4.19343C10.5869 3.9401 10.2336 3.72676 9.84024 3.56676L9.60024 1.87343C9.57357 1.71343 9.44024 1.6001 9.28024 1.6001H6.72024C6.56024 1.6001 6.43357 1.71343 6.4069 1.87343L6.1669 3.56676C5.77357 3.72676 5.41357 3.94676 5.0869 4.19343L3.49357 3.55343C3.3469 3.5001 3.18024 3.55343 3.10024 3.7001L1.8269 5.91343C1.7469 6.05343 1.77357 6.22676 1.9069 6.3201L3.26024 7.37343C3.2269 7.57343 3.20024 7.79343 3.20024 8.0001C3.20024 8.20676 3.21357 8.42676 3.2469 8.62676L1.89357 9.6801C1.77357 9.77343 1.74024 9.95343 1.81357 10.0868L3.09357 12.3001C3.17357 12.4468 3.34024 12.4934 3.4869 12.4468L5.08024 11.8068C5.41357 12.0601 5.7669 12.2734 6.16024 12.4334L6.40024 14.1268C6.43357 14.2868 6.56024 14.4001 6.72024 14.4001H9.28024C9.44024 14.4001 9.57357 14.2868 9.59357 14.1268L9.83357 12.4334C10.2269 12.2734 10.5869 12.0601 10.9136 11.8068L12.5069 12.4468C12.6536 12.5001 12.8202 12.4468 12.9002 12.3001L14.1802 10.0868C14.2602 9.9401 14.2269 9.77343 14.1002 9.6801L12.7602 8.62676ZM8.00024 10.4001C6.68024 10.4001 5.60024 9.3201 5.60024 8.0001C5.60024 6.6801 6.68024 5.6001 8.00024 5.6001C9.32024 5.6001 10.4002 6.6801 10.4002 8.0001C10.4002 9.3201 9.32024 10.4001 8.00024 10.4001Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
