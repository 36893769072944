import { useMemo, useState } from "react";
import { WHEPClient } from "../../../../utils/whepClient";
import { VideoMemo } from "../VideoMemo/VideoMemo";

interface Props {
  url: string;
  muted: boolean;
  videoLoad: boolean;
  isBlur?: boolean;
  errorVideoLabel: string;
  onReady: () => any;
  onError: (label: string) => any;
}
export const Cams: React.FC<Props> = ({
  url,
  muted,
  videoLoad,
  errorVideoLabel,
  onError,
  onReady,
  isBlur,
}) => {
  const [stream, setStream] = useState<MediaStream | undefined>();
  const whepClient = useMemo(
    () =>
      new WHEPClient(
        url,
        (stream) => setStream(stream),
        () => onReady?.(),
        () => onError?.("Video not available")
      ),
    [url]
  );

  return (
    <div
      className={`cams ${videoLoad && "loading"} ${isBlur && "blur"}`}
      aria-label="cams"
    >
      {videoLoad && (
        <h2 className="label" aria-label="loading-label">
          {errorVideoLabel}
        </h2>
      )}
      <VideoMemo stream={stream} muted={muted} />
    </div>
  );
};
