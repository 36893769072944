import {
  AudioOperationsEnum,
  ContactCommandOperationsEnum,
  MonitoringOperationsEnum,
  PatientCommandOperationsEnum,
  PatientQueryOperationsEnum,
} from "../enums/apisOperations";
import { CatalogsEnum } from "../enums/catalogs";
import { ConnectionsEnum } from "../enums/connectionsEnum";
import { env } from "../env";

class EndpointGenerator {
  generatePatientQueryEndpoint(
    operationName: PatientQueryOperationsEnum,
    params?: string
  ): string {
    const sufix = PatientQueryOperationsEnum[operationName];
    const baseEndpoint = `${env.REACT_APP_API_PATIENTS}/api/v1/PatientLookup/${sufix}`;
    return this.addParams(baseEndpoint, params);
  }

  generatePatientCommandEndpoint(
    operationName: PatientCommandOperationsEnum,
    params?: string
  ): string {
    const sufix = PatientCommandOperationsEnum[operationName];
    const baseEndpoint = `${env.REACT_APP_API_COMMAND_PATIENTS}/api/v1/PatientOperations/${sufix}`;
    return this.addParams(baseEndpoint, params);
  }

  generateContactCommandEndpoint(
    operationName: ContactCommandOperationsEnum,
    params?: string
  ): string {
    const sufix = ContactCommandOperationsEnum[operationName];
    const baseEndpoint = `${env.REACT_APP_API_COMMAND_NURSES}/api/v1/ContactOperations/${sufix}`;
    return this.addParams(baseEndpoint, params);
  }

  generateAudioQueryEndpoint(
    operationName: AudioOperationsEnum,
    params: string,
    patientLang?: string
  ): string {
    const sufix = AudioOperationsEnum[operationName];
    const baseEndpoint = `${env.REACT_APP_DEVICES_API}/devices/${sufix}`;
    const langParam = patientLang ? `&lang=${patientLang}` : "";
    return `${baseEndpoint}/${params}?api_key=${env.REACT_APP_DEVICES_API_KEY}${langParam}`;
  }

  generateDeviceQueryEndpoint(
    location: string,
    room: string,
    cartId?: string
  ): string {
    const baseEndpoint = `${env.REACT_APP_DEVICES_API}/devices/room/${
      cartId || `${location}-${room}`
    }`;
    return `${baseEndpoint}?api_key=${env.REACT_APP_DEVICES_API_KEY}`;
  }

  generateMonitoringEndpoint(
    monitoringOperation: MonitoringOperationsEnum,
    location: string,
    room: string,
    cartId?: string
  ) {
    const sufix = MonitoringOperationsEnum[monitoringOperation];
    const baseEndpoint = `${env.REACT_APP_DEVICES_API}/devices/${sufix}/${
      cartId || `${location}-${room}`
    }`;

    return `${baseEndpoint}?api_key=${env.REACT_APP_DEVICES_API_KEY}`;
  }

  generateDeviceQueryEndpointByCart(cartId: string): string {
    const baseEndpoint = `${env.REACT_APP_DEVICES_API}/devices/room/${cartId}`;
    return `${baseEndpoint}?api_key=${env.REACT_APP_DEVICES_API_KEY}`;
  }

  generateCatalogsEndpoint(catalog: CatalogsEnum, params?: string): string {
    const sufix = CatalogsEnum[catalog];
    const baseEndpoint = `${env.REACT_APP_SETTINGS_API}/api/Catalogs/${sufix}`;
    return this.addParams(baseEndpoint, params);
  }

  generateLocationEndpoint(catalog?: CatalogsEnum, params?: string | string[]) {
    const locationId = typeof params === "string" ? params : params?.[0];
    const nurseId = Array.isArray(params) ? params[1] : undefined;

    const baseEndpoint = `${env.REACT_APP_SETTINGS_API}/api/Locations/${
      params ? locationId + "/" : ""
    }${catalog ? CatalogsEnum[catalog] : ""}${nurseId ? nurseId : ""}`;

    return baseEndpoint;
  }

  generateConnectionsEndpoint(
    operationName?: ConnectionsEnum,
    connectionId?: string,
    query?: string
  ): string {
    const sufix = operationName !== undefined && ConnectionsEnum[operationName];
    let baseEndpoint = `${env.REACT_APP_API_CONNECTIONS}/api/v1/Connections`;

    if (connectionId) {
      baseEndpoint = `${baseEndpoint}/${connectionId}`;
    }

    if (sufix) {
      baseEndpoint = `${baseEndpoint}/${sufix}`;
    }

    return this.addQuery(baseEndpoint, query);
  }

  private addParams(baseEndpoint: string, params?: string) {
    if (params) {
      return `${baseEndpoint}/${params}`;
    }
    return baseEndpoint;
  }

  private addQuery(baseEndpoint: string, query?: string) {
    if (query) {
      return `${baseEndpoint}?${query}`;
    }
    return baseEndpoint;
  }
}

export default new EndpointGenerator();
