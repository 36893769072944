import moment from "moment";

const MINS_TO_SHOW_PILL = 10;
export const lessThan10Min = (
  utcTime?: string,
  time: number = MINS_TO_SHOW_PILL
) => {
  if (!utcTime) {
    return;
  }

  let now = moment().utc();
  let diff = moment(utcTime).utc().diff(now, "minutes");

  if (diff < time) {
    return true;
  }
};

export const alreadyExpired = (utcTime?: string) => {
  if (!utcTime) {
    return;
  }

  let now = moment().utc();
  let diff = moment(utcTime).utc().diff(now, "minutes");

  if (diff < 0) {
    return true;
  }
};

export const getPassedTime = (
  dateTimeBeforeNow: string,
  withHour?: boolean,
  withDay?: boolean
) => {
  const now = moment().utc();

  dateTimeBeforeNow = dateTimeBeforeNow.endsWith("Z")
    ? dateTimeBeforeNow
    : `${dateTimeBeforeNow}Z`;

  const remSeconds = moment(now).diff(dateTimeBeforeNow, "seconds");
  const { seconds, minutes } = calculateMinsFromSecs(remSeconds);

  if (!withHour) {
    return { seconds, minutes };
  }

  const hours =
    minutes > 0 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60);
  const remMinutes = minutes % 60;

  if (!withDay) {
    return {
      hours: hours !== -0 ? hours : 0,
      minutes: remMinutes,
      seconds,
    };
  }

  const day = hours > 0 ? Math.floor(hours / 24) : Math.ceil(hours / 24);
  const remHours = hours % 24;

  return {
    day: day !== -0 ? day : 0,
    hours: remHours,
    minutes: remMinutes,
    seconds,
  };
};

export const getPassedTimeText = (
  seconds: number,
  minutes?: number,
  hours?: number,
  day?: number
) => {
  if (day && day > 0 && hours && hours > 0) {
    return `${day} day${day > 1 ? "s" : ""} ${hours} hour${
      hours > 1 ? "s" : ""
    }`;
  }

  if (day && day > 0) {
    return `${day} day${day > 1 ? "s" : ""}`;
  }

  if (hours && hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  if (minutes && minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ${seconds} second${
      seconds > 1 ? "s" : ""
    }`;
  }

  return `${seconds} second${seconds > 1 ? "s" : ""}`;
};

export const getTimeBetweenTwoDates = (
  firstUtcTime: string,
  secondUtcTime: string
) => {
  if (!firstUtcTime || !secondUtcTime) {
    return;
  }

  const seconds = -moment(firstUtcTime).utc().diff(secondUtcTime, "seconds");
  return calculateMinsFromSecs(seconds);
};

export const calculateMinsFromSecs = (seconds: number) => {
  const minutes =
    seconds > 0 ? Math.floor(seconds / 60) : Math.ceil(seconds / 60);
  const remSeconds = seconds % 60;

  return { minutes: minutes !== -0 ? minutes : 0, seconds: remSeconds };
};
