import { useEffect, useState } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { SelectPatient } from "../../atoms/SelectPatient/SelectPatient";

interface Props {
  id: string;
  loading: boolean;
  handleSubmit: () => void;
  handleChange: (e: any) => void;
  handleRiskChange: (e: any) => void;
  riskList: number[];
  riskValue: number;
  patients: Patient[];
}

export const AutoFakeAI: React.FC<Props> = ({
  handleSubmit,
  handleChange,
  handleRiskChange,
  riskList,
  patients,
  id,
  loading,
  riskValue,
}) => {
  const [msInterval, setMsInterval] = useState(1000);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isSending) {
        handleSubmit();
        return;
      }
      clearInterval(interval);
    }, msInterval);
    return () => clearInterval(interval);
  }, [msInterval, isSending, riskValue]);

  return (
    <div className="p-2">
      <h3>Auto send Fake AI using interval</h3>
      <h4>Is this sending values? {isSending ? "yes" : "no"}</h4>
      <h4>
        Sending data: id({id}) - risk({riskValue})
      </h4>
      <form onSubmit={(e) => e.preventDefault}>
        <div className="form-group row column py-1">
          <label htmlFor="id-patient">Choose patient</label>
          {!loading && <SelectPatient patients={patients} handleChange={handleChange}/>}
          {loading && (
            <div className="loaderDiv">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <div className="form-group row column py-1">
          <label htmlFor="risk">Choose risk</label>
          <select
            value={riskValue}
            onChange={handleRiskChange}
            aria-label="fake-ai-value"
          >
            {riskList.map((currRisk) => (
              <option value={currRisk} key={currRisk}>
                {currRisk}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group row column py-1">
          <label htmlFor="msInterval">Interval for auto sending (ms)</label>
          <input
            type="number"
            name="msInterval"
            id="msInterval"
            placeholder="1000"
            value={msInterval}
            disabled={isSending}
            min={100}
            max={60000}
            onChange={({ target }) => setMsInterval(Number(target.value))}
          />
        </div>
        <div className="btn-group left row">
          <button
            type="button"
            className="btn-xsmall light"
            aria-label="start-btn"
            onClick={() => setIsSending(true)}
          >
            Start Interval
          </button>
          <button
            type="button"
            className="btn-xsmall light"
            aria-label="stop-btn"
            onClick={() => setIsSending(false)}
          >
            Stop Interval
          </button>
        </div>
      </form>
    </div>
  );
};
