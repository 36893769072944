import React from "react";
import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { RiskValue } from "../../../../interfaces/fallRisk/fallRisk.interface";
import { Badge } from "../Badge/Badge";

interface Props {
  title?: string;
  arrow?: boolean;
  arrowAction?: () => any;
  riskFall?: number;
  close?: boolean;
  closeAction?: () => any;
}

export const SiderbarTitle: React.FC<Props> = ({
  title = "Patients Information",
  arrow = false,
  arrowAction,
  riskFall,
  close = false,
  closeAction,
}) => {
  return (
    <div className="sidebar-title-bx">
      {arrow && <ArrowDownLogo action={arrowAction} orientation="left" />}
      <div className="sidebar-title">
        <p>{title}</p>
        <Badge riskFall={riskFall as RiskValue} />
      </div>
      {close && <CloseIcon action={closeAction} />}
    </div>
  );
};
