import React from "react";

interface Props {
  action?: () => any | void;
  tooltipPosition?: "right" | "down" | "left" | "top";
  tooltipText?: string;
}

export const Nurse: React.FC<Props> = ({
  action,
  tooltipText,
  tooltipPosition = "right",
}) => {
  return (
    <div className="tooltip">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={action}
        aria-label="nurse-icon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5301 15.1839C11.9157 15.1839 8.9856 12.2325 8.9856 8.59193C8.9856 4.9513 11.9157 2 15.5301 2C19.1445 2 22.0746 4.9513 22.0746 8.59193C22.0746 12.2325 19.1445 15.1839 15.5301 15.1839ZM21.1911 15.5276C23.1265 16.1144 24.8993 17.3544 25.9639 18.8662C26.9959 20.3318 27.3026 21.3446 27.734 24.7089C28.0792 27.3999 28.0839 27.8473 27.7745 28.4583C27.2597 29.4747 26.7004 29.77 24.596 30.1365C18.4573 31.2053 12.2233 31.1923 6.24485 30.0983C5.56385 29.9737 4.82991 29.8056 4.61396 29.7249C4.14079 29.5481 3.50627 28.9794 3.27692 28.5267C2.91452 27.8111 2.91249 27.5896 3.24341 24.9292C3.60018 22.0618 3.76593 21.2966 4.25178 20.2741C5.05183 18.5901 6.13698 17.4066 7.78485 16.4205C8.29813 16.1133 9.48577 15.628 10.1701 15.4396C10.897 15.1977 11.2899 15.5149 11.4765 15.7728L12.9552 17.498C14.5359 19.3422 14.8148 19.5706 15.4867 19.5715C16.2017 19.5724 16.4532 19.3706 17.9883 17.5639C18.1133 17.4169 18.2274 17.2817 18.332 17.1578L18.3712 17.1114C18.5671 16.8794 18.7283 16.6889 18.863 16.5365C19.1052 16.1308 19.8935 15.0464 20.7679 15.3929C20.7785 15.3971 20.7893 15.4016 20.8001 15.4063L20.8192 15.4149L20.8345 15.4221C20.9349 15.4501 21.0532 15.4858 21.1911 15.5276ZM19.7715 20.7501H21.7643V22.5215H23.5356V24.5143H21.7643V26.2857H19.7715V24.5143H18V22.5215H19.7715V20.7501Z"
          fill="black"
        />
      </svg>
      {tooltipText && (
        <span className={`tooltiptext ${tooltipPosition}`}>{tooltipText}</span>
      )}
    </div>
  );
};
