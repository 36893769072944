const DEFAULT_MAX_CHARS = 16;

export const cutString = (
  string: string,
  maxLength: number = DEFAULT_MAX_CHARS
) => {
  if (string.length <= maxLength) {
    return string;
  }

  return `${string.slice(0, maxLength - 3)}...`;
};
