import { RiskValue } from "../interfaces/fallRisk/fallRisk.interface";
import { env } from "../env";
import { ErrorTypeEnum } from "../enums/errorType";
import { CustomSocket } from "../utils/customSocket";
import { ESitterEventsEnum } from "../enums/eSitterEvents";

let socket: CustomSocket;

export const initiateAISocket = (
  errorCallback: (arg: ErrorTypeEnum) => any,
  onConnection: () => any,
  onNewMessage: (arg1: { Id: string; FallRisk: RiskValue }) => any
) => {
  if (!socket) {
    socket = new CustomSocket(
      env.REACT_APP_AI_URL,
      "/ai-socket/",
      () => {
        onConnection();
        subscribeToFallRisk(onNewMessage)
      },
      errorCallback,
      ErrorTypeEnum.AISocket
    );
  }
};

export const disconnectAISocket = () => {
  if (!socket) return;
  socket.disconnect();
};

export const subscribeToFallRisk = (
  cb: (arg1: { Id: string; FallRisk: RiskValue }) => void
) => {
  if (!socket) return false;

  socket.suscribe(
    ESitterEventsEnum.fallRisk,
    ({ Id, FallRisk }: { Id: string; FallRisk: RiskValue }) => {
      return cb({ Id, FallRisk });
    }
  );
};
