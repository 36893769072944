import { ActivityPillEnum } from "../../../../interfaces/activity/activity.interface";
import { PillAlert } from "../../../../interfaces/pillAlert/pillAlerts.interface";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { cutString } from "../../../../utils/cutString";
import { AlertPill } from "../AlertPill/AlertPill";

interface Props {
  room: string;
  firstName: string;
  lastName: string;
  pillAlertByRoom: PillAlert;
  endEscalationPath: (type: ActivityPillEnum, secs?: number) => void;
}

export const EscPathPills: React.FC<Props> = ({
  room,
  firstName,
  lastName,
  pillAlertByRoom,
  endEscalationPath,
}) => {
  return (
    <>
      <div className="escalation-path-name patient-name">
        <div className="room-number">
          <h3>
            <span>ROOM</span> {room}
          </h3>
        </div>
        <h3>
          {` ${cutString(capitalizeString(lastName))}, ${cutString(
            capitalizeString(firstName)
          )}`}
        </h3>
      </div>
      <AlertPill
        {...pillAlertByRoom}
        actionOnEnd={(secs?: number) =>
          pillAlertByRoom.type &&
          endEscalationPath(
            pillAlertByRoom.type === ActivityPillEnum.dispatch
              ? ActivityPillEnum.callEnded
              : pillAlertByRoom.type,
            secs
          )
        }
        tooltipText={
          pillAlertByRoom.type === ActivityPillEnum.dispatch
            ? "Waiting for care team member"
            : undefined
        }
      />
    </>
  );
};
