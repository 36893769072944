export const PinIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2171_32285)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2997 8.47136L13.6567 6.11434L14.1281 6.58575C14.3874 6.84502 14.8116 6.84502 15.0709 6.58575C15.3302 6.32647 15.3302 5.90221 15.0709 5.64294L10.3569 0.928893C10.0976 0.66962 9.67333 0.66962 9.41406 0.928893C9.15479 1.18817 9.15479 1.61243 9.41406 1.8717L9.88547 2.34311L7.52844 4.70013C6.74591 5.48266 5.48255 5.48266 4.70002 4.70013L3.75721 5.64294L6.57149 8.45722L3.27166 11.7571L3.27166 12.6999L4.21447 12.6999L7.5143 9.40003L10.3569 12.2426L11.2997 11.2998C10.5171 10.5173 10.5171 9.2539 11.2997 8.47136Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2171_32285">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
