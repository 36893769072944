import { OrderEnum } from "../enums/global";
import { Patient } from "../interfaces/patients/patients.interface";

export const sortPatientsByProp = (
  patients: Patient[],
  prop: keyof Patient,
  order: OrderEnum
): Patient[] => {
  return [...patients].sort((pA, pB) => {
    if ((pA[prop] || 0) > (pB[prop] || 0)) {
      return order === OrderEnum.desc ? -1 : 1;
    }

    return order === OrderEnum.desc ? 1 : -1;
  });
};
