import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { MiniForm } from "../../atoms/MiniForm/MiniForm";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { RiskValue } from "../../../../interfaces/fallRisk/fallRisk.interface";
import {
  PillAlert,
  TimerStatus,
} from "../../../../interfaces/pillAlert/pillAlerts.interface";
import {
  AiFeedback,
  AudioRequest,
  CallError,
} from "../../../../interfaces/shared/shared.interface";
import {
  ActivityPillEnum,
  ActivityTypeEnum,
  AddActivity,
} from "../../../../interfaces/activity/activity.interface";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";
import { LowerMenu } from "../../atoms/LowerMenu/LowerMenu";
import { RootState } from "../../../../stores";
import { playAudioMessage } from "../../../../helpers/audio";
import { setSelectedPatient } from "../../../../features/selectedPatient/selectedPatient";
import { AudioOperationsEnum } from "../../../../enums/apisOperations";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { CallLabelEnum } from "../../../../enums/callLabel";
import logger from "../../../../logger/logger";
import { setNewAlarm } from "../../../../features/sosAlarm/sosAlarmSlice";
import { UpperMenu } from "../../atoms/UpperMenu/UpperMenu";
import { useVideoRecording } from "../../../../hooks/useVideoRecording";
import { env } from "../../../../env";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { deletePatient } from "../../../../helpers/commandsPatient";
import { usePatients } from "../../../../hooks/usePatients";

interface Props {
  patient: Patient;
  audioRecording?: boolean;
  toogleMuted?: (arg: boolean) => any;
  videoLoading?: boolean;
  mutedStatus?: boolean;
  riskValue: RiskValue;
  updatePatientFlag?: boolean;
  helperToAddActivity?: (args: {
    id: string;
    activity: string;
    activityType: ActivityTypeEnum;
  }) => Promise<any | void>;
  helperToFetchPatient?: (id: string) => Promise<Patient | any>;
  aiFeedback: AiFeedback;
  setAiFeedback?: (args: AiFeedback) => void;
  playAudioMsg: boolean;
  setPlayAudioMsg?: (arg: boolean) => void;
  callLoading: boolean;
  setCallLoading?: (arg: boolean) => void;
  undoFeedbackAI?: () => any;
  microMuted: boolean;
  setMicroMuted?: (arg: boolean) => void;
  handleRemoveAudio?: () => Promise<void>;
  handleCall?: (number: string) => void;
  hangOn?: () => void;
  callLabel: CallLabelEnum;
  callError?: CallError;
  handleRemoveAlert?: () => void;
  addActivityAndFetch?: (
    data: AddActivity,
    updatePatient?: boolean
  ) => Promise<void>;
  statusLabel: string;
}

const ALARM_RECORDING_SECS = isNaN(env.REACT_APP_ALARM_RECORDING_SECS)
  ? 10
  : Number(env.REACT_APP_ALARM_RECORDING_SECS);

const AWAIT_TO_CLOSE = 5000;
export const CamsMenu: React.FC<Props> = ({
  patient,
  audioRecording = true,
  toogleMuted,
  videoLoading,
  mutedStatus = false,
  aiFeedback,
  setAiFeedback,
  playAudioMsg,
  setPlayAudioMsg,
  callLoading,
  setCallLoading,
  undoFeedbackAI,
  microMuted,
  setMicroMuted,
  handleRemoveAudio,
  hangOn,
  handleCall,
  callError,
  callLabel,
  handleRemoveAlert,
  addActivityAndFetch,
  statusLabel,
}) => {
  const dispatch = useDispatch();
  const { startVideoRecord } = useVideoRecording();
  const options = useMemo(
    () => ["Lying in bed", "Getting out", "Out of bed"],
    []
  );
  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState<ErrorTypeEnum>(
    ErrorTypeEnum.Default
  );
  const [alarmModal, setAlarmModal] = useState(false);
  const { alarms } = useSelector((state: RootState) => state.sosAlarm);
  const [currAlarm, setCurrAlarm] = useState<PillAlert | null>(null);
  const { generalErrors } = useSelector(
    (state: RootState) => state.globalErrors
  );
  const [connectionError, setConnectionError] = useState(false);
  const { defaultStatus } = useSelector((state: RootState) => state.patients);
  const { removeInput } = useSelector((state: RootState) => state.openForms);
  const [errorManualRemoval, setErrorManualRemoval] = useState<
    ErrorTypeEnum | undefined
  >(undefined);
  const [loadingManualRemoval, setLoadingManualRemoval] = useState(false);
  const { getDeviceInfoAndUpdatePatient } = usePatients();
  const {
    session: {
      sub: [username, userid],
    },
  } = useSelector((state: RootState) => state.sessionInfo);


  useEffect(() => {
    if (videoLoading) {
      setAlarmModal(false);
    }
  }, [videoLoading]);

  useEffect(() => {
    if (generalErrors.includes(ErrorTypeEnum.AudioSocket)) {
      setConnectionError(true);
    } else {
      setConnectionError(false);
    }
  }, [generalErrors]);

  useEffect(() => {
    const alarm = alarms.find((alarm) => alarm.room === patient.roomNumber);
    setCurrAlarm(alarm as PillAlert);
  }, [alarms]);

  useEffect(() => {
    if (callError && !isNaN(callError)) {
      setError(true);
      setErrorLabel(callError);
      setMicroMuted?.(true);
      setPlayAudioMsg?.(false);
    }
  }, [callError]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, AWAIT_TO_CLOSE);
    }
  }, [error]);

  const toogleDetailedMenu = () => {
    dispatch(setSelectedPatient(patient.id));
  };

  const declineAI = (response: string) => {
    setAiFeedback?.({
      ...aiFeedback,
      confirmation: false,
      declineMsg: response,
      declination: true,
      declineMiniForm: false,
    });
  };

  const openMiniForm = () => {
    setAlarmModal(false);
    setAiFeedback?.({
      ...aiFeedback,
      confirmation: false,
      declination: false,
      declineMiniForm: true,
    });
  };

  const confirmAI = async () => {
    setAiFeedback?.({
      ...aiFeedback,
      confirmation: true,
      declination: false,
      declineMiniForm: false,
    });
  };

  const sendAlert = () => {
    setAlarmModal(true);
    setAiFeedback?.({
      declineMsg: "",
      confirmation: false,
      declination: false,
      declineMiniForm: false,
    });
  };

  const sendAudioMessage = async () => {
    setCallLoading?.(true);
    setError(false);
    if (currAlarm?.timerStatus === "play") {
      handleStopAudio("alarm");
      saveNewAudio(ActivityTypeEnum.AlarmReproduced, { isPillOpen: false });
    }

    try {
      const response: AudioRequest = await playAudioMessage(
        patient.location,
        patient.roomNumber,
        patient.language,
        AudioOperationsEnum.playMessage,
        patient.cartId
      );
      logger.log(`New audio id`, response?.Status?.id);
      if (response?.Status?.id) {
        setPlayAudioMsg?.(true);
        saveNewAudio(ActivityTypeEnum.AudioReproduced, { isPillOpen: false });
      } else {
        throw response;
      }
    } catch (e) {
      getDeviceInfoAndUpdatePatient(patient);
      if (e === "Allocation failed") {
        setErrorLabel(ErrorTypeEnum.DeviceOffline);
      } else {
        setErrorLabel(ErrorTypeEnum.Retry);
      }
      logger.error("Playing an audio message", e);
      setError(true);
    } finally {
      setCallLoading?.(false);
    }
  };

  const handleStopAudio = async (type: "alarm" | "audioMessage") => {
    try {
      if (type === "alarm") {
        handleRemoveAlert?.();
        return;
      }
      await handleRemoveAudio?.();
    } catch (e) {
      setError(true);
      type === "alarm"
        ? setErrorLabel(ErrorTypeEnum.AlarmCannotStop)
        : setErrorLabel(ErrorTypeEnum.AudioCannotStop);
    }
  };

  useEffect(() => {
    if (playAudioMsg) {
      setTimeout(() => {
        setPlayAudioMsg?.(false);
      }, AWAIT_TO_CLOSE);
    }
  }, [playAudioMsg, callLabel]);

  const sendAlarm = async () => {
    setAlarmModal(false);
    setCallLoading?.(true);
    setError(false);

    if (playAudioMsg) {
      handleStopAudio("audioMessage");
      setPlayAudioMsg?.(false);
    }

    try {
      const response: AudioRequest = await playAudioMessage(
        patient.location,
        patient.roomNumber,
        patient.language,
        AudioOperationsEnum.playAlarm,
        patient.cartId
      );
      logger.log(`New alarm id`, response?.Status?.id);
      if (response?.Status?.id) {
        openAlarmPill();

        const data: AddActivity = {
          id: patient.id,
          user: userid,
          activity: ActivityTypeEnum[ActivityTypeEnum.AlarmOn],
          activityType: ActivityTypeEnum.AlarmOn,
          clientDateTime: moment().utc().format(),
        };
        addActivityAndFetch?.(data, false);
        saveNewAudio(ActivityTypeEnum.AlarmReproduced, { isPillOpen: true });

        startVideoRecord({
          admissionId: patient.admissionId,
          time: ALARM_RECORDING_SECS,
          issue: `alarm`,
          room: patient.roomNumber,
          location: patient.location,
          cartId: patient.cartId
        });
      } else {
        throw response;
      }
    } catch (e) {
      getDeviceInfoAndUpdatePatient(patient);
      if (e === "Allocation failed") {
        setErrorLabel(ErrorTypeEnum.DeviceOffline);
      } else {
        setErrorLabel(ErrorTypeEnum.Retry);
      }
      logger.error("Playing an alarm", e);
      setError(true);
    } finally {
      setCallLoading?.(false);
    }
  };

  const openAlarmPill = () => {
    const pill: PillAlert = {
      room: patient.roomNumber,
      activate: true,
      type: ActivityPillEnum.sosAlert,
      timerStatus: "play",
      flag: true,
    };
    dispatch(setNewAlarm(pill));
  };

  const saveNewAudio = async (
    activityType:
      | ActivityTypeEnum.AlarmReproduced
      | ActivityTypeEnum.AudioReproduced,
    activity: { isPillOpen?: boolean }
  ) => {
    const data: AddActivity = {
      id: patient.id,
      user: userid,
      activity: JSON.stringify(activity),
      activityType,
      clientDateTime: moment().utc().format(),
    };
    await addActivityAndFetch?.(data);
  };

  useEffect(() => {
    if (
      !microMuted &&
      callLabel === CallLabelEnum.Call &&
      patient.deviceInfo?.sipExtension
    ) {
      if (currAlarm?.timerStatus === "play") {
        handleStopAudio("alarm");
        saveNewAudio(ActivityTypeEnum.AlarmReproduced, { isPillOpen: false });
      }
      if (playAudioMsg) {
        handleStopAudio("audioMessage");
      }
      toogleMuted?.(true);
      handleCall?.(patient.deviceInfo?.sipExtension.toString());
      const data: AddActivity = {
        id: patient.id,
        user: userid,
        activity: ActivityTypeEnum[ActivityTypeEnum.EnableMicrophone],
        activityType: ActivityTypeEnum.EnableMicrophone,
        clientDateTime: moment().utc().format(),
      };
      addActivityAndFetch?.(data, false);
      return;
    }

    if (
      microMuted &&
      audioRecording &&
      callLabel === CallLabelEnum.HangOn &&
      !connectionError
    ) {
      toogleMuted?.(false);
      hangOn?.();
      const data: AddActivity = {
        id: patient.id,
        user: userid,
        activity: ActivityTypeEnum[ActivityTypeEnum.DisableMicrophone],
        activityType: ActivityTypeEnum.DisableMicrophone,
        clientDateTime: moment().utc().format(),
      };
      addActivityAndFetch?.(data, false);
      return;
    }
  }, [microMuted, audioRecording, callLabel]);

  const changeMicro = async () => {
    setMicroMuted?.(!microMuted);
  };

  const handleManualRemoval = async (response: string) => {
    const EXPECTED_KEY_TO_REMOVE = "remove";
    const regExp = new RegExp(EXPECTED_KEY_TO_REMOVE, "i");
    if (response.match(regExp) && !loadingManualRemoval) {
      setLoadingManualRemoval(true);
      try {
        await deletePatient(patient.id, true);
        dispatch(setForm({ typeInput: "removeInput", status: false }));
      } catch (e) {
        logger.error("Error on manual removal", e);
        setErrorManualRemoval(ErrorTypeEnum.Retry);
      } finally {
        setLoadingManualRemoval(false);
      }
      return;
    }
    setErrorManualRemoval(ErrorTypeEnum.Misspelling);
  };

  return (
    <div
      className="cams-menu"
      onClick={toogleDetailedMenu}
      aria-label="cams-menu"
    >
      <UpperMenu
        patient={patient}
        isVideoLoading={videoLoading}
        mutedStatus={mutedStatus}
        toogleMuted={toogleMuted}
        defaultStatus={defaultStatus}
        shouldCloseMenu={alarmModal || aiFeedback.declineMiniForm}
        statusLabel={statusLabel}
      />

      {error && (
        <ErrorMsg
          errorMsg={errorLabel}
          filled={true}
          width={80}
          position="absolute bottom"
          Icon={false}
          closeBtn={true}
          padding="small"
          interval={AWAIT_TO_CLOSE}
        />
      )}

      {aiFeedback.declineMiniForm && (
        <MiniForm
          title="What is the patient doing?"
          submitAction={declineAI}
          undoAction={undoFeedbackAI}
          options={options}
          type="illustratedSurvey"
        />
      )}

      {alarmModal && (
        <MiniForm
          title="Sound Emergency Alarm?"
          type="message"
          message="This will play a critical sound in the patient's room."
          undoAction={() => setAlarmModal(false)}
          onAccept={sendAlarm}
        />
      )}

      {removeInput.status && (
        <MiniForm
          title={`Remove video from Room ${patient.roomNumber}`}
          type="input"
          message={`Type`}
          messageSpan={`"Remove"`}
          undoAction={() =>
            dispatch(setForm({ typeInput: "removeInput", status: false }))
          }
          submitAction={handleManualRemoval}
          error={errorManualRemoval}
          loading={loadingManualRemoval}
        />
      )}

      <div className="lower-menu">
        <LowerMenu
          videoLoading={videoLoading || false}
          sendAlert={sendAlert}
          changeMicro={changeMicro}
          microMuted={microMuted}
          confirmAI={confirmAI}
          openMiniForm={openMiniForm}
          sendAudioMessage={sendAudioMessage}
          declinationMiniForm={aiFeedback.declineMiniForm}
          confirmationAi={aiFeedback.confirmation}
          declinationAi={aiFeedback.declination}
          audioAvailable={!connectionError}
          alarmStatus={currAlarm?.timerStatus as TimerStatus}
          stopAlarm={() => handleRemoveAlert?.()}
          alarmModalStatus={alarmModal}
          loadingCall={
            (!microMuted && callLabel === CallLabelEnum.Call) || callLoading
          }
          callLabel={callLabel}
          playAudioMsg={playAudioMsg}
        />
      </div>
    </div>
  );
};
