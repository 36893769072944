import { useState } from "react";
import {
  deletePatient,
  undoDeletePatient,
} from "../../../../helpers/commandsPatient";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { SelectPatient } from "../../atoms/SelectPatient/SelectPatient";

interface Props {
  patients: Patient[];
  patientsLoading: boolean;
  removedPatients: Patient[];
}

export const RemovePatient: React.FC<Props> = ({
  patients,
  patientsLoading,
  removedPatients,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<{
    patientId: string;
    isManual: "false" | "true";
  }>({ patientId: "", isManual: "false" });
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [undoLoading, setUndoLoading] = useState(false)
  const [undoResponse, setUndoResponse] = useState("")

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setResponse("");
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (values.patientId) {
      try {
        setError("");
        setLoading(true);
        const response = await deletePatient(
          values.patientId,
          values.isManual === "true"
        );
        setResponse(JSON.stringify(response));
      } catch (e) {
        setError(JSON.stringify(e));
      } finally {
        setLoading(false);
        setValues({ patientId: "", isManual: "false" });
      }
    }
  };

  const handleSubmitUndo = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (values.patientId) {
      try {
        setError("");
        setUndoLoading(true);
        const response = await undoDeletePatient(values.patientId);
        setUndoResponse(JSON.stringify(response));
      } catch (e) {
        setError(JSON.stringify(e));
      } finally {
        setUndoLoading(false);
        setValues({ patientId: "", isManual: "false" });
      }
    }
  };

  return (
    <>
      <div className="m-2">
        <h1>Remove patient</h1>
        <form className="form p-2" onSubmit={handleSubmit}>
          <div className="form-group row column py-1">
            <label htmlFor="patientId">Choose patient</label>
            {!loading && (
              <SelectPatient patients={patients} handleChange={handleChange} />
            )}
            {(loading || patientsLoading) && (
              <div className="loaderDiv">
                <div className="loader"></div>
              </div>
            )}
          </div>
          <div className="form-group row column py-1">
            <label htmlFor="isManual">Is manual removal?</label>
            <select
              name="isManual"
              id="isManual"
              value={values.isManual}
              onChange={handleChange}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <button type="submit" className="btn-xsmall light">
            Submit
          </button>
          {error && <h4>{error}</h4>}
        </form>
        {response && <h3>Response: {response}</h3>}
      </div>
      <hr />
      <div className="m-2">
        <h2>Undo removed patients</h2>
        <form className="form p-2" onSubmit={handleSubmitUndo}>
          <div className="form-group row column py-1">
            <label htmlFor="patientId">Choose patient</label>
            {!undoLoading && (
              <SelectPatient
                patients={removedPatients}
                handleChange={handleChange}
              />
            )}
            {(undoLoading || patientsLoading) && (
              <div className="loaderDiv">
                <div className="loader"></div>
              </div>
            )}
          </div>
          <button type="submit" className="btn-xsmall light">
            Submit
          </button>
        </form>
        {undoResponse && <h3>Response: {undoResponse}</h3>}
      </div>
    </>
  );
};
