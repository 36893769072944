import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { getPassedTime } from "../../../../utils/timeOperations";
import { useDetectIssues } from "../../../../hooks/useDetectIssues";
import { IssueEnum } from "../../../../enums/issues";
import { Nurse } from "../../../../imgs/Nurse";
import { MuteIcon } from "../../../../imgs/MuteIcon";
import { CamOffIcon } from "../../../../imgs/CamOffIcon";
import { ReactNode } from "react";
import { AlarmIcon } from "../../../../imgs/AlarmIcon";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { cutString } from "../../../../utils/cutString";

const WrapperIssue: React.FC<{
  children: ReactNode;
  passedTime: string;
  patientName: string;
}> = ({ children, passedTime, patientName }) => {
  return (
    <div className="row" style={{ gap: 12, alignItems: "center" }}>
      <p className="bold line-clamp one">
        {
          cutString(patientName, 32)
        }
      </p>
      {children}
      <p style={{ fontSize: "0.8rem" }}>{passedTime}</p>
    </div>
  );
};

export const CurrentIssues: React.FC<{ patients: Patient[] }> = ({
  patients,
}) => {
  const {} = useDetectIssues(patients);
  const { issuesList } = useSelector((state: RootState) => state.supervisors);

  return (
    <div className="card-black">
      <p className="title">Current Issues</p>
      <div className="issues-list">
        {issuesList.length > 0 ? (
          [...issuesList]
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map(({ patientName, issueType, createdAt, patientId }) => {
              const time = getPassedTime(createdAt);
              const passedTime = time.hours
                ? `${time.hours} hours ${time.minutes}`
                : `${time.minutes} minutes`;

              if (issueType === IssueEnum.ContactNotAssigned) {
                return (
                  <WrapperIssue
                    passedTime={passedTime}
                    patientName={patientName}
                    key={`issue-${patientId}-${issueType}`}
                  >
                    <div className="issue-row">
                      <div className="icons-row">
                        <div className="icon-yellow">
                          <Nurse />
                        </div>
                      </div>
                      <p className="yellow">Care team member not assigned</p>
                    </div>
                  </WrapperIssue>
                );
              }

              if (issueType === IssueEnum.SpeakerOff) {
                return (
                  <WrapperIssue
                    passedTime={passedTime}
                    patientName={patientName}
                    key={`issue-${patientId}-${issueType}`}
                  >
                    <div className="issue-row">
                      <div className="icons-row">
                        <div className="icon-red">
                          <MuteIcon />
                        </div>
                      </div>
                      <p className="red">Speaker Offline</p>
                    </div>
                  </WrapperIssue>
                );
              }

              if (issueType === IssueEnum.CameraOff) {
                return (
                  <WrapperIssue
                    passedTime={passedTime}
                    patientName={patientName}
                    key={`issue-${patientId}-${issueType}`}
                  >
                    <div className="issue-row">
                      <div className="icons-row">
                        <div className="icon-red">
                          <CamOffIcon />
                        </div>
                      </div>
                      <p className="red">Camera Offline</p>
                    </div>
                  </WrapperIssue>
                );
              }

              if (issueType === IssueEnum.NetworkIssues) {
                return (
                  <WrapperIssue
                    passedTime={passedTime}
                    patientName={patientName}
                    key={`issue-${patientId}-${issueType}`}
                  >
                    <div className="issue-row">
                      <div className="icons-row">
                        <div className="icon-yellow">
                          <AlarmIcon />
                        </div>
                      </div>
                      <p className="yellow">Network Issues</p>
                    </div>
                  </WrapperIssue>
                );
              }

              return (
                <WrapperIssue
                  passedTime={passedTime}
                  patientName={patientName}
                  key={`issue-${patientId}-${issueType}`}
                >
                  <div
                    className="row"
                    style={{ gap: 8 }}
                    key={`row-${patientName}-${issueType}`}
                  >
                    <p className="bold">{patientName}</p>
                    {issueType}
                    <p>{passedTime}</p>
                  </div>
                </WrapperIssue>
              );
            })
        ) : (
          <p>No activity to show</p>
        )}
      </div>
    </div>
  );
};
