import { Dislike } from "../../../../imgs/Dislike";
import { Like } from "../../../../imgs/Like";

interface IconProps {
  disabled: boolean;
  confirmAI: () => any;
  openMiniForm: () => any;
  declinationMiniForm: boolean;
}

export const AIInteractions: React.FC<IconProps> = ({
  disabled,
  confirmAI,
  openMiniForm,
  declinationMiniForm,
}) => {
  if (disabled) {
    return (
      <>
        <Like action={confirmAI} disabled={true} />
        <Dislike action={openMiniForm} disabled={true} />
      </>
    );
  }

  return (
    <>
      <Like action={confirmAI} tooltipText={"Agree with fall risk alert "} />
      <Dislike
        tooltipText={"Disagree with fall risk alert "}
        action={openMiniForm}
        selected={declinationMiniForm}
      />
    </>
  );
};
