import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { useState } from "react";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { useNavigate } from "react-router-dom";
import {
  logoutConnection,
  postSwithUser,
} from "../../../../helpers/connections";
import { RootState } from "../../../../stores";
import routes from "../../../../routes";
import { removeSession } from "../../../../features/session/sessionSlice";

export const SwitchUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | ErrorTypeEnum>(undefined);
  const [values, setValues] = useState<{ user: string; pwd: string }>({
    user: "",
    pwd: "",
  });
  const {
    session: {
      sub: [, user],
    },
    connectionId,
  } = useSelector((state: RootState) => state.sessionInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);

    if (values.user === user) {
      setError(ErrorTypeEnum.SameUser);
      return;
    }

    if (values.user && values.pwd) {
      dispatch(
        setForm({
          typeInput: "userInput",
          status: true,
          ...values,
        })
      );

      try {
        setLoading(true);

        const response = await postSwithUser(values.user, values.pwd);
        if (response.param_token) {
          navigate(`${routes.login.main}?${response.param_token}`);
          dispatch(
            setForm({
              typeInput: "userInput",
              status: false,
              user: "",
              pwd: "",
            })
          );
          dispatch(removeSession({ avoidRedirection: true }));
          logoutConnection(connectionId);
        }
      } catch (e) {
        switch (e) {
          case 400:
            setError(ErrorTypeEnum.WrongPassword);
            break;
          case 406:
            setError(ErrorTypeEnum.MissingData);
            break;
          case 404:
            setError(ErrorTypeEnum.NotFound);
            break;
          default:
            setError(ErrorTypeEnum.Retry);
            break;
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    dispatch(setForm({ typeInput: "userInput", status: false }));
  };

  return (
    <>
      <div className="switch-user-overlay" onClick={handleCancel}></div>
      <div className="floating-form">
        <h3 className="title">Switch user</h3>
        <form action="" onSubmit={(e) => !loading && handleSubmit(e)}>
          <div className="my-2">
            <div className="form-control my-1">
              <label htmlFor="user">*Username</label>
              <input
                type="text"
                name="user"
                id="user"
                maxLength={15}
                required
                value={values.user}
                onChange={handleChange}
              />
            </div>
            <div className="form-control my-1">
              <label htmlFor="pwd">*Password</label>
              <input
                type="password"
                name="pwd"
                id="pwd"
                required
                maxLength={30}
                value={values.pwd}
                onChange={handleChange}
              />
            </div>
          </div>
          {error && (
            <ErrorMsg
              position="inline"
              errorMsg={error}
              filled={false}
              closeBtn={false}
              padding={"none"}
            />
          )}
          <div
            className={`row btn-group left reverse-order my-1 ${
              loading && "loading"
            }`}
          >
            <button
              className="btn-small light"
              type="submit"
              disabled={loading}
            >
              SIGN ON
            </button>
            <button
              className="btn-small transparent outlined"
              type="button"
              onClick={handleCancel}
              disabled={loading}
            >
              CANCEL
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
