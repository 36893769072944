import { AddConnection } from "../../atoms/AddConnection/AddConnection";
import { ConnectionInfo } from "../../../../interfaces/connections/connections.interface";
import { RemoveConnections } from "../../atoms/RemoveConnections/RemoveConnectins";
import { LogoutConnections } from "../../atoms/LogoutConnections/LogoutConnections";

interface Metadata {
  columns: { label: string; prop: keyof ConnectionInfo }[];
}

const metadataTable: Metadata = {
  columns: [
    {
      label: "id",
      prop: "id",
    },
    {
      label: "userName",
      prop: "userName",
    },
    {
      label: "isOnBreak",
      prop: "isOnBreak",
    },
    {
      label: "breakeStartDateTime",
      prop: "breakeStartDateTime",
    },
    {
      label: "patientsAssigned",
      prop: "patientsAssigned",
    },
    {
      label: "totalPatientsAssigned",
      prop: "totalPatientsAssigned",
    },
    {
      label: "createdDate",
      prop: "createdDate",
    },
  ],
};

interface Props {
  connectionsList: ConnectionInfo[];
  handleFetchConnection: () => any;
  loading: boolean;
}
export const Connections: React.FC<Props> = ({
  connectionsList,
  handleFetchConnection,
  loading,
}) => {
  const ConnectionsTable = ({
    connectionsList,
  }: {
    connectionsList: ConnectionInfo[];
  }) => {
    return (
      <table className="simple-table">
        <thead>
          <tr>
            {metadataTable.columns.map((column) => (
              <th key={`th-label-${column.label}`}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {connectionsList.map((connection: ConnectionInfo) => (
            <tr key={`tr-${connection.id}`}>
              {metadataTable.columns.map((column) => (
                <td key={`td-${connection.id}-${column.prop}`}>
                  {JSON.stringify(connection[column.prop])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <AddConnection />
      <hr />
      <div className="form addition-form m-2" style={{ overflowX: "auto" }}>
        <h1>Connections List</h1>
        {loading && <div className="loader"></div>}
        {!loading && connectionsList.length && (
          <ConnectionsTable connectionsList={connectionsList} />
        )}
      </div>
      <hr />
      <RemoveConnections connectionsList={connectionsList} />
      <hr />
      <LogoutConnections connectionsList={connectionsList} />
    </>
  );
};
