import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getGlobalActivity } from "../../../../helpers/connections";
import { GlobalActivity } from "../../../../interfaces/connections/connections.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y" as const,
  fontFamily: "Montserrat",
  elements: {
    bar: {
      borderWidth: 1,
      width: 20,
    },
  },
  responsive: true,
  animation: {
    duration: 0.2,
    onComplete: function ({ chart }: { chart: any }) {
      const ctx = chart.ctx;
      ctx.fillStyle = "white";

      chart.config.data.datasets.forEach(function (
        dataset: { data: { [x: string]: any } },
        i: any
      ) {
        const meta = chart.getDatasetMeta(i);

        meta.data.forEach(function (
          bar: { x: any; y: number },
          index: string | number
        ) {
          const data = dataset.data[index];

          ctx.fillText(data, bar.x + 5, bar.y + 3);
        });
      });
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
        tickColor: "white",
      },
      ticks: {
        color: "white",
        font: {
          family: "Montserrat",
        },
      },
    },
    y: {
      grid: {
        display: false,
        tickColor: "white",
      },
      ticks: {
        color: "white",
        font: {
          family: "Montserrat",
        },
      },
    },
  },
};

interface Props {
  hours: 1 | 8 | 24;
  setHours: (arg: 1 | 8 | 24) => void;
  globalActivity?: GlobalActivity;
}
export const GlobalActivityCard: React.FC<Props> = ({
  hours,
  setHours,
  globalActivity,
}) => {
  const data = useMemo(
    () => ({
      labels: ["Nurse Calls", "Audio Messages", "Alarms", "AI Events"],
      datasets: [
        {
          label: "Activity",
          data: globalActivity ? Object.values(globalActivity) : [],
          borderColor: "transparent",
          backgroundColor: "rgba(102, 194, 149, 1)",
        },
      ],
    }),
    [globalActivity]
  );

  return (
    <div className="card-black">
      <div className="row" style={{ justifyContent: "space-between" }}>
        <p className="title">Global Activity</p>
        <div className="tabs">
          {[1, 8, 24].map((val) => (
            <p
              className={`tab ${val === hours ? "isActive" : ""}`}
              key={val}
              onClick={() => setHours(val as 1 | 8 | 24)}
            >
              {val} hour{val > 1 ? "s" : ""}
            </p>
          ))}
        </div>
      </div>
      <div style={{ overflowX: "visible", width: "96%" }}>
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};
