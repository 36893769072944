import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import { ESitterList } from "../UI/organisms/ESitterList/ESitterList";
export const ESitters = () => {
  const children = useOutlet();

  if (children) {
    return (
      <div className="container compressed">
        <Outlet />
      </div>
    );
  }

  return (
    <div className="container">
      <ESitterList/>
    </div>
  );
};
