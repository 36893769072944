import React, { useEffect, useState, useMemo } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { useSelector } from "react-redux";
import { MainPatients } from "../MainPatients/MainPatients";
import { CamsCard } from "../CamsCard/CamsCard";
import { RootState } from "../../../../stores";
import { filterPatientsById } from "../../../../utils/filterPatientsByIds";

interface Props {
  patients: Patient[];
  selectedPatient?: string;
}

export const CamsContainerList: React.FC<Props> = ({
  patients,
  selectedPatient,
}) => {
  const [voidCams, setVoidCams] = useState<{ id: number }[]>([]);
  const { pinnedPatientsStack } = useSelector(
    (state: RootState) => state.patients
  );
  const filteredPatients = useMemo(
    () => filterPatientsById(patients, pinnedPatientsStack),
    [pinnedPatientsStack, patients]
  );

  useEffect(() => {
    const MAX_PATIENTS = 20;
    const delta = MAX_PATIENTS - patients.length;
    let array = [];
    for (let i = 0; i < delta; i++) {
      array.push({ id: i });
    }
    setVoidCams(array);
  }, [patients.length]);

  return (
    <div className="cams-container">
      <MainPatients
        patients={filteredPatients}
        selectedPatient={selectedPatient}
      />
      <div className="secondary-grid">
        {patients.map((patient, index) => (
          <CamsCard
            patient={patient}
            key={patient.id}
            isPatientSelected={selectedPatient === patient.id}
            index={index}
            isInSecondaryGrid={true}
          />
        ))}
        {voidCams.length > 0 &&
          voidCams.map((box) => {
            return <div className={"void-cam"} key={box.id}></div>;
          })}
      </div>
    </div>
  );
};
