import {
  AddPatient,
  UpdatePatient,
} from "../interfaces/patients/patients.interface";
import { AddActivity } from "../interfaces/activity/activity.interface";
import { AddNote } from "../interfaces/note/note.interface";
import {
  FallRiskConfirmation,
  FallRiskDecline,
} from "../interfaces/fallRisk/fallRisk.interface";
import EndpointGenerator from "../utils/EndpointGenerator";
import { PatientCommandOperationsEnum } from "../enums/apisOperations";
import axiosHttpClient from "../utils/axiosHttpClient";
import { setToken } from "../utils/setToken";

export const patientOperationAdd = async (obj: AddPatient) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.Add
  );
  const response = await axiosHttpClient.postData(endpoint, obj);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const patientOperationUpdate = async (
  id: string,
  obj: UpdatePatient
) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.Update
  );
  const response = await axiosHttpClient.patchData(endpoint, { ...obj, id });
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const addActivity = async (
  { id, user, activity, activityType, clientDateTime }: AddActivity,
  token: string
) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.AddActivity
  );
  const response = await axiosHttpClient.putData(
    endpoint,
    {
      id,
      user,
      activity,
      activityType,
      clientDateTime,
    },
    setToken(token)
  );
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const addNote = async ({ patientId, note }: AddNote, token: string) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.AddNote
  );
  const response = await axiosHttpClient.putData(
    endpoint,
    { patientId, note },
    setToken(token)
  );
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const deletePatient = async (patientId: string, isManual = false) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.Remove
  );
  const response = await axiosHttpClient.deleteData(endpoint, {
    id: patientId,
    isManual,
  });
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const undoDeletePatient = async (patientId: string) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.Undo,
    patientId
  );
  const response = await axiosHttpClient.postData(endpoint, patientId);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const confirmFallRisk = async (
  obj: FallRiskConfirmation,
  token: string
) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.ConfirmFallRisk
  );
  const response = await axiosHttpClient.postData(
    endpoint,
    obj,
    setToken(token)
  );
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const declineFallRisk = async (obj: FallRiskDecline, token: string) => {
  const endpoint = EndpointGenerator.generatePatientCommandEndpoint(
    PatientCommandOperationsEnum.DeclineFallRisk
  );
  const response = await axiosHttpClient.postData(
    endpoint,
    obj,
    setToken(token)
  );
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};
