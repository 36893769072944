import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import {
  addGlobalError,
  removeGlobalError,
} from "../../../../features/globalErrors/globalErrorsSlice";
import { initiateCallEvents } from "../../../../hooks/useCall";
import { useHealtCheck } from "../../../../hooks/useHealthCheck";
import { useSocket } from "../../../../hooks/useSocket";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { RootState } from "../../../../stores";
import { CamsContainerList } from "../../molecules/CamsContainerList/CamsContainerList";
import { sendESitterConnection } from "../../../../helpers/socketESitter";
import { useDetectIssues } from "../../../../hooks/useDetectIssues";

interface Props {
  patients: Patient[];
  selectedPatient?: string;
  loading: boolean;
  connectToSocket?: boolean;
}

export const gridLimits = {
  4: {
    total: 4,
    highActivity: 2,
    mediumActivity: 1,
    lowActivity: 1,
  },
  9: {
    total: 9,
    highActivity: 2,
    mediumActivity: 3,
    lowActivity: 4,
  },
  16: {
    total: 16,
    highActivity: 2,
    mediumActivity: 4,
    lowActivity: 10,
  },
};

export const CamsContainer: React.FC<Props> = ({
  patients,
  selectedPatient,
  loading,
  connectToSocket = true,
}) => {
  const dispatch = useDispatch();
  const { errorSocket, eSitterSocketReady } = useSocket(
    connectToSocket,
    dispatch
  );
  const { apiErrors } = useHealtCheck();
  const { generalErrors: errors } = useSelector(
    (state: RootState) => state.globalErrors
  );
  const { connectionId } = useSelector((state: RootState) => state.sessionInfo);
  const {} = useDetectIssues(patients);

  useEffect(() => {
    apiErrors.forEach((err) => {
      if (err.status) {
        dispatch(addGlobalError(err.error));
        return;
      }

      dispatch(removeGlobalError(err.error));
    });
  }, [apiErrors, dispatch]);

  useEffect(() => {
    errorSocket.forEach((err) => {
      if (err.status) {
        dispatch(addGlobalError(err.error));
        return;
      }

      dispatch(removeGlobalError(err.error));
    });
  }, [dispatch, errorSocket]);

  useEffect(() => {
    connectToSocket &&
      initiateCallEvents(
        onConnectedCall,
        onDisconectedCall,
        onDisconectedCall,
        onConnectedCall
      );
  }, []);

  useEffect(() => {
    if (connectionId && eSitterSocketReady) {
      sendESitterConnection(connectionId);
    }
  }, [eSitterSocketReady, connectionId]);

  const onConnectedCall = () => {
    dispatch(removeGlobalError(ErrorTypeEnum.AudioSocket));
  };

  const onDisconectedCall = () => {
    dispatch(addGlobalError(ErrorTypeEnum.AudioSocket));
  };

  if (loading) {
    return (
      <div className="cams-container-void row">
        <div className="loaderDiv">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="cams-container-bx">
      {patients && patients.length > 0 ? (
        <CamsContainerList
          patients={patients}
          selectedPatient={selectedPatient}
        />
      ) : (
        <div className="cams-container-void row">
          {!errors?.includes(ErrorTypeEnum.FetchFailed) ? (
            <div className="info">
              <h3>No patients assigned yet.</h3>
              <p>When patients are added, you will see their videos here.</p>
            </div>
          ) : (
            <div className="info">
              <h3>
                Patient data is currently unavailable. Please contact the
                service desk.
              </h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
