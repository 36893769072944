import { AddPatient } from "../UI/molecules/AddPatient/AddPatient";
import { AssignNurse } from "../UI/molecules/AssignNurseTest/AssignNurseTest";
import { RemovePatient } from "../UI/molecules/RemovePatient/RemovePatient";
import { FakeAI } from "../UI/molecules/FakeAI/FakeAI";
import { Patient } from "../../interfaces/patients/patients.interface";
import { useEffect, useState } from "react";
import { getAllPatients, getDeletedPatients } from "../../helpers/patients";
import { Connections } from "../UI/molecules/Connections/Connections";
import { getConnections } from "../../helpers/connections";
import { ConnectionInfo } from "../../interfaces/connections/connections.interface";
import { ArrowDownLogo } from "../../imgs/ArrowDownLogo";
import { Listener } from "../UI/molecules/Listener/Listener";

export const Command = () => {
  const [connectionsList, setConnectionsList] = useState<ConnectionInfo[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [removedPatients, setRemovedPatients] = useState<Patient[]>([]);

  const handleFetchPatients = async () => {
    try {
      setLoading(true);
      const res = await getAllPatients();
      res && setPatients(res);
    } catch (e) {
      setError(JSON.stringify(e));
    } finally {
      setLoading(false);
    }
  };

  const getConnectionsList = async () => {
    setConnectionsLoading(true);
    try {
      const response = await getConnections();
      setConnectionsList(response);
    } catch (e) {
      console.log(e);
    } finally {
      setConnectionsLoading(false);
    }
  };

  const getRemovedPatients = async () => {
    try {
      setLoading(true);
      const response = await getDeletedPatients();
      setRemovedPatients(response);
    } catch (e) {
      setError(JSON.stringify(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchPatients();
    getConnectionsList();
    getRemovedPatients();
  }, []);

  return (
    <div
      className="col-100"
      style={{
        overflowX: "auto",
        height: "100vh",
        backgroundColor: "#101820",
        color: "#e2dfdf",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "600px",
          backgroundColor: "#182430",
          borderRadius: "10px",
          boxShadow: "5px 5px 5px #283849",
          overflowY: "scroll",
          margin: "40px 0px",
        }}
      >
        <h1 style={{ textAlign: "center" }}>EPIC simulation</h1>
        <div className="floating-modal left">
          <div className="row center align-center">
            Open fetch buttons
            <ArrowDownLogo orientation="up" />
          </div>
          <button
            className="btn-xsmall transparent outlined"
            onClick={handleFetchPatients}
          >
            Fetch patients manually
          </button>
          <button
            className="btn-xsmall transparent outlined"
            onClick={getConnectionsList}
          >
            Fetch connections
          </button>
          <button
            className="btn-xsmall transparent outlined"
            onClick={getRemovedPatients}
          >
            Fetch removed patients
          </button>
        </div>
        {error && <h4>Patients can't be retrieved: {error}</h4>}
        <AddPatient />
        <hr />
        <AssignNurse patients={patients} patientsLoading={loading} />
        <hr />
        <RemovePatient
          patients={patients}
          patientsLoading={loading}
          removedPatients={removedPatients}
        />
        <hr />
        <FakeAI patients={patients} patientsLoading={loading} />
        <hr />
        <Connections
          connectionsList={connectionsList}
          handleFetchConnection={getConnectionsList}
          loading={connectionsLoading}
        />
        <hr />
        <Listener />
      </div>
    </div>
  );
};
