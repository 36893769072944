import React from "react";

interface Props {
  disabled?: boolean;
  noHover?: boolean;
  action?: () => any | void;
  tooltipPosition?: "right" | "down" | "left" | "top";
  tooltipText?: string;
}

export const AlarmIcon: React.FC<Props> = ({
  disabled = false,
  noHover = false,
  action,
  tooltipPosition,
  tooltipText,
}) => {
  return (
    <div className="tooltip">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        className={`${disabled ? "disabled" : ""} ${noHover && "noHover"}`}
        fill="none"
        onClick={action}
        xmlns="http://www.w3.org/2000/svg"
        aria-label="alert-icon"
      >
        <g clipPath="url(#clip0_941_13405)">
          <path
            d="M1.33337 28H30.6667L16 2.66669L1.33337 28ZM17.3334 24H14.6667V21.3334H17.3334V24ZM17.3334 18.6667H14.6667V13.3334H17.3334V18.6667Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_941_13405">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {tooltipText && (
        <span className={`tooltiptext ${tooltipPosition}`}>{tooltipText}</span>
      )}
    </div>
  );
};
