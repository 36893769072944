import { useEffect, useState } from "react";
import { buildTime } from "../utils/generateTime";
import { getPassedTime } from "../utils/timeOperations";

interface defaultTime {
  seconds: number;
  minutes: number;
  hours?: number;
}
export const useTimer = (
  defaultIsRunning = true,
  intervalMs = 1000,
  defaultMinutes = 0,
  defaultSeconds = 0,
  defaultHours = 0,
  utcEndTime?: string
) => {
  const [defaultRemainingTime, setDefaultRemainingTime] = useState<
    defaultTime | undefined
  >(undefined);
  const [totalSeconds, setTotalSeconds] = useState(defaultSeconds);
  const [seconds, setSeconds] = useState(defaultSeconds);
  const [minutes, setMinutes] = useState(defaultMinutes);
  const [hours, setHours] = useState(defaultHours);
  const [timer, setTimer] = useState("");
  const [isRunning, setIsRunning] = useState(defaultIsRunning);
  const [isTimerEnded, setIsTimerEnded] = useState(false);

  useEffect(() => {
    setTotalSeconds(defaultSeconds);
    setSeconds(defaultSeconds);
    setMinutes(defaultMinutes);
    setHours(defaultHours);
  }, [defaultHours, defaultMinutes, defaultSeconds]);

  useEffect(() => {
    if (utcEndTime) {
      setDefaultRemainingTime(getPassedTime(utcEndTime, true));
      return;
    }
    setDefaultRemainingTime(undefined);
  }, [utcEndTime]);

  useEffect(() => {
    if (defaultRemainingTime) {
      setSeconds(-defaultRemainingTime.seconds);
      setMinutes(-defaultRemainingTime.minutes);
      setHours(defaultRemainingTime.hours ? -defaultRemainingTime.hours : 0);
    }
  }, [defaultRemainingTime]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (!isRunning) {
        return;
      }
      setSeconds((seconds) => (utcEndTime ? seconds - 1 : seconds + 1));
      setTotalSeconds(totalSeconds + 1);
    }, intervalMs);

    return () => clearTimeout(interval);
  }, [seconds, isRunning]);

  useEffect(() => {
    if (seconds === 60) {
      setSeconds(0);
      setMinutes((minutes) => minutes + 1);
    }

    if (minutes === 60) {
      setMinutes(0);
      setHours((hours) => hours + 1);
    }

    if (seconds < 0 && minutes > 0) {
      setSeconds(59);
      setMinutes(minutes - 1);
    }

    if (minutes < 0 && hours > 0) {
      setMinutes(59);
      setHours(hours - 1);
    }

    setTimer(buildTime(seconds, minutes, hours));

    if (utcEndTime && defaultRemainingTime && !seconds && !minutes && !hours) {
      stopTimer();
      setIsTimerEnded(true);
      return;
    }

    if (seconds < 0 && !minutes && !hours) {
      stopTimer();
      setIsTimerEnded(true);
      setSeconds(0);
    }
  }, [seconds, minutes, hours]);

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resumeTimer = () => {
    setIsRunning(true);
  };

  const resetTimer = () => {
    setSeconds(0);
    setMinutes(0);
    setHours(0);
  };

  const Timer = () => {
    return <div aria-label={`timer-label ${isRunning && "play"}`}>{timer}</div>;
  };

  return {
    Timer,
    timer,
    isRunning,
    stopTimer,
    resumeTimer,
    isTimerEnded,
    resetTimer,
    totalSeconds,
  };
};
