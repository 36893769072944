import React, { useEffect, useRef, useState } from "react";
import { addNote } from "../../../../helpers/commandsPatient";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientByProp } from "../../../../features/patients/patientsSlice";
import { getPatientById } from "../../../../helpers/patients";
import { Plus } from "../../../../imgs/Plus";
import { EditIcon } from "../../../../imgs/EditIcon";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { RootState } from "../../../../stores";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { truncateLongStr } from "../../../../utils/hyphenLongStrings";
import { ErrorTypeEnum } from "../../../../enums/errorType";

interface Props {
  patient: Patient;
  onSent?: () => any;
  helperToFetchPatient?: (id: string) => Promise<Patient | any | void>;
}

const MAX_CHARS_FOR_SIDEBAR_WIDTH = 30;

export const StickyNotes: React.FC<Props> = ({
  patient,
  onSent,
  helperToFetchPatient = getPatientById,
}) => {
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const inputNote = useRef<HTMLTextAreaElement>(null);
  const { noteInput, newCallInput } = useSelector(
    (state: RootState) => state.openForms
  );
  const { token } = useSelector((state: RootState) => state.sessionInfo);

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const text = e.target.value.toString().trimStart();
    const firstLetter = text.slice(0, 1).toLocaleUpperCase();
    dispatch(
      setForm({
        typeInput: "noteInput",
        status: true,
        text: `${firstLetter}${text.slice(1)}`,
      })
    );
  };

  const openInput = () => {
    dispatch(
      setForm({ typeInput: "noteInput", status: true, text: patient.note })
    );
  };

  useEffect(() => {
    noteInput.status && inputNote?.current && inputNote.current.focus();
  }, [noteInput.status, inputNote]);

  /* istanbul ignore next */
  useEffect(() => {
    if (noteInput.text === patient.note) {
      inputNote.current && inputNote.current.focus();
      inputNote.current && inputNote.current.select();
    }
  }, [noteInput.text]);

  const handleKeyUp = async (e: { key: string }) => {
    if (e.key === "Escape") {
      handleCancel();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      dispatch(setForm({ typeInput: "noteInput", status: false }));
      let res = await addNote(
        {
          patientId: patient.id,
          note: noteInput.text.trimEnd(),
        },
        token
      );
      let resById = await helperToFetchPatient(patient.id);
      resById && dispatch(updatePatientByProp({ data: resById, prop: "note" }));
      res && resById && setSent(true);
    } catch (e) {
      setError(true);
      setLoading(false);
      dispatch(setForm({ typeInput: "noteInput", status: false }));
    }
  };

  const handleCancel = () => {
    dispatch(setForm({ typeInput: "noteInput", status: false }));
  };

  useEffect(() => {
    if (sent) {
      onSent && onSent();

      dispatch(setForm({ typeInput: "noteInput", status: false }));
      setLoading(false);
      setSent(false);
    }
  }, [sent]);

  useEffect(() => {
    if (newCallInput.status && noteInput.status) {
      handleCancel();
    }
  }, [newCallInput.status]);

  return (
    <div className="sticky-notes" aria-label="sticky-notes">
      <div className="row align-center" aria-label="sticky-notes-info">
        <h3>Note</h3>
        {patient.note && !newCallInput.status && (
          <EditIcon action={openInput} />
        )}
      </div>
      {loading && (
        <div className="loaderDiv">
          <div className="loader small"></div>
        </div>
      )}
      {noteInput.status && !loading && (
        <>
          <div className="row note">
            <textarea
              ref={inputNote}
              name="sticky-note"
              aria-label="sticky-note-input"
              value={noteInput.text}
              disabled={loading}
              onChange={handleChange}
              className="medium-input blue-input transparent fixed-textarea"
              onKeyUp={handleKeyUp}
              maxLength={100}
              rows={3}
            />
          </div>
          <div className="row btn-group left reverse-order">
            <button
              className="btn-small light"
              aria-label="submit-btn"
              disabled={!noteInput.text}
              onClick={handleSubmit}
              style={{ minWidth: "auto" }}
            >
              SAVE
            </button>
            <button
              className="btn-small transparent outlined"
              onClick={handleCancel}
            >
              CANCEL
            </button>
          </div>
        </>
      )}
      {!noteInput.status && patient.note && (
        <div className="current-note-bx">
          {truncateLongStr(patient.note, MAX_CHARS_FOR_SIDEBAR_WIDTH)?.map(
            (str, i) => (
              <p
                aria-label="current-note"
                className="current-note"
                key={`note-part-${i}`}
              >
                {str}
              </p>
            )
          )}
        </div>
      )}
      {!noteInput.status && !patient.note && !loading && (
        <button
          className={`icon btn-xsmall opaque-input ${
            newCallInput.status && "disabled"
          }`}
          onClick={openInput}
          aria-label="add-sticky-note-btn"
          disabled={newCallInput.status}
        >
          <Plus /> <p>ADD NOTE</p>
        </button>
      )}
      {error && (
        <ErrorMsg
          errorMsg={ErrorTypeEnum.Retry}
          filled={false}
          closeBtn={false}
          padding={"none"}
          position={"inline"}
        />
      )}
    </div>
  );
};
