import React from "react";

interface Props {
  action?: () => any | void;
  tooltipText?: string;
  disabled?: boolean;
  active?: boolean;
}

export const Micro: React.FC<Props> = ({
  action,
  tooltipText,
  disabled = false,
  active = false,
}) => {
  return (
    <div className="tooltip">
      <svg
        className={`${disabled && "btn disabled"} ${active && "active"}`}
        onClick={() => !disabled && action?.()}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="micro-active"
        aria-label="micro-active"
      >
        <path
          d="M16 18.6667C18.2133 18.6667 19.9867 16.88 19.9867 14.6667L20 6.66669C20 4.45335 18.2133 2.66669 16 2.66669C13.7867 2.66669 12 4.45335 12 6.66669V14.6667C12 16.88 13.7867 18.6667 16 18.6667ZM23.0667 14.6667C23.0667 18.6667 19.68 21.4667 16 21.4667C12.32 21.4667 8.93333 18.6667 8.93333 14.6667H6.66667C6.66667 19.2134 10.2933 22.9734 14.6667 23.6267V28H17.3333V23.6267C21.7067 22.9867 25.3333 19.2267 25.3333 14.6667H23.0667Z"
          fill="black"
        />
      </svg>
      {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
    </div>
  );
};
