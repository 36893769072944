import {
  Activity,
  ActivityPillEnum,
} from "../interfaces/activity/activity.interface";
import logger from "../logger/logger";
import { getPassedTime } from "./timeOperations";

export const lookForLastActivity = (
  roomNumber: string,
  onOpenPill: (
    room: string,
    activityType: ActivityPillEnum,
    passedSeconds: number,
    passedMinutes: number
  ) => void,
  onClosePill: (room: string, activityType: ActivityPillEnum) => void,
  lastActivity?: Activity
) => {
  const activitiesWithPill: { [key: number]: ActivityPillEnum } = {
    1: ActivityPillEnum.dispatch,
    4: ActivityPillEnum.assignNurse,
    10: ActivityPillEnum.sosAlert,
  };

  if (
    lastActivity?.activity &&
    lastActivity?.activityType &&
    activitiesWithPill[lastActivity.activityType]
  ) {
    try {
      const openPill = JSON.parse(lastActivity.activity).isPillOpen;

      if (openPill) {
        const parsedDate = lastActivity.dateTime.endsWith("Z")
          ? lastActivity.dateTime
          : `${lastActivity.dateTime}Z`;

        const time = getPassedTime(parsedDate);

        onOpenPill(
          roomNumber,
          activitiesWithPill[lastActivity.activityType],
          time.seconds,
          time.minutes
        );
        return;
      }
      
      onClosePill(roomNumber, activitiesWithPill[lastActivity.activityType]);
    } catch (e) {
      logger.error(
        `Activity (${lastActivity.activity}) unappropiate format`,
        e
      );
    }
  }
};
