import React, { useState, useEffect } from "react";
import {
  Color,
  TimerStatus,
} from "../../../../interfaces/pillAlert/pillAlerts.interface";
import { ActivityPillEnum } from "../../../../interfaces/activity/activity.interface";
import { PositionEnum } from "../../../../enums/pillStyles";
import { PillContent } from "../PillContent/PillContent";

interface Props {
  type?: ActivityPillEnum;
  color?: Color;
  position?: PositionEnum;
  timerStatus?: TimerStatus;
  tooltipText?: string;
  activate?: boolean;
  invisible?: boolean;
  actionOnEnd?: (secs?: number) => any | void;
  onClick?: () => any | void;
  onExpiration?: () => any | void;
  saveTime?: (time: string) => any | void;
  saveExpirationTime?: (time: string) => any | void;
  endDateTime?: string;
  defaultStartSec?: number;
  defaultStartMin?: number;
}

export const AlertPill: React.FC<Props> = ({
  type,
  position = PositionEnum.right,
  timerStatus = "play",
  tooltipText,
  activate,
  invisible,
  actionOnEnd,
  onClick,
  onExpiration,
  saveTime,
  saveExpirationTime,
  endDateTime,
  defaultStartMin,
  defaultStartSec,
}) => {
  const [nurseArrived, setNurseArrived] = useState(false);
  const [color, setColor] = useState<Color>("transparent");
  const [finishTimer, setFinishTimer] = useState(false);
  const [totalSeconds, setTotalSeconds] = useState(0);

  useEffect(() => {
    switch (type) {
      case ActivityPillEnum.assignNurse:
        setColor("yellow");
        break;
      case ActivityPillEnum.contactExpiration:
        setColor("yellow");
        break;
      case ActivityPillEnum.sosAlert:
        setColor("red");
        break;
      default:
        setColor("green");
        break;
    }
  }, []);

  const onClickToPill = () => {
    onClick?.();
  };

  const onDefaultClick = () => {
    setFinishTimer(true);
    actionOnEnd?.(totalSeconds);

    if (type === ActivityPillEnum.dispatch) {
      setNurseArrived(true);
    }
  };

  useEffect(() => {
    if (timerStatus === "play") {
      setFinishTimer(false);
      return;
    }

    onDefaultClick();
  }, [timerStatus]);

  if (activate) {
    return (
      <div
        className={`pill ${color} ${PositionEnum[position]} tooltip ${
          invisible && "invisible"
        } ${type === ActivityPillEnum.sosAlert && "large centered"}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick ? onClickToPill() : onDefaultClick();
        }}
        aria-label="pill-alert"
      >
        <PillContent
          type={type}
          nurseArrived={nurseArrived}
          tooltipText={tooltipText}
          timerStatus={timerStatus}
          defaultStartSec={defaultStartSec}
          defaultStartMin={defaultStartMin}
          endDateTime={endDateTime}
          setTotalSeconds={setTotalSeconds}
          onExpiration={onExpiration}
          saveTime={saveTime}
          saveExpirationTime={saveExpirationTime}
          finishTimer={finishTimer}
        />
      </div>
    );
  }

  return <></>;
};
