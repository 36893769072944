import { useNavigate, useParams } from "react-router-dom";
import { Arrow } from "../../../../imgs/Arrow";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { TableMetada } from "../../../../interfaces/shared/shared.interface";
import { capitalizeString } from "../../../../utils/capitalizeString";
import routes from "../../../../routes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { filterPatientsById } from "../../../../utils/filterPatientsByIds";
import { PatientIcons } from "../../atoms/PatientIcons/PatientIcons";
import { BrowserStatusCard } from "../../atoms/BrowserStatusCard/BrowserStatusCard";
import { ConnectionActivityEnum } from "../../../../enums/connectionsEnum";
import { CurrentIssues } from "../CurrentIssues/CurrentIssues";
import { GlobalActivityCard } from "../GlobalActivityCard/GlobalActivityCard";
import { AlarmIcon } from "../../../../imgs/AlarmIcon";
import { GlobalActivity } from "../../../../interfaces/connections/connections.interface";
import { getGlobalActivity } from "../../../../helpers/connections";
import { calculateMinsFromSecs } from "../../../../utils/timeOperations";
import { ReassignPatient } from "../ReassignPatient/ReassignPatient";
import { addNotifications } from "../../../../features/globalErrors/globalErrorsSlice";
import { NotificationsEnum } from "../../../../enums/notifications";
import { ChangeCart } from "../ChangeCart/ChangeCart";
import { MAX_PATIENTS } from "../../../../features/patients/patientsSlice";
import { NoPeople } from "../../../../imgs/NoPeople";

export const ESitterDetailed: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hours, setHours] = useState<1 | 8 | 24>(1);
  const [globalActivity, setGlobalActivity] = useState<GlobalActivity>();
  const { connectionId } = useParams();
  const { list: patients, patientStatusList: statusList } = useSelector(
    (state: RootState) => state.patients
  );
  const { list: eSitters } = useSelector(
    (state: RootState) => state.supervisors
  );
  const selectedESitter = useMemo(
    () => eSitters.find((eSit) => eSit.id === connectionId),
    [eSitters, connectionId]
  );
  const filteredPatients = useMemo(
    () =>
      selectedESitter &&
      filterPatientsById(patients, selectedESitter.patientsAssigned),
    [selectedESitter, patients]
  );
  const averageStaffResponse = useMemo(
    () =>
      globalActivity?.StaffResponse
        ? calculateMinsFromSecs(globalActivity?.StaffResponse)
        : undefined,
    [globalActivity?.StaffResponse]
  );
  const [patientToAssign, setPatientToAssign] = useState<Patient>();
  const [patientToChangeCart, setPatientToChangeCart] = useState<Patient>();
  const [isCollapsedById, setIsCollapsedById] = useState("");

  const fetchGlobalActivity = useCallback(
    async (hours: 1 | 8 | 24, connectionId: string) => {
      try {
        const response: GlobalActivity = await getGlobalActivity(
          hours,
          connectionId
        );
        setGlobalActivity(response);
      } catch (e: any) {
        console.error(e);
      }
    },
    []
  );

  useEffect(() => {
    if (!connectionId) {
      return;
    }
    fetchGlobalActivity(hours, connectionId);
  }, [hours, connectionId, fetchGlobalActivity]);

  useEffect(() => {
    if (!connectionId) {
      return;
    }
    fetchGlobalActivity(hours, connectionId);
  }, [patients, connectionId, fetchGlobalActivity]);

  const metadata: TableMetada = useMemo(
    () => ({
      columns: [
        {
          title: "Name",
          compute: (patient: Patient, i?: number) => (
            <div className="row align-center">
              {i !== undefined && i >= MAX_PATIENTS ? (
                <div className="icon-red" style={{ marginRight: 8 }}>
                  <NoPeople
                    tooltipText="Unmonitored Patient"
                    tooltipPosition="top"
                  />
                </div>
              ) : (
                <></>
              )}
              <PatientIcons pat={patient} />
            </div>
          ),
          className: "main-td",
        },
        {
          title: "Room",
          className: "text-center",
          compute: (patient: Patient) => patient.roomNumber,
        },
        {
          title: "Language",
          compute: (patient: Patient) => patient.language,
        },
        {
          title: "Hospital",
          compute: (patient: Patient) =>
            patient.hospitalName || "No hospital assigned",
        },
        {
          title: "Unit",
          compute: (patient: Patient) =>
            patient.nurseUnitName || "No unit name assigned",
        },
        {
          title: "Cart",
          compute: (patient: Patient) => (
            <div style={{ textTransform: "uppercase" }}>
              {patient.cartId?.split("-")[2] || patient.cartId || "None"}
            </div>
          ),
        },
        {
          title: "Status",
          compute: (patient: Patient) =>
            statusList.find((sta) => sta.id === patient.patientStatus)?.value ||
            "No status",
        },
        {
          title: "",
          compute: (patient: Patient, i?: number) => {
            const isLast =
              i && filteredPatients && i === filteredPatients?.length - 1;
            return (
              <div
                className={`options ${
                  isCollapsedById !== patient.id ? "collapsed" : "expanded"
                }`}
              >
                <div
                  className="dropdown-overlay"
                  onClick={() => setIsCollapsedById("")}
                ></div>
                <div
                  className={`outer-circle ${
                    isCollapsedById === patient.id ? "active" : ""
                  }`}
                  onClick={() =>
                    isCollapsedById === patient.id
                      ? setIsCollapsedById("")
                      : setIsCollapsedById(patient.id)
                  }
                >
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
                <div
                  className="dropdown-menu expansible-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCollapsedById("");
                  }}
                  style={{
                    top: isLast ? "-30px" : "30px",
                    right: "50px",
                    width: "120px",
                  }}
                >
                  <div
                    onClick={() => setPatientToAssign(patient)}
                    style={{ padding: "4px", cursor: "pointer" }}
                  >
                    <span>Reassign</span>
                  </div>
                  <div
                    onClick={() => setPatientToChangeCart(patient)}
                    style={{ padding: "4px", cursor: "pointer" }}
                  >
                    <span>Change Cart</span>
                  </div>
                </div>
              </div>
            );
          },
        },
      ],
    }),
    [
      statusList,
      setPatientToAssign,
      isCollapsedById,
      setIsCollapsedById,
      setPatientToChangeCart,
      filteredPatients,
    ]
  );

  if (!selectedESitter || !filteredPatients) {
    return (
      <>
        <div className="info">
          <h3>No patient selected.</h3>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row align-center" style={{ marginBottom: "40px" }}>
        <Arrow onClick={() => navigate(routes.supervisor.main)} />
        <h2 style={{ marginLeft: "20px" }}>
          {capitalizeString(selectedESitter.userName)}
        </h2>
        <h4>
          {selectedESitter.isOnBreak
            ? "On break"
            : `${selectedESitter.totalPatientsAssigned} patient${
                selectedESitter.totalPatientsAssigned === 1 ? "" : "s"
              }`}
        </h4>
      </div>
      <div className="analytics-row">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
          }}
        >
          <BrowserStatusCard
            connection={selectedESitter}
            calculateTimeFrom={ConnectionActivityEnum.BrowserInactive}
            label={"Browser inactive minutes"}
          />
          <div
            className="card-black"
            style={{
              alignItems: "center",
              textAlign: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <AlarmIcon />{" "}
            <p className="highlight">
              {averageStaffResponse
                ? `${
                    averageStaffResponse.minutes
                  }:${averageStaffResponse.seconds
                    .toString()
                    .padStart(2, "0")} min`
                : "-"}
            </p>
            <p>Average staff response </p>
          </div>
        </div>
        <CurrentIssues patients={filteredPatients} />
        <GlobalActivityCard
          setHours={setHours}
          hours={hours}
          globalActivity={globalActivity}
        />
      </div>
      {filteredPatients.length === 0 ? (
        <div className="info">
          <h3>No patients assigned yet.</h3>
          <p>When patients are added, you will see their information here.</p>
        </div>
      ) : (
        <div
          className="table-container"
          style={{ height: "calc(100vh - 550px)", overflow: "auto" }}
        >
          <table className="table">
            <thead>
              <tr>
                {metadata.columns.map((column, i) => {
                  return (
                    <th
                      key={`th-label-${column.title}`}
                      className={column.className}
                      style={
                        i === metadata.columns.length - 1
                          ? { width: "200px" }
                          : {}
                      }
                    >
                      {column.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="hover">
              {filteredPatients.map((pat, i) => (
                <tr key={`tr-${pat.id}`}>
                  {metadata.columns.map((column) => (
                    <td
                      key={`td-${pat.id}-${column.title}`}
                      className={`${column.className} ${
                        column.title === "Status" &&
                        `pill ${column
                          .compute(pat)
                          ?.toString()
                          .toLocaleLowerCase()}`
                      }`}
                    >
                      {typeof column.compute(pat) === "string" ? (
                        <p>{column.compute(pat)}</p>
                      ) : (
                        column.compute(pat, i)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {patientToAssign && selectedESitter && (
        <ReassignPatient
          selectedESitter={selectedESitter}
          patient={patientToAssign}
          onSubmit={() => {
            dispatch(addNotifications(NotificationsEnum.ReassignSuccess));
          }}
          onCancel={() => {
            setPatientToAssign(undefined);
          }}
        />
      )}
      {patientToChangeCart && (
        <ChangeCart
          patient={patientToChangeCart}
          onSubmit={() => {
            dispatch(addNotifications(NotificationsEnum.CartIdChanged));
          }}
          onCancel={() => {
            setPatientToChangeCart(undefined);
          }}
        />
      )}
    </>
  );
};
