export const Like = ({ action, tooltipText = "", disabled = false }) => {
  return (
    <div className={`${tooltipText && "tooltip"}`}>
      <svg
        onClick={() => !disabled && action()}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`thumbs-icons ${disabled && "btn disabled"}`}
        aria-label="thumbsUp-icon"
      >
        <path
          d="M1.33333 28H6.66666V12H1.33333V28ZM30.6667 13.3333C30.6667 11.8666 29.4667 10.6666 28 10.6666H19.5867L20.8533 4.57331L20.8933 4.14665C20.8933 3.59998 20.6667 3.09331 20.3067 2.73331L18.8933 1.33331L10.12 10.12C9.62666 10.6 9.33333 11.2666 9.33333 12V25.3333C9.33333 26.8 10.5333 28 12 28H24C25.1067 28 26.0533 27.3333 26.4533 26.3733L30.48 16.9733C30.6 16.6666 30.6667 16.3466 30.6667 16V13.3333Z"
          fill="black"
        />
      </svg>
      {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
    </div>
  );
};
