export enum ErrorTypeEnum {
  Default = 0,
  FetchFailed = 1,
  NetworkError = 2,
  AISocket = 3,
  VideoSocket = 4,
  AudioSocket = 5,
  SocketError = 6,
  Retry = 7,
  ContactSaved = 8,
  UnableConnection = 9,
  BusyCall = 10,
  CodeFailure = 11,
  ESitterSocket = 12,
  PatientsAPI = 13,
  CommandPatientsAPI = 14,
  CommandContactsAPI = 15,
  AlarmCannotStop = 16,
  AudioCannotStop = 17,
  Canceled = 18,
  DeviceOffline = 19,
  MicUnavailable = 20,
  PatientStatusAPI = 21,
  EmptyStatusList = 22,
  ConnectionCantBeSaved = 23,
  StartBreak = 24,
  Misspelling = 25,
  StopBreak = 26,
  WrongPassword=27,
  NotFound=28,
  MissingData=29,
  SameUser=30,
  UserCancelation = 31,
  RoomDevicesAPI,
  DevicesInfoUnavailable,
  CartIdFormat
}
