import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteExpirationTime } from "../../../../features/timeToExpire/timeToExpireSlice";
import { Contacts } from "../../../../interfaces/contacts/contacts.interface";
import { RootState } from "../../../../stores";
import { lessThan10Min } from "../../../../utils/timeOperations";
import { ContactTypeInputEnum } from "../../../../enums/contactsEnum";
import { resetAllForms, setForm } from "../../../../features/openForms/openFormSlice";

interface Props {
  contact: Contacts;
}
export const DetailedContact: React.FC<Props> = ({ contact }) => {
  const [minutesToExpire, setMinutesToExpire] = useState("");
  const dispatch = useDispatch();

  const parsedDate = useMemo(
    () =>
      contact?.endDateTime.endsWith("Z")
        ? contact.endDateTime
        : `${contact?.endDateTime}Z`,
    [contact]
  );

  const { expirationTimeByContact } = useSelector(
    (state: RootState) => state.timeToExpire
  );
  useEffect(() => {
    if (!lessThan10Min(parsedDate) && contact?.id) {
      dispatch(deleteExpirationTime({ contactId: contact.id }));
    }
  }, [parsedDate, contact]);

  useEffect(() => {
    const currContact = expirationTimeByContact.find(
      (exp) => exp.contactId === contact?.id
    );
    if (currContact) {
      setMinutesToExpire(currContact.timeToExpire);
      return;
    }

    setMinutesToExpire("");
  }, [expirationTimeByContact, contact?.id]);

  const onContactExpirationClick = ()=>{
    dispatch(resetAllForms());
    dispatch(
      setForm({
        typeInput: ContactTypeInputEnum[contact.type],
        status: true,
        name: "",
        phone: "",
      })
    );
  }

  return (
    <div className="detailed-contact">
      <div className="row wrap">
        <p>{contact?.phone}</p>
        {(contact?.type === "Nurse" || contact?.type === "PCT") && minutesToExpire && (
          <p className="expiration" onClick={onContactExpirationClick}>{` Contact expires in ${minutesToExpire}`}</p>
        )}
      </div>
    </div>
  );
};
