import React from "react";

export const IIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2787_13206)">
        <path
          d="M7.99998 1.33203C4.31998 1.33203 1.33331 4.3187 1.33331 7.9987C1.33331 11.6787 4.31998 14.6654 7.99998 14.6654C11.68 14.6654 14.6666 11.6787 14.6666 7.9987C14.6666 4.3187 11.68 1.33203 7.99998 1.33203ZM8.66665 11.332H7.33331V7.33203H8.66665V11.332ZM8.66665 5.9987H7.33331V4.66536H8.66665V5.9987Z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="clip0_2787_13206">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
