import React from "react";

interface Props {
  tooltipText?: string;
}

export const LanguageLogo: React.FC<Props> = ({ tooltipText }) => {
  return (
    <div className="tooltip">
      <svg
        aria-label="language-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 5.33331H14.6666L13.3333 1.33331H3.99998C2.53331 1.33331 1.33331 2.53331 1.33331 3.99998V24C1.33331 25.4666 2.53331 26.6666 3.99998 26.6666H14.6666L16 30.6666H28C29.4666 30.6666 30.6666 29.4666 30.6666 28V7.99998C30.6666 6.53331 29.4666 5.33331 28 5.33331ZM9.33331 21.3333C5.65331 21.3333 2.66665 18.3466 2.66665 14.6666C2.66665 10.9866 5.65331 7.99998 9.33331 7.99998C11.1333 7.99998 12.64 8.66665 13.8 9.73331L12.04 11.4266C11.5333 10.9466 10.6533 10.3866 9.33331 10.3866C7.01331 10.3866 5.13331 12.3066 5.13331 14.6666C5.13331 17.0266 7.01331 18.9466 9.33331 18.9466C12.0133 18.9466 13.12 17.0266 13.2266 15.7333H9.33331V13.4533H15.5733C15.6666 13.8666 15.7333 14.2666 15.7333 14.8133C15.7333 18.6266 13.1866 21.3333 9.33331 21.3333ZM17.56 14.1066H22.4933C21.92 15.7733 21.0133 17.3466 19.76 18.7333C19.3466 18.2666 18.96 17.7733 18.6133 17.2666L17.56 14.1066ZM28.6666 27.3333C28.6666 28.0666 28.0666 28.6666 27.3333 28.6666H18.6666L21.3333 25.3333L19.9466 21.2L24.08 25.3333L25.3066 24.1066L20.9066 19.7733L20.9333 19.7466C22.44 18.08 23.5066 16.16 24.1333 14.12H26.6666V12.3866H20.6266V10.6666H18.9066V12.3866H16.9866L15.28 7.33331H27.3333C28.0666 7.33331 28.6666 7.93331 28.6666 8.66665V27.3333Z"
          fill="black"
        />
      </svg>
      {tooltipText && (
        <span className="tooltiptext" aria-label="tooltip-text">
          {tooltipText}
        </span>
      )}
    </div>
  );
};
