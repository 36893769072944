import { useState, useEffect, useCallback } from "react";
import { ErrorTypeEnum } from "../enums/errorType";
import { env } from "../env";
import axiosHttpClient from "../utils/axiosHttpClient";
import logger from "../logger/logger";

const apisToCheck: { name: ErrorTypeEnum; url: string }[] = [
  {
    name: ErrorTypeEnum.PatientsAPI,
    url: `${env.REACT_APP_API_PATIENTS}`,
  },
  {
    name: ErrorTypeEnum.CommandPatientsAPI,
    url: `${env.REACT_APP_API_COMMAND_PATIENTS}`,
  },
  {
    name: ErrorTypeEnum.CommandContactsAPI,
    url: `${env.REACT_APP_API_COMMAND_NURSES}`,
  },
];
export const useHealtCheck = () => {
  const [apiErrors, setApiErrors] = useState<
    { status: boolean; error: ErrorTypeEnum }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const checkHealthSingleAPI = useCallback(
    async ({ name, url }: { name: ErrorTypeEnum; url: string }) => {
      const response = await axiosHttpClient.getData(`${url}/health`);
      if (response.isSuccessStatusCode) {
        logger.log(`[HEALTHY]: ${ErrorTypeEnum[name]}`);
        return { error: name, status: false };
      }
      logger.error(`[UNHEALTHY]: ${ErrorTypeEnum[name]}`);
      return { error: name, status: true };
    },
    []
  );

  const checkHealthAPIs = useCallback(async () => {
    setLoading(true);
    const promisesArray = apisToCheck.map(
      async (api) => await checkHealthSingleAPI(api)
    );
    const responseArray = await Promise.all(promisesArray);
    setApiErrors(responseArray);
    setLoading(false);
  }, [checkHealthSingleAPI]);

  useEffect(() => {
    !loading && checkHealthAPIs();
    let interval = setInterval(() => {
      checkHealthAPIs();
    }, 60 * 5 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return { apiErrors };
};
