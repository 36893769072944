import React, { useCallback, useState } from "react";
import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { ConnectionInfo } from "../../../../interfaces/connections/connections.interface";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { addPatientToConnection } from "../../../../helpers/connections";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";

interface Props {
  selectedESitter: ConnectionInfo;
  patient: Patient;
  onSubmit: () => void;
  onCancel: () => void;
}

export const ReassignPatient: React.FC<Props> = ({
  selectedESitter,
  patient,
  onCancel,
  onSubmit,
}) => {
  const { list: eSitters } = useSelector(
    (state: RootState) => state.supervisors
  );
  const [loading, setLoading] = useState(false);
  const [esitterId, setEsitterId] = useState("");
  const [error, setError] = useState<ErrorTypeEnum>();
  const [step, setStep] = useState(0);

  const handleReassignPatient = useCallback(
    async (connectionId: string) => {
      setLoading(true);
      try {
        await addPatientToConnection(connectionId, {
          patientId: patient.id,
        });
        onSubmit();
        onCancel();
      } catch (e: any) {
        setError(ErrorTypeEnum.Default);
      } finally {
        setLoading(false);
      }
    },
    [patient]
  );

  const Body = () => {
    if (step === 1) {
      const currentEsitter = eSitters.find((eS) => eS.id === esitterId);
      let className = "green";

      if (currentEsitter?.workload && currentEsitter?.workload?.total > 33) {
        className = "yellow";
      }

      if (currentEsitter?.workload && currentEsitter?.workload?.total > 66) {
        className = "red";
      }
      return (
        <div style={{ margin: "64px 0" }}>
          <p className="text-big">
            Reassign patient{" "}
            <span>
              {patient.lastName}, {patient.firstName}
            </span>{" "}
            to E-Sitter <span>{currentEsitter?.userName}</span>{" "}
            <span className={className}>
              ({currentEsitter?.workload?.total}%)
            </span>
            ?
          </p>
        </div>
      );
    }

    return (
      <>
        <div style={{ margin: "30px 0px 10px" }}>
          <p className="label">From</p>
          <p
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            {selectedESitter.userName}
          </p>
        </div>
        <div style={{ maxWidth: "380px", margin: "16px 0px 64px" }}>
          <p className="label">To</p>
          <select
            name="esitter"
            id="esitter"
            style={{
              textTransform: "capitalize",
              fontSize: "0.9rem",
              padding: 10,
              marginBottom: 5,
            }}
            value={esitterId}
            onChange={(e) => setEsitterId(e.target.value)}
          >
            <option value="" disabled>
              Select E-Sitter
            </option>
            {eSitters.map((esitter) => {
              if (esitter.id === selectedESitter.id) {
                return;
              }

              return (
                <option
                  value={esitter.id}
                  style={{
                    backgroundColor: "#182430",
                    borderRadius: 0,
                  }}
                  key={esitter.id}
                >
                  {esitter.userName} ({esitter.workload?.total || ""}%)
                </option>
              );
            })}
          </select>
        </div>
      </>
    );
  };
  return (
    <div className="overlay dark-transparent">
      <div className="dialog">
        <div className="row align-center start">
          <p className="title-2">Reassign Patient</p>
          {step === 0 && (
            <>
              <ArrowDownLogo orientation="right" />
              <p className="subtitle">
                {patient.firstName} {patient.lastName}
              </p>
            </>
          )}
        </div>
        <div className="close">
          <CloseIcon action={onCancel} />
        </div>
        <Body />

        <div style={{ marginBottom: "1rem" }}>
          {error && (
            <ErrorMsg
              filled={false}
              errorMsg={error}
              position="inline"
              padding="none"
              closeBtn={false}
            />
          )}
        </div>
        <div className="row btn-group right reverse-order">
          <button
            className={`btn-small light`}
            aria-label="submit-btn"
            type="submit"
            disabled={!esitterId || loading || Boolean(error)}
            onClick={() =>
              step === 0
                ? esitterId && setStep(1)
                : esitterId && handleReassignPatient(esitterId)
            }
            style={
              !esitterId || loading || Boolean(error)
                ? { backgroundColor: "#8f8f8f" }
                : step === 1
                ? { backgroundColor: "#66c295" }
                : {}
            }
          >
            {loading ? (
              <div
                className="loader xsmall"
                style={{ width: 10, height: 10 }}
              ></div>
            ) : (
              <>CONTINUE</>
            )}
          </button>
          <button
            className="btn-small transparent outlined"
            type="button"
            onClick={onCancel}
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};
