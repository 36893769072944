import React from "react";
interface Props {
  action?: () => any | void;
  tooltipText?: string;
  tooltipPosition?: "right" | "down" | "left" | "top";
}

export const NoPeople: React.FC<Props> = ({
  action,
  tooltipText,
  tooltipPosition = "right",
}) => {
  return (
    <div className="tooltip">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2498_17216)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.625 4.375C9.625 5.82531 8.45031 7 7 7C6.76544 7 6.5381 6.96927 6.32176 6.91163L4.46337 5.05324C4.40573 4.8369 4.375 4.60956 4.375 4.375C4.375 2.92469 5.54969 1.75 7 1.75C8.45031 1.75 9.625 2.92469 9.625 4.375ZM6.22229 8.35895C4.38089 8.5612 1.75 9.42358 1.75 10.9375V12.25H10.1133L6.22229 8.35895ZM11.6601 12.25L7.76802 8.35789C9.60986 8.55818 12.25 9.42092 12.25 10.9375V12.25H11.6601Z"
            fill="#CF6679"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 13.8984L0.101626 3.49998L0.875024 2.72659L11.2734 13.125L10.5 13.8984Z"
            fill="#CF6679"
          />
        </g>
        <defs>
          <clipPath id="clip0_2498_17216">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {tooltipText && (
        <span className={`tooltiptext ${tooltipPosition}`}>{tooltipText}</span>
      )}
    </div>
  );
};
