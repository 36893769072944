import React from "react";

interface Props {
  action?: (args?: any) => any | void;
  tooltipText?: string;
}

export const CallLogo: React.FC<Props> = ({ action, tooltipText }) => {
  return (
    <div className="tooltip">
      <svg
        onClick={action}
        aria-label="call-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.22889 14.2604C10.3089 18.3459 13.66 21.6807 17.7478 23.774L20.9256 20.598C21.3156 20.2082 21.8933 20.0783 22.3989 20.2515C24.0167 20.7857 25.7644 21.0744 27.5556 21.0744C28.35 21.0744 29 21.724 29 22.518V27.5564C29 28.3504 28.35 29 27.5556 29C13.9922 29 3 18.0139 3 4.45808C3 3.66408 3.65 3.01444 4.44444 3.01444H9.5C10.2944 3.01444 10.9444 3.66408 10.9444 4.45808C10.9444 6.26263 11.2333 7.995 11.7678 9.61188C11.9267 10.1172 11.8111 10.6802 11.4067 11.0844L8.22889 14.2604ZM27.7867 4.01055L26.7611 3L17.4444 12.0949V5.90172H16V14.5636H24.6667V13.1199H18.6722L27.7867 4.01055Z"
          fill="black"
        />
      </svg>
      {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
    </div>
  );
};
