import React from "react";
import { useEffect, useState } from "react";
import { CamsContainer } from "../UI/organisms/CamsContainer/CamsContainer";
import { Sidebar } from "../UI/organisms/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getPatientStatus } from "../../features/patients/patientsSlice";
import store, { RootState } from "../../stores";
import {
  addGlobalError,
  removeGlobalError,
} from "../../features/globalErrors/globalErrorsSlice";
import { ErrorTypeEnum } from "../../enums/errorType";
import { StatusBar } from "../UI/organisms/StatusBar/StatusBar";
import { LoadingEnum, UserTypes } from "../../enums/global";
import {
  getSavedSession,
  removeSession,
} from "../../features/session/sessionSlice";
import logger from "../../logger/logger";
import { getAudioMedia } from "../../utils/getUserMedia";
import {
  deleteConnection,
  saveConnectionActivity,
} from "../../helpers/connections";
import { usePatients } from "../../hooks/usePatients";
import { ConnectionActivityEnum } from "../../enums/connectionsEnum";
import { ConnectionActivity } from "../../interfaces/connections/connections.interface";
import moment from "moment";
import { useConnections } from "../../hooks/useConnection";

export const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading: connectionLoading, saveConnection } = useConnections();
  const {
    session: {
      sub: [username, userid],
    },
    loading: sessionLoading,
    token,
    connectionId,
  } = useSelector((state: RootState) => state.sessionInfo);
  const { fetchPatients, isLoading: patientsLoading } = usePatients();
  const { list: patients } = useSelector((state: RootState) => state.patients);
  const { generalErrors } = useSelector(
    (state: RootState) => state.globalErrors
  );
  const { patientId: selectedPatientId } = useSelector(
    (state: RootState) => state.selectedPatient
  );
  const [internetHasBeenLost, setInternetHasBeenLost] = useState(false);
  const [internetReconnected, setInternetReconnected] = useState(false);

  useEffect(() => {
    micValidation();
    detectInternet();
  }, []);

  const detectInternet = () => {
    let timer: NodeJS.Timeout | undefined;
    const AWAIT_TIMER = 5000;
    window.addEventListener("online", () => {
      setInternetReconnected(true);
      clearTimeout(timer);
    });

    window.addEventListener("offline", () => {
      setInternetReconnected(false);
      timer = setTimeout(() => {
        setInternetHasBeenLost(true);
      }, AWAIT_TIMER);
    });
  };

  useEffect(() => {
    if (internetHasBeenLost && internetReconnected) {
      dispatch(removeSession());
    }
  }, [internetHasBeenLost, internetReconnected]);

  useEffect(() => {
    dispatch(getSavedSession(UserTypes.Esitter));
  }, []);

  useEffect(() => {
    userid && token && saveConnection(userid, token);
  }, [userid, token]);

  useEffect(() => {
    if (generalErrors.includes(ErrorTypeEnum.MicUnavailable)) {
      const micValidationTimeOut = 60;
      setTimeout(() => {
        micValidation();
      }, micValidationTimeOut * 1000);
    }
  }, [generalErrors]);

  const micValidation = () => {
    getAudioMedia(
      () => dispatch(removeGlobalError(ErrorTypeEnum.MicUnavailable)),
      () => dispatch(addGlobalError(ErrorTypeEnum.MicUnavailable))
    );
  };

  useEffect(() => {
    if (connectionId && !internetHasBeenLost) {
      fetchPatients(connectionId);
      return;
    }
  }, [connectionId, internetHasBeenLost]);

  useEffect(() => {
    if (connectionId && token) {
      onWindowBlur(connectionId, token);
    }
  }, [connectionId, token]);

  const onWindowBlur = async (connectionId: string, token: string) => {
    window.addEventListener("blur", () => {
      sendBrowserStatus(connectionId, ConnectionActivityEnum.BrowserInactive);
    });

    window.addEventListener("focus", () => {
      sendBrowserStatus(connectionId, ConnectionActivityEnum.BrowserActive);
    });
  };

  const sendBrowserStatus = async (
    connectionId: string,
    activityType: ConnectionActivityEnum
  ) => {
    const data: ConnectionActivity = {
      activityDateTime: moment().utc().format(),
      activityType,
      addittionalDetails: "",
    };

    try {
      await saveConnectionActivity(connectionId, data);
    } catch (e: any) {
      logger.error(`Browser Status can't be saved ${e}`);
    }
  };

  useEffect(() => {
    const deleteSession = () => {
      try {
        deleteConnection(connectionId);
        dispatch(removeSession());
      } catch (e) {
        logger.error("Error deleting connection", e);
      }
    };
    switch (sessionLoading) {
      case LoadingEnum.succeeded:
        store.dispatch(getPatientStatus());
        break;
      case LoadingEnum.failed:
        if (connectionId) {
          deleteSession();
          return;
        }
        dispatch(removeSession());
        break;
      default:
        break;
    }
  }, [dispatch, sessionLoading]);

  if (internetHasBeenLost) {
    return (
      <div className="overlay dark">
        <div className="title-bx">
          <div className="title">You've Been Logged Out</div>
          <p
            className="text-normal"
            style={{ maxWidth: "600px", textAlign: "center" }}
          >
            Please check your internet connection. You’ll be redirected when
            your connection is back.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      {patients && patients.length > 0 && (
        <div className="sidebar-col">
          <Sidebar patients={patients} selectedPatientId={selectedPatientId} />
        </div>
      )}
      <div className="cams-container-col col-100">
        <StatusBar
          patientsTotal={patients.length}
          username={username}
          userType={UserTypes.Esitter}
        />
        <CamsContainer
          patients={patients}
          selectedPatient={selectedPatientId}
          loading={patientsLoading || connectionLoading}
        />
      </div>
    </div>
  );
};
