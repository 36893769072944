import React from "react";
import { useEffect, useState } from "react";
import { RiskColor, RiskValue } from "../../../../interfaces/fallRisk/fallRisk.interface";

interface Props {
  riskFall: RiskValue;
}

export const Badge: React.FC<Props> = ({ riskFall }) => {
  const [riskFallClass, setRiskFallClass] = useState<RiskColor>("default");

  useEffect(() => {
    switch (riskFall) {
      case 0:
        setRiskFallClass("default");
        break;
      case 1:
        setRiskFallClass("medium");
        break;
      case 2:
        setRiskFallClass("high");
        break;
      default:
        setRiskFallClass("default");
        break;
    }

    return () => {};
  }, [riskFall]);

  return (
    <div
      className={`badge ${riskFallClass}`}
      aria-label={`badge ${riskFallClass}`}
    >
      {
        {
          0: "Default",
          1: "Medium",
          2: "High",
        }[riskFall]
      }
    </div>
  );
};
