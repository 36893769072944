export const RoomLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4286 8.14287V12.8572C29.0001 12.8572 30.2858 14.2715 30.2858 16V23.8572H28.3858L27.4286 27.0001H26.0001L25.0572 23.8572H6.95711L5.99997 27.0001H4.57139L3.61424 23.8572H1.71423V16C1.71423 14.2715 2.99995 12.8572 4.57139 12.8572V8.14287C4.57139 6.41429 5.85711 5 7.42854 5H24.5715C26.1429 5 27.4286 6.41429 27.4286 8.14287ZM11 12.8572H7.42854V8.14287H14.5714V8.14289H17.4286V8.14287H24.5715V12.8572H19.9286V12.8572H11V12.8572Z"
        fill="black"
      />
    </svg>
  );
};
