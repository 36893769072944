import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedPatient } from "../../../../features/selectedPatient/selectedPatient";
import { PinIcon } from "../../../../imgs/PinIcon";
import { removePinnedPatient } from "../../../../features/patients/patientsSlice";

interface Props {
  isPatientSelected: boolean;
  patientName: string;
  patientId: string;
}

export const CamOverlay: React.FC<Props> = ({
  isPatientSelected,
  patientName,
  patientId,
}) => {
  const dispatch = useDispatch();

  const handleSelectPatient = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(setSelectedPatient(patientId));
  };

  const handleUnpinPatient = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(removePinnedPatient(patientId));
  };

  return (
    <div
      className={`cam-overlay opaque ${isPatientSelected && "green"}`}
      onClick={handleSelectPatient}
    >
      <div className="circle-icon" onClick={handleUnpinPatient}>
        <PinIcon />
      </div>
      <div className="title">PINNED PATIENT</div>
      <div className="subtitle">{patientName}</div>
    </div>
  );
};
