import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PillAlert } from "../../interfaces/pillAlert/pillAlerts.interface";

export interface PillAlertsState {
  pillsByPatient: {
    [room: string]: PillAlert;
  };
}

const initialState: PillAlertsState = {
  pillsByPatient: {},
};

export const pillAlertsSlice = createSlice({
  name: "pillAlerts",
  initialState,
  reducers: {
    setPillAlert: (
      state: PillAlertsState,
      action: PayloadAction<PillAlert>
    ) => {
      state.pillsByPatient[action.payload.room] = {
        ...state.pillsByPatient[action.payload.room],
        ...action.payload,
      };
    },

    removePillAlert: (
      state: PillAlertsState,
      action: PayloadAction<PillAlert>
    ) => {
      delete state.pillsByPatient[action.payload.room];
    },
  },
});

export default pillAlertsSlice.reducer;

export const { setPillAlert, removePillAlert } = pillAlertsSlice.actions;
