import { env } from "../env";
import { ErrorTypeEnum } from "../enums/errorType";
import { ESitterEventsEnum } from "../enums/eSitterEvents";
import { CustomSocket } from "../utils/customSocket";

let socket: CustomSocket;

const eventsFromApi: ESitterEventsEnum[] = [
  ESitterEventsEnum.LoadBalanceCompletedEvent,
  ESitterEventsEnum.ContactCreatedEvent,
  ESitterEventsEnum.ContactUpdatedEvent,
  ESitterEventsEnum.ContactRemovedEvent,
  ESitterEventsEnum.ContactExpiredEvent,
  ESitterEventsEnum.PatientUpdatedEvent,
  ESitterEventsEnum.PatientRemovedEvent,
  ESitterEventsEnum.ActivityAddedEvent,
  ESitterEventsEnum.ConnectionActivityAddedEvent,
];

export const initiateSupervisorSocket = (
  errorCallback: (arg: ErrorTypeEnum) => any,
  onConnection: () => any,
  onNewMessageCallback: (eventName: ESitterEventsEnum, message: any) => any
) => {
  if (!socket) {
    socket = new CustomSocket(
      env.REACT_APP_ESITTER_SOCKET,
      "/esitter-socket/",
      () => {
        onConnection?.();
        subscribeToSupervisorEvents(onNewMessageCallback);
      },
      errorCallback,
      ErrorTypeEnum.ESitterSocket
    );
  }
};

export const disconnectSupervisorSocket = () => {
  if (!socket) return;
  socket.disconnect();
};

export const subscribeToSupervisorEvents = (
  onNewMessageCallback: (event: ESitterEventsEnum, message: any) => void
) => {
  if (!socket) return false;

  eventsFromApi.forEach((eventName) => {
    socket.suscribe(eventName, (message: any) => {
      onNewMessageCallback?.(eventName, message);
    });
  });
};
