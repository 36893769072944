export const Dislike = ({
  action,
  tooltipText = "",
  selected = false,
  disabled = false,
}) => {
  return (
    <div className={`${tooltipText && "tooltip"}`}>
      <svg
        onClick={() => !disabled && action()}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`thumbs-icons ${selected && "selected"} ${
          disabled && "btn disabled"
        }`}
        aria-label="thumbsDown-icon"
      >
        <path
          d="M20 4H8C6.89334 4 5.94667 4.66667 5.54667 5.62667L1.52 15.0267C1.4 15.3333 1.33334 15.6533 1.33334 16V18.6667C1.33334 20.1333 2.53334 21.3333 4 21.3333H12.4133L11.1467 27.4267L11.1067 27.8533C11.1067 28.4 11.3333 28.9067 11.6933 29.2667L13.1067 30.6667L21.8933 21.88C22.3733 21.4 22.6667 20.7333 22.6667 20V6.66667C22.6667 5.2 21.4667 4 20 4ZM25.3333 4V20H30.6667V4H25.3333Z"
          fill="black"
        />
      </svg>
      {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
    </div>
  );
};
