import React, { useState } from "react";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { Pagination } from "../Pagination/Pagination";

interface Props {
  errorMsg?: ErrorTypeEnum;
  globalErrors?: ErrorTypeEnum[];
  filled: boolean;
  position?:
    | "relative"
    | "top"
    | "bottom"
    | "center"
    | "inline"
    | "absolute bottom"
    | "absolute top";
  Icon?: any;
  closeBtn?: boolean;
  width?: number | "auto";
  padding?: "big" | "small" | "none";
  interval?: number;
  onClose?: () => any;
}

const errorText: { [error in ErrorTypeEnum]: string } = {
  [ErrorTypeEnum.Default]: "Something went wrong.",
  [ErrorTypeEnum.FetchFailed]:
    "We are having some issue updating the data. Please contact the service desk.",
  [ErrorTypeEnum.NetworkError]:
    "Poor network connection detected. Please check your internet connection.",
  [ErrorTypeEnum.AISocket]:
    "Risk alert is currently unavailable. Please contact the service desk.",
  [ErrorTypeEnum.VideoSocket]:
    "Video recording is currently unavailable. Please contact the service desk.",
  [ErrorTypeEnum.Retry]: "An error ocurred. Please try again.",
  [ErrorTypeEnum.ContactSaved]:
    "This type of contact is already saved. Please refresh the app.",
  [ErrorTypeEnum.UnableConnection]:
    "Unable to connect. Please check your internet connection and try again.",
  [ErrorTypeEnum.BusyCall]:
    "The room calling system is busy. Please try again in few minutes.",
  [ErrorTypeEnum.CodeFailure]:
    "Extension unreachable. Please contact the service desk.",
  [ErrorTypeEnum.ESitterSocket]:
    "We encountered an unexpected problem. Please contact the service desk.",
  [ErrorTypeEnum.PatientsAPI]:
    "Patient data is currently unavailable. Please contact the service desk.",
  [ErrorTypeEnum.CommandPatientsAPI]:
    "We are having some issues updating data. Please refresh the application. Please contact the service desk.",
  [ErrorTypeEnum.CommandContactsAPI]:
    "We are having some issues updating data. Please refresh the application. Please contact the service desk.",
  [ErrorTypeEnum.AlarmCannotStop]: "Alarm can't be stopped.",
  [ErrorTypeEnum.AudioCannotStop]: "Audio can't be stopped.",
  [ErrorTypeEnum.Canceled]: "Call canceled. Please try again in few minutes.",
  [ErrorTypeEnum.AudioSocket]:
    "Audio intervention is currently unavailable. Please contact the service desk.",
  [ErrorTypeEnum.DeviceOffline]: "Device is offline.",
  [ErrorTypeEnum.MicUnavailable]:
    "Microphone not detected. Please make sure your microphone is properly connected.",
  [ErrorTypeEnum.SocketError]: "We encountered an unexpected problem.",
  [ErrorTypeEnum.PatientStatusAPI]: "The status list is not available.",
  [ErrorTypeEnum.EmptyStatusList]: "The status list is empty.",
  [ErrorTypeEnum.ConnectionCantBeSaved]: "Connection info can't be saved.",
  [ErrorTypeEnum.StartBreak]:
    "Take a break can't be started. Please contact the service desk.",
  [ErrorTypeEnum.StopBreak]:
    "Take a break can't be stopped. Please contact the service desk.",
  [ErrorTypeEnum.Misspelling]: "Type word correctly.",
  [ErrorTypeEnum.WrongPassword]: "Password is wrong.",
  [ErrorTypeEnum.NotFound]: "User not found.",
  [ErrorTypeEnum.MissingData]: "All data is needed.",
  [ErrorTypeEnum.SameUser]: "The username should be different.",
  [ErrorTypeEnum.UserCancelation]: "Request canceled.",
  [ErrorTypeEnum.RoomDevicesAPI]: "Missing data from Room Devices API",
  [ErrorTypeEnum.DevicesInfoUnavailable]:
    "This cart id is not set up in Room Devices API",
  [ErrorTypeEnum.CartIdFormat]:
    "The Cart Id format is invalid (Only letters and numbers).",
};

export const ErrorMsg: React.FC<Props> = ({
  errorMsg = ErrorTypeEnum.Default,
  globalErrors,
  filled,
  position = "relative",
  Icon = InfoIcon,
  closeBtn = true,
  width = 100,
  padding = "big",
  interval,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const [currentError, setCurrentError] = useState(0);

  const closeAlert = () => {
    setOpen(false);
    onClose?.();
  };

  if (interval) {
    setTimeout(() => {
      setOpen(false);
    }, interval);
  }

  if (globalErrors?.length) {
    return (
      <>
        {open && (
          <div className="global-errors error-msg filled">
            {globalErrors.length > 1 && (
              <Pagination
                maxPage={globalErrors.length}
                currentPage={currentError + 1}
                onNext={() => setCurrentError(currentError + 1)}
                onPrevious={() => setCurrentError(currentError - 1)}
              />
            )}
            <div className="errors-stack">
              <div className={`single-error`} aria-label="error-msg">
                {errorText[globalErrors[currentError]]}
              </div>
            </div>
            {closeBtn && <CloseIcon action={closeAlert} />}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {open && (
        <div
          className={`error-msg w-${width} ${
            filled && "filled"
          } ${position} p-${padding}`}
          aria-label="error-msg"
        >
          {Icon && <Icon />}
          <p className="message">{errorText[errorMsg]}</p>
          {closeBtn && <CloseIcon action={closeAlert} />}
        </div>
      )}
    </>
  );
};
