export const Check = ({noHover= false, isActive= false}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label='check-icon'
      className={`${noHover && "noHover"} ${isActive && "active"}`}
    >
      <path
        d="M10.8982 22.3572L4.26038 15.516L2 17.8292L10.8982 27L30 7.3132L27.7555 5L10.8982 22.3572Z"
        fill="black"
      />
    </svg>
  );
};
