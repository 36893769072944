export const ClockIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="clock-icon"
    >
      <path
        d="M15.986 2C8.258 2 2 8.272 2 16C2 23.728 8.258 30 15.986 30C23.728 30 30 23.728 30 16C30 8.272 23.728 2 15.986 2ZM16 27.2C9.812 27.2 4.8 22.188 4.8 16C4.8 9.812 9.812 4.8 16 4.8C22.188 4.8 27.2 9.812 27.2 16C27.2 22.188 22.188 27.2 16 27.2Z"
        fill="black"
      />
      <path d="M17 9H15V17.5246L22 22L23 20.2525L17 16.459V9Z" fill="black" />
    </svg>
  );
};
