import { ConnectionActivityEnum } from "../enums/connectionsEnum";
import { ConnectionActivity } from "../interfaces/connections/connections.interface";
import { getPassedTime } from "./timeOperations";

export const getMinutesFromBrowserStatus = (
  activities: ConnectionActivity[],
  lookFor: ConnectionActivityEnum
): string => {
  if (!activities || !activities.length) {
    return "";
  }

  let i = activities.length;

  do {
    i--;
    if (
      !(
        activities[i].activityType === ConnectionActivityEnum.BrowserActive ||
        activities[i].activityType === ConnectionActivityEnum.BrowserInactive
      )
    ) {
      continue;
    }

    if (activities[i]?.activityType === lookFor) {
      const { minutes } = getPassedTime(
        activities[i].activityDateTime,
        false,
        false
      );
      return `${minutes}`;
    }
    return "-";
  } while (i >= 0);

  return "-";
};
