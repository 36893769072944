export const parseJSON = (string: string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return string;
  }
};

export const getAudioInfo = (string: string, prop = "id") => {
  const firstIndex = string.indexOf("(") + 1;

  return JSON.parse(string.slice(firstIndex, -1))[prop];
};
