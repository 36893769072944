import { CallLabelEnum } from "../../../../enums/callLabel";
import { TimerStatus } from "../../../../interfaces/pillAlert/pillAlerts.interface";
import { AIInteractions } from "../AIInteractions/AIInteractions";
import { AlarmButton } from "../AlarmButton/AlarmButton";
import { MicroButton } from "../MicroButton/MicroButton";
import { PlayButton } from "../PlayButton/PlayButton";

interface Props {
  changeMicro: () => any;
  microMuted: boolean;
  confirmAI: () => any;
  openMiniForm: () => any;
  declinationMiniForm: boolean;
  confirmationAi: boolean;
  declinationAi: boolean;
  sendAudioMessage: () => any;
  sendAlert: () => any;
  audioAvailable: boolean;
  alarmStatus: TimerStatus;
  stopAlarm?: () => any;
  alarmModalStatus: boolean;
  loadingCall: boolean;
  videoLoading: boolean;
  callLabel?: CallLabelEnum;
  playAudioMsg: boolean;
}

export const LowerMenu: React.FC<Props> = ({
  changeMicro,
  microMuted,
  confirmAI,
  openMiniForm,
  declinationMiniForm,
  confirmationAi,
  declinationAi,
  sendAudioMessage,
  sendAlert,
  audioAvailable,
  alarmStatus,
  stopAlarm,
  alarmModalStatus,
  loadingCall,
  videoLoading,
  callLabel,
  playAudioMsg,
}) => {
  const globalDisabled =
    alarmModalStatus || declinationMiniForm || videoLoading;

  const toogleAlert = () => {
    if (alarmStatus === "play") {
      stopAlarm?.();
      return;
    }

    sendAlert();
  };

  return (
    <>
      <AlarmButton
        disabled={
          globalDisabled ||
          alarmStatus === "stop" ||
          callLabel === CallLabelEnum.HangOn
        }
        loading={loadingCall}
        alarmStatus={alarmStatus}
        toogleAlert={toogleAlert}
      />
      <div className="iconsBx">
        <MicroButton
          loading={loadingCall}
          disabled={globalDisabled || !audioAvailable || playAudioMsg}
          microMuted={microMuted}
          changeMicro={changeMicro}
        />
        <PlayButton
          disabled={globalDisabled || callLabel === CallLabelEnum.HangOn}
          loading={loadingCall}
          sendAudioMessage={sendAudioMessage}
          playAudioMsg={playAudioMsg}
        />
        <AIInteractions
          disabled={globalDisabled || confirmationAi || declinationAi}
          confirmAI={confirmAI}
          openMiniForm={openMiniForm}
          declinationMiniForm={declinationMiniForm}
        />
      </div>
    </>
  );
};
