import React from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { PatientMenu } from "../../atoms/PatientMenu/PatientMenu";

interface Props {
  patients: Patient[];
  overlay?: boolean;
  onClick?: ()=>void;
}

export const PatientMenuContainer: React.FC<Props> = ({
  patients,
  overlay,
  onClick
}) => {

  return (
    <div
      className={`patient-menu-container ${overlay && "overlay-sidebar"}`}
      aria-label="patient-menu-container"
      onClick={onClick}
    >
      {patients.map((patient: Patient) => {
        return <PatientMenu patient={patient} key={patient.id} />;
      })}
    </div>
  );
};
