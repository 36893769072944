import EndpointGenerator from "../utils/EndpointGenerator";
import {
  MonitoringOperationsEnum,
  PatientQueryOperationsEnum,
} from "../enums/apisOperations";
import axiosHttpClient from "../utils/axiosHttpClient";
import { CatalogsEnum } from "../enums/catalogs";

export const getAllPatients = async () => {
  const endpoint = EndpointGenerator.generatePatientQueryEndpoint(
    PatientQueryOperationsEnum.AllPatients
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getPatientById = async (id: string) => {
  const endpoint = EndpointGenerator.generatePatientQueryEndpoint(
    PatientQueryOperationsEnum.PatientById,
    id
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getDeviceInfoByLocation = async (
  location: string,
  room: string,
  cartId?: string,
) => {
  const endpoint = EndpointGenerator.generateDeviceQueryEndpoint(
    location,
    room, 
    cartId
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getDeviceInfoByCart = async (
  cartId: string,
) => {
  const endpoint = EndpointGenerator.generateDeviceQueryEndpointByCart(
    cartId
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getPatientStatusInfo = async () => {
  const endpoint = EndpointGenerator.generateCatalogsEndpoint(
    CatalogsEnum.PatientStatus
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getLocations = async () => {
  const endpoint = EndpointGenerator.generateLocationEndpoint(
    CatalogsEnum.Locations
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getDeletedPatients = async () => {
  const endpoint = EndpointGenerator.generatePatientQueryEndpoint(
    PatientQueryOperationsEnum.PatientsDeleted
  );
  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getActivePeople = async () => {
  const endpoint = EndpointGenerator.generatePatientQueryEndpoint(
    PatientQueryOperationsEnum.ActivePeopleAtHospital
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getContactsByLocation = async (locationId: string) => {
  const endpoint = EndpointGenerator.generatePatientQueryEndpoint(
    PatientQueryOperationsEnum.ContactsByLocation,
    locationId
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getNurseUnitsByLocation = async (locationId: string) => {
  const endpoint = EndpointGenerator.generateLocationEndpoint(
    CatalogsEnum.NurseUnits,
    locationId
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const setMonitoring = async (
  locationId: string,
  roomId: string,
  monitoringOperation: MonitoringOperationsEnum,
  cartId?: string
) => {
  const endpoint = EndpointGenerator.generateMonitoringEndpoint(
    monitoringOperation,
    locationId,
    roomId,
    cartId
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};
