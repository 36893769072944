import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { ErrorTypeEnum } from "../enums/errorType";
import { addGlobalError } from "../features/globalErrors/globalErrorsSlice";
import { setConnectionId } from "../features/session/sessionSlice";
import { postConnection } from "../helpers/connections";
import logger from "../logger/logger";

export const useConnections = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getNewConnectionId = useCallback(
    async (userid: string, token: string) => {
      try {
        const newConnectionId = await postConnection(userid, token);
        sessionStorage.setItem("connectionId", newConnectionId);
        logger.log("New connection Id", newConnectionId);
        dispatch(setConnectionId(newConnectionId));
      } catch (e) {
        logger.error("Error saving connection", e);
        dispatch(addGlobalError(ErrorTypeEnum.ConnectionCantBeSaved));
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const saveConnection = useCallback(
    async (userid: string, token: string) => {
      const savedConnectionId = sessionStorage.getItem("connectionId");
      if (!savedConnectionId) {
        setIsLoading(true);
        !isLoading && (await getNewConnectionId(userid, token));
        return;
      }
      logger.log("Saved connection Id", savedConnectionId);
      dispatch(setConnectionId(savedConnectionId));
    },
    [dispatch, isLoading]
  );

  return { getNewConnectionId, isLoading, saveConnection };
};
