import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface TimeToExpireState {
  expirationTimeByContact: { contactId: string; timeToExpire: string }[];
}

const initialState: TimeToExpireState = {
  expirationTimeByContact: [],
};

export const timeToExpire = createSlice({
  name: "timeToExpire",
  initialState,
  reducers: {
    setExpirationTimer: (
      state: TimeToExpireState,
      action: PayloadAction<{ timeToExpire: string; contactId: string }>
    ) => {
      const { contactId } = action.payload;

      const indexOfContact = state.expirationTimeByContact.findIndex(
        (contact) => contact.contactId === contactId
      );

      if (indexOfContact === -1) {
        state.expirationTimeByContact.push(action.payload);
        return;
      }

      state.expirationTimeByContact[indexOfContact] = action.payload;
    },
    deleteExpirationTime: (
      state: TimeToExpireState,
      action: PayloadAction<{ contactId: string }>
    ) => {
      const { contactId } = action.payload;

      state.expirationTimeByContact = state.expirationTimeByContact.filter(
        (exp) => exp.contactId !== contactId
      );
    },
  },
});

export default timeToExpire.reducer;

export const { setExpirationTimer, deleteExpirationTime } =
  timeToExpire.actions;
