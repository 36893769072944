import React from "react";

interface Props {
  action?: () => any | void;
  tooltipText?: string;
  disabled?: boolean;
}

export const NotificationOn: React.FC<Props> = ({
  action,
  tooltipText,
  disabled = false,
}) => {
  return (
    <div className={`${tooltipText && "tooltip"}`}>
      <svg
        onClick={() => !disabled && action?.()}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`btn ${disabled && "disabled"}`}
      >
        <g clipPath="url(#clip0_2679_13625)">
          <path
            d="M5.68496 3.06L4.61246 1.9875C2.81246 3.36 1.62746 5.475 1.52246 7.875H3.02246C3.13496 5.8875 4.15496 4.1475 5.68496 3.06ZM14.9775 7.875H16.4775C16.365 5.475 15.18 3.36 13.3875 1.9875L12.3225 3.06C13.8375 4.1475 14.865 5.8875 14.9775 7.875ZM13.5 8.25C13.5 5.9475 12.27 4.02 10.125 3.51V3C10.125 2.3775 9.62246 1.875 8.99996 1.875C8.37746 1.875 7.87496 2.3775 7.87496 3V3.51C5.72246 4.02 4.49996 5.94 4.49996 8.25V12L2.99996 13.5V14.25H15V13.5L13.5 12V8.25ZM8.99996 16.5C9.10496 16.5 9.20246 16.4925 9.29996 16.47C9.78746 16.365 10.185 16.035 10.38 15.585C10.455 15.405 10.4925 15.21 10.4925 15H7.49246C7.49996 15.825 8.16746 16.5 8.99996 16.5Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_2679_13625">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {tooltipText && <span className="tooltiptext down">{tooltipText}</span>}
    </div>
  );
};
