import React, { ChangeEvent, useState, useEffect, FormEvent } from "react";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import {
  AddContact,
  UpdateContact,
  Contacts,
  ContactType,
} from "../../../../interfaces/contacts/contacts.interface";
import TimePicker from "react-time-picker";
import momentTz from "moment-timezone";
import { addZeroToHour } from "../../../../utils/addZeroToHour";
import moment from "moment";
import { nextEndShift } from "../../../../utils/nextEndShift";
import { newEndDateTimeWithTZ } from "../../../../utils/calcDateTime";
import { Alert } from "../Alert/Alert";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { getPatientById } from "../../../../helpers/patients";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { Inputs } from "../../../../interfaces/shared/shared.interface";
import { RootState } from "../../../../stores";
import {
  createContact,
  editContact,
} from "../../../../helpers/commandsContact";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import logger from "../../../../logger/logger";
import { ContactTypeEnum } from "../../../../enums/contactsEnum";
import { CloseIcon } from "../../../../imgs/CloseIcon";

interface Props {
  patientId: string;
  contactType: ContactType;
  contact?: Contacts;
  savedName: string;
  location: string;
  savedPhone: string;
  formName: Inputs;
  newContact: boolean;
  emptyInputs?: boolean;
  setNewContact: (arg: boolean) => any;
  onCancel: () => any;
  helperToAssignContact?: (args: AddContact | any) => Promise<any | void>;
  helperToUpdateContact?: (args: UpdateContact | any) => Promise<any | void>;
  helperToFetchPatient?: (id: string) => Promise<any | void>;
}

export const ContactForm: React.FC<Props> = ({
  patientId,
  contactType,
  contact,
  onCancel,
  location,
  formName,
  newContact,
  setNewContact,
  savedName,
  savedPhone,
  emptyInputs,
  helperToAssignContact = createContact,
  helperToUpdateContact = editContact,
  helperToFetchPatient = getPatientById,
}) => {
  const [values, setValues] = useState<AddContact>({
    patientId,
    Name: (!emptyInputs && contact?.Name) || savedName || "",
    phoneNumber: (!emptyInputs && contact?.phone) || savedPhone || "",
    endDateTime: moment
      .utc((!emptyInputs && contact?.endDateTime) || nextEndShift())
      .local()
      .format(),
    contactType: contact?.type || contactType,
  });
  const [hour, setHour] = useState(
    !emptyInputs && contact?.endDateTime
      ? addZeroToHour(contact.endDateTime) +
          moment.utc(contact.endDateTime).local().format("LT").split(" ")[0]
      : addZeroToHour("", true) +
          moment(nextEndShift()).format("LT").split(" ")[0]
  );
  const [ltTime, setLtTime] = useState(
    moment
      .utc((!emptyInputs && contact?.endDateTime) || nextEndShift())
      .local()
      .format("A")
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [changed, setChanged] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [errorType, setErrorType] = useState<
    ErrorTypeEnum.Retry | ErrorTypeEnum.ContactSaved
  >(ErrorTypeEnum.Retry);
  const dispatch = useDispatch();
  const { list: patients } = useSelector((state: RootState) => state.patients);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setValues({ ...values, [e.target.name]: e.target.value.trimStart() });
  };

  useEffect(() => {
    dispatch(
      setForm({
        typeInput: formName,
        status: true,
        name: values.Name,
        phone: values.phoneNumber,
      })
    );
  }, [values.Name, values.phoneNumber]);

  const handleChangeHour = (value: any) => {
    setChanged(true);
    setHour(value);
  };

  const handleChangeLT = (e: ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setLtTime(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (values.Name && values.phoneNumber && !loading) {
      setShowLabel(false);
      setLoading(true);

      let response = null;
      const capitalizedName = capitalizeString(values.Name);
      try {
        if ((emptyInputs && contact?.id) || contact?.id) {
          response = await helperToUpdateContact({
            ...values,
            Name: capitalizedName,
            phoneNumber: values.phoneNumber,
            id: contact.id,
          });
        } else {
          const savedPatient: Patient = await helperToFetchPatient(patientId);
          if (savedPatient.contacts?.find((con) => con.type === contactType)) {
            setLoading(false);
            setError(true);
            setErrorType(ErrorTypeEnum.ContactSaved);
            return;
          }

          response = await helperToAssignContact({
            ...values,
            Name: capitalizedName,
            phoneNumber: values.phoneNumber,
          });
        }
        setNewContact(true);
        logger.log(`Contact Command API response: ${response}`);
      } catch (e) {
        setLoading(false);
        setErrorType(ErrorTypeEnum.Retry);
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (newContact) {
      setTimeout(() => {
        dispatch(
          setForm({
            typeInput: formName,
            status: false,
          })
        );
      }, 500);
    }
  }, [newContact, patients, location]);

  useEffect(() => {
    setValues({
      ...values,
      endDateTime: newEndDateTimeWithTZ(hour, ltTime, contact?.endDateTime),
    });
  }, [hour, ltTime, contact?.endDateTime]);

  return (
    <div className="contact">
      <div className="row between align-center">
        <h2>Assign {ContactTypeEnum[contactType]}</h2>
        <CloseIcon action={onCancel} />
      </div>
      <form
        action=""
        id={`form-${contactType}`}
        onSubmit={handleSubmit}
        aria-label={`form-${contactType}`}
      >
        <div className="form-control">
          <label htmlFor={`Name`}>*Name</label>
          <input
            type="text"
            className={`${showLabel && !values.Name && "error"}`}
            name="Name"
            id="Name"
            value={values.Name}
            onInvalid={(e) => e.target as HTMLInputElement}
            onChange={handleChange}
            pattern="([A-ZÀ-ÿa-z][-,a-zA-ZÀ-ÿa-z. ']+[ ]*)+"
            title="Numbers are not allowed."
            maxLength={50}
            aria-label={`${contactType}-name-input`}
          />
          {showLabel && !values.Name && (
            <label htmlFor={`Name`} className="error-label">
              <InfoIcon /> Name is required
            </label>
          )}
        </div>
        <div className="form-control">
          <label htmlFor="phoneNumber">*Phone</label>
          <input
            type="text"
            className={`${showLabel && !values.phoneNumber && "error"}`}
            name="phoneNumber"
            id="phoneNumber"
            value={values.phoneNumber}
            onInvalid={(e) => e.target as HTMLInputElement}
            onChange={handleChange}
            maxLength={30}
            aria-label={`${contactType}-phone-input`}
          />
          <p className="helper-text">Phone number and extension</p>
          {showLabel && !values.phoneNumber && (
            <label htmlFor={`phoneNumber`} className="error-label">
              <InfoIcon /> Phone number is required
            </label>
          )}
        </div>
        {values.contactType !== "UnitDirector" && (
          <div className="form-control small">
            <label htmlFor="endDateTime">*Shift end time</label>
            <div className="row">
              <TimePicker
                className="time-picker"
                disableClock={true}
                required
                format={"HH:mm"}
                minTime="01:00"
                maxTime="12:59"
                name={`${contactType}-hour-input`}
                hourAriaLabel={`${contactType}-hour-input`}
                clearIcon={null}
                value={hour}
                onChange={handleChangeHour}
              />
              <div className="form-control halfWidth m-0 radio-option">
                <input
                  type="radio"
                  name="ltTime"
                  id="ltTime"
                  value={"AM"}
                  aria-label={`${contactType}-am-radio`}
                  required
                  onChange={handleChangeLT}
                  checked={ltTime === "AM"}
                />
                <label htmlFor="ltTime">am</label>
              </div>
              <div className="form-control halfWidth m-0 radio-option">
                <input
                  type="radio"
                  name="ltTime"
                  id="ltTime"
                  value={"PM"}
                  aria-label={`${contactType}-pm-radio`}
                  required
                  onChange={handleChangeLT}
                  checked={ltTime === "PM"}
                />
                <label htmlFor="ltTime">pm</label>
              </div>
            </div>
          </div>
        )}
        <div className="row btn-group left mt-3">
          <button
            className="btn btn-small transparent outlined"
            onClick={onCancel}
            type="reset"
          >
            CANCEL
          </button>
          <button
            className="btn btn-small light"
            aria-label="submit-btn"
            disabled={(contact?.Name && !changed) || loading}
            onClick={() => !loading && setShowLabel(true)}
          >
            SAVE
          </button>
        </div>
        {newContact && (
          <Alert
            text={`${ContactTypeEnum[contactType]} ${
              contact?.id ? "updated" : "saved"
            } successfully`}
            type={"success"}
            closeBtn={false}
          />
        )}
        {loading && !newContact && (
          <Alert text={"Submitting..."} type={"info"} closeBtn={false} />
        )}
        {error && (
          <ErrorMsg
            errorMsg={errorType}
            filled={false}
            closeBtn={false}
            padding={"none"}
            position={"inline"}
          />
        )}
      </form>
    </div>
  );
};
