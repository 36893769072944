import React from "react";

interface Props {
  action?: () => any | void;
  tooltipText?: string;
  disabled?: boolean;
  active?: boolean;
}

export const PlayAudio: React.FC<Props> = ({
  action,
  tooltipText,
  disabled = false,
  active = false,
}) => {
  return (
    <div className="tooltip">
      <svg
        onClick={() => !disabled && action?.()}
        aria-label="play-audio"
        className={`${disabled && "btn disabled"} ${active && "active"}`}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 2.66669C8.64002 2.66669 2.66669 8.64002 2.66669 16C2.66669 23.36 8.64002 29.3334 16 29.3334C23.36 29.3334 29.3334 23.36 29.3334 16C29.3334 8.64002 23.36 2.66669 16 2.66669ZM13.3334 22V10L21.3334 16L13.3334 22Z"
          fill="black"
        />
      </svg>
      {tooltipText && (
        <span className="tooltiptext" aria-label="play-audio-tooltip">
          {tooltipText}
        </span>
      )}
    </div>
  );
};
