import React from "react";
import { useSelector } from "react-redux";
import { Contacts } from "../../../../interfaces/contacts/contacts.interface";
import { RootState } from "../../../../stores";
import { cutString } from "../../../../utils/cutString";

interface Props {
  room: string;
  contactInformation?: Contacts;
  expanded?: boolean;
}
export const ContactInfoHeader: React.FC<Props> = ({
  room,
  contactInformation,
  expanded = false,
}) => {
  const { contactsByPatient } = useSelector(
    (state: RootState) => state.assignedContact
  );

  const isContactAssigned = contactsByPatient[room]?.assigned && contactInformation;

  if (isContactAssigned && !expanded) {
    return (
      <p>
        {cutString(contactInformation.Name)} {contactInformation.phone}
      </p>
    );
  }

  if (expanded) {
    return <p>Care team members</p>;
  }

  return (
    <div
      className="p-alert"
      aria-label="no-contact-alert"
    >
      <p>Care team member not assigned</p>
    </div>
  );
};
