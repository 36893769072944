import React, { useMemo } from "react";
import { ConnectionInfo } from "../../../../interfaces/connections/connections.interface";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { filterPatientsById } from "../../../../utils/filterPatientsByIds";
import { Nurse } from "../../../../imgs/Nurse";
import { AlarmIcon } from "../../../../imgs/AlarmIcon";
import { NoPeople } from "../../../../imgs/NoPeople";
import { MuteIcon } from "../../../../imgs/MuteIcon";
import { CamOffIcon } from "../../../../imgs/CamOffIcon";
import { IssueEnum } from "../../../../enums/issues";

interface Props {
  eSitter: ConnectionInfo;
}

export const ESitterIcons: React.FC<Props> = ({ eSitter }) => {
  const { list: patients } = useSelector((state: RootState) => state.patients);
  const { issuesList } = useSelector((state: RootState) => state.supervisors);
  const filteredPatients = useMemo(
    () => filterPatientsById(patients, eSitter.patientsAssigned),
    [eSitter, patients]
  );
  const filteredIssues = useMemo(
    () =>
      issuesList.filter((is) =>
        filteredPatients.find((pat) => pat.id === is.patientId)
      ),
    [issuesList, filteredPatients]
  );

  return (
    <div className="row">
      {capitalizeString(eSitter.userName)}
      <div className="icons-row" style={{ marginLeft: 8 }}>
        {eSitter.totalPatientsAssigned === 0 && (
          <div className="icon-red">
            <NoPeople
              tooltipText="Unmonitored Patients"
              tooltipPosition="top"
            />
          </div>
        )}
        {filteredIssues.some(
          (is) => is.issueType === IssueEnum.ContactNotAssigned
        ) && (
          <div
            className="icon-yellow"
            key={`esitter-issue-contact-not-assigned`}
          >
            <Nurse
              tooltipText="Care team member not assigned"
              tooltipPosition="top"
            />
          </div>
        )}

        {filteredIssues.some(
          (is) => is.issueType === IssueEnum.NetworkIssues
        ) && (
          <div className="icon-yellow" key={`esitter-issue-network`}>
            <AlarmIcon tooltipText="Network Issues" tooltipPosition="top" />
          </div>
        )}

        {filteredIssues.some((is) => is.issueType === IssueEnum.SpeakerOff) && (
          <div className="icon-red" key={`esitter-issue-speaker-off`}>
            <MuteIcon tooltipText="Speaker Offline" tooltipPosition="top" />
          </div>
        )}

        {filteredIssues.some((is) => is.issueType === IssueEnum.CameraOff) && (
          <div className="icon-red" key={`esitter-issue-camera-off`}>
            <CamOffIcon tooltipText="Camera Offline" tooltipPosition="top" />
          </div>
        )}
      </div>
    </div>
  );
};
