import React from "react";
import { Nurse } from "../../../../imgs/Nurse";
import { ContactInfoHeader } from "../ContactInfoHeader/ContactInfoHeader";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { ContactsList } from "../../molecules/ContactsList/ContactsList";

interface Props {
  patient: Patient;
  expanded: boolean;
  room: string;
}
export const ContactMenu: React.FC<Props> = ({
  patient,
  expanded = false,
  room,
}) => {
  if (!expanded) {
    return (
      <div className="contact-menu row" aria-label="plain-contact-menu">
        <Nurse />
        <ContactInfoHeader
          room={room}
          contactInformation={patient.contacts?.[0]}
        />
      </div>
    );
  }

  return (
    <div
      className={`contact-menu ${expanded ? "expanded" : "collapsed"}`}
      aria-label="expanded-contact-menu"
    >
      <div className="row">
        <Nurse />
        <ContactInfoHeader
          room={room}
          contactInformation={patient.contacts?.[0]}
          expanded={expanded}
        />
      </div>
      <ContactsList patient={patient} />
    </div>
  );
};
