import React from "react";
interface Props {
  action?: () => any | void;
  tooltipText?: string;
  tooltipPosition?: "right" | "down" | "left" | "top";
}

export const CamOffIcon: React.FC<Props> = ({
  action,
  tooltipText,
  tooltipPosition = "right",
}) => {
  return (
    <div className="tooltip">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2511_23103)">
          <path
            d="M12.25 3.79175L9.91663 6.12508V4.08341C9.91663 3.76258 9.65413 3.50008 9.33329 3.50008H5.72829L12.25 10.0217V3.79175ZM1.90746 1.16675L1.16663 1.90758L2.75913 3.50008H2.33329C2.01246 3.50008 1.74996 3.76258 1.74996 4.08341V9.91675C1.74996 10.2376 2.01246 10.5001 2.33329 10.5001H9.33329C9.45579 10.5001 9.56079 10.4534 9.64829 10.3951L11.5091 12.2501L12.25 11.5092L1.90746 1.16675Z"
            fill="#CF6679"
          />
        </g>
        <defs>
          <clipPath id="clip0_2511_23103">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {tooltipText && (
        <span className={`tooltiptext ${tooltipPosition}`}>{tooltipText}</span>
      )}
    </div>
  );
};
