import React, { useCallback, useEffect, useState } from "react";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { patientOperationUpdate } from "../../../../helpers/commandsPatient";
import { ArrowDownLogo } from "../../../../imgs/ArrowDownLogo";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { ErrorMsg } from "../../atoms/ErrorMsg/ErrorMsg";
import { getDeviceInfoByCart } from "../../../../helpers/patients";

interface Props {
  patient: Patient;
  onSubmit: () => void;
  onCancel: () => void;
}

export const ChangeCart: React.FC<Props> = ({
  patient,
  onCancel,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorTypeEnum>();
  const [step, setStep] = useState(0);
  const [newCartId, setNewCartId] = useState("");

  const handleChangeCart = useCallback(
    async (cartId: string) => {
      try {
        setLoading(true);
        const cart = `200-200-${cartId}`;
        await patientOperationUpdate(patient.id, {
          id: patient.id,
          cartId: cart,
        });
        onSubmit();
        onCancel();
      } catch (e: any) {
        setError(ErrorTypeEnum.Default);
      } finally {
        setLoading(false);
      }
    },
    [patient, patientOperationUpdate]
  );

  const handleOnChangeCart = useCallback((cartId: string) => {
    if (!cartId) {
      setError(undefined);
      return;
    }
    if (!new RegExp(/^[0-9a-zA-Z]+[^-]+$/gi).test(cartId)) {
      setError(ErrorTypeEnum.CartIdFormat);
      return;
    }
    setError(undefined);
  }, []);

  const handleValidateCartId = useCallback(
    async (cartId: string) => {
      try {
        const cart = `200-200-${cartId}`;
        await getDeviceInfoByCart(cart);
        handleChangeCart(cartId);
        setError(undefined);
      } catch (e: any) {
        setError(ErrorTypeEnum.DevicesInfoUnavailable);
      }
    },
    [handleChangeCart, getDeviceInfoByCart, setError, patient]
  );

  useEffect(() => {
    handleOnChangeCart(newCartId);
  }, [newCartId]);

  return (
    <div className="overlay dark-transparent">
      <div className="dialog">
        <div className="row align-center start">
          <p className="title-2">Change Cart</p>
          {step === 0 && (
            <>
              <ArrowDownLogo orientation="right" />
              <p className="subtitle">
                {patient.lastName}, {patient.firstName}
              </p>
            </>
          )}
        </div>
        <div className="close">
          <CloseIcon action={onCancel} />
        </div>
        {step === 0 ? (
          <div style={{ maxWidth: "380px", margin: "16px 0px 64px" }}>
            <p className="label">Add Cart ID (Example SENT002)</p>
            <input
              type="text"
              name="newCartId"
              id="newCartId"
              style={{
                textTransform: "capitalize",
                fontSize: "0.9rem",
                padding: 10,
                marginBottom: 5,
              }}
              value={newCartId}
              placeholder="Cart Id"
              onChange={(e) => setNewCartId(e.target.value)}
            />
          </div>
        ) : (
          <div style={{ margin: "64px 0" }}>
            <p className="text-big">
              Assign cart <span>{newCartId}</span> to {patient.firstName}{" "}
              {patient.lastName}?
            </p>
          </div>
        )}

        <div style={{ marginBottom: "1rem" }}>
          {error && (
            <ErrorMsg
              filled={false}
              errorMsg={error}
              position="inline"
              padding="none"
              closeBtn={false}
            />
          )}
        </div>
        <div className="row btn-group right reverse-order">
          <button
            className={`btn-small light`}
            aria-label="submit-btn"
            type="submit"
            disabled={!newCartId || loading || Boolean(error)}
            onClick={() =>
              step === 0
                ? newCartId && setStep(1)
                : newCartId && handleValidateCartId(newCartId)
            }
            style={
              !newCartId || loading || Boolean(error)
                ? { backgroundColor: "#8f8f8f" }
                : step === 1
                ? { backgroundColor: "#66c295" }
                : {}
            }
          >
            {loading ? (
              <div
                className="loader xsmall"
                style={{ width: 10, height: 10 }}
              ></div>
            ) : (
              <>CONTINUE</>
            )}
          </button>
          <button
            className="btn-small transparent outlined"
            type="button"
            onClick={onCancel}
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};
