import { MAX_STACK_LENGTH } from "../features/patients/patientsSlice";

export const getMaximumFrames = (patientsLength: number) => {
  if (!patientsLength) {
    return 0;
  }

  // if (patientsLength <= 2) {
  //   return 2;
  // }

  return MAX_STACK_LENGTH;
};
