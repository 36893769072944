import {
  AddContact,
  UpdateContact,
} from "../interfaces/contacts/contacts.interface";
import EndpointGenerator from "../utils/EndpointGenerator";
import { ContactCommandOperationsEnum } from "../enums/apisOperations";
import axiosHttpClient from "../utils/axiosHttpClient";
import { AxiosRequestConfig } from "axios";

export const createContact = async (
  obj: AddContact,
  config?: AxiosRequestConfig<{}>
) => {
  const endpoint = EndpointGenerator.generateContactCommandEndpoint(
    ContactCommandOperationsEnum.Create
  );
  const response = await axiosHttpClient.postData(endpoint, obj, config);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const editContact = async (obj: UpdateContact) => {
  const endpoint = EndpointGenerator.generateContactCommandEndpoint(
    ContactCommandOperationsEnum.Update
  );
  const response = await axiosHttpClient.patchData(endpoint, obj);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const removeContact = async (contactId: string) => {
  const endpoint = EndpointGenerator.generateContactCommandEndpoint(
    ContactCommandOperationsEnum.Remove,
    contactId
  );
  const response = await axiosHttpClient.deleteData(endpoint, contactId);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};
