import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import store, { RootState } from "../../stores";
import { useEffect } from "react";
import {
  isSessionActive,
  removeSession,
} from "../../features/session/sessionSlice";
import { LoadingEnum, UserTypes } from "../../enums/global";
import routes from "../../routes";

export const Login = () => {
  const {
    connectionId,
    error,
    loading,
    session: {
      sub: [, , userRole],
    },
  } = useSelector((state: RootState) => state.sessionInfo);

  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (loading) {
      case LoadingEnum.succeeded:
        if (userRole === UserTypes.Supervisor) {
          navigate(routes.supervisor.main);
          return;
        }

        navigate(routes.eSitter.main);
        break;
      case LoadingEnum.failed:
        setTimeout(() => {
          dispatch(removeSession());
        }, 2000);
        break;
      case LoadingEnum.idle:
        if (!connectionId) {
          const query = new URLSearchParams(search);
          const token = query.get("token");
          token && store.dispatch(isSessionActive({ token }));
        }
        break;
      default:
        break;
    }
  }, [loading, connectionId]);

  return (
    <div className="cams-container-col col-100" style={{ padding: 0 }}>
      <div className="cams-container-void row" style={{ height: "100vh" }}>
        {
          {
            [LoadingEnum.pending]: (
              <div className="loaderDiv">
                <div className="loader"></div>
              </div>
            ),
            [LoadingEnum.failed]: (
              <div className="info">
                <h3>{error.message}</h3>
              </div>
            ),
            [LoadingEnum.idle]: (
              <div className="info">
                <h3>Connection can't be stablished</h3>
              </div>
            ),
            [LoadingEnum.succeeded]: (
              <div className="info">
                <h3>Log in succesfully</h3>
              </div>
            ),
          }[loading]
        }
      </div>
    </div>
  );
};
