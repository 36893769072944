import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ErrorById } from "../../interfaces/shared/shared.interface";
import { ErrorTypeEnum } from "../../enums/errorType";
import { NotificationsEnum } from "../../enums/notifications";
export interface GlobalErrorsState {
  errorsById: ErrorById[];
  generalErrors: ErrorTypeEnum[];
  status: boolean;
  generalNotifications: NotificationsEnum[];
}

const initialState: GlobalErrorsState = {
  errorsById: [],
  generalErrors: [],
  status: false,
  generalNotifications: [],
};

export const globalErrorsSlice = createSlice({
  name: "globalErrors",
  initialState,
  reducers: {
    setErrorById: (
      state: GlobalErrorsState,
      action: PayloadAction<ErrorById>
    ) => {
      const { id, errorMsg } = action.payload;
      state.errorsById.push({ id, errorMsg });
    },
    deleteErrorById: (
      state: GlobalErrorsState,
      action: PayloadAction<string>
    ) => {
      state.errorsById = state.errorsById.filter(
        (errors) => errors.id !== action.payload
      );
    },
    addGlobalError: (
      state: GlobalErrorsState,
      action: PayloadAction<ErrorTypeEnum>
    ) => {
      if (!state.generalErrors.includes(action.payload)) {
        state.generalErrors = [...state.generalErrors, action.payload];
        state.status = true;
      }
    },
    removeGlobalError: (
      state: GlobalErrorsState,
      action: PayloadAction<ErrorTypeEnum>
    ) => {
      state.generalErrors = state.generalErrors.filter(
        (error) => error !== action.payload
      );
    },
    setStatusGlobalError: (
      state: GlobalErrorsState,
      action: PayloadAction<boolean>
    ) => {
      state.status = action.payload;
    },
    addNotifications: (
      state: GlobalErrorsState,
      action: PayloadAction<NotificationsEnum>
    ) => {
      if (!state.generalNotifications.includes(action.payload)) {
        state.generalNotifications = [
          ...state.generalNotifications,
          action.payload,
        ];
      }
    },
    removeNotifications: (
      state: GlobalErrorsState,
      action: PayloadAction<NotificationsEnum>
    ) => {
      if (!state.generalNotifications.includes(action.payload)) {
        return;
      }

      state.generalNotifications = state.generalNotifications.filter(
        (not) => not !== action.payload
      );
    },
  },
});

export default globalErrorsSlice.reducer;

export const {
  setErrorById,
  deleteErrorById,
  addGlobalError,
  removeGlobalError,
  setStatusGlobalError,
  addNotifications,
  removeNotifications,
} = globalErrorsSlice.actions;
