import { useEffect, useState } from "react";
import { Audio } from "../../../../imgs/Audio";
import { MuteIcon } from "../../../../imgs/MuteIcon";
import {
  Patient,
  PatientStatus,
} from "../../../../interfaces/patients/patients.interface";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { cutString } from "../../../../utils/cutString";
import { Check } from "../../../../imgs/Check";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { LoadingEnum } from "../../../../enums/global";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import logger from "../../../../logger/logger";
import { patientOperationUpdate } from "../../../../helpers/commandsPatient";
import { getPatientById } from "../../../../helpers/patients";
import { updatePatientByProp } from "../../../../features/patients/patientsSlice";

interface Props {
  isVideoLoading?: boolean;
  mutedStatus: boolean;
  patient: Patient;
  toogleMuted?: (arg: boolean) => void;
  defaultStatus?: PatientStatus;
  shouldCloseMenu?: boolean;
}

export const PatientName: React.FC<Props> = ({
  isVideoLoading,
  patient,
  mutedStatus,
  toogleMuted,
  defaultStatus,
  shouldCloseMenu,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { patientStatusList, loading: statusListLoading } = useSelector(
    (state: RootState) => state.patients
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (shouldCloseMenu) {
      setIsMenuOpen(false);
    }
  }, [shouldCloseMenu]);

  const handleStatusChange = async (statusId: string) => {
    try {
      setLoading(true);
      await patientOperationUpdate(patient.id, {
        id: patient.id,
        patientStatus: statusId,
      });
      const data: Patient = await getPatientById(patient.id);
      dispatch(
        updatePatientByProp({
          data,
          prop: "patientStatus",
        })
      );
    } catch (e: any) {
      logger.error(e);
      setError(true);
    } finally {
      setLoading(false);
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const INTERVAL_TO_CLOSE_ERROR = 5000;

    if (error) {
      setTimeout(() => {
        setError(true);
      }, INTERVAL_TO_CLOSE_ERROR);
    }
  }, [error]);

  const Mute = () => {
    if (isVideoLoading) {
      return <MuteIcon disabled={true} tooltipText="Unmute not available" />;
    }

    if (mutedStatus) {
      return <MuteIcon action={() => toogleMuted?.(false)} />;
    }

    return <Audio action={() => toogleMuted?.(true)} />;
  };

  const PatientName = () => {
    return (
      <h3 className="line-clamp one">
        {` ${cutString(capitalizeString(patient.lastName))}, ${cutString(
          capitalizeString(patient.firstName)
        )}`}
      </h3>
    );
  };

  const PatientStatusMenu = () => {
    if (error) {
      return (
        <ErrorMsg
          errorMsg={ErrorTypeEnum.Retry}
          filled={false}
          closeBtn={false}
          padding={"none"}
          position={"inline"}
        />
      );
    }

    switch (statusListLoading) {
      case LoadingEnum.succeeded:
        if (patientStatusList.length) {
          return (
            <>
              {patientStatusList.map((status) => {
                const isSelected = status.id === patient.patientStatus;
                return (
                  <div
                    className="menu"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusChange(status.id);
                    }}
                    key={`menu-${status.id}`}
                    aria-label={`menu-${status.id}-${isSelected}`}
                  >
                    <p>{status.value}</p>
                    {isSelected && <Check isActive={true} />}
                  </div>
                );
              })}
            </>
          );
        }

        return (
          <ErrorMsg
            errorMsg={ErrorTypeEnum.EmptyStatusList}
            filled={false}
            closeBtn={false}
            padding={"none"}
            position={"inline"}
          />
        );
      case LoadingEnum.pending:
        return (
          <div className="menu">
            <div className="loader small" aria-label="loader"></div>
          </div>
        );
      case LoadingEnum.failed:
        return (
          <ErrorMsg
            errorMsg={ErrorTypeEnum.PatientStatusAPI}
            filled={false}
            closeBtn={false}
            padding={"none"}
            position={"inline"}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`patient-name ${!shouldCloseMenu && "active"} ${
        isMenuOpen ? "expanded" : "collapsed"
      }`}
      onClick={() => !shouldCloseMenu && setIsMenuOpen(!isMenuOpen)}
      aria-label="patient-name"
    >
      <div
        className="dropdown-overlay"
        onClick={() => setIsMenuOpen(false)}
      ></div>
      <Mute />
      <PatientName />
      {patient.patientStatus === defaultStatus?.id && (
        <div className="circle green small" aria-label="circle-green"></div>
      )}
      <div
        className={`dropdown-menu expansible-menu wide ${loading && "loading"}`}
        style={{ right: 13 }}
        aria-label="patient-name-dropdown"
      >
        <PatientStatusMenu />
      </div>
    </div>
  );
};
