import io, { Socket } from "socket.io-client";
import { ErrorTypeEnum } from "../enums/errorType";
import { ESitterEventsEnum } from "../enums/eSitterEvents";
import logger from "../logger/logger";
export class CustomSocket {
  socket: Socket | undefined;
  path: string;
  id: string;
  errorCallback: (arg: any) => any;
  constructor(
    url: string,
    path: string,
    onConnection: (arg: any) => any,
    errorCallback: (arg: ErrorTypeEnum) => void,
    errorConnectionName: ErrorTypeEnum
  ) {
    this.id = "";

    if (!url || url === "") throw new Error("EmptyURL");
    this.path = path;

    this.socket = io(url, {
      path,
      rememberUpgrade: process.env.NODE_ENV === "production",
    });

    if (!errorCallback) {
      errorCallback = (err) => {
        logger.error(`Error on socket`, err);
      };
    }

    this.errorCallback = errorCallback;

    if (!onConnection) {
      onConnection = (param) => {
        logger.log(param);
      };
    }

    this.socket.on("connect", () => {
      logger.log(`Connected to socket: ${url} - ${path} - ${this.socket?.id}`);
      onConnection(this.socket);
      if (this.socket?.id) {
        this.id = this.socket.id;
      }
    });

    this.socket.on("connect_error", (err: any) => {
      errorCallback(errorConnectionName);
    });

    this.socket.on("error", (e) => {
      errorCallback(errorConnectionName);
    });
  }

  suscribe(event: ESitterEventsEnum, callback: (message: any) => any) {
    logger.log(`Subscribed to ${ESitterEventsEnum[event]}`);

    if (!callback) {
      callback = (message) => {
        logger.log(`Message received`, message);
      };
    }
    this.socket?.on(ESitterEventsEnum[event], callback);
  }

  emit(event: string, data: any) {
    this.socket?.emit(event, data);
  }

  disconnect() {
    if (this.socket?.connected) this.socket.disconnect();
    logger.log(`Socket for path ${this.path} disconnected`);
  }
}
