import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPatient } from "../../../../features/selectedPatient/selectedPatient";
import { Badge } from "../Badge/Badge";
import { PatientInfoBody } from "../PatientInfoBody/PatientInfoBody";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { RootState } from "../../../../stores";
import { RiskValue } from "../../../../interfaces/fallRisk/fallRisk.interface";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { cutString } from "../../../../utils/cutString";
import { PinIcon } from "../../../../imgs/PinIcon";
interface Props {
  patient: Patient;
}

export const PatientMenu: React.FC<Props> = ({ patient }) => {
  const dispatch = useDispatch();
  const { riskById } = useSelector((state: RootState) => state.fallRisk);
  const { patientId } = useSelector(
    (state: RootState) => state.selectedPatient
  );
  const { pinnedPatientsStack } = useSelector(
    (state: RootState) => state.patients
  );
  const container = useRef<HTMLDivElement | null>(null);

  const handleChange = () => {
    dispatch(setSelectedPatient(patient.id));
  };

  useEffect(() => {
    if (patientId === patient.id) {
      container.current?.scrollIntoView();
    }
  }, [patientId]);

  return (
    <div
      ref={container}
      className={`patient-menu ${patientId === patient.id && "active"}`}
      id={`patient-menu-${patient.id}`}
      aria-label="patient-menu"
      onClick={handleChange}
    >
      <div className="header" aria-label="patient-menu-header">
        <div className="title">
          <p>
            {`${cutString(capitalizeString(patient.lastName))}, ${cutString(
              capitalizeString(patient.firstName)
            )}`}
          </p>
          {pinnedPatientsStack.includes(patient.id) && <PinIcon />}
          <Badge riskFall={riskById?.[patient.id] as RiskValue} />
        </div>
      </div>

      <PatientInfoBody patient={patient} />
    </div>
  );
};
