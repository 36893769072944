import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePatients } from "../../hooks/usePatients";
import store, { RootState } from "../../stores";
import {
  getPatientStatus,
  setAllPatients,
} from "../../features/patients/patientsSlice";
import { ErrorTypeEnum } from "../../enums/errorType";
import { addGlobalError } from "../../features/globalErrors/globalErrorsSlice";
import logger from "../../logger/logger";
import { LoadingEnum, UserTypes } from "../../enums/global";
import { StatusBar } from "../UI/organisms/StatusBar/StatusBar";
import {
  getActivePeopleList,
  getConnectionsList,
  getContactsByLocationList,
  getLocationList,
  getRemovedPatientList,
  getWorkloadInfo,
} from "../../features/supervisors/supervisorsSlice";
import { useSupervisorSocket } from "../../hooks/useSupervisorSocket";
import {
  getSavedSession,
  removeSession,
} from "../../features/session/sessionSlice";
import { Outlet, useLocation } from "react-router-dom";
import { Breadcrumb } from "../UI/atoms/Breadcrumb/Breadcrumb";
import routes from "../../routes";
import { LogoutConnections } from "../UI/atoms/LogoutConnections/LogoutConnections";

const INCLUDES_INACTIVE = true;
const CONNECT_TO_SOCKET = true;

export const Supervisor = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    error: errorSession,
    session: {
      sub: [username],
    },
    loading: sessionLoading,
  } = useSelector((state: RootState) => state.sessionInfo);
  const { getFilteredPatients, addDevicesInfoToPatients } = usePatients();
  const { list: patients, loading: statusLoading } = useSelector(
    (state: RootState) => state.patients
  );
  const {
    loading: supervisorLoading,
    list: eSittersList,
    locations,
  } = useSelector((state: RootState) => state.supervisors);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getSavedSession(UserTypes.Supervisor));
  }, [username]);

  const fetchPatients = async () => {
    try {
      const newPatients = await getFilteredPatients();
      const patientsWithDevice = await addDevicesInfoToPatients(newPatients);
      dispatch(setAllPatients(patientsWithDevice));
    } catch (e) {
      logger.error("Error on first fetch", e);
      dispatch(addGlobalError(ErrorTypeEnum.FetchFailed));
    } finally {
      setLoading(false);
    }
  };

  const updateConnectionsAndPatients = () => {
    fetchPatients();
    store.dispatch(getConnectionsList(INCLUDES_INACTIVE));
    store.dispatch(getActivePeopleList());
  };


  const { errorSocket } = useSupervisorSocket(
    CONNECT_TO_SOCKET,
    updateConnectionsAndPatients
  );

  useEffect(() => {
    if (locations.length > 0) {
      locations.forEach(({ id }) =>
        store.dispatch(getContactsByLocationList(id))
      );
    }
  }, [locations]);

  useEffect(() => {
    switch (sessionLoading) {
      case LoadingEnum.succeeded:
        store.dispatch(getPatientStatus());
        store.dispatch(getLocationList());
        store.dispatch(getRemovedPatientList());
        updateConnectionsAndPatients();
        break;
      case LoadingEnum.failed:
        dispatch(removeSession());
        break;
      default:
        break;
    }
  }, [sessionLoading]);

  useEffect(() => {
    if (
      supervisorLoading === LoadingEnum.failed ||
      statusLoading === LoadingEnum.failed ||
      errorSocket.find((err) => err.status) ||
      errorSession.message
    ) {
      dispatch(addGlobalError(ErrorTypeEnum.FetchFailed));
    }
  }, [supervisorLoading, statusLoading, errorSocket, errorSession]);

  useEffect(() => {
    eSittersList.forEach((eS) => {
      if (eS.workload) {
        return;
      }
      store.dispatch(getWorkloadInfo(eS.id));
    });
  }, [eSittersList]);

  return (
    <>
      <div className="row col-100 column">
        <StatusBar
          patientsTotal={patients.length}
          username={username}
          userType={UserTypes.Supervisor}
        />
        <Breadcrumb
          pathname={pathname}
          mainRoute={routes.supervisor.main}
          idSearch={
            pathname.split("/").includes(routes.supervisor.nested[0].nested[0].main)
              ? {
                  list: locations,
                  idProp: "id",
                  labelProp: "name",
                }
              : {
                  list: eSittersList,
                  idProp: "id",
                  labelProp: "userName",
                }
          }
        />
        {loading ||
        supervisorLoading === LoadingEnum.pending ||
        statusLoading === LoadingEnum.pending ? (
          <div className="container">
            <h2>E-sitters</h2>
            <div className="loader" aria-label="loader"></div>
          </div>
        ) : (
          <div className="containerLogout">
            <Outlet />
            <LogoutConnections connectionsList={eSittersList} /> 
          </div>
        )}
      </div>
    </>
  );
};
