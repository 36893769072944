import moment from "moment";
import momentTz from "moment-timezone";
import { nextEndShift } from "./nextEndShift";

export const addZeroToHour = (
  utcDate: string = "",
  withTimeZone: boolean = true
) => {
  if(!utcDate){
    return ""
  }

  const currTZ = momentTz.tz.guess();
  let hour, datetimeTz;
  utcDate = !utcDate.endsWith("Z") ? `${utcDate}Z` : utcDate;

  if (withTimeZone) {
    datetimeTz = momentTz
      .utc(utcDate || nextEndShift())
      .tz(currTZ);
  } else {
    datetimeTz = moment(utcDate || nextEndShift()).utc();
  }

  hour = Number(datetimeTz.format("LT").split(" ")[0].split(":")[0]);

  if (hour < 10) {
    return "0";
  }
  return "";
};
