const restartPause = 10000;

const unquoteCredential = (v: string) => JSON.parse(`"${v}"`);

const linkToIceServers = (links: string): any[] => {
  return links !== null
    ? links.split(", ").map((link) => {
        const m = link.match(
          /^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i
        );
        if (!m) {
          return {
            urls: [],
            username: "",
            credential: "",
            credentialType: "password",
          };
        }
        const ret = {
          urls: [m[1]],
          username: "",
          credential: "",
          credentialType: "",
        };

        if (m[3] !== undefined) {
          ret.username = unquoteCredential(m[3]);
          ret.credential = unquoteCredential(m[4]);
          ret.credentialType = "password";
        }

        return ret;
      })
    : [];
};

const parseOffer = (offer?: string) => {
  const ret: { iceUfrag: string; icePwd: string; medias: any[] } = {
    iceUfrag: "",
    icePwd: "",
    medias: [],
  };

  if (!offer) {
    return ret;
  }

  for (const line of offer.split("\r\n")) {
    if (line.startsWith("m=")) {
      ret.medias.push(line.slice("m=".length));
    } else if (ret.iceUfrag === "" && line.startsWith("a=ice-ufrag:")) {
      ret.iceUfrag = line.slice("a=ice-ufrag:".length);
    } else if (ret.icePwd === "" && line.startsWith("a=ice-pwd:")) {
      ret.icePwd = line.slice("a=ice-pwd:".length);
    }
  }

  return ret;
};

const generateSdpFragment = (
  offerData:
    | {
        iceUfrag: string;
        icePwd: string;
        medias: any[];
      }
    | undefined,
  candidates: RTCIceCandidate[]
) => {
  const candidatesByMedia: { [key in number]: any[] } = {};
  for (const candidate of candidates) {
    const mid = candidate.sdpMLineIndex;
    if (mid === null) {
      return;
    }
    if (candidatesByMedia[mid] === undefined) {
      candidatesByMedia[mid] = [];
    }
    candidatesByMedia[mid].push(candidate);
  }

  let frag =
    "a=ice-ufrag:" +
    offerData?.iceUfrag +
    "\r\n" +
    "a=ice-pwd:" +
    offerData?.icePwd +
    "\r\n";

  let mid = 0;

  if (!offerData) {
    return;
  }

  for (const media of offerData.medias) {
    if (candidatesByMedia[mid] !== undefined) {
      frag += "m=" + media + "\r\n" + "a=mid:" + mid + "\r\n";

      for (const candidate of candidatesByMedia[mid]) {
        frag += "a=" + candidate.candidate + "\r\n";
      }
    }
    mid++;
  }

  return frag;
};

export class WHEPClient {
  pc: RTCPeerConnection | undefined;
  restartTimeout: number | undefined;
  eTag: string;
  location: string;
  queuedCandidates: any[];
  url: string;
  onNewStream: (arg: any) => void;
  onReady: () => void;
  onError: (arg: any) => void;
  offerData: { iceUfrag: string; icePwd: string; medias: any[] } | undefined;
  constructor(
    url: string,
    onNewStream: (arg: any) => void,
    onReady: () => void,
    onError: () => void
  ) {
    this.url = url;
    this.restartTimeout = undefined;
    this.eTag = "";
    this.location = "";
    this.queuedCandidates = [];
    this.start();
    this.onNewStream = onNewStream;
    this.onReady = onReady;
    this.onError = onError;
  }

  start() {
    fetch(this.url + "/whep", {
      method: "OPTIONS",
    })
      .then((res) => {
        this.onIceServers(
          res.headers.get("Link") ||
            `<stun:stun.l.google.com:19302>; rel="ice-server"`
        );
      })
      .catch((err) => {
        console.log("error: " + err);
        this.scheduleRestart();
      });
  }

  onIceServers(link: string) {
    const iceServers = linkToIceServers(link);
    this.pc = new RTCPeerConnection({
      iceServers,
    });

    const direction = "sendrecv";
    this.pc.addTransceiver("video", { direction });
    this.pc.addTransceiver("audio", { direction });

    this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt);
    this.pc.oniceconnectionstatechange = (ev: Event) =>
      this.onConnectionState(ev);

    this.pc.ontrack = (evt: RTCTrackEvent) => {
      this.onNewStream(evt.streams[0]);
    };

    this.pc.createOffer().then((offer) => this.onLocalOffer(offer));
  }

  onLocalOffer(offer: RTCSessionDescriptionInit) {
    if (!this.pc) {
      return;
    }

    this.offerData = parseOffer(offer.sdp);
    this.pc.setLocalDescription(offer);
    fetch(this.url + "/whep", {
      method: "POST",
      headers: {
        "Content-Type": "application/sdp",
      },
      body: offer.sdp,
    })
      .then((res) => {
        if (res.status !== 201) {
          throw new Error("bad status code");
        }
        this.eTag = res.headers.get("E-Tag") || "";
        this.location = res.headers.get("Location") || "";
        return res.text();
      })
      .then((sdp) => {
        // console.log(`SDP list: \n ${JSON.stringify(sdp)}`);
        this.onRemoteAnswer(
          new RTCSessionDescription({
            type: "answer",
            sdp,
          })
        );
      })
      .catch((err) => {
        console.log("error: " + err);
        this.scheduleRestart();
      });
  }

  onConnectionState(ev: Event) {
    if (this.restartTimeout || !this.pc) {
      return;
    }

    console.log("peer connection state:", this.pc.iceConnectionState);

    switch (this.pc.iceConnectionState) {
      case "connected":
        setTimeout(() => {
          this.onReady();
        }, 2000);
        break;
      case "disconnected":
        this.scheduleRestart();
    }
  }

  async onRemoteAnswer(answer: RTCSessionDescription) {
    if (this.restartTimeout || !this.pc) {
      return;
    }

    await this.pc.setRemoteDescription(new RTCSessionDescription(answer));

    if (this.queuedCandidates.length !== 0) {
      this.sendLocalCandidates(this.queuedCandidates);
      this.queuedCandidates = [];
    }
  }

  onLocalCandidate(evt: RTCPeerConnectionIceEvent) {
    if (this.restartTimeout) {
      return;
    }

    if (!evt.candidate) {
      return;
    }

    if (this.eTag === "") {
      this.queuedCandidates.push(evt.candidate);
    } else {
      this.sendLocalCandidates([evt.candidate]);
    }
  }

  sendLocalCandidates(candidates: RTCIceCandidate[]) {
    const body = generateSdpFragment(this.offerData, candidates);
    fetch(this.url + "/" + this.location, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/trickle-ice-sdpfrag",
        "If-Match": this.eTag,
      },
      body,
    })
      .then((res) => {
        if (res.status !== 204) {
          throw new Error("bad status code");
        }
      })
      .catch((err) => {
        console.log("error: " + err);
        this.scheduleRestart();
      });
  }

  scheduleRestart() {
    if (this.restartTimeout) {
      return;
    }

    if (this.pc !== undefined) {
      this.pc?.close();
      this.pc = undefined;
    }

    this.restartTimeout = window.setTimeout(() => {
      this.restartTimeout = undefined;
      this.start();
    }, restartPause);

    this.eTag = "";
    this.queuedCandidates = [];
  }
}
