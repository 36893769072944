import {
  UpdatePatient,
  Patient,
  PatientStatus,
} from "../../interfaces/patients/patients.interface";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { sortContacts } from "../../utils/sortContacts";
import logger from "../../logger/logger";
import { getPatientStatusInfo } from "../../helpers/patients";
import { LoadingEnum, OrderEnum } from "../../enums/global";
import { sortPatientsByProp } from "../../utils/sortPatients";
import { env } from "../../env";

export const MAX_STACK_LENGTH = 3;

export const MAX_PATIENTS = env.REACT_APP_MAX_PATIENTS || 60;

export interface PatientsState {
  list: Patient[];
  addedPatients: string[];
  patientStatusList: PatientStatus[];
  defaultStatus?: PatientStatus;
  privacyStatus?: PatientStatus;
  pinnedPatientsStack: string[];
  loading: LoadingEnum;
}

export const getPatientStatus = createAsyncThunk(
  "patientStatus/get",
  async () => {
    try {
      const statusList = await getPatientStatusInfo();
      return statusList;
    } catch (e) {
      throw e;
    }
  }
);

const initialState: PatientsState = {
  list: [],
  addedPatients: [],
  patientStatusList: [],
  pinnedPatientsStack: [],
  loading: LoadingEnum.idle,
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setAllPatients: (
      state: PatientsState,
      action: PayloadAction<Patient[]>
    ) => {
      const activePatients = action.payload.filter((pat) => pat.active);
      const orderedPatients = sortPatientsByProp(
        activePatients,
        "roomNumber",
        OrderEnum.asc
      );

      state.list = orderedPatients.map((patient) => ({
        ...patient,
        contacts: sortContacts(patient.contacts || []),
      }));
    },
    setNewPatient: (state: PatientsState, action: PayloadAction<Patient>) => {
      //Verify if patient is not already saved
      if (
        !state.list.some((patient) => patient.id === action.payload.id) &&
        action.payload.active
      ) {
        const patient = action.payload;

        const prevPatientList = [
          ...state.list,
          { ...patient, contacts: sortContacts(patient.contacts || []) },
        ];

        state.list = sortPatientsByProp(
          prevPatientList,
          "roomNumber",
          OrderEnum.asc
        );
      }
    },
    updatePatient: (
      state: PatientsState,
      action: PayloadAction<UpdatePatient>
    ) => {
      const indexOfUpdated = state.list.findIndex(
        (patient) => patient.id === action.payload.id
      );
      if (indexOfUpdated === -1) {
        return;
      }
      const contacts = action.payload.contacts
        ? sortContacts(action.payload.contacts)
        : state.list[indexOfUpdated].contacts;

      state.list[indexOfUpdated] = {
        ...state.list[indexOfUpdated],
        ...action.payload,
        contacts,
      };
    },
    updatePatientByProp: (
      state: PatientsState,
      action: PayloadAction<{ data: UpdatePatient; prop: keyof Patient }>
    ) => {
      const { data, prop } = action.payload;
      const indexOfUpdated = state.list.findIndex(
        (patient) => patient.id === data.id
      );
      if (indexOfUpdated === -1) {
        return;
      }
      if (prop === "contacts" && data.contacts) {
        state.list[indexOfUpdated].contacts = sortContacts(data.contacts);
        return;
      }

      if (typeof data[prop] === typeof state.list[indexOfUpdated][prop]) {
        state.list[indexOfUpdated][prop] = data[prop] as never;
      }
    },
    updatePatientsActivity: (
      state: PatientsState,
      action: PayloadAction<UpdatePatient>
    ) => {
      const {
        id,
        activity,
        callsToNurse,
        audioInterventionReproduced,
        audioReproduced,
        lastActivity,
      } = action.payload;
      const indexOfUpdated = state.list.findIndex(
        (patient) => patient.id === id
      );

      if (activity?.length === state.list[indexOfUpdated].activity?.length) {
        logger.log(`Activies for patient ${id} are updated.`);
        return;
      }
      logger.log(
        `Activites length for patient ${id} are not the same: incoming (${activity?.length}) - saved (${state.list[indexOfUpdated].activity?.length})`
      );
      state.list[indexOfUpdated].activity = activity;
      state.list[indexOfUpdated].callsToNurse = callsToNurse;
      state.list[indexOfUpdated].audioInterventionReproduced =
        audioInterventionReproduced;
      state.list[indexOfUpdated].audioReproduced = audioReproduced;
      state.list[indexOfUpdated].lastActivity = lastActivity;
    },
    deletePatient: (state: PatientsState, action: PayloadAction<string>) => {
      const id = action.payload;
      state.list = state.list.filter((patient) => patient.id !== id);

      if (state.pinnedPatientsStack.includes(id)) {
        state.pinnedPatientsStack = state.pinnedPatientsStack.filter(
          (patId) => patId !== id
        );
      }
    },
    deleteContact: (
      state: PatientsState,
      action: PayloadAction<{ patientId: string; id: string }>
    ) => {
      const { patientId, id } = action.payload;
      const indexOfUpdated = state.list.findIndex(
        (patient) => patient.id === patientId
      );
      const filteredContacts = state.list[indexOfUpdated].contacts?.filter(
        (contact) => contact.id !== id
      );

      state.list[indexOfUpdated] = {
        ...state.list[indexOfUpdated],
        contacts: sortContacts(filteredContacts || []),
      };
    },
    setAddedPatient: (state: PatientsState, action: PayloadAction<string>) => {
      const id = action.payload;
      if (!state.addedPatients.some((patientId) => patientId === id)) {
        state.addedPatients = [...state.addedPatients, id];
      }
    },
    removeAddedPatient: (
      state: PatientsState,
      action: PayloadAction<string>
    ) => {
      const id = action.payload;
      if (state.addedPatients.some((patientId) => patientId === id)) {
        state.addedPatients = state.addedPatients.filter(
          (patientId) => patientId !== id
        );
      }
    },
    addPinnedPatient: (state: PatientsState, action: PayloadAction<string>) => {
      const id = action.payload;

      state.pinnedPatientsStack.unshift(id);

      if (state.pinnedPatientsStack.length > MAX_STACK_LENGTH) {
        const removedId = state.pinnedPatientsStack.pop();
        logger.log(`Removed id ${removedId}`);
      }
    },
    removePinnedPatient: (
      state: PatientsState,
      action: PayloadAction<string>
    ) => {
      const id = action.payload;

      state.pinnedPatientsStack = state.pinnedPatientsStack.filter(
        (savedId) => savedId !== id
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPatientStatus.fulfilled,
      (state: PatientsState, action: { payload: PatientStatus[] }) => {
        state.patientStatusList = action.payload;
        state.defaultStatus = action.payload.find((status) => status.isDefault);
        state.privacyStatus = action.payload.find((status) =>
          status.value.match(/privacy/gi)
        );
        state.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getPatientStatus.pending, (state: PatientsState) => {
      state.loading = LoadingEnum.pending;
    });
    builder.addCase(getPatientStatus.rejected, (state: PatientsState) => {
      state.patientStatusList = [];
      state.loading = LoadingEnum.failed;
    });
  },
});

export default patientsSlice.reducer;

export const {
  setAllPatients,
  setNewPatient,
  updatePatient,
  updatePatientByProp,
  updatePatientsActivity,
  deleteContact,
  deletePatient,
  setAddedPatient,
  removeAddedPatient,
  addPinnedPatient,
  removePinnedPatient,
} = patientsSlice.actions;
