import { useNavigate, useParams } from "react-router-dom";
import { Arrow } from "../../../../imgs/Arrow";
import routes from "../../../../routes";
import { TableMetada } from "../../../../interfaces/shared/shared.interface";
import { ActivePeopleByHospital } from "../../../../interfaces/patients/patients.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";

const metadata: TableMetada = {
  columns: [
    {
      title: "Hospital Name",
      compute: (activePeople: ActivePeopleByHospital) =>
        activePeople.hospitalName,
    },
    {
      title: "N° patients",
      compute: (activePeople: ActivePeopleByHospital) => activePeople.patients,
    },
    {
      title: "Care Team members",
      compute: (activePeople: ActivePeopleByHospital) =>
        activePeople.careTeamMembers,
    },
  ],
};

export const SuperContactsList = () => {
  const navigate = useNavigate();
  const { activePeople } = useSelector((state: RootState) => state.supervisors);

  return (
    <>
      <div className="row align-center" style={{ marginBottom: "40px" }}>
        <Arrow onClick={() => navigate(routes.supervisor.main)} />
        <h2 style={{ marginLeft: "20px" }}>Manage Contacts</h2>
      </div>
      {!activePeople || activePeople.length === 0 ? (
        <div className="info">
          <h3>No patients assigned to any hospital yet.</h3>
        </div>
      ) : (
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {metadata.columns.map((column) => (
                  <th
                    key={`th-label-${column.title}`}
                    className={column.className}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="interactive">
              {activePeople.map((activePerson) => (
                <tr
                  key={`tr-${activePerson.locationId}`}
                  onClick={() =>
                    navigate(
                      `${routes.supervisor.main}/${routes.supervisor.nested[0].nested[0].main}/${activePerson.locationId}`
                    )
                  }
                >
                  {metadata.columns.map((column) => (
                    <td
                      key={`td-${activePerson.locationId}-${column.title}`}
                      className={`${column.className} ${
                        column.title === "Status" &&
                        `pill ${column
                          .compute(activePerson)
                          ?.toString()
                          .toLocaleLowerCase()}`
                      }`}
                    >
                      <p>{column.compute(activePerson)}</p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
