export const addZeroToTimeUnit = (unit: number) => {
  return unit.toString().padStart(2,'0');
};

export const buildTime = (seconds: number, minutes: number, hours?: number) => {
  if (!hours) {
    return `${minutes}:${addZeroToTimeUnit(seconds)}`;
  }

  return `${hours}:${addZeroToTimeUnit(minutes)}:${addZeroToTimeUnit(seconds)}`;
};
