import { env } from "../env";
import { ErrorTypeEnum } from "../enums/errorType";
import { ESitterEventsEnum } from "../enums/eSitterEvents";
import { CustomSocket } from "../utils/customSocket";
import logger from "../logger/logger";

let socket: CustomSocket;

const eventsFromApi: ESitterEventsEnum[] = [
  ESitterEventsEnum.PatientUpdatedEvent,
  ESitterEventsEnum.ActivityAddedEvent,
  ESitterEventsEnum.PatientRemovedEvent,
  ESitterEventsEnum.AdmissionAddedEvent,
  ESitterEventsEnum.ContactExpiredEvent,
  ESitterEventsEnum.ContactRemovedEvent,
  ESitterEventsEnum.ContactAboutToExpireEvent,
  ESitterEventsEnum.EscalationPathStartedEvent,
  ESitterEventsEnum.EscalationPathCompletedEvent,
  ESitterEventsEnum.LoadBalanceCompletedEvent,
  ESitterEventsEnum.BreakStartedEvent,
  ESitterEventsEnum.BreakEndedEvent,
];

export const initiateESitterSocket = (
  errorCallback: (arg: ErrorTypeEnum) => any,
  onConnection: () => any,
  onNewMessageCallback: (eventName: ESitterEventsEnum, message: any) => any
) => {
  if (!socket) {
    socket = new CustomSocket(
      env.REACT_APP_ESITTER_SOCKET,
      "/esitter-socket/",
      () => {
        onConnection?.();
        subscribeToESitterEvents(onNewMessageCallback);
      },
      errorCallback,
      ErrorTypeEnum.ESitterSocket
    );
  }
};

export const disconnectESitterSocket = () => {
  if (!socket) return;
  socket.disconnect();
};

export const subscribeToESitterEvents = (
  onNewMessageCallback: (event: ESitterEventsEnum, message: any) => void
) => {
  if (!socket) return false;

  eventsFromApi.forEach((eventName) => {
    socket.suscribe(eventName, (message: any) => {
      onNewMessageCallback?.(eventName, message);
    });
  });
};

export const sendESitterConnection = (connectionId: string) => {
  if (socket.socket?.connected && socket.id) {
    logger.log(
      `Emitting ESitterConnected event to save esitter connection ${socket.id}-${connectionId}`
    );
    socket.emit(ESitterEventsEnum[ESitterEventsEnum.ESitterConnected], {
      socketId: socket.id,
      connectionId,
    });
  }
};
