import { useDispatch } from "react-redux";
import {
  setContacts,
  removeExpiredContact,
  setContactExpiration,
} from "../features/assignedContact/assignedContactSlice";
import { deleteContact } from "../features/patients/patientsSlice";
import {
  setPillAlert,
  removePillAlert,
} from "../features/pillAlerts/pillAlertsSlice";
import { setNewAlarm, removeAlarm } from "../features/sosAlarm/sosAlarmSlice";
import { deleteExpirationTime } from "../features/timeToExpire/timeToExpireSlice";
import {
  Activity,
  ActivityPillEnum,
} from "../interfaces/activity/activity.interface";
import {
  ContactType,
  Contacts,
} from "../interfaces/contacts/contacts.interface";
import { PillAlert } from "../interfaces/pillAlert/pillAlerts.interface";
import { alreadyExpired } from "../utils/timeOperations";
import { removeContact } from "../helpers/commandsContact";
import { lookForContactExpiration } from "../utils/lookForContactExpiration";
import { lookForLastActivity } from "../utils/lookForLastActivity";

export const useCheckPatients = (helperToDeleteContact = removeContact) => {
  const dispatch = useDispatch();

  const checkContactExpiration = (
    id: string,
    roomNumber: string,
    contacts?: Contacts[]
  ) => {
    lookForContactExpiration(
      id,
      roomNumber,
      contactsFound,
      expiredContactFound,
      removeExpiration,
      contacts
    );
  };

  const checkLastActivity = (roomNumber: string, lastActivity?: Activity) => {
    lookForLastActivity(
      roomNumber,
      recoverEscalationPath,
      removeEscalationPath,
      lastActivity
    );
  };

  const contactsFound = (room: string, assigned: boolean) => {
    dispatch(
      setContacts({
        room: room,
        assigned,
      })
    );
  };

  const expiredContactFound = (
    room: string,
    contactId: string,
    endDateTime: string,
    contactType: ContactType,
    patientId: string
  ) => {
    if (alreadyExpired(`${endDateTime}Z`)) {
      dispatch(deleteContact({ patientId, id: contactId }));
      dispatch(removeExpiredContact({ room, contactId }));
      removeContactFromBack(contactId, room);
      return;
    }
    dispatch(
      setContactExpiration({
        room: room,
        contactId: contactId,
        type: contactType,
        endDateTime: `${endDateTime}Z`,
      })
    );
  };

  const removeExpiration = (room: string, contactId: string) => {
    dispatch(removeExpiredContact({ room, contactId }));
    dispatch(deleteExpirationTime({ contactId }));
  };

  const removeContactFromBack = async (contactId: string, room: string) => {
    await helperToDeleteContact(contactId);
  };

  const recoverEscalationPath = (
    room: string,
    activityAlertType: ActivityPillEnum,
    passedSeconds: number,
    passedMinutes: number
  ) => {
    const pill: PillAlert = {
      room,
      activate: true,
      type: activityAlertType,
      timerStatus: "play",
      flag: true,
      defaultStartSec: passedSeconds,
      defaultStartMin: passedMinutes,
    };

    if (activityAlertType === ActivityPillEnum.sosAlert) {
      dispatch(setNewAlarm(pill));
      return;
    }
    dispatch(setPillAlert(pill));
  };

  const removeEscalationPath = (
    room: string,
    activityAlertType: ActivityPillEnum
  ) => {
    if (activityAlertType === ActivityPillEnum.sosAlert) {
      dispatch(removeAlarm({ room }));
      return;
    }
    dispatch(removePillAlert({ room }));
  };

  return { checkContactExpiration, checkLastActivity };
};
