import React from "react";

interface Props {
  action?: () => any | void;
  tooltipText?: string;
  muted?: boolean;
}
export const Audio: React.FC<Props> = ({ action, tooltipText, muted }) => {
  return (
    <div className={`tooltip`}>
      <svg
        onClick={(e) => {
          e.stopPropagation();
          action?.();
        }}
        aria-label="audio-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`audio-icon ${muted && "selected"}`}
      >
        <path
          d="M3 12.0234V20.3666H8.56214L15.5148 27.3193V5.07071L8.56214 12.0234H3ZM21.7722 16.195C21.7722 13.7338 20.3539 11.6201 18.2959 10.5911V21.785C20.3539 20.7699 21.7722 18.6562 21.7722 16.195ZM18.2959 4V6.8645C22.3145 8.06036 25.2486 11.787 25.2486 16.195C25.2486 20.603 22.3145 24.3296 18.2959 25.5255V28.39C23.8719 27.1246 28.0296 22.1465 28.0296 16.195C28.0296 10.2435 23.8719 5.26539 18.2959 4Z"
          fill="black"
        />
      </svg>
      {tooltipText && <span className="tooltiptext">{tooltipText}</span>}
    </div>
  );
};
