import { AlarmIcon } from "../../../../imgs/AlarmIcon";
import { TimerStatus } from "../../../../interfaces/pillAlert/pillAlerts.interface";

interface IconProps {
  alarmStatus: TimerStatus;
  disabled: boolean;
  loading: boolean;
  toogleAlert: () => any;
}

export const AlarmButton: React.FC<IconProps> = ({
  disabled,
  loading,
  alarmStatus,
  toogleAlert,
}) => {
  if (disabled) {
    return (
      <button
        className="btn btn-mini disabled icon transparent outlined"
        type="button"
      >
        <AlarmIcon disabled={disabled} noHover={true} />
        ALARM
      </button>
    );
  }

  return (
    <div
      className={`${loading && "loading"}`}
      onClick={() => !disabled && !loading && toogleAlert()}
    >
      <button
        className={`btn btn-mini ${
          alarmStatus === "play" ? "red" : "transparent"
        } ${disabled && "disabled"} ${
          loading && "disabled loading"
        } icon outlined`}
        type="button"
      >
        <AlarmIcon disabled={disabled || loading} noHover={true} />
        {alarmStatus === "play" ? "STOP ALARM" : "ALARM"}
      </button>
    </div>
  );
};
