import moment from "moment";
import {
  Activity,
  ActivityTypeEnum,
} from "../interfaces/activity/activity.interface";

type ActivityIssues =
  | ActivityTypeEnum.CameraOff
  | ActivityTypeEnum.CameraStart
  | ActivityTypeEnum.ContactAssigned
  | ActivityTypeEnum.ContactNotAssigned
  | ActivityTypeEnum.SpeakerOff
  | ActivityTypeEnum.SpeakerOn;

export const lookForIssuesInActivites = (
  onCameraOff: (createdAt: string) => void,
  onCameraOn: (createdAt: string) => void,
  onContactsNotAssigned: (createdAt: string) => void,
  onContactsAssigned: (createdAt: string) => void,
  onSpeakerOff: (createdAt: string) => void,
  onSpeakerOn: (createdAt: string) => void,
  activities?: Activity[]
) => {
  const hashMap: {
    [key in ActivityIssues]: {
      action: (createdAt: string) => void;
      dependsOn: ActivityIssues;
      flag: boolean;
    };
  } = {
    [ActivityTypeEnum.CameraOff]: {
      action: onCameraOff,
      dependsOn: ActivityTypeEnum.CameraStart,
      flag: false,
    },
    [ActivityTypeEnum.CameraStart]: {
      action: onCameraOn,
      dependsOn: ActivityTypeEnum.CameraOff,
      flag: false,
    },
    [ActivityTypeEnum.SpeakerOff]: {
      action: onSpeakerOff,
      dependsOn: ActivityTypeEnum.SpeakerOn,
      flag: false,
    },
    [ActivityTypeEnum.SpeakerOn]: {
      action: onSpeakerOn,
      dependsOn: ActivityTypeEnum.SpeakerOff,
      flag: false,
    },
    [ActivityTypeEnum.ContactAssigned]: {
      action: onContactsAssigned,
      dependsOn: ActivityTypeEnum.ContactNotAssigned,
      flag: false,
    },
    [ActivityTypeEnum.ContactNotAssigned]: {
      action: onContactsNotAssigned,
      dependsOn: ActivityTypeEnum.ContactAssigned,
      flag: false,
    },
  };

  if (!activities || !activities?.length) {
    return false;
  }

  let i = activities.length - 1;

  const sortedActivity = [...activities].sort((a, b) =>
    a.createdDateTime.localeCompare(b.createdDateTime)
  );

  do {
    let activityType = sortedActivity[i].activityType;

    if (
      activityType &&
      hashMap[activityType as ActivityIssues]?.flag === false
    ) {
      hashMap[activityType as ActivityIssues].action(
        sortedActivity[i].createdDateTime
      );
      hashMap[activityType as ActivityIssues].flag = true;

      let dependency = hashMap[activityType as ActivityIssues].dependsOn;

      hashMap[dependency].flag = true;
    }

    if (
      hashMap[ActivityTypeEnum.CameraOff].flag &&
      hashMap[ActivityTypeEnum.CameraStart].flag &&
      hashMap[ActivityTypeEnum.ContactAssigned].flag &&
      hashMap[ActivityTypeEnum.ContactNotAssigned].flag &&
      hashMap[ActivityTypeEnum.SpeakerOff].flag &&
      hashMap[ActivityTypeEnum.SpeakerOn].flag
    ) {
      return;
    }
    i--;
  } while (i >= 0);
};
