import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Patient } from "../interfaces/patients/patients.interface";
import { getPassedTime } from "../utils/timeOperations";
import {
  addIssue,
  removeIssue,
  resetIssues,
} from "../features/supervisors/supervisorsSlice";
import { IssueEnum } from "../enums/issues";
import moment from "moment";
import { lookForIssuesInActivites } from "../utils/lookForIssuesOnActivites";

export const useDetectIssues = (patients: Patient[]) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetIssues())
    patients.forEach((pat) => {
      analizeActivities(pat);
    });
  }, [patients]);

  const analizeActivities = useCallback((pat: Patient) => {
    lookForIssuesInActivites(
      (createdAt: string) => {
        dispatch(
          addIssue({
            createdAt,
            issueType: IssueEnum.CameraOff,
            patientId: pat.id,
            patientName: `${pat.lastName}, ${pat.firstName}`,
          })
        );
      },
      () => {
        dispatch(
          removeIssue({
            issueType: IssueEnum.CameraOff,
            patientId: pat.id,
          })
        );
      },
      (createdAt: string) => {
        dispatch(
          addIssue({
            createdAt,
            issueType: IssueEnum.ContactNotAssigned,
            patientId: pat.id,
            patientName: `${pat.lastName}, ${pat.firstName}`,
          })
        );
      },
      () => {
        dispatch(
          removeIssue({
            issueType: IssueEnum.ContactNotAssigned,
            patientId: pat.id,
          })
        );
      },
      (createdAt: string) => {
        dispatch(
          addIssue({
            createdAt,
            issueType: IssueEnum.SpeakerOff,
            patientId: pat.id,
            patientName: `${pat.lastName}, ${pat.firstName}`,
          })
        );
      },
      () => {
        removeIssue({
          issueType: IssueEnum.SpeakerOff,
          patientId: pat.id,
        });
      },
      pat.activity
    );

    if (
      pat.lastActivity?.createdDateTime &&
      getPassedTime(pat.lastActivity?.createdDateTime).minutes > 5
    ) {
      dispatch(
        addIssue({
          createdAt: moment().utc().format(),
          issueType: IssueEnum.NetworkIssues,
          patientId: pat.id,
          patientName: `${pat.lastName}, ${pat.firstName}`,
        })
      );
    } else {
      dispatch(
        removeIssue({
          issueType: IssueEnum.NetworkIssues,
          patientId: `${pat.lastName}, ${pat.firstName}`,
        })
      );
    }
  }, []);

  return { analizeActivities };
};
