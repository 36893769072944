export enum PatientQueryOperationsEnum {
  AllPatients = 1,
  PatientById = 2,
  PatientsByConnection = 3,
  PatientsDeleted = 4,
  ActivePeopleAtHospital = 5,
  ContactsByLocation = 6,
}

export enum PatientCommandOperationsEnum {
  Add = 1,
  Update = 2,
  Remove = 3,
  DeclineFallRisk = 4,
  ConfirmFallRisk = 5,
  AddActivity = 6,
  AddNote = 7,
  Undo = 8,
}

export enum ContactCommandOperationsEnum {
  Create = 1,
  Update = 2,
  Remove = 3,
}

export enum AudioOperationsEnum {
  playAlarm = 1,
  playMessage = 2,
  stop = 3,
  stopRoom = 4,
}

export enum MonitoringOperationsEnum{
  beginMonitoring,
  endMonitoring
}