import { env } from "../env";
import { ErrorTypeEnum } from "../enums/errorType";
import { CustomSocket } from "../utils/customSocket";
import logger from "../logger/logger";

let socket: CustomSocket;
export const initiateVideoSocket = (
  errorCallback: (arg: ErrorTypeEnum) => any,
  onConnection: (arg: any) => any
) => {
  if (!socket) {
    socket = new CustomSocket(
      env.REACT_APP_VIDEO_RECORD,
      "/video_recording/",
      onConnection,
      errorCallback,
      ErrorTypeEnum.VideoSocket
    );
  }
};

/* istanbul ignore next */
export const disconnectVideoSocket = () => {
  if (!socket) return;
  socket.disconnect();
};

export const sendVideoFlag = (
  command: string,
  {
    filename,
    time,
    location,
    cartId,
    room,
  }: {
    filename: string;
    room: string;
    location: string;
    cartId?: string;
    time: number;
  }
) => {
  if (!socket) return false;

  logger.log("Video recording", `${filename} - record for ${time}secs`);
  socket.emit(command, { filename, time, location, room, cartId });
};
