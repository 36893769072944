export const QuestionIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="question-icon"
    >
      <path
        d="M14.9052 20.3333C14.9052 19.4389 15.0502 18.6532 15.3403 17.9763C15.6304 17.2994 16.0051 16.695 16.4644 16.1632C16.9238 15.6313 17.4073 15.1358 17.9149 14.6764C18.4468 14.2171 18.9424 13.7699 19.4017 13.3347C19.861 12.8996 20.2357 12.4403 20.5258 11.9568C20.8159 11.4733 20.9609 10.9293 20.9609 10.325C20.9609 9.30962 20.55 8.48768 19.728 7.85914C18.9061 7.23059 17.8061 6.91632 16.4282 6.91632C15.0986 6.91632 13.9382 7.15807 12.947 7.64156C11.98 8.12506 11.1702 8.81404 10.5174 9.70851L7 7.42399C8.01534 6.04603 9.34496 4.97025 10.9888 4.19665C12.6327 3.39888 14.5788 3 16.8271 3C18.5918 3 20.139 3.26592 21.4686 3.79777C22.7982 4.30544 23.8377 5.05486 24.5872 6.04602C25.3366 7.01302 25.7113 8.19758 25.7113 9.59972C25.7113 10.5667 25.5542 11.4128 25.2399 12.1381C24.9498 12.8633 24.563 13.504 24.0795 14.06C23.6202 14.5918 23.1246 15.0995 22.5927 15.583C22.0609 16.0423 21.5532 16.5016 21.0697 16.9609C20.6104 17.4203 20.2357 17.9159 19.9456 18.4477C19.6555 18.9795 19.5105 19.6081 19.5105 20.3333H14.9052ZM17.2259 29C16.3798 29 15.6788 28.722 15.1227 28.166C14.5909 27.6099 14.325 26.9451 14.325 26.1715C14.325 25.398 14.5909 24.7452 15.1227 24.2134C15.6788 23.6574 16.3798 23.3794 17.2259 23.3794C18.0721 23.3794 18.761 23.6574 19.2929 24.2134C19.8247 24.7452 20.0907 25.398 20.0907 26.1715C20.0907 26.9451 19.8247 27.6099 19.2929 28.166C18.761 28.722 18.0721 29 17.2259 29Z"
        fill="black"
      />
    </svg>
  );
};
