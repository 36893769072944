import logger from "../logger/logger"

export const getAudioMedia = async (onSuccess:()=>void, onError:()=>void) =>{
  try{
    const audioStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
    onSuccess()
    audioStream.getTracks().forEach((track)=> track.stop())
    
  }catch(e){
    onError()
    logger.error("Error getting user media", e)
  }
}