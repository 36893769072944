import { PositionEnum } from "../enums/pillStyles";
import { ActivityPillEnum } from "../interfaces/activity/activity.interface";

export const tooltipTextPosition = (type?: ActivityPillEnum) => {
  switch (type) {
    case ActivityPillEnum.assignNurse:
      return PositionEnum.right;
    case ActivityPillEnum.contactExpiration:
      return PositionEnum.right;
    default:
      return PositionEnum.left;
  }
};
