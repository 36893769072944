export const analyzePathname = (
  pathname: string,
  idSearch?: {
    list: any[];
    idProp: string;
    labelProp: string;
  },
  ignoreIndex?: number
): { route: string; location: string }[] => {
  const routesArray = [];
  const routes = pathname.split("/");
  let location = "";

  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];

    if (!route) {
      continue;
    }

    location = `${location}/${route}`;

    if (!route.match(/[0-9]/)) {
      routesArray.push({
        route: splitRoutesWithUnderscore(route),
        location,
      });
      continue;
    }

    if (idSearch?.list && idSearch.idProp && idSearch.labelProp) {
      const { list, idProp, labelProp } = idSearch;

      const name = list.find((item) => item[idProp] === route)?.[labelProp];

      routesArray.push({
        route: name,
        location,
      });
    }
  }

  if (typeof ignoreIndex === "number") {
    routesArray.splice(ignoreIndex, 1);
  }

  return routesArray;
};

const splitRoutesWithUnderscore = (route: string): string => {
  if (route.includes("_")) {
    const array = route
      .split("_")
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`);
    return array.join(" ");
  }

  return route;
};
