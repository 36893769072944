interface Props {
  onClick: () => any;
}

export const Arrow: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="btn"
    >
      <g clipPath="url(#clip0_2105_29606)">
        <path
          d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="clip0_2105_29606">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
