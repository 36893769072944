import { ConnectionsEnum } from "../enums/connectionsEnum";
import { env } from "../env";
import { ConnectionActivity } from "../interfaces/connections/connections.interface";
import EndpointGenerator from "../utils/EndpointGenerator";
import axiosHttpClient from "../utils/axiosHttpClient";
import { setToken } from "../utils/setToken";

export const getConnections = async (includesInactive: boolean = false) => {
  const query = `includesInactive=${includesInactive}`;
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    undefined,
    undefined,
    query
  );

  const response = await axiosHttpClient.getData(endpoint);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const postConnection = async (userName: string, token: string) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint();
  const response = await axiosHttpClient.postData(
    endpoint,
    { userName },
    setToken(token)
  );

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getPatientsByConnection = async (connectionId: string) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    undefined,
    connectionId
  );
  const response = await axiosHttpClient.getData(endpoint);

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const deleteConnection = async (connectionId: string) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    undefined,
    connectionId
  );
  const response = await axiosHttpClient.deleteData(endpoint);

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const setConnectionAction = async (
  action: ConnectionsEnum,
  connectionId: string,
  startDateTime?: string
) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    action,
    connectionId
  );
  const response = await axiosHttpClient.patchData(endpoint, { startDateTime });

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const postSwithUser = async (user: string, password: string) => {
  const response = await axiosHttpClient.postData(
    env.REACT_APP_SWITCH_USER_URL,
    {
      email: user,
      password,
    }
  );
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const saveConnectionActivity = async (
  connectionId: string,
  data: ConnectionActivity
) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    ConnectionsEnum.AddActivity,
    connectionId
  );

  const response = await axiosHttpClient.patchData(endpoint, data);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getGlobalActivity = async (
  hours: 1 | 8 | 24,
  connectionId: string
) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    ConnectionsEnum.Analytics,
    connectionId,
    `hours=${hours}`
  );

  const response = await axiosHttpClient.getData(endpoint);

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const getWorkloadByConnection = async (connectionId: string) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    ConnectionsEnum.workload,
    connectionId
  );

  const response = await axiosHttpClient.getData(endpoint);

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const logoutConnection = async (connectionId: string) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    ConnectionsEnum.Logout,
    connectionId
  );

  const response = await axiosHttpClient.deleteData(endpoint);

  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};

export const addPatientToConnection = async (
  connectionId: string,
  data: { patientId: string }
) => {
  const endpoint = EndpointGenerator.generateConnectionsEndpoint(
    ConnectionsEnum.AddPatient,
    connectionId
  );

  const response = await axiosHttpClient.patchData(endpoint, data);
  if (response.isSuccessStatusCode) {
    return response.data;
  }

  throw response.status;
};
