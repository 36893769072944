export const capitalizeString = (string?: string): string => {
  if (!string) {
    return "";
  }

  string = string.toLowerCase();

  if (!string.includes(" ")) {
    return string[0].toUpperCase() + string.substring(1);
  }

  const array = string.split(" ");

  return array.reduce(
    (prev: string, curr: string) =>
      `${capitalizeString(prev)} ${capitalizeString(curr)}`
  );
};
