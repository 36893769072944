import React, { useState } from "react";
interface Props {
  text: string;
  type?: "success" | "info" | string;
  closeBtn: boolean;
}
export const Alert: React.FC<Props> = ({
  text,
  type = "success",
  closeBtn,
}) => {
  const [open, setOpen] = useState(true);
  const closeAlert = () => {
    setOpen(false);
  };
  return (
    <>
      {open && (
        <div className={`alert alert-${type}`} aria-label="alert">
          {text}
          {closeBtn && (
            <div
              className="btn btn-close"
              onClick={closeAlert}
              aria-label="close-alert"
            >
              x
            </div>
          )}
        </div>
      )}
    </>
  );
};
