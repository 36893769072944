import React, { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeSelectedPatients } from "../../../../features/selectedPatient/selectedPatient";
import { SiderbarTitle } from "../../atoms/SidebarTitle/SidebarTitle";
import { PatientInfoBody } from "../../atoms/PatientInfoBody/PatientInfoBody";
import { StickyNotes } from "../../atoms/StickyNotes/StickyNotes";
import { PatientHistory } from "../PatientHistory/PatientHistory";
import { DocumentIncident } from "../DocumentIncident/DocumentIncident";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { RootState } from "../../../../stores";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { cutString } from "../../../../utils/cutString";
import { setForm } from "../../../../features/openForms/openFormSlice";
import { CogIcon } from "../../../../imgs/CogIcon";
import {
  addPinnedPatient,
  removePinnedPatient,
} from "../../../../features/patients/patientsSlice";

interface Props {
  patient: Patient;
}

export const PatientDetailed: React.FC<Props> = ({ patient }) => {
  const dispatch = useDispatch();
  const { riskById } = useSelector((state: RootState) => state.fallRisk);
  const {
    removeInput: { status: isRemoveUserOpen },
    userInput: { status: isSwitchUserOpen },
  } = useSelector((state: RootState) => state.openForms);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const { pinnedPatientsStack } = useSelector(
    (state: RootState) => state.patients
  );

  useEffect(() => {
    if (pinnedPatientsStack.includes(patient.id)) {
      setIsPinned(true);
      return;
    }

    setIsPinned(false);
  }, [pinnedPatientsStack, patient.id]);

  const toogleMenu = () => {
    dispatch(removeSelectedPatients());
  };

  const openAssignForm = () => {};

  const handleToggleRemovalForm = () => {
    dispatch(
      setForm({
        typeInput: "removeInput",
        status: !isRemoveUserOpen,
      })
    );
  };

  const handleTooglePin = () => {
    handleCloseModal();
    if (isPinned) {
      dispatch(removePinnedPatient(patient.id));
      return;
    }

    dispatch(addPinnedPatient(patient.id));
  };

  const handleOpenModal = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOptionsOpen(true);
  };

  const handleCloseModal = () => {
    setOptionsOpen(false);
  };

  const Modals = () => {
    return (
      <div className="modals-stack">
        <div className="modal short black">
          <p className="normal-text btn" onClick={handleTooglePin}>
            {isPinned ? "Unpin patient" : "Pin patient"}
          </p>
        </div>
        <div className="modal short black">
          <p className="alert-text btn" onClick={handleToggleRemovalForm}>
            {isRemoveUserOpen ? "Cancel video removal" : "Remove video"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <SiderbarTitle
        title={`${cutString(capitalizeString(patient.lastName))}, ${cutString(
          capitalizeString(patient.firstName)
        )}`}
        riskFall={riskById?.[patient?.id]}
        close={true}
        closeAction={toogleMenu}
      />
      <div
        className={`body ${isRemoveUserOpen && "overlay-sidebar"}`}
        onClick={() => {
          optionsOpen && handleCloseModal();
          isRemoveUserOpen && handleToggleRemovalForm();
        }}
      >
        <PatientInfoBody
          patient={patient}
          assignClick={openAssignForm}
          expanded={true}
        />
        <DocumentIncident id={patient?.id} roomNumber={patient?.roomNumber} />
        <StickyNotes patient={patient} />
        <PatientHistory patient={patient} expansible={true} />
      </div>
      <div className="footer">
        <CogIcon />
        <p className={`normal-text btn`} onClick={handleOpenModal}>
          Options
        </p>
        {optionsOpen && <Modals />}
      </div>
      <audio src=""></audio>
    </>
  );
};
