import { Command } from "./Command";
import { Dashboard } from "./Dashboard";

export const Testing = () => {
  return (
    <div className="row">
      <div style={{ maxWidth: 300 }} className="testing">
        <Command />
      </div>
      <Dashboard/>
    </div>
  );
};
