export enum LoadingEnum {
  idle = 0,
  pending = 1,
  succeeded = 2,
  failed = 3,
}

export enum OrderEnum {
  desc = 1,
  asc = 0,
}

export enum EnvVarsConfig {
  on = 1,
  off = 2,
}

export enum UserTypes {
  Esitter = "user",
  Supervisor = "supervisor",
  Admin = "admin",
}
