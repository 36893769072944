import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Cams } from "../../atoms/Cams/Cams";
import { CamsMenu } from "../CamsMenu/CamsMenu";
import { useSelector, useDispatch } from "react-redux";
import {
  removeSelectedPatients,
  setSelectedPatient,
} from "../../../../features/selectedPatient/selectedPatient";
import { useVideoRecording } from "../../../../hooks/useVideoRecording";
import { AlertPill } from "../../atoms/AlertPill/AlertPill";
import { removePillAlert } from "../../../../features/pillAlerts/pillAlertsSlice";
import moment from "moment";
import { AudioAlert } from "../../atoms/AudioAlert/AudioAlert";
import { removeExpiredContact } from "../../../../features/assignedContact/assignedContactSlice";
import {
  removeAlarm,
  setNewAlarm,
} from "../../../../features/sosAlarm/sosAlarmSlice";
import {
  deleteContact,
  updatePatientByProp,
  updatePatientsActivity,
} from "../../../../features/patients/patientsSlice";
import { Patient } from "../../../../interfaces/patients/patients.interface";
import { RootState } from "../../../../stores";
import { PillAlert } from "../../../../interfaces/pillAlert/pillAlerts.interface";
import {
  FallRiskDecline,
  RiskLevel,
  RiskValue,
} from "../../../../interfaces/fallRisk/fallRisk.interface";
import {
  ContactToExpire,
  ContactType,
} from "../../../../interfaces/contacts/contacts.interface";
import {
  addActivity,
  confirmFallRisk,
  declineFallRisk,
} from "../../../../helpers/commandsPatient";
import {
  deleteExpirationTime,
  setExpirationTimer,
} from "../../../../features/timeToExpire/timeToExpireSlice";
import {
  resetAllForms,
  setForm,
} from "../../../../features/openForms/openFormSlice";
import { useCall } from "../../../../hooks/useCall";
import { CurrentAlert } from "../CurrentAlert/CurrentAlert";
import {
  ActivityPillEnum,
  ActivityTypeEnum,
  AddActivity,
} from "../../../../interfaces/activity/activity.interface";
import { setErrorById } from "../../../../features/globalErrors/globalErrorsSlice";
import { stopAudioMessage } from "../../../../helpers/audio";
import { EscPathPills } from "../../atoms/EscPathPills/EscPathPills";
import { env } from "../../../../env";
import { removeContact } from "../../../../helpers/commandsContact";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { CallLabelEnum } from "../../../../enums/callLabel";
import logger from "../../../../logger/logger";
import { PositionEnum } from "../../../../enums/pillStyles";
import {
  ContactTypeInputEnum,
  MainContactsEnum,
} from "../../../../enums/contactsEnum";
import { getPatientById } from "../../../../helpers/patients";
import {
  AiFeedback,
  OptionsToDecline,
} from "../../../../interfaces/shared/shared.interface";
import {
  setBlockedRisk,
  setRiskById,
  setZeroAvailable,
} from "../../../../features/fallRisk/fallRisk";
import { useCheckPatients } from "../../../../hooks/useCheckPatients";
import { CamOverlay } from "../../atoms/CamOverlay/CamOverlay";
import { truncateLongStr } from "../../../../utils/hyphenLongStrings";
import { usePatients } from "../../../../hooks/usePatients";
import { setNotificationsSuspension } from "../../../../features/session/sessionSlice";
import { IssueEnum } from "../../../../enums/issues";

interface Props {
  patient: Patient;
  isPatientSelected: boolean;
  helperToDeleteContact?: (id: string) => Promise<boolean | any>;
  helperToFetchPatient?: (id: string) => Promise<Patient | any>;
  callObject?: any;
  index: number;
  isInSecondaryGrid?: boolean;
}

const DECLINE_RECORDING_SECS = isNaN(env.REACT_APP_DECLINE_RECORDING_SECS)
  ? 10
  : Number(env.REACT_APP_DECLINE_RECORDING_SECS);
const AI_RECORDIND_SECS = isNaN(env.REACT_APP_AI_RECORDING_SECS)
  ? 20
  : Number(env.REACT_APP_AI_RECORDING_SECS);
const ALERTS_WAITING_MS = 5000;
const NOTIFICATIONS_WAIT = isNaN(env.REACT_APP_NOTIFICATIONS_WAIT)
  ? 5000
  : Number(env.REACT_APP_NOTIFICATIONS_WAIT);

const initialAiFeedback: AiFeedback = {
  confirmation: false,
  declination: false,
  declineMiniForm: false,
  declineMsg: "",
};

const responseToRiskValue: { [str: OptionsToDecline | string]: RiskValue } = {
  "Patient is lying in bed": 0,
  "Patient is getting out of bed": 1,
  "Patient is out of bed": 2,
};

export const CamsCard: React.FC<Props> = ({
  patient,
  isPatientSelected,
  index,
  helperToDeleteContact = removeContact,
  helperToFetchPatient = getPatientById,
  isInSecondaryGrid,
}) => {
  const dispatch = useDispatch();
  const {
    token,
    session: {
      sub: [username, userid],
    },
    notifications: { areActive, areSuspended },
  } = useSelector((state: RootState) => state.sessionInfo);
  const saveAudioIntervention = () => {
    logger.log(`Save audio intervention`);
    const data: AddActivity = {
      id: patient.id,
      user: userid,
      activity: '{"isPillOpen":false}',
      activityType: ActivityTypeEnum.AudioIntervention,
      clientDateTime: moment().utc().format(),
    };
    addActivityAndFetch(data);
  };

  const addActivityAndFetch = useCallback(
    async (data: AddActivity, updatePatient: boolean = true) => {
      try {
        logger.log(
          `Add Activity and fetch for patient ${data.id}- activity type: ${
            ActivityTypeEnum[data.activityType]
          }`
        );
        await addActivity(data, token);
        if (updatePatient) {
          const resById = await helperToFetchPatient(patient.id);
          resById && dispatch(updatePatientsActivity(resById));
        }
      } catch (e) {
        dispatch(
          setErrorById({
            id: patient.id,
            errorMsg: ErrorTypeEnum.NetworkError,
          })
        );
      }
    },
    [dispatch]
  );

  const { getDeviceInfoAndUpdatePatient } = usePatients();
  const { checkContactExpiration, checkLastActivity } = useCheckPatients();
  const { handleCall, hangOn, callError, callLabel, callEnded } = useCall(
    saveAudioIntervention
  );
  const {
    addedPatients,
    pinnedPatientsStack,
    patientStatusList,
    privacyStatus,
  } = useSelector((state: RootState) => state.patients);
  const { riskById } = useSelector((state: RootState) => state.fallRisk);
  const { pillsByPatient } = useSelector(
    (state: RootState) => state.pillAlerts
  );
  const { contactsByPatient } = useSelector(
    (state: RootState) => state.assignedContact
  );
  const { issuesList } = useSelector((state: RootState) => state.supervisors);

  const [riskLevel, setRiskLevel] = useState<RiskLevel>("normal");
  const [firstRiskChange, setFirstRiskChange] = useState(true);
  const [mutedStatus, setMutedStatus] = useState<boolean>(true);
  const { startVideoRecord } = useVideoRecording();
  const [riskByRoomForClass, setRiskByRoomForClass] = useState<RiskValue>(0);
  const [videoLoad, setVideoLoad] = useState<boolean>(true);
  const [errorVideoLabel, setErrorVideoLabel] = useState("Loading...");
  const [noContact, setNoContact] = useState<boolean>(false);
  const [contactsToExpire, setContactsToExpire] = useState<ContactToExpire[]>(
    []
  );
  const { alarms } = useSelector((state: RootState) => state.sosAlarm);
  const [sosAlarm, setSosAlarm] = useState<PillAlert | undefined>({
    room: "",
    activate: false,
  });
  const [playAudioMsg, setPlayAudioMsg] = useState(false);
  const [aiFeedback, setAiFeedback] = useState<AiFeedback>(initialAiFeedback);
  const [callLoading, setCallLoading] = useState(false);
  const [microMuted, setMicroMuted] = useState(true);
  const videoUrl = useMemo(
    () =>
      process.env.NODE_ENV === "production"
        ? `${env.REACT_APP_RTSP_SERVER}/${
            patient.cartId || `${patient.location}-${patient.roomNumber}`
          }`
        : env.REACT_APP_WHEP_DEV_VIDEO,
    [patient.cartId, patient.location, patient.roomNumber]
  );
  const camsCardRef = useRef<null | HTMLDivElement>(null);
  const statusLabel = useMemo(
    () =>
      patientStatusList.find((status) => status.id === patient.patientStatus)
        ?.value || "",
    [patientStatusList, patient.patientStatus]
  );
  const [camOverlay, setCamOverlay] = useState(false);
  const audio = useMemo(() => new Audio(), []);

  useEffect(() => {
    dispatch(setRiskById({ id: patient.id, fallRisk: patient.fallRisk || 0 }));
  }, []);

  useEffect(() => {
    checkContactExpiration(patient.id, patient.roomNumber, patient.contacts);
    saveActivityForContactsIssue(patient);
  }, [patient.roomNumber, patient.contacts]);

  const saveActivityForContactsIssue = useCallback(
    (pat: Patient) => {
      const issuesByPatientId = issuesList.filter(
        (issue) => issue.patientId === pat.id
      );
      const hasNoContact = issuesByPatientId.find(
        (issue) => issue.issueType === IssueEnum.ContactNotAssigned
      );

      if (
        pat.contacts?.find(
          (con) => MainContactsEnum[con.type as keyof typeof MainContactsEnum]
        ) &&
        !hasNoContact
      ) {
        const data: AddActivity = {
          id: pat.id,
          user: userid,
          activity: ActivityTypeEnum[ActivityTypeEnum.ContactAssigned],
          activityType: ActivityTypeEnum.ContactAssigned,
          clientDateTime: moment().utc().format(),
        };
        addActivityAndFetch(data, true);
        return;
      }

      if (hasNoContact) {
        return;
      }

      const data: AddActivity = {
        id: pat.id,
        user: userid,
        activity: ActivityTypeEnum[ActivityTypeEnum.ContactNotAssigned],
        activityType: ActivityTypeEnum.ContactNotAssigned,
        clientDateTime: moment().utc().format(),
      };
      addActivityAndFetch(data, true);
    },
    [addActivityAndFetch, issuesList]
  );

  useEffect(() => {
    if (!patient.lastActivity) {
      return;
    }
    checkLastActivity(patient.roomNumber, patient.lastActivity);
  }, [patient.roomNumber, patient.lastActivity]);

  useEffect(() => {
    if (callLabel === CallLabelEnum.HangOn) {
      setMutedStatus(true);
    }
  }, [callLabel]);

  useEffect(() => {
    if (videoLoad) {
      setAiFeedback({ ...aiFeedback, declineMiniForm: false });
    }
  }, [videoLoad]);

  useEffect(() => {
    setSosAlarm(
      alarms.find((alarm: PillAlert) => alarm.room === patient.roomNumber)
    );
  }, [alarms]);

  useEffect(() => {
    setNoContact(!contactsByPatient[patient.roomNumber]?.assigned);
    setContactsToExpire(
      contactsByPatient[patient.roomNumber]?.contactsToExpire || []
    );
  }, [contactsByPatient[patient.roomNumber]]);

  useEffect(() => {
    saveSpeakerStatus(patient);
  }, [patient.deviceInfo?.speakerStatus]);

  const saveSpeakerStatus = useCallback(
    async (pat: Patient) => {
      const MINS_TO_CHECK_STATUS = 2;
      let interval: number | undefined;

      const issuesByPatientId = issuesList.filter(
        (issue) => issue.patientId === pat.id
      );
      const hasSpeakerOff = issuesByPatientId.find(
        (issue) => issue.issueType === IssueEnum.SpeakerOff
      );

      const data: AddActivity = {
        id: pat.id,
        user: userid,
        activity: ActivityTypeEnum[ActivityTypeEnum.SpeakerOff],
        activityType: ActivityTypeEnum.SpeakerOff,
        clientDateTime: moment().utc().format(),
      };
      if (patient.deviceInfo?.speakerStatus === "offline" && !hasSpeakerOff) {
        await addActivity(data, token);
        interval = window.setTimeout(() => {
          getDeviceInfoAndUpdatePatient(pat);
        }, MINS_TO_CHECK_STATUS * 60 * 1000);
        return;
      }

      if (hasSpeakerOff) {
        return;
      }

      interval && clearTimeout(interval);

      data.activityType = ActivityTypeEnum.SpeakerOn;
      data.activity = ActivityTypeEnum[ActivityTypeEnum.SpeakerOn];
      await addActivity(data, token);
    },
    [addActivity, issuesList]
  );

  useEffect(() => {
    if (isNaN(riskById?.[patient.id])) {
      return;
    }
    const risk = riskById?.[patient.id];
    analizeRisk(risk as RiskValue);
  }, [riskById?.[patient.id]]);

  const saveVideo = useCallback(
    (risk: RiskValue) => {
      if (risk === 1 || risk === 2) {
        let issue = risk === 1 ? "yellow-risk" : "red-risk";
        startVideoRecord({
          admissionId: patient.admissionId,
          time: AI_RECORDIND_SECS,
          issue,
          room: patient.roomNumber,
          location: patient.location,
        });
      }
    },
    [startVideoRecord]
  );

  const analizeRisk = useCallback(
    (risk: RiskValue) => {
      setRiskByRoomForClass(risk);
      logger.log(`[AI-Change] Id:${patient.id}, risk: ${riskById[patient.id]}`);
      !firstRiskChange && saveVideo(risk);
      setFirstRiskChange(false);

      switch (risk) {
        case 0:
          setRiskLevel("normal");
          break;
        case 1:
          setRiskLevel("warning");
          break;
        case 2:
          setRiskLevel("danger");
          break;
        default:
          setRiskLevel("normal");
          break;
      }

      dispatch(
        updatePatientByProp({
          data: { id: patient.id, fallRisk: risk },
          prop: "fallRisk",
        })
      );

      if (!areActive || areSuspended) {
        return;
      }

      if (riskById[patient.id] === 1) {
        audio.pause();
        const path = require("../../../../audios/yellowAlarm.mp3");
        audio.src = path;
        audio.play();
        dispatch(setNotificationsSuspension(true));
      }

      if (riskById[patient.id] === 2) {
        audio.pause();
        const path = require("../../../../audios/redAlarmOnce.mp3");
        audio.src = path;
        audio.play();
        dispatch(setNotificationsSuspension(true));
      }

      setTimeout(() => {
        dispatch(setNotificationsSuspension(false));
      }, NOTIFICATIONS_WAIT);
    },
    [dispatch, saveVideo]
  );

  useEffect(() => {
    if (isPatientSelected) {
      dispatch(resetAllForms());
      setMutedStatus(false);
      return;
    }

    hangOn();
    setAiFeedback({ ...aiFeedback, declineMiniForm: false, declineMsg: "" });
    setMicroMuted(true);
    setMutedStatus(true);
  }, [isPatientSelected]);

  useEffect(() => {
    if (pinnedPatientsStack.includes(patient.id) && isInSecondaryGrid) {
      setCamOverlay(true);
      return;
    }

    setCamOverlay(false);
  }, [pinnedPatientsStack]);

  useEffect(() => {
    let interval: any;
    if (aiFeedback.confirmation) {
      let now = moment.now();
      sendUpdateRiskFall("confirm", riskByRoomForClass, now);
      interval = setTimeout(() => {
        setAiFeedback?.({ ...aiFeedback, confirmation: false });
        dispatch(removePillAlert({ room: patient.roomNumber }));
      }, ALERTS_WAITING_MS);
    } else {
      clearTimeout(interval);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [aiFeedback.confirmation]);

  useEffect(() => {
    let interval: any;
    const valueToBlock = riskByRoomForClass;
    if (aiFeedback.declination) {
      logger.log(`Declination ai`);
      startVideoRecord({
        admissionId: patient.admissionId,
        time: DECLINE_RECORDING_SECS,
        issue: `decline-${riskByRoomForClass}-${aiFeedback.declineMsg}`,
        room: patient.roomNumber,
        location: patient.location,
        cartId: patient.cartId,
      });
      const now = moment.now();
      sendUpdateRiskFall(
        "decline",
        riskByRoomForClass,
        now,
        aiFeedback.declineMsg
      );

      interval = setTimeout(() => {
        setAiFeedback?.({ ...aiFeedback, declination: false });
        dispatch(setBlockedRisk({ id: patient.id, fallRisk: valueToBlock }));
        if (!responseToRiskValue[aiFeedback.declineMsg]) {
          dispatch(setZeroAvailable({ id: patient.id }));
        }
        dispatch(
          setRiskById({
            id: patient.id,
            fallRisk: responseToRiskValue[aiFeedback.declineMsg],
          })
        );
        //remove pill alert
        dispatch(removePillAlert({ room: patient.roomNumber }));
      }, ALERTS_WAITING_MS);
    } else {
      clearTimeout(interval);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [aiFeedback.declination]);

  const sendUpdateRiskFall = useCallback(
    async (
      interactionType: "decline" | "confirm",
      riskValue: RiskValue = 0,
      now: number,
      message?: string
    ) => {
      if (interactionType === "decline") {
        const data: FallRiskDecline = {
          id: patient.id,
          currentFallRisk: (riskValue as RiskValue) || (0 as RiskValue),
          newFallRisk: message ? responseToRiskValue[message] : riskValue,
          uiTimeStamp: now.toString(),
        };
        await declineFallRisk(data, token);
        return;
      }
      await confirmFallRisk(
        {
          id: patient.id,
          fallRisk: riskValue,
          uiTimeStamp: now.toString(),
        },
        token
      );
    },
    [declineFallRisk, confirmFallRisk]
  );

  const handlePlayerReady = useCallback(() => {
    setVideoLoad(false);
    setErrorVideoLabel("Loading...");
    const data: AddActivity = {
      id: patient.id,
      user: userid,
      activity: ActivityTypeEnum[ActivityTypeEnum.CameraStart],
      activityType: ActivityTypeEnum.CameraStart,
      clientDateTime: moment().utc().format(),
    };
    addActivityAndFetch?.(data, false);
  }, [addActivityAndFetch]);

  const handlePlayerDisconnection = useCallback(
    (erroLabel: string) => {
      setErrorVideoLabel(erroLabel); //No video available
      setVideoLoad(true);
      const data: AddActivity = {
        id: patient.id,
        user: userid,
        activity: ActivityTypeEnum[ActivityTypeEnum.CameraOff],
        activityType: ActivityTypeEnum.CameraOff,
        clientDateTime: moment().utc().format(),
      };
      addActivityAndFetch?.(data, false);
    },
    [addActivityAndFetch]
  );

  const toogleMuted = useCallback(
    (forcedMute: boolean) => {
      setMutedStatus(forcedMute);
    },
    [setMutedStatus]
  );

  const openMenu = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      dispatch(setSelectedPatient(patient.id));
    },
    [dispatch]
  );

  const endEscalationPath = useCallback(
    async (type?: ActivityPillEnum, seconds?: number) => {
      if (!type) {
        return;
      }
      const data: AddActivity = {
        id: patient.id,
        user: userid,
        activity: '{"isPillOpen": false}',
        activityType: type as unknown as ActivityTypeEnum,
        clientDateTime: moment().utc().format(),
      };
      addActivityAndFetch?.(data);
      dispatch(removePillAlert({ room: patient.roomNumber }));
      if (type === ActivityPillEnum.callEnded && seconds) {
        data.activity = seconds?.toString();
        data.activityType = ActivityTypeEnum.StaffArrived;
        addActivityAndFetch(data);
      }
    },
    [addActivityAndFetch]
  );

  const openEditionForm = useCallback(
    (contactType: ContactType) => {
      dispatch(setSelectedPatient(patient.id));
      dispatch(resetAllForms());
      dispatch(
        setForm({
          typeInput: ContactTypeInputEnum[contactType],
          status: true,
          name: "",
          phone: "",
        })
      );
    },
    [dispatch]
  );

  const handleRemoveAudio = useCallback(async () => {
    try {
      logger.log(`Stopping audio, location:${patient.location}`);
      await stopAudioMessage(
        patient.location,
        patient.roomNumber,
        patient.cartId
      );
    } catch (e) {
      logger.error("Error stoping audio", e);
    }
  }, [stopAudioMessage]);

  const handleRemoveAlert = useCallback(() => {
    dispatch(
      setNewAlarm({
        room: patient.roomNumber,
        activate: true,
        type: ActivityPillEnum.sosAlert,
        timerStatus: "stop",
      })
    );
    const data: AddActivity = {
      id: patient.id,
      user: userid,
      activity: ActivityTypeEnum[ActivityTypeEnum.AlarmOff],
      activityType: ActivityTypeEnum.AlarmOff,
      clientDateTime: moment().utc().format(),
    };
    addActivityAndFetch?.(data, false);
    endEscalationPath(ActivityPillEnum.sosAlert);
    handleRemoveAudio();
    dispatch(removeAlarm({ room: patient.roomNumber }));
  }, [dispatch, addActivityAndFetch, endEscalationPath, handleRemoveAudio]);

  const handleSaveExpirationTime = useCallback(
    (timeToExpire: string, contactId: string) => {
      dispatch(setExpirationTimer({ timeToExpire, contactId }));
    },
    [dispatch]
  );

  const expiredContact = useCallback(
    async (contactId: string) => {
      logger.log(`Timer alert expiration `);
      dispatch(
        removeExpiredContact({
          room: patient.roomNumber,
          contactId,
        })
      );
      dispatch(deleteContact({ patientId: patient.id, id: contactId }));
      dispatch(deleteExpirationTime({ contactId }));
      await helperToDeleteContact(contactId);
    },
    [dispatch]
  );

  const undoFeedbackAI = useCallback(() => {
    setAiFeedback({
      ...aiFeedback,
      confirmation: false,
      declineMiniForm: false,
      declination: false,
    });
  }, [setAiFeedback, aiFeedback]);

  useEffect(() => {
    if (!camOverlay && isPatientSelected && isInSecondaryGrid) {
      dispatch(removeSelectedPatients());
    }
  }, [camOverlay]);

  return (
    <div
      className={`cams-card ${riskLevel}`}
      aria-label={`cams-card ${riskLevel}`}
      onClick={openMenu}
      ref={camsCardRef}
    >
      {camOverlay && (
        <CamOverlay
          patientId={patient.id}
          isPatientSelected={isPatientSelected}
          patientName={truncateLongStr(
            `${patient.firstName} ${patient.lastName}`
          ).join(" ")}
        />
      )}
      <Cams
        url={videoUrl}
        muted={mutedStatus || camOverlay}
        onReady={handlePlayerReady}
        onError={handlePlayerDisconnection}
        videoLoad={videoLoad}
        errorVideoLabel={errorVideoLabel}
        isBlur={patient.patientStatus === privacyStatus?.id}
      />

      {isPatientSelected && !camOverlay && (
        <CamsMenu
          patient={patient}
          mutedStatus={mutedStatus}
          riskValue={riskByRoomForClass}
          videoLoading={videoLoad}
          toogleMuted={toogleMuted}
          aiFeedback={aiFeedback}
          setAiFeedback={setAiFeedback}
          playAudioMsg={playAudioMsg}
          setPlayAudioMsg={setPlayAudioMsg}
          callLoading={callLoading}
          setCallLoading={setCallLoading}
          undoFeedbackAI={undoFeedbackAI}
          microMuted={microMuted}
          setMicroMuted={setMicroMuted}
          handleRemoveAudio={handleRemoveAudio}
          hangOn={hangOn}
          handleCall={handleCall}
          callError={callError}
          callLabel={callLabel}
          handleRemoveAlert={handleRemoveAlert}
          addActivityAndFetch={addActivityAndFetch}
          statusLabel={statusLabel}
        />
      )}

      {addedPatients.find((patientId) => patientId === patient.id) &&
        !isPatientSelected && <AudioAlert text={"New Patient Added"} />}

      {!sosAlarm?.activate &&
        pillsByPatient[patient.roomNumber] &&
        pillsByPatient[patient.roomNumber].activate && (
          <EscPathPills
            room={patient.roomNumber}
            firstName={patient.firstName}
            lastName={patient.lastName}
            pillAlertByRoom={pillsByPatient[patient.roomNumber]}
            endEscalationPath={endEscalationPath}
          />
        )}

      <CurrentAlert
        microMuted={microMuted}
        callLabel={callLabel}
        callLoading={callLoading}
        playAudioMsg={playAudioMsg}
        aiFeedback={aiFeedback}
        callEnded={callEnded}
        undoFeedbackAI={undoFeedbackAI}
      />

      {noContact && (
        <AlertPill
          {...contactsByPatient[patient.roomNumber]}
          onClick={openMenu}
          type={ActivityPillEnum.assignNurse}
          timerStatus="stop"
          position={PositionEnum.left}
          color="yellow"
          tooltipText={"Care team member not assigned"}
          activate={true}
        />
      )}

      {contactsToExpire &&
        [...contactsToExpire]
          .sort((a, b) => (a.endDateTime < b.endDateTime ? 1 : -1))
          .map((contact: ContactToExpire) => (
            <AlertPill
              {...contactsByPatient[patient.roomNumber]}
              invisible={
                contact.type === "ChargeNurse" ||
                contact.type === "UnitDirector"
              }
              type={ActivityPillEnum.contactExpiration}
              position={PositionEnum.left}
              color="yellow"
              tooltipText={`Care team member expires in`}
              activate={true}
              onClick={() => contact.type && openEditionForm(contact.type)}
              endDateTime={contact.endDateTime}
              onExpiration={() => {
                expiredContact(contact.id as string);
              }}
              saveExpirationTime={(time) => {
                handleSaveExpirationTime(time, contact.id as string);
              }}
              key={contact.id}
            />
          ))}

      {sosAlarm?.activate && (
        <AlertPill
          {...sosAlarm}
          type={ActivityPillEnum.sosAlert}
          color="red"
          actionOnEnd={handleRemoveAlert}
        />
      )}
      {statusLabel !==
        patientStatusList.find((status) => status.isDefault)?.value && (
        <div className="pill-alert info floating top centered large">
          {statusLabel}
        </div>
      )}
    </div>
  );
};
