import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";
import routes from "../../../../routes";
import { Arrow } from "../../../../imgs/Arrow";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import {
  ContactsByLocation,
  LocationInfo,
} from "../../../../interfaces/patients/patients.interface";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { TableMetada } from "../../../../interfaces/shared/shared.interface";
import { ContactTypeEnum } from "../../../../enums/contactsEnum";
import { Plus } from "../../../../imgs/Plus";
import { ContactType } from "../../../../interfaces/contacts/contacts.interface";

const metadata: TableMetada = {
  columns: [
    {
      title: "Role",
      compute: (contact: ContactsByLocation) => ContactTypeEnum[contact.role],
    },
    {
      title: "Name",
      compute: (contact: ContactsByLocation) => contact.name,
    },
    {
      title: "Phone Number",
      compute: (contact: ContactsByLocation) => contact.phoneNumber,
    },
    {
      title: "Unit",
      compute: (contact: ContactsByLocation) => contact.unitName,
    },
  ],
};

const rolesToDisplay: ContactType[] = ["UnitDirector", "ChargeNurse"];

export const SuperContactsDetailed = () => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const children = useOutlet();
  const { contactsByLocation, locations } = useSelector(
    (state: RootState) => state.supervisors
  );
  const selectedLocation = useMemo(
    () => locations.find((location) => location.id === locationId),
    [locationId, contactsByLocation]
  );

  if (children) {
    return <Outlet />;
  }

  if (!locationId) {
    return (
      <div className="info">
        <h3>No Id detected.</h3>
      </div>
    );
  }

  if (!selectedLocation) {
    return (
      <div className="info">
        <h3>No hospital information.</h3>
      </div>
    );
  }

  return (
    <>
      <div className="row align-center" style={{ marginBottom: "40px" }}>
        <Arrow
          onClick={() =>
            navigate(
              `${routes.supervisor.main}/${routes.supervisor.nested[0].nested[0].main}`
            )
          }
        />
        <h2 style={{ marginLeft: "20px", marginRight: "20px" }}>
          {capitalizeString(selectedLocation.name || "Hospital")}
        </h2>
        <button
          className="btn btn-xsmall opaque-input outlined icon"
          type="button"
          style={{ margin: 0 }}
          onClick={() => {
            navigate(
              `${routes.supervisor.main}/${routes.supervisor.nested[0].nested[0].main}/${locationId}/${routes.supervisor.nested[0].nested[0].nested[0].main}`
            );
          }}
        >
          <Plus />
          ADD CARE TEAM MEMBER
        </button>
      </div>
      {!contactsByLocation[locationId] ||
      contactsByLocation[locationId].length === 0 ? (
        <div className="info">
          <h3 style={{ fontSize: 24 }}>No Care Team Members assigned yet.</h3>
          <h4 style={{ fontSize: 20 }}>
            When contacts are added, you will see their information here.
          </h4>
        </div>
      ) : (
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {metadata.columns.map((column) => (
                  <th
                    key={`th-label-${column.title}`}
                    className={column.className}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {contactsByLocation[locationId]
                .filter((con) => rolesToDisplay.includes(con.role))
                .map((con) => (
                  <tr key={`tr-${con.id}`}>
                    {metadata.columns.map((column) => (
                      <td
                        key={`td-${con.id}-${column.title}`}
                        className={`${column.className} ${
                          column.title === "Status" &&
                          `pill ${column
                            .compute(con)
                            ?.toString()
                            .toLocaleLowerCase()}`
                        }`}
                      >
                        <p>{column.compute(con)}</p>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
