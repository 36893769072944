import React, { useState } from "react";
import { CloseIcon } from "../../../../imgs/CloseIcon";
import { InfoIcon } from "../../../../imgs/InfoIcon";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { ErrorTypeEnum } from "../../../../enums/errorType";
import { LyingIcon } from "../../../../imgs/LyingIcon";
import { GettingOutIcon } from "../../../../imgs/GettingOutIcon";
import { OutOfBedIcon } from "../../../../imgs/OutOfBedIcon";

interface Props {
  title?: string;
  options?: string[];
  submitAction?: (arg: any | undefined) => any | void;
  undoAction?: () => any | void;
  type?: "survey" | "message" | "input" | "illustratedSurvey";
  message?: string;
  messageSpan?: string;
  onAccept?: () => void;
  error?: ErrorTypeEnum;
  loading?: boolean;
}
export const MiniForm: React.FC<Props> = ({
  title = "Why was the fall risk alert wrong?",
  options,
  submitAction,
  undoAction,
  type = "survey",
  message,
  messageSpan,
  onAccept,
  error,
  loading,
}) => {
  const [response, setResponse] = useState("");

  const handleChange = (e: { target: { value: string } }) => {
    setResponse(e.target.value);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (response) {
      submitAction?.(response);
    }
  };

  const Buttons = () => {
    return (
      <div className="row btn-group center">
        <button
          className="btn-small transparent outlined"
          style={{ padding: "4px 12px", minWidth: "0px" }}
          onClick={() => !loading && undoAction?.()}
          aria-label="cancel-mini-form"
        >
          CANCEL
        </button>
        <button
          type="submit"
          className={`btn-small light ${loading && "loading"}`}
          style={{ padding: "4px 12px", minWidth: "0px" }}
          aria-label="mini-form-submit"
          onClick={() => !loading && onAccept?.()}
        >
          SUBMIT
        </button>
      </div>
    );
  };

  return (
    <div className="mini-form" aria-label="mini-form">
      <h3 className="mini-form-title">{title}</h3>
      {
        {
          illustratedSurvey: (
            <>
              <form onSubmit={handleSubmit} aria-label="form">
                <div className="row center options-form " style={{ gap: 8 }}>
                  {options?.map((option) => (
                    <div
                      className={`image-option-bx${
                        option === response ? " active" : ""
                      }`}
                      key={`image-option-bx-${option}-${type}`}
                      onClick={() => setResponse(option)}
                      style={{margin: "4px 0px"}}
                    >
                      {
                        {
                          "Lying in bed": <LyingIcon color="normal" />,
                          "Getting out": <GettingOutIcon color="warning" />,
                          "Out of bed": <OutOfBedIcon color="danger" />,
                        }[option]
                      }
                    </div>
                  ))}
                </div>
                <Buttons />
              </form>
            </>
          ),
          survey: (
            <>
              <form onSubmit={handleSubmit} aria-label="form">
                <div className="row center columns wrap options-form">
                  {options?.map((option) => (
                    <div
                      className="form-control radio"
                      key={`form-control-${option}`}
                    >
                      <input
                        type="radio"
                        name={`response`}
                        id={`response-${option}`}
                        value={option}
                        onChange={handleChange}
                        required
                        aria-label={`response-${option}`}
                      />
                      <label htmlFor={`response-${option}`}>{option}</label>
                    </div>
                  ))}
                </div>
                <CloseIcon action={undoAction} />
                <Buttons />
              </form>
            </>
          ),
          message: (
            <>
              <div className="message-modal">
                <p>{message}</p>
                <CloseIcon action={undoAction} />
                <Buttons />
              </div>
            </>
          ),
          input: (
            <>
              <form onSubmit={handleSubmit}>
                <div className="row my-1 column">
                  <div className="form-control single-input">
                    <label htmlFor="remove-input">
                      {`${message} `}
                      <span>{messageSpan}</span>
                    </label>
                    <input
                      type="text"
                      name="remove-input"
                      id="remove-input"
                      pattern="([A-Za-z]{6})"
                      value={response}
                      onChange={(e) => !loading && handleChange(e)}
                      required
                      aria-label="remove-input"
                    />
                    {error && (
                      <ErrorMsg
                        errorMsg={error}
                        filled={false}
                        Icon={InfoIcon}
                        closeBtn={false}
                        padding={"none"}
                        position={"inline"}
                      />
                    )}
                  </div>
                </div>
                <CloseIcon action={undoAction} />
                <Buttons />
              </form>
            </>
          ),
        }[type]
      }
    </div>
  );
};
