import { Check } from "../../../../imgs/Check";
import { ActivityPillEnum } from "../../../../interfaces/activity/activity.interface";
import { tooltipTextPosition } from "../../../../utils/calculateTooltipPosition";
import { TooltipText } from "../TooltipText/TooltipText";

interface Props {
  type: ActivityPillEnum.sosAlert | ActivityPillEnum.dispatch;
  isChecked: boolean;
  Timer: React.FC;
  tooltipText?: string;
}

export const LabelOnPill: React.FC<Props> = ({
  isChecked,
  type,
  Timer,
  tooltipText,
}) => {
  if (isChecked) {
    return <Check noHover={true} />;
  }

  if (type === ActivityPillEnum.dispatch) {
    return (
      <>
        <Timer />
        <TooltipText text={tooltipText} position={tooltipTextPosition(type)} />
      </>
    );
  }

  return (
    <>
      <h4>STOP EMERGENCY ALARM</h4>
      <>
        <Timer />
      </>
    </>
  );
};
