import { Patient } from "../interfaces/patients/patients.interface";

export const updateListOfPatients = (
  incomingPatients: Patient[],
  currPatientsCopy: Patient[],
  onPatAlreadySaved: (newPatient: Patient) => any,
  onPatToAdd: (newPatient: Patient) => any,
  onPatToDelete: (newPatient: Patient) => any
) => {
  const patientsHash: { [id: string]: boolean } = {};

  for (const patient of currPatientsCopy) {
    patientsHash[patient.id] = true;
  }

  for (const newPatient of incomingPatients) {
    if (patientsHash[newPatient.id]) {
      onPatAlreadySaved(newPatient);
      patientsHash[newPatient.id] = false;
      continue;
    }
    onPatToAdd(newPatient);
  }

  for (const patient of currPatientsCopy) {
    if (patientsHash[patient.id]) {
      onPatToDelete(patient);
    }
  }
};
