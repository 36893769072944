import React from "react";

interface Props {
  type?: string;
  text: string;
  action?: () => any | void;
  undoAction?: () => any | void;
  flatBorders?: boolean;
}

export const AudioAlert: React.FC<Props> = ({
  type = "success",
  text,
  action,
  undoAction,
  flatBorders = false,
}) => {
  return (
    <div className={`audio-alert ${type} ${flatBorders && "flat-borders"}`}>
      <p>{text}</p>
      {undoAction && (
        <span className="btn" onClick={undoAction}>
          UNDO
        </span>
      )}
    </div>
  );
};
