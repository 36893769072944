import "./styles/main.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "./components/pages/Dashboard";
import { Command } from "./components/pages/Command";
import { Testing } from "./components/pages/Testing";
import { Provider } from "react-redux";
import store from "./stores";
import { Login } from "./components/pages/Login";
import { Supervisor } from "./components/pages/Supervisor";
import { ESitters } from "./components/pages/ESitters";
import routes from "./routes";
import { Contacts } from "./components/pages/Contacts";
import { ESitterList } from "./components/UI/organisms/ESitterList/ESitterList";
import { ESitterDetailed } from "./components/UI/molecules/ESitterDetailed/ESitterDetailed";
import { SuperContactsList } from "./components/UI/organisms/SuperContactsList/SuperContactsList";
import { SuperContactsDetailed } from "./components/UI/molecules/SuperContactsDetailed/SuperContactsDetailed";
import { SuperAddContact } from "./components/UI/molecules/SuperAddContact/SuperAddContact";
import { RemovedPatients } from "./components/pages/RemovedPatients";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">
          <Routes>
            <Route index element={<Dashboard />}></Route>
            <Route path={routes.commands.main} element={<Command />}></Route>
            <Route path={routes.testing.main} element={<Testing />}></Route>
            <Route path={routes.login.main} element={<Login />}></Route>
            <Route path={routes.supervisor.parent} element={<Supervisor />}>
              <Route index element={<Navigate to={routes.supervisor.main} />} />
              <Route
                path={routes.supervisor.nested[0].main}
                element={<ESitters />}
              >
                <Route
                  path={routes.supervisor.nested[0].nested[0].main}
                  element={<Contacts />}
                >
                  <Route index element={<SuperContactsList />} />
                  <Route path=":locationId" element={<SuperContactsDetailed />}>
                    <Route
                      path={
                        routes.supervisor.nested[0].nested[0].nested[0].main
                      }
                      element={<SuperAddContact />}
                    />
                  </Route>
                </Route>
                <Route
                  path={routes.supervisor.nested[0].nested[1].main}
                  element={<RemovedPatients />}
                />
                <Route path=":connectionId" element={<ESitterDetailed />} />
              </Route>
              <Route
                path="*"
                element={<Navigate to={routes.supervisor.main} />}
              />
            </Route>
            <Route
              path="/*"
              element={<Navigate to={`${routes.login.main}?token=no_token`} />}
            />
          </Routes>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
