export const Plus = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.67 17.8114H17.8114V26.67H14.8586V17.8114H6V14.8586H14.8586V6H17.8114V14.8586H26.67V17.8114Z"
        fill="black"
      />
    </svg>
  );
};
